import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { getUsers } from '../../stores/users/actions';
import {
  getCandidatesWithFilters,
  linkCandidateWithPost,
  unlinkCandidateWithPost,
  unloadCandidates,
} from '../../stores/candidates/actions';
import { defaultStyles } from '../../themes/theme';
import CustomTextField from '../../components/common/form/CustomTextField';
import CustomSelectField from '../../components/common/form/CustomSelectField';
import { getStaticJobs } from '../../stores/statics/actions';
import ApiService from '../../services/ApiService';
import { CANDIDATE_VIEW } from '../../constants/routes';
import FilterIcon from '../../components/icons/FilterIcon';

const INITIAL_STATE = {
  datas: [],
  experienceYears: 0,
  diploma: '',
  global: '',
  loadedFromPost: false,
  skillsDatas: [],
  softSkillsDatas: [],
  skillsLoading: false,
  softSkillsLoading: false,
};

const columnData = [
  {
    id: 'firstname',
    label: 'Prénom',
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'lastname',
    label: 'Nom',
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'diploma',
    label: 'Diplôme',
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'experience_years',
    label: 'Années d\'expérience',
    numeric: true,
    align: 'left',
    disablePadding: false,
  },
  {
    id: 'scoring',
    label: 'Score',
    numeric: true,
    align: 'left',
    disablePadding: false,
  },
];

export class ClientPostSearchPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    candidatesPending: PropTypes.bool.isRequired,
    usersPending: PropTypes.bool.isRequired,
    jobsPending: PropTypes.bool.isRequired,
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    getCandidatesWithFilters: PropTypes.func.isRequired,
    unloadCandidates: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
    jobs: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state) !== JSON.stringify(prevState)
      && this.state.name
      && this.state.skillsDatas === []
      && this.state.softSkillsDatas === []
    ) {
      this.loadSkills(this.state.name);
    }
  }

  loadSkills = (name) => {
    if (!this.state.skillsLoading) {
      ApiService.getSkills(name.value.substring(0, 5)).then((data) => {
        this.setState({
          skillsDatas: data,
          skillsLoading: false,
        });
      });
      this.setState({ skillsLoading: true });
    }
    if (!this.state.softSkillsLoading) {
      ApiService.getSoftSkills(name.value.substring(0, 5)).then((data) => {
        this.setState({
          softSkillsDatas: data,
          softSkillsLoading: false,
        });
      });
      this.setState({ softSkillsLoading: true });
    }
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.users && !props.usersPending) {
      props.getUsers();
    }
    if (props.authUser && !props.jobs && !props.jobsPending) {
      props.getStaticJobs();
    }
    if (props.candidates) {
      if (state.datas === INITIAL_STATE.datas
        || (
          props.candidates !== state.datas && state.datas !== INITIAL_STATE.datas
        )) {
        let newDatas = props.candidates;
        newDatas.map(candidate =>
          candidate['isAttachedToPost'] = candidate.posts.some(post => post.id
            === props.match.params.postId),
        );
        newState = {
          ...newState,
          datas: newDatas,
        };
      }
    }
    if (props.location.state && !state.loadedFromPost && !props.candidatesPending) {
      const searchFrom = props.location.state;
      newState = {
        ...newState,
        experienceYears: searchFrom.experienceYears ? searchFrom.experienceYears.toString() : '0',
        diploma: searchFrom.diploma,
        name: {
          value: searchFrom.name
            ? searchFrom.name
            : '',
          label: searchFrom.name
            ? searchFrom.name
            : '',
        },
        softSkills: searchFrom.softSkills
          ? searchFrom.softSkills.map((softSkill) =>
            (
              {
                value: softSkill.summary,
                label: softSkill.summary,
              }
            ),
          )
          : [],
        skills: searchFrom.skills
          ? searchFrom.skills.map((skill) =>
            (
              {
                value: skill.code_ogr,
                label: skill.libelle_competence,
              }
            ),
          )
          : [],
        loadedFromPost: true,
      };
      props.unloadCandidates();

      props.authUser.getIdToken(true).then(token => {
        props.getCandidatesWithFilters(
          token,
          {
            code_rome: searchFrom.name.substring(0, 5),
            experience_years: searchFrom.experienceYears ?? '0',
            diploma: searchFrom.diploma
              ? searchFrom.diploma
              : '',
            name: searchFrom.name,
            softskills: (searchFrom.softSkills ?? []).map(softSkill => softSkill.summary).toString(),
            skills: (searchFrom.skills ?? []).map(skill => skill.code_ogr).toString(),
          },
        );
      });
    }
    return newState;
  }

  handleFilters = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.unloadCandidates();
    let softSkills = this.state.softSkills
      ?
      this.state.softSkills.map(softSkill =>
        softSkill.value,
      )
      : '';
    let skills = this.state.skills
      ?
      this.state.skills.map(skill =>
        skill.value,
      )
      : '';

    this.props.authUser.getIdToken(true).then(token => {
      this.props.getCandidatesWithFilters(
        token,
        {
          code_rome: this.state.name
            ? this.state.name.value.substring(0, 5)
            : '',
          experience_years: this.state.experienceYears
            ? this.state.experienceYears
            : 0,
          diploma: this.state.diploma
            ? this.state.diploma
            : '',
          global: this.state.global,
          softskills: softSkills.toString(),
          skills: skills.toString(),
        },
      );
    });
  };

  handleSelect = element => {
    this.props.history.push(CANDIDATE_VIEW.replace(':candidateId', element.id));
  };

  handleAttachCandidateToPost = element => {
    this.props.authUser.getIdToken(true).then(token => {
      if (element.isAttachedToPost) {
        return this.props.unlinkCandidateWithPost(
          token,
          element,
          this.props.match.params.postId,
        );
      } else {
        return this.props.linkCandidateWithPost(
          token,
          element,
          this.props.match.params.postId,
        );
      }
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  onChangeObservable = (changedState) => {
    if (changedState.name) {
      this.loadSkills(changedState.name);
    }
  };

  render() {
    const { jobs } = this.props;
    const {
      skillsDatas,
      softSkillsDatas,
      experienceYears,
    } = this.state;
    const jobsOptions = (
      jobs !== undefined && jobs !== null
    )
      ? jobs.map((job) =>
        (
          {
            value: job.code_rome + ' - ' + job.libelle_rome,
            label: job.code_rome + ' - ' + job.libelle_rome,
          }
        ),
      )
      : [];
    const skillsOptions = (
      skillsDatas !== undefined && skillsDatas !== null
    )
      ? skillsDatas.map((skill) =>
        (
          {
            value: skill.code_ogr,
            label: skill.libelle_competence,
          }
        ),
      )
      : [];
    const softSkillsOptions = (
      softSkillsDatas !== undefined && softSkillsDatas !== null
    )
      ? softSkillsDatas.map((softSkill) =>
        (
          {
            value: softSkill.summary,
            label: softSkill.summary,
          }
        ),
      )
      : [];

    return (
      <>
        <Paper style={defaultStyles.clientTabs}>
          <form onSubmit={this.handleFilters}>
            <Grid container direction="row" spacing={4}>
              <CustomTextField
                gridMD={3}
                key={'experience_year'}
                id={'experience_year'}
                type={'number'}
                label={'Années d\'expérience'}
                onChange={event => this.setState({ experienceYears: event.target.value })}
                value={experienceYears}
              />
              <CustomTextField
                gridMD={3}
                key={'diploma'}
                id={'diploma'}
                label={'Diplôme'}
                onChange={event => this.setState({ diploma: event.target.value })}
                value={this.state.diploma}
              />

              <CustomTextField
                gridMD={6}
                key={'global'}
                id={'global'}
                label={'Recherche globale'}
                onChange={event => this.setState({ global: event.target.value })}
                value={this.state.global}
              />
              <CustomSelectField
                gridMD={3}
                key={'name'}
                id={'name'}
                label={'Intitulé'}
                onChange={event => {
                  let newName = { name: event };
                  this.setState(newName);
                  this.onChangeObservable(newName);
                }}
                value={this.state.name}
                autocomplete={true}
                options={jobsOptions}
              />
              <CustomSelectField
                gridMD={3}
                key={'skills'}
                id={'skills'}
                label={'Savoir-faire'}
                onChange={event => this.setState({ skills: event })}
                value={this.state.skills}
                autocomplete={true}
                multiple={true}
                options={skillsOptions}
                noOptions={'Choisir d\'abord un métier (Intitulé)'}
              />
              <CustomSelectField
                gridMD={3}
                key={'softSkills'}
                id={'softSkills'}
                label={'Savoir-être'}
                onChange={event => this.setState({ softSkills: event })}
                value={this.state.softSkills}
                autocomplete={true}
                multiple={true}
                options={softSkillsOptions}
                noOptions={'Choisir d\'abord un métier (Intitulé)'}
              />
              <Grid item xs={12} sm={12} md={3}>
                <Fab
                  size={'small'}
                  color="primary"
                  type="submit"
                >
                  {this.props.candidatesPending
                    ? <CircularProgress color="secondary" />
                    : <FilterIcon />
                  }
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {this.props.pending && <Spinner />}
        {!this.props.pending && (
          <CustomTable
            datas={this.state.datas}
            paginationLabel="Candidats par page"
            emptyDataMessage="Aucun Candidat"
            columnData={columnData}
            sending={this.props.sending}
            onClickRow={this.handleSelect}
            showDelete={false}
            onAddCandidature={this.handleAttachCandidateToPost}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    usersPending: state.users.pending,
    candidates: state.candidates.content,
    candidatesPending: state.candidates.pending,
    sending: state.candidates.sending,
    jobs: state.statics.jobs,
    jobsPending: state.statics.pending,
  };
}

export default compose(
  withTitle({
    title: 'Recherche de candidats pour une offre',
    subtitle: 'Recherche de candidats',
  }),
  connect(mapStateToProps, {
    getCandidatesWithFilters,
    unloadCandidates,
    getUsers,
    getStaticJobs,
    handleError,
    linkCandidateWithPost,
    unlinkCandidateWithPost,
  }),
)(ClientPostSearchPage);
