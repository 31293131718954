import { DEFAULT_WHITE } from '../defaultColors';
import logo from '../../resources/images/logo_carrefour.svg';
import logoWhite from '../../resources/images/logo_carrefour_white.svg';
import header from '../../resources/images/header_carrefour.png';

export const CARREFOUR_PRIMARY = '#2A59AD';
export const CARREFOUR_SECONDARY = '#C20016';
export const CARREFOUR_LABEL = '#213A66';
export const CARREFOUR_SUCCESS = '#A1BF36';
export const CARREFOUR_ERROR = '#99000E';
export const CARREFOUR_WARNING = '#D06519';
export const CARREFOUR_MENU = '#0A172C';
export const CARREFOUR_BACKGROUND = '#213A66';
export const CARREFOUR_TEXT_DARK = '#0A172C';
export const CARREFOUR_TEXT_PLACEHOLDER = '#B0B4B8';
export const CARREFOUR_TABLE_HEAD = '#0A172C';

const carrefour_variation = {
  font : "Verdana",
  primary: CARREFOUR_PRIMARY,
  secondary: CARREFOUR_SECONDARY,
  label: CARREFOUR_LABEL,
  info: CARREFOUR_PRIMARY,
  success: CARREFOUR_SUCCESS,
  warning: CARREFOUR_WARNING,
  error: CARREFOUR_ERROR,
  menu: CARREFOUR_MENU,
  background: CARREFOUR_BACKGROUND,
  text_light: DEFAULT_WHITE,
  text_dark: CARREFOUR_TEXT_DARK,
  text_placeholder: CARREFOUR_TEXT_PLACEHOLDER,
  table_head: CARREFOUR_TABLE_HEAD,
  logo: logo,
  logo_white: logoWhite,
  app_bar_background: `url(${header})`
}

export default carrefour_variation;
