export const stateWithInterview = (interview) => (
  {
    presentation: interview.presentation || '',
    formation: interview.formation || '',
    experiences: interview.experiences || '',
    softskill: interview.softskill || '',
    skill: interview.skill || '',
    competences: interview.competences || '',
    coherence: interview.coherence || '',
    motivation: interview.motivation || '',
    diction: interview.diction || '',
    rythme: interview.rythme || '',
    voix: interview.voix || '',
    expressions: interview.expressions || '',
    formulation: interview.formulation || '',
    courtoisie: interview.courtoisie || '',
    disponibilite: interview.disponibilite || '',
    comprehension: interview.comprehension || '',
    decision: interview.decision || '',
    note: interview.note || '',
    reformulation: interview.reformulation || '',
    poste: interview.poste || '',
    personnelles: interview.personnelles || '',
    objectif: interview.objectif || '',
    communication: interview.communication || '',
    temps: interview.temps || '',
    point: interview.point || '',
    arret: interview.arret || '',
    avis: interview.avis || '',
    free: interview.free || '',
    auto_eval: interview.auto_eval || '',
    control_reference: interview.control_reference || '',
    personnal_context: interview.personnal_context || '',
  }
);