import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getClients, postClientContact, putClientContact } from '../../stores/clients/actions';
import { handleError } from '../../stores/infos/actions';
import Spinner from '../common/Spinner';

import CustomForm from '../common/form/CustomForm';
import { getUsers } from '../../stores/users/actions';
import { CLIENT_VIEW } from '../../constants/routes';
import { getStaticJobs } from '../../stores/statics/actions';

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  title: '',
  lastname: '',
  firstname: '',
  mail: '',
  phone: '',
  mobile_phone: '',
  assistant_phone: '',
  position: '',
  profession: '',
  qualification: '',
};

const stateWithContact = (contact) => (
  {
    id: contact.id,
    title: contact.title,
    lastname: contact.lastname,
    firstname: contact.firstname,
    mail: contact.mail,
    phone: contact.phone
      ? contact.phone
      : '',
    mobile_phone: contact.mobile_phone
      ? contact.mobile_phone
      : '',
    assistant_phone: contact.assistant_phone
      ? contact.assistant_phone
      : '',
    position: contact.position,
    profession: {
      value: contact.profession
        ? contact.profession
        : '',
      label: contact.profession
        ? contact.profession
        : '',
    },
    qualification: contact.qualification,
  }
);

export class ClientContactForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    postClientContact: PropTypes.func.isRequired,
    putClientContact: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contacts: null,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (props.authUser && !props.clients && !props.pending && props.roles) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken().then(token => {
        props.getClients(token, role);
      });
    }
    if (props.authUser && !props.jobs && !props.pending) {
      props.getStaticJobs();
    }
    if (props.clients
      && props.match
      && props.match.params.contactId
      && state.id
      === INITIAL_STATE.id) {
      const currentClientId = props.match.params.clientId;
      const foundClient = props.clients.find(client => client.id === currentClientId);
      const contacts = foundClient.contacts;
      const currentContactId = props.match.params.contactId;
      const foundContact = contacts.find(contact => contact.id === currentContactId);

      if (foundContact) {
        newState = { ...newState, ...stateWithContact(foundContact) };
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event, formState) => {
    this.props.authUser.getIdToken().then(token => {
      const role = this.props.roles[this.props.authUser.uid];

      const selectedClient = this.props.clients.find(client => client.id
        === this.props.match.params.clientId);
      formState.client_id = selectedClient.id;

      formState.profession = formState.profession
        ? formState.profession.value
        : '';

      if (this.state.id === INITIAL_STATE.id) {
        this.props.postClientContact(
          token,
          role,
          formState,
        ).then((contactId) => {
          if (contactId) {
            this.props.history.push(CLIENT_VIEW.replace(':clientId',
              this.props.match.params.clientId,
            ));
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      } else {
        this.props.putClientContact(
          token,
          role,
          this.state.id,
          formState,
        ).then((contactId) => {
          if (contactId) {
            this.props.history.push(CLIENT_VIEW.replace(':clientId',
              this.props.match.params.clientId,
            ));
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      }
      event.preventDefault();
    });
  };

  render() {

    const {
      pending,
      sending,
      jobs,
      clients,
    } = this.props;

    const { id } = this.state;

    let jobsOptions = {};
    if (jobs) {
      jobsOptions = jobs.map(job =>
        (
          {
            value: job.code_rome + ' - ' + job.libelle_rome,
            label: job.code_rome + ' - ' + job.libelle_rome,
          }
        ),
      );
    }
    let clientsOptions = {};
    if (clients) {
      clients.map(client =>
        clientsOptions[client.business_name] = client.business_name,
      );
    }
    const qualifications = {
      Utilisateur: 'Utilisateur',
      Décideur: 'Décideur',
      Interlocuteur: 'Interlocuteur',
    };

    const buttonLabel = sending
      ?
      (
        <Spinner variant="nomargin" />
      )
      : id === ''
        ? 'Créer'
        : 'Éditer';

    const formFields = [
      {
        id: 'title',
        type: 'textField',
        gridMD: 2,
        label: 'Civilité',
        isMandatory: false,
      },
      {
        id: 'lastname',
        type: 'textField',
        gridMD: 3,
        label: 'Nom',
        isMandatory: true,
      },
      {
        id: 'firstname',
        type: 'textField',
        gridMD: 3,
        label: 'Prénom',
        isMandatory: true,
      },
      {
        id: 'mail',
        type: 'textField',
        gridMD: 4,
        label: 'Mail',
        isMandatory: true,
      },
      {
        id: 'position',
        type: 'textField',
        label: 'Poste',
        isMandatory: true,
      },
      {
        id: 'profession',
        type: 'selectField',
        options: jobsOptions,
        autocomplete: true,
        label: 'Métier',
        isMandatory: false,
      },
      {
        id: 'qualification',
        type: 'selectField',
        options: qualifications,
        label: 'Qualification',
        isMandatory: true,
      },
      {
        id: 'title_Phone',
        type: 'title',
        gridMD: 12,
        label: 'Veuillez remplir au moins un numéro de téléphone',
      },
      {
        id: 'phone',
        type: 'textField',
        label: 'Téléphone Fixe',
        isPartialMandatory: true,
      },
      {
        id: 'mobile_phone',
        type: 'textField',
        label: 'Téléphone Mobile',
        isPartialMandatory: true,
      },
      {
        id: 'assistant_phone',
        type: 'textField',
        label: 'Téléphone Assistant',
        isPartialMandatory: true,
      },
    ];

    if (pending) {
      return (
        <Spinner />
      );
    } else {
      return (
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    pending: state.clients.pending || state.statics.pending,
    sending: state.clients.sending,
    jobs: state.statics.jobs,
  };
}

export default compose(
  connect(mapStateToProps, {
    getUsers,
    getClients,
    postClientContact,
    putClientContact,
    getStaticJobs,
    handleError,
  }),
)(ClientContactForm);
