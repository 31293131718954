import React from 'react';
import Typography from '@material-ui/core/Typography';
import PasswordForgetForm from '../components/forms/PasswordForgetForm';

export const PasswordForgetPage = () =>
(
  <>
    <Typography
      variant="h2"
      gutterBottom
    >
      Mot de passe oublié
    </Typography>
    <PasswordForgetForm />
  </>
);

export default PasswordForgetPage;
