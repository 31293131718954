import React, { Component } from 'react';
import StringService from '../../services/StringService';
import PropTypes from 'prop-types';
import { CANDIDATE_VIEW } from '../../constants/routes';
import Grid from '@material-ui/core/Grid';
import CustomForm from '../../components/common/form/CustomForm';
import { interviewFormFields } from '../../constants/form/interview/interviewFormFields';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { putCandidate } from '../../stores/candidates/actions';
import { putCandidature } from '../../stores/candidatures/actions';
import withTitle from '../../hocs/withTitle';
import { stateWithInterview } from '../../constants/state/stateWithInterview';
import { stateWithCandidature } from '../../constants/state/stateWithCandidature';
import { stateWithCandidate } from '../../constants/state/stateWithCandidate';
import { INITIAL_STATE } from '../../constants/state/initialStateReportForm';

export class ReportEdit extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
    sendingCandidatures: PropTypes.bool.isRequired,
    sendingCandidate: PropTypes.bool.isRequired,
    putCandidate: PropTypes.func.isRequired,
    putCandidature: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sendingCandidate
      === true
      && prevProps.sendingCandidate
      !== this.props.sendingCandidate) {
      this.props.authUser.getIdToken(true)
        .then((token) => {
          this.props.putCandidature(
            token,
            this.props.match.params.candidatureId,
            {
              new_interview: {
                stage: 1,
                presentation: this.state.presentation,
                formation: this.state.formation,
                competences: this.state.competences,
                experiences: this.state.experiences,
                coherence: this.state.coherence,
                motivation: this.state.motivation,
                diction: this.state.diction,
                rythme: this.state.rythme,
                voix: this.state.voix,
                expressions: this.state.expressions,
                formulation: this.state.formulation,
                courtoisie: this.state.courtoisie,
                disponibilite: this.state.disponibilite,
                comprehension: this.state.comprehension,
                decision: this.state.decision,
                note: this.state.note,
                reformulation: this.state.reformulation,
                poste: this.state.poste,
                personnelles: this.state.personnelles,
                objectif: this.state.objectif,
                communication: this.state.communication,
                temps: this.state.temps,
                point: this.state.point,
                arret: this.state.arret,
                avis: this.state.avis,
                free: this.state.free || '',
                auto_eval: this.state.auto_eval || '',
                control_reference: this.state.control_reference || '',
                personnal_context: this.state.personnal_context || '',
              },
            },
          );
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
    if (prevProps.sendingCandidatures
      === true
      && prevProps.sendingCandidatures
      !== this.props.sendingCandidatures) {
      this.props.history.push(CANDIDATE_VIEW
        .replace(':candidateId', this.props.match.params.candidateId));
    }
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.location.state.candidature && !state.candidature) {
      let currentInterview = props.location.state.candidature.interviews
        .find(interview => interview.stage === 1);
      if (currentInterview) {
        newState = {
          ...newState,
          ...stateWithInterview(currentInterview),
          candidature: props.location.state.candidature,
        };
      } else {
        newState = { ...newState, ...stateWithCandidature(props.location.state.candidature) };
      }
    }
    if (props.location.state.candidate && !state.candidate) {
      newState = { ...newState, ...stateWithCandidate(props.location.state.candidate) };
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event, formState) => {
    event.preventDefault();
    this.props.authUser.getIdToken(true).then(token => {
      this.setState(formState);
      this.props.putCandidate(
        token,
        this.props.match.params.candidateId,
        {
          availability: StringService.dateStringToTimestamp(formState.availability),
          mobility: formState.mobility,
          salary: formState.salary,
          wanted_salary: formState.wanted_salary,
        },
      );
    });
  };

  render() {
    const {
      sendingCandidate,
      sendingCandidatures,
    } = this.props;

    return (
      <>
        <Grid container>
          <Grid item>
            <CustomForm
              data={this.state}
              formFields={interviewFormFields}
              buttonLabel={'Terminer'}
              onSubmit={this.onSubmit}
              sending={sendingCandidatures || sendingCandidate}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    sendingCandidate: state.candidates.sending,
    sendingCandidatures: state.candidatures.sending,
    pending: state.candidates.pending || state.candidatures.pending || state.users.pending,
    candidates: state.candidates.content,
    candidatures: state.candidatures.content,
  };
}

export default compose(
  connect(mapStateToProps, {
    putCandidate,
    putCandidature,
  }),
  withTitle({ title: 'Entretien téléphone' }),
)(ReportEdit);
