import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getClients,
  postClient,
  postClientPDF,
  postClientPicture,
  putClient,
} from '../../stores/clients/actions';
import { handleError } from '../../stores/infos/actions';
import Spinner from '../common/Spinner';

import CustomForm from '../common/form/CustomForm';
import { getUsers } from '../../stores/users/actions';
import AuthService from '../../services/AuthService';
import { CLIENT_EDIT, CLIENTS } from '../../constants/routes';
import {
  getStaticCibles,
  getStaticLegalEntities,
  getStaticSectors,
  getStaticStatus,
  getStaticTalentPlugSectors,
} from '../../stores/statics/actions';
import { ROLE_ADMIN, ROLE_CONSULTANT } from '../../constants/roles';

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  business_name: '',
  director_name: '',
  siret: '',
  legal_entity: '',
  status: '',
  client_status: '',
  city: '',
  address_line1: '',
  address_line2: '',
  zip_code: '',
  department: '',
  phone: '',
  website: '',
  details: '',
  referent_name: null,
  image_path: '',
  pictureToUpload: null,
  sending: false,
  nb_employee: 0,
  hr_profil_name: null,
  hr_profil_details: '',
  values: '',
  vision: '',
  target: '',
  pdf_path: '',
  pdfToUpload: null,
  internals: 0,
  externals: 0,
  qualification: '',
  extendor_address: true,
  extendor_infos: false,
  extendor_recruits: false,
  extendor_advantages: false,
  extendor_life: false,
  extendor_extern: false,
};

const stateWithClient = (client, referent, yearlyRecruit) => (
  {
    id: client.id,
    business_name: client.business_name,
    director_name: client.director_name,
    siret: client.siret,
    legal_entity: client.legal_entity,
    status: client.status,
    client_status: client.client_status,
    city: client.city,
    address_line1: client.address_line1,
    address_line2: client.address_line2,
    zip_code: client.zip_code,
    department: client.department,
    phone: client.phone,
    website: client.website
      ? client.website
      : '',
    details: client.details,
    referent_name: referent
      ? referent.firstname + ' ' + referent.lastname
      : null,
    image_path: client.image_path,
    nb_employee: client.nb_employee
      ? client.nb_employee
      : 0,
    hr_profil_name: client.hr_profil
      ? client.hr_profil.name
      : null,
    hr_profil_details: client.hr_profil_details
      ? client.hr_profil_details
      : '',
    values: client.values
      ? client.values
      : '',
    vision: client.vision
      ? client.vision
      : '',
    ape_code: {
      value: client.ape_code
        ? client.ape_code
        : '',
      label: client.ape_code
        ? client.ape_code
        : '',
    },
    sector: {
      value: client.sector
        ? client.sector
        : '',
      label: client.sector
        ? client.sector
        : '',
    },
    target: client.target
      ? client.target
      : '',
    pdf_path: client.pdf_path,
    internals: yearlyRecruit
      ? yearlyRecruit.internals
      : 0,
    externals: yearlyRecruit
      ? yearlyRecruit.externals
      : 0,
    qualification: yearlyRecruit
      ? yearlyRecruit.qualification
      : '',
  }
);

export class ClientForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    editing: PropTypes.bool.isRequired,
    viewing: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    postClient: PropTypes.func.isRequired,
    putClient: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    clientError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    clients: null,
    viewing: false,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (!props.pending) {
      if (!props.users) {
        props.getUsers();
      }
      if (!props.sectors) {
        props.getStaticLegalEntities();
        props.getStaticSectors();
        props.getStaticTalentPlugSectors();
        props.getStaticCibles();
        props.getStaticStatus();
      }
      if (props.authUser) {
        if (!props.clients && props.roles && !props.clientError) {
          const role = props.roles[props.authUser.uid];
          props.authUser.getIdToken().then(token => {
            props.getClients(token, role);
          });
        }
      }
    }
    if (props.authUser && props.roles && props.users) {
      const role = props.roles[props.authUser.uid];
      if (role === ROLE_CONSULTANT) {
        const selectedUser = props.users.find(user => user.uid === props.authUser.uid);
        newState = {
          ...newState,
          referent_name: selectedUser.firstname + ' ' + selectedUser.lastname,
        };
      }
    }
    if (props.clients && props.users && props.advantages
      && props.match && props.match.params.clientId && state.id === INITIAL_STATE.id) {

      const clients = props.clients;
      const currentClientId = props.match.params.clientId;
      const foundClient = clients.find(client => client.id === currentClientId);
      const clientAdvantages = foundClient && foundClient.client_advantages;

      props.advantages.forEach(advantage => {
        let clientAdvantage = null;
        if (clientAdvantages) {
          clientAdvantage = clientAdvantages
            .find(clientAdvantage => clientAdvantage.advantage.id === advantage.id);
        }
        newState = {
          ...newState,
          ['advantage_' + advantage.id]: clientAdvantage
            ? clientAdvantage.exists
            : false,
        };
        if (advantage.type > 0) {
          newState = {
            ...newState,
            ['advantage_' + advantage.id + '_custom']:
              clientAdvantage && clientAdvantage.value
                ? clientAdvantage.value
                : '',
          };
        }
      });
      const d = new Date();
      const currentYearlyRecruit = foundClient && foundClient.yearly_recruits
        .find(yearlyRecruit => yearlyRecruit.year === (
          d.getFullYear() - 1
        ).toString());

      if (foundClient) {
        const clientReferent = props.users.find(user => user.uid === foundClient.referent_id);
        newState = {
          ...newState, ...stateWithClient(foundClient,
            clientReferent,
            currentYearlyRecruit,
          ),
        };
      }
    }

    if (props.sending && !state.sending) { // Start sending
      return { sending: true };
    }
    if (!props.sending && state.sending) { // End sending
      if (state.pictureToUpload && state.id) { // picture To Upload after deal sent
        props.authUser.getIdToken().then(token => {
          props.postClientPicture(
            token,
            state.id,
            state.pictureToUpload,
            (progressEvent) => {
              const percentCompleted = Math.round((
                progressEvent.loaded * 100
              ) / progressEvent.total);
              console.log(state.pictureToUpload.name
                + ' -> '
                + percentCompleted
                + '% ('
                + progressEvent.loaded
                + ' / '
                + progressEvent.total
                + ')');
            },
          );
        });
        return {
          pictureToUpload: null,
          sending: false,
        };
      } else if (state.pdfToUpload && state.id) { // picture To Upload after deal sent
        props.authUser.getIdToken().then(token => {
          props.postClientPDF(token, state.id, state.pdfToUpload, (progressEvent) => {
            const percentCompleted = Math.round((
              progressEvent.loaded * 100
            ) / progressEvent.total);
            console.log(state.pdfToUpload.name
              + ' -> '
              + percentCompleted
              + '% ('
              + progressEvent.loaded
              + ' / '
              + progressEvent.total
              + ')');
          });
        });
        return {
          pdfToUpload: null,
          sending: false,
        };
      } else if (!state.pictureToUpload && !state.pdfToUpload && state.id) {
        props.history.push(CLIENTS);
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  computeFormAdvantages = (formState) => {
    formState.advantages = [];
    this.props.advantages.forEach(advantage => {
      formState.advantages.push({
        id: advantage.id,
        exists: formState['advantage_' + advantage.id],
        value: advantage.type > 0
          ? formState['advantage_' + advantage.id + '_custom']
          : '',
      });
    });

    return formState;
  };

  updateFormState = (formState) => {
    // Retrieve ImageToUpload if available
    if (formState.pictureToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pictureToUpload: formState.pictureToUpload,
      });
    }
    // Retrieve pdfToUpload if available
    if (formState.pdfToUpload) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        pdfToUpload: formState.pdfToUpload,
      });
    }

    if (AuthService.isGranted(ROLE_ADMIN, this.props.authUser, this.props.roles)) {
      const selectedUser = this.props.users.find(user => user.firstname
        + ' '
        + user.lastname
        === formState.referent_name);
      formState.referent_id = selectedUser.uid;
    } else {
      formState.referent_id = this.props.authUser.uid;
    }

    if (formState.hr_profil_name) {
      const selectedHRProfil = this.props.hrprofils.find(hrprofil => hrprofil.name
        === formState.hr_profil_name);
      formState.hr_profil_id = selectedHRProfil
        ? selectedHRProfil.id
        : null;
    }

    // Change nb_employee type
    formState.nb_employee = parseInt(formState.nb_employee, 10);

    // Handle Recruits
    const d = new Date();
    formState.yearly_recruit = {
      internals: formState.internals
        ? parseInt(formState.internals, 10)
        : 0,
      externals: formState.externals
        ? parseInt(formState.externals, 10)
        : 0,
      qualification: formState.qualification,
      year: (
        d.getFullYear() - 1
      ).toString(),
    };

    formState.ape_code = formState.ape_code
      ? formState.ape_code.value
      : '';
    formState.sector = formState.sector
      ? formState.sector.value
      : '';

    formState = this.computeFormAdvantages(formState);

    return formState;
  };

  checkData = (formState) => {
    let formErrors = [];
    // TODO add more verification
    [
      {
        id: 'website',
        name: 'Site web',
      },
      {
        id: 'sector',
        name: 'Secteur Talent Plug',
      },
      {
        id: 'values',
        name: 'Valeurs',
      },
      {
        id: 'vision',
        name: 'Description',
      },
    ].forEach(mandatoryFields => {
        if (
          formState[mandatoryFields.id] === null
          || !formState[mandatoryFields.id]
          || formState[mandatoryFields.id] === '') {
          formErrors.push(mandatoryFields.name);
        }
      },
    );

    return formErrors;
  };

  onSubmit = (event, formState) => {
    event.preventDefault();
    const errorFields = this.checkData(formState);
    if (!this.props.editing) {
      this.props.history.push(CLIENT_EDIT.replace(':clientId', this.state.id));
    } else {
      if (errorFields.length === 0) {
        this.props.authUser.getIdToken().then(token => {
          const role = this.props.roles[this.props.authUser.uid];
          formState = this.updateFormState(formState);
          if (!this.state.id || this.state.id === INITIAL_STATE.id) {
            this.handlePost(token, role, formState);
          } else {
            this.handlePut(token, role, formState);
          }
        });
      } else {
        this.props.handleError({
          'message': 'Certains champs sont incorrectes : '
            + errorFields[0],
        });
      }
    }
  };

  handlePost = (token, role, formState) => {
    this.props.postClient(
      token,
      role,
      formState,
    ).then((
      clientId => {
        this.setState({ id: clientId });
      }
    )).catch((error) => {
      this.props.handleError(error);
    });
  };

  handlePut = (token, role, formState) => {
    this.props.putClient(
      token,
      role,
      this.state.id,
      formState,
    ).then((
      clientId => {
        this.setState({ id: clientId });
      }
    )).catch((error) => {
      this.props.handleError(error);
    });
  };

  computeAdvantageFields = () => {
    let advantageFields = [];

    advantageFields.push({
      id: 'extendor_advantages',
      type: 'extendor',
      gridMD: 12,
      label: 'Avantages',
      noPadding: true,
      disabled: !this.props.editing,
    });

    let advantages = this.props.advantages.filter(advantage => advantage.category === 0);
    advantages.forEach(advantage => {
      if (this.props.editing || this.props.viewing || advantage.value) {
        let onlyCheckbox = advantage.type === 0;
        advantageFields.push({
          id: 'advantage_' + advantage.id,
          gridMD: 2,
          type: 'checkboxField',
          label: advantage.title,
          noPadding: true,
          dependances: ['extendor_advantages'],
          disabled: !this.props.editing,
        });
        if (!onlyCheckbox) {
          advantageFields.push({
            id: 'advantage_' + advantage.id + '_custom',
            gridMD: 2,
            type: 'textField',
            subtype: advantage.type === 1
              ? 'number'
              : 'text',
            label: 'Précisez',
            noPadding: true,
            dependances: ['advantage_' + advantage.id, 'extendor_advantages'],
            disabled: !this.props.editing,
          });
        }
      }
    });

    if (!this.props.viewing) {
      const advantagesOptions = [];
      advantages.map(advantage =>
        advantagesOptions['advantage_' + advantage.id] = advantage.title,
      );
      advantageFields.push({
        id: 'advantageFields',
        type: 'checkboxSelector',
        options: advantagesOptions,
        gridMD: 12,
        label: '+ Ajouter',
        dependances: ['extendor_advantages'],
        isMandatory: false,
        disabled: !this.props.editing,
      });
    }

    return advantageFields;
  };

  computeLifeFields = () => {
    let lifeFields = [];

    lifeFields.push({
      id: 'extendor_life',
      type: 'extendor',
      gridMD: 12,
      label: 'Cadre de vie dans l’entreprise',
      noPadding: true,
      disabled: !this.props.editing,
    });

    let lifes = this.props.advantages.filter(advantage => advantage.category === 1);
    lifes.forEach(life => {
      if (this.props.editing || this.props.viewing || lifes.value) {
        let onlyCheckbox = life.type === 0;
        lifeFields.push({
          id: 'advantage_' + life.id,
          gridMD: 2,
          type: 'checkboxField',
          label: life.title,
          noPadding: true,
          dependances: ['extendor_life'],
          disabled: !this.props.editing,
        });
        if (!onlyCheckbox) {
          lifeFields.push({
            id: 'advantage_' + life.id + '_custom',
            gridMD: 2,
            type: 'textField',
            subtype: life.type === 1
              ? 'number'
              : 'text',
            label: 'Précisez',
            noPadding: true,
            dependances: ['advantage_' + life.id, 'extendor_life'],
            disabled: !this.props.editing,
          });
        }
      }
    });

    if (!this.props.viewing) {
      const lifesOptions = [];
      lifes.map(life =>
        lifesOptions['advantage_' + life.id] = life.title,
      );
      lifeFields.push({
        id: 'lifeFields',
        type: 'checkboxSelector',
        options: lifesOptions,
        gridMD: 12,
        label: '+ Ajouter',
        dependances: ['extendor_life'],
        isMandatory: false,
        disabled: !this.props.editing,
      });
    }
    return lifeFields;
  };

  computeExternFields = () => {
    let externFields = [];

    externFields.push({
      id: 'extendor_extern',
      type: 'extendor',
      gridMD: 12,
      label: 'Commodités externes',
      noPadding: true,
      disabled: !this.props.editing,
    });

    let externs = this.props.advantages.filter(advantage => advantage.category === 2);
    externs.forEach(extern => {
      if (this.props.editing || this.props.viewing || extern.value) {
        let onlyCheckbox = extern.type === 0;
        externFields.push({
          id: 'advantage_' + extern.id,
          gridMD: 2,
          type: 'checkboxField',
          label: extern.title,
          noPadding: true,
          dependances: ['extendor_extern'],
          disabled: !this.props.editing,
        });
        if (!onlyCheckbox) {
          externFields.push({
            id: 'advantage_' + extern.id + '_custom',
            gridMD: 2,
            type: 'textField',
            subtype: extern.type === 1
              ? 'number'
              : 'text',
            label: 'Précisez',
            noPadding: true,
            dependances: ['advantage_' + extern.id, 'extendor_extern'],
            disabled: !this.props.editing,
          });
        }
      }
    });

    if (!this.props.viewing) {
      const externsOptions = [];
      externs.map(extern =>
        externsOptions['advantage_' + extern.id] = extern.title,
      );
      externFields.push({
        id: 'externFields',
        type: 'checkboxSelector',
        options: externsOptions,
        gridMD: 12,
        label: '+ Ajouter',
        dependances: ['extendor_extern'],
        isMandatory: false,
        disabled: !this.props.editing,
      });
    }

    return externFields;
  };

  render() {

    const {
      pending,
      sending,
      users,
      clients,
      hrprofils,
      editing,
      talent_plug_sectors,
      legal_entities,
      sectors,
      cibles,
      status,
      advantages,
      authUser,
      roles,
    } = this.props;
    const usersMName = {};
    if (users) {
      users.map(user =>
        usersMName[user.firstname + ' ' + user.lastname] = user.firstname + ' ' + user.lastname,
      );
    }
    const legalEntities = {};
    if (legal_entities) {
      legal_entities.map(legalEntity =>
        legalEntities[legalEntity.Libellé] = legalEntity.Libellé,
      );
    }
    const hrProfils = {};
    if (hrprofils) {
      hrprofils.map(hrprofil =>
        hrProfils[hrprofil.name] = hrprofil.name,
      );
    }
    const ciblesOptions = {};
    if (cibles) {
      cibles.map(cible =>
        ciblesOptions[cible.libelle] = cible.libelle,
      );
    }
    const statusOptions = {};
    if (status) {
      status.map(statut =>
        statusOptions[statut.libelle] = statut.libelle,
      );
    }
    let apeCodes = {};
    if (sectors) {
      apeCodes = sectors.map(sector =>
        (
          {
            value: sector.code + ' - ' + sector.libelle,
            label: sector.code + ' - ' + sector.libelle,
          }
        ),
      );
    }
    let talentPlugCodes = {};
    if (talent_plug_sectors) {
      talentPlugCodes = talent_plug_sectors.map(talent_plug_sector =>
        (
          {
            value: talent_plug_sector.code,
            label: talent_plug_sector.code + ' - ' + talent_plug_sector.libelle,
          }
        ),
      );
    }
    const clientStatus = {
      Client: 'Client',
      Prospect: 'Prospect',
    };

    const { id } = this.state;

    const buttonLabel = sending
      ?
      (
        <Spinner variant="nomargin" />
      )
      : !id || id === ''
        ? 'Créer'
        : 'Éditer';

    let formFields = [
      {
        id: 'image_path',
        picture: 'pictureToUpload',
        gridMD: 12,
        condition: editing,
        type: 'pictureDropzone',
        isMandatory: false,
      },
      {
        id: 'business_name',
        type: 'textField',
        label: 'Raison sociale',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'director_name',
        type: 'textField',
        label: 'Nom du dirigeant',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'siret',
        type: 'textField',
        label: 'SIRET',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'legal_entity',
        type: 'selectField',
        options: legalEntities,
        label: 'Type d\'entité légale',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'status',
        type: 'selectField',
        options: statusOptions,
        label: 'Statut',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'client_status',
        type: 'selectField',
        options: clientStatus,
        label: 'Statut Client',
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'referent_name',
        type: 'selectField',
        condition: AuthService.isGranted(ROLE_ADMIN, authUser, roles),
        options: usersMName,
        label: 'Référent',
        isMandatory: true,
        disabled: !editing,
      },

      {
        id: 'extendor_address',
        type: 'extendor',
        gridMD: 12,
        label: 'Coordonnées',
        noPadding: true,
        disabled: !editing,
      },
      {
        id: 'address_line1',
        type: 'textField',
        label: 'Adresse',
        isMandatory: true,
        dependances: ['extendor_address'],
        disabled: !editing,
      },
      {
        id: 'address_line2',
        type: 'textField',
        label: 'Complétement d\'adresse',
        dependances: ['extendor_address'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'details',
        type: 'textField',
        label: 'Précision d\'adresse',
        dependances: ['extendor_address'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'zip_code',
        type: 'textField',
        label: 'Code Postal',
        dependances: ['extendor_address'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'city',
        type: 'textField',
        label: 'Ville',
        dependances: ['extendor_address'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'phone',
        type: 'textField',
        label: 'Téléphone',
        dependances: ['extendor_address'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'website',
        type: 'textField',
        label: 'Site web',
        dependances: ['extendor_address'],
        isMandatory: true,
        disabled: !editing,
      },

      {
        id: 'extendor_infos',
        type: 'extendor',
        gridMD: 12,
        label: 'Informations complémentaires',
        noPadding: true,
        disabled: !editing,
      },
      {
        id: 'hr_profil_name',
        type: 'selectField',
        options: hrProfils,
        label: 'Profil gestion RH',
        dependances: ['extendor_infos'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'hr_profil_details',
        type: 'textField',
        label: 'Champ libre',
        dependances: ['extendor_infos'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'nb_employee',
        type: 'textField',
        subtype: 'number',
        label: 'Nombre de salariés',
        dependances: ['extendor_infos'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'ape_code',
        type: 'selectField',
        options: apeCodes,
        autocomplete: true,
        label: 'Secteur',
        dependances: ['extendor_infos'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'sector',
        type: 'selectField',
        options: talentPlugCodes,
        autocomplete: true,
        label: 'Secteur Talent Plug',
        dependances: ['extendor_infos'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'values',
        type: 'richText',
        gridMD: 6,
        label: 'Valeurs',
        dependances: ['extendor_infos'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'vision',
        type: 'richText',
        gridMD: 6,
        label: 'Description',
        dependances: ['extendor_infos'],
        isMandatory: true,
        disabled: !editing,
      },
      {
        id: 'target',
        type: 'selectField',
        options: ciblesOptions,
        label: 'Type de cibles',
        dependances: ['extendor_infos'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'pdf_path',
        pdf: 'pdfToUpload',
        gridMD: 12,
        condition: editing,
        type: 'pdfDropzone',
        dependances: ['extendor_infos'],
        isMandatory: false,
      },

      {
        id: 'extendor_recruits',
        type: 'extendor',
        gridMD: 12,
        label: 'Recrutements année n-1',
        noPadding: true,
        disabled: !editing,
      },
      {
        id: 'internals',
        type: 'textField',
        subtype: 'number',
        label: 'Internalisés',
        dependances: ['extendor_recruits'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'externals',
        type: 'textField',
        subtype: 'number',
        label: 'Externalisés',
        dependances: ['extendor_recruits'],
        isMandatory: false,
        disabled: !editing,
      },
      {
        id: 'qualification',
        type: 'textField',
        label: 'Qualification des recrutements',
        dependances: ['extendor_recruits'],
        isMandatory: false,
        disabled: !editing,
      },
    ];

    if (!editing) {
      formFields = formFields.filter(formField => this.state[formField.id]
        && this.state[formField.id]
        !== '');
    }

    if (advantages) {
      formFields.push(...this.computeAdvantageFields());
      formFields.push(...this.computeLifeFields());
      formFields.push(...this.computeExternFields());
    }

    if (pending) {
      return (
        <Spinner />
      );
    } else if (clients && users){
      return (
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          sending={sending}
        />
      );
    }
    return null
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    hrprofils: state.clients.hrprofils,
    advantages: state.clients.advantages,
    pending: state.clients.pending || state.users.pending || state.statics.pending,
    sending: state.clients.sending,
    legal_entities: state.statics.legal_entities,
    sectors: state.statics.sectors,
    talent_plug_sectors: state.statics.talent_plug_sectors,
    cibles: state.statics.cibles,
    status: state.statics.status,
    clientError: state.clients.error,
  };
}

export default connect(mapStateToProps, {
    getUsers,
    getClients,
    postClient,
    getStaticTalentPlugSectors,
    getStaticLegalEntities,
    getStaticSectors,
    getStaticCibles,
    getStaticStatus,
    postClientPicture,
    postClientPDF,
    putClient,
    handleError,
  })(ClientForm);
