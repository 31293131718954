import React, {Component}  from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles/index";
import Button from "@material-ui/core/Button";
import { getVariationTheme } from '../../../themes/theme';
import CloseIcon from '../../icons/CloseIcon';

const colorTheme = getVariationTheme();
const styles = {
  grid: {
    padding: '1.5em!important'
  },
  checkbox: {
    width: '100%',
    height: '100%',
    backgroundColor: colorTheme.label,
    justifyContent: 'left',
    color: colorTheme.text_light,
    fontSize: '14px',
    fontWeight: 'normal'
  },
  close: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    top: 10,
    right: 10,
    color: colorTheme.text_light
  }
};

/**
 * Class CustomCheckboxField
 *
 * @Component : <CustomCheckboxField />
 */
export class CustomCheckboxField extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    gridMD: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    value: false,
    disabled: false
  };

  handleClick = () => {
    this.props.onChange(!this.props.value);
  };

  render() {
    // Constant
    const {
      value,
      gridMD,
      label,
      classes
    } = this.props;

    if (value) {
      return (
        <Grid item xs={12} sm={12} md={gridMD} className={classes.grid}>
          <Button
            className={classes.checkbox}
            variant="contained"
            onClick={this.handleClick}
            disabled={this.props.disabled}
          >
            {label}
            <CloseIcon className={classes.close}/>
          </Button>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles) (CustomCheckboxField);
