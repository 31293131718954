import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "recompose";

import {postUser, putUser, getUsers} from "../../stores/users/actions";
import Spinner from "../common/Spinner";

import CustomForm from "../common/form/CustomForm";
import {handleError} from "../../stores/infos/actions";
import {USERS} from "../../constants/routes";
import {getRoles} from "../../stores/roles/actions";

// Intial State of the Component
const INITIAL_STATE = {
  uid:'',
  mail:'',
  phone:'',
  firstname:'',
  lastname:'',
  role:''
};

const stateWithUser = (user) => ({
  uid: user.uid,
  mail:user.mail,
  phone:user.phone,
  firstname:user.firstname,
  lastname:user.lastname,
  role:user.role,
  is_active: user.is_active
});

const rolesOptions = {
  5: 'Consultant',
  10: 'Administrateur'};

export class UserForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    users: PropTypes.array,
    postUser: PropTypes.func.isRequired,
    putUser: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object
  };

  static defaultProps = {
    users: null
  };

  static getDerivedStateFromProps(props, state) {
    let newState = {...state};

    if (!props.users && !props.pending && props.authUser && props.match && props.match.params.userId) {
      props.getUsers();
    }
    if (props.users && props.match && props.match.params.userId && state.uid === INITIAL_STATE.uid) {
      const users = props.users;
      const currentUserId = props.match.params.userId;
      const foundUser = users.find(user => user.uid === currentUserId);
      if (foundUser) {
        newState = {...newState, ...stateWithUser(foundUser)};
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event, formState) => {
    event.preventDefault();
    this.props.authUser.getIdToken().then(token => {
      if (this.state.uid === INITIAL_STATE.uid) {
        this.props.postUser(
          token,
          formState
        )
          .then((
            () => {
              this.props.getRoles()
                .then(() => {
                  this.props.history.push(USERS);
                });
            }
          ))
          .catch((error) => {
            this.props.handleError(error);
          });
      } else {
        this.props.putUser(
          this.state.uid,
          {
            firstname: formState.firstname,
            lastname: formState.lastname,
            mail: formState.mail,
            phone: formState.phone,
            is_active: this.state.is_active
          }
        );
        this.props.getRoles()
          .then(() => {
            this.props.history.push(USERS);
          });
      }
    });
  };

  render() {

    const {pending, sending} = this.props;

    const {uid} = this.state;

    const buttonLabel = sending ?
      (<Spinner variant="nomargin" />)
      : uid === ''  ? 'Créer' : 'Éditer';

    const load = (<Spinner />);

    const formFields = [
      {id: 'mail', type:'textField', gridMD:6, label: 'Mail', isMandatory:true},
      {id: 'firstname', type:'textField', gridMD:6, label: 'Prénom', isMandatory:true},
      {id: 'lastname', type:'textField', gridMD:6, label: 'Nom', isMandatory:true},
      {id: 'phone', type:'textField', gridMD:6, label: 'Téléphone', isMandatory:true},
      {id: 'role', type:'selectField', options: rolesOptions, label: 'Rôle', isMandatory:false},
    ];

    const customForm = (
      <CustomForm
        data={this.state}
        formFields={formFields}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        sending={sending}
      />
    );

    if (pending) {
      return (load);
    } else {
      return (customForm);
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    users: state.users.content,
    pending: state.users.pending,
    sending: state.users.sending
  };
}

export default compose(
  connect(mapStateToProps, {
    getUsers, postUser, putUser, handleError, getRoles
  }),
)(UserForm);
