import React, { useState, useCallback, useEffect } from 'react';
import Room from "./Room";
import {useDispatch, useSelector} from "react-redux";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {getCandidates} from "../../stores/candidates/actions";
import ApiService from "../../services/ApiService";
import { getVariationTheme } from '../../themes/theme';
import CallIcon from '../../components/icons/CallIcon';
import HangUpIcon from '../../components/icons/HangUpIcon';
import NotifyIcon from '../../components/icons/NotifyIcon';

const colorTheme = getVariationTheme();

const useStyles = makeStyles(() => {
    return ({
      card: {
        height: `calc(100vh - 100px)`,
        margin: 20,
        borderRadius: 40,
        backgroundColor: colorTheme.menu
      },
      iconButton: {
        color: colorTheme.text_light
      },
      actions: {
        position: 'absolute',
        bottom: 30
      }
    });
  }
);

export const VideoChat = ({ match, candidature }) => {

  const classes = useStyles();

  const {authUser} = useSelector(state => state.users);
  const {content, pending} = useSelector(state => state.candidates);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();

  const [candidate, setCandidate] = useState(null);
  const [roomName] = useState(candidature.id);
  const [enabledCallButton, setEnabledCallButton] = useState(false);
  const [enabledHangupButton, setEnabledHangupButton] = useState(false);
  const [token, setToken] = useState(null);

  if (authUser && !content && !pending) {

    authUser.getIdToken(true).then(token => {
      dispatch(getCandidates(token));
    });
  }
  if (!candidate && content) {
    setCandidate(content.find(candidate => candidate.id === match.params.candidateId));
    setEnabledCallButton(true);
  }

  const reset = (active = false) => {
    setSeconds(0);
    setIsActive(active);
  };

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const notifyCandidate = () => {
    reset(true);
    ApiService.postFirebaseCheckToken(candidate.firebase_push_token)
      .then(firebase => {
        if (firebase.platform === "IOS") {
          ApiService.postCloudFunctionForPushNotificationiOS(
            candidate.push_kit_token,
            candidature.post_name,
            candidature.company_name,
            candidature.company_image,
            candidature.id
          );
        } else if (firebase.platform === "ANDROID") {
          ApiService.postFirebasePushNotificationAndroid(
            candidate.firebase_push_token,
            candidature.post_name,
            candidature.company_name,
            candidature.company_image,
            candidature.id
          );
        }
      });
  };

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    const token = await authUser.getIdToken(true);
    const data = await ApiService.postTwilioToken(
      token,
      {
        identity: authUser.uid,
        room: roomName
      }
    );
    notifyCandidate();
    setToken(data);
    setEnabledCallButton(false);
    setEnabledHangupButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName, candidate]);

  const handleLogout = useCallback(event => {
    reset();
    setToken(null);
    setEnabledCallButton(true);
    setEnabledHangupButton(false);
  }, []);

  return (
    <Card className={classes.card}>
      {token && candidate && <Room roomName={roomName} token={token} candidate={candidate} />}
      <CardActions className={classes.actions}>
        <IconButton
          aria-label="call"
          className={classes.iconButton}
          style={{backgroundColor: colorTheme.primary}}
          onClick={handleSubmit}
          disabled={!enabledCallButton}
        >
          <CallIcon/>
        </IconButton>
        <IconButton
          aria-label="hang_up"
          className={classes.iconButton}
          style={{backgroundColor: colorTheme.secondary}}
          onClick={handleLogout}
          disabled={!enabledHangupButton}
        >
          <HangUpIcon/>
        </IconButton>
        <IconButton
          aria-label="notify"
          className={classes.iconButton}
          style={{backgroundColor: colorTheme.secondary}}
          onClick={notifyCandidate}
          disabled={!isActive || seconds < 15}
        >
          <NotifyIcon/>
        </IconButton>
      </CardActions>
    </Card>
  );
};
