import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import ContactForm from '../../components/forms/ContactForm';

export const ContactAddPage = props => (
  <ContactForm history={props.history} />
);

ContactAddPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Contacts',
  subtitle: 'Ajouter un Contact',
})(ContactAddPage);
