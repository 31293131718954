import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Dropzone from "react-dropzone";
import Fab from "@material-ui/core/Fab";
import SvgIcon from "@material-ui/core/SvgIcon";
import withStyles from "@material-ui/core/styles/withStyles";
import { defaultStyles } from '../../../themes/theme';
import camera from "../../../resources/icons/camera.svg"

const styles = (theme) => ({
  card : {
    width: 80,
    height: 80,
    borderRadius: 80
  },
  button: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      left: 380,
    },
    [theme.breakpoints.down('md')]: {
      left: 140,
    },
    top: 180
  },
  cardMedia: {
    width: 80,
    height: 80
  }
});

function DrawIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16.0186,10.6787 L13.3236,7.9837 L15.2716,6.0347 L17.9656,8.7287 L16.0186,10.6787 Z M9.0796,17.6247 L6.1026,17.8957 L6.3666,14.9397 L11.9836,9.3227 L14.6796,12.0187 L9.0796,17.6247 Z M19.4036,7.3377 L19.4026,7.3367 L16.6646,4.5987 C15.9236,3.8597 14.6506,3.8247 13.9486,4.5297 L4.9526,13.5257 C4.6266,13.8507 4.4246,14.2827 4.3826,14.7397 L4.0036,18.9097 C3.9776,19.2047 4.0826,19.4967 4.2926,19.7067 C4.4816,19.8957 4.7366,19.9997 4.9996,19.9997 C5.0306,19.9997 5.0606,19.9987 5.0906,19.9957 L9.2606,19.6167 C9.7186,19.5747 10.1496,19.3737 10.4746,19.0487 L19.4716,10.0517 C20.1996,9.3217 20.1686,8.1037 19.4036,7.3377 L19.4036,7.3377 Z" />
    </SvgIcon>
  );
}

export class CustomPictureDropzone extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    labelTitle: PropTypes.string,
    labelAddImage: PropTypes.string,
    labelDropImage: PropTypes.string,
    imagePath: PropTypes.string,
    onDrop: PropTypes.func.isRequired
  };

  static defaultProps = {
    imagePath: null,
    labelTitle: "Image",
    labelAddImage: "Ajouter une image",
    labelDropImage: "Glissez votre image dans cette zone."
  };

  static supportedDropFiles = [
    'image/bmp',
    'image/x-ms-bmp',
    'image/png',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
  ].toString();

  // Handler

  handleAddMedia = () => {
    this.dropzoneRef.open();
  };

  render() {

    const {gridMD, imagePath, onDrop, classes} = this.props;

    const pictureDropzone = (
      <Card className={classes.card}>
        <Dropzone
          style={defaultStyles.media}
          className={classes.dropzone}
          ref={(node) => {
            this.dropzoneRef = node
          }}
          accept={CustomPictureDropzone.supportedDropFiles}
          onDrop={onDrop}
        >
          {({getRootProps, getInputProps}) => (
            imagePath ?
              (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CardMedia
                    component="img"
                    image={imagePath}
                    title="Image"
                    className={classes.cardMedia}
                  />
                </div>
              ) : (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CardMedia
                    component="img"
                    src={camera}
                    title="Image"
                    className={classes.cardMedia}
                  />
                </div>
              )
          )}
        </Dropzone>
        <Fab
          className={classes.button}
          size="small"
          color="primary"
          onClick={this.handleAddMedia}
        >
          <DrawIcon />
        </Fab>
      </Card>
    );
    return (
      <Grid item xs={12} sm={12} md={gridMD}>
        {pictureDropzone}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomPictureDropzone);
