import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import { withStyles } from '@material-ui/core/styles/index';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';

import CustomTableHead from './CustomTableHead';
import CustomTableRow from './CustomTableRow';
import CustomTablePagination from './CustomTablePagination';
import ArrayService from '../../../services/ArrayService';
import * as Roles from '../../../constants/roles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { DEFAULT_GRAY_LIGHT, DEFAULT_GRAY } from '../../../themes/defaultColors';
import { STEPS } from '../../../constants/interviewSteps';

/**
 * Style of Table elements
 *
 * @returns json
 */
const styles = (theme) => (
  {
    table: {
      minWidth: 200,
      width: '100%',
      marginTop: 30,
      [theme.breakpoints.down('md')]: {
        overflowX: 'scroll',
      },
    },
    EmptyData: {
      margin: '50px 0',
    },
    addButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '3vh',
      right: '3vh',
      zIndex: '5',
    },
    tableCell: {
      paddingRight: 10,
      paddingLeft: 10,
      color: DEFAULT_GRAY,
    },
    tableCellDisabled: {
      paddingRight: 10,
      paddingLeft: 10,
      color: DEFAULT_GRAY_LIGHT,
    },
    mobilePadding: {
      height: '60px',
    },
  }
);

/**
 * Class CustomTable
 *
 * @Component : <CustomTable />
 * @Extend : React.Component
 */
class CustomTable extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    classes: PropTypes.object.isRequired,

    // eslint-disable-next-line react/no-unused-prop-types
    datas: PropTypes.array,

    onAdd: PropTypes.func,
    onClickRow: PropTypes.func,
    onSearch: PropTypes.func,
    onLink: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSwitchActive: PropTypes.func,
    switchDialogText: PropTypes.string,

    buttonLabel: PropTypes.string,
    paginationLabel: PropTypes.string.isRequired,
    emptyDataMessage: PropTypes.string.isRequired,
    deleteDialogText: PropTypes.string,
    handleNavigateToReportInterview: PropTypes.func,
    columnData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        align: PropTypes.string, //["inherit","left","center","right","justify"]
        disablePadding: PropTypes.bool,
      }),
    ).isRequired,
    sending: PropTypes.bool.isRequired,
    canReorder: PropTypes.bool,
    onUpOrder: PropTypes.func,
    onDownOrder: PropTypes.func,
    deleteExceptId: PropTypes.string,
    showDelete: PropTypes.bool,
    showEdit: PropTypes.bool,
    shouldArchive: PropTypes.bool,
    defaultOrderBy: PropTypes.string,
    defaultOrder: PropTypes.string,
  };

  // ----------------
  // Default Values

  static defaultProps = {
    datas: [],
    deleteDialogText: 'Êtes-vous sur de vouloir supprimer cet élément ?',
    canReorder: false,
    onAdd: null,
    onSearch: null,
    onDelete: null,
    onUpOrder: null,
    onDownOrder: null,
    onClickRow: null,
    buttonLabel: '',
    deleteExceptId: '',
    showDelete: true,
    showEdit: true,
    shouldArchive: false,
    defaultOrderBy: 'title',
    defaultOrder: 'desc',
    handleNavigateToReportInterview: null,
  };

  // ----------------
  // Life Cycle

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.datas
      && (
        props.datas.length !== state.data.length
        || props.datas.map(data => state.data.includes(data))
      )
    ) {
      if (!props.canReorder) {
        props.columnData.forEach(element => {
          if (element.specialOrderBy !== undefined) {
            props.datas.forEach(data => {
              if (data[element.specialOrderBy] === undefined) {
                data[element.specialOrderBy] = CustomTable.displayAttrOfElement(data, element);
              }
            });
          }
        });
      }
      newState = {
        ...newState,
        data: props.datas.sort((a, b) => ArrayService.sort(a, b, state.orderBy, state.order)),

      };
    }

    return newState;
  }

  // ----------------
  // Computing

  static displayAttrOfElement = (element, column, action) => {
    const baseElement = { ...element };
    if (typeof column.id === 'undefined') {
      return;
    }
    const columnIdArray = column.id.split('.');
    columnIdArray.forEach((value, index) => {
      if (value === 'role') {
        let roleName = element[value];
        element = Roles[roleName];
      } else if (value.includes('[')) {
        let openBracketIndex = value.indexOf('[');
        let closeBracketIndex = value.indexOf(']');
        let arrayToFilter = value.slice(0, openBracketIndex);
        let filterCondition = 'elem => elem.'
          .concat(value.slice(openBracketIndex + 1, closeBracketIndex));
        // eslint-disable-next-line no-eval
        let temp = element[arrayToFilter].find(eval(filterCondition));
        if (temp) {
          element = temp;
        }
      } else if (typeof column.data !== 'undefined' && column.data) {
        let isElement = function (e) {
          return e.id === element[value];
        };
        if (column.data[index]) {
          let temp = column.data[index].find((e) => {
            return isElement(e);
          });
          if (temp) {
            element = temp;
          }
        } else if (element) {
          element = element[value];
        }
      } else if (element && action === undefined) {
        element = element[value];
      }
    });

    if (typeof column.date !== 'undefined' && column.date) {
      if (element === null && column.id === 'updated_at' ) {
        return <Typography>Non modifiée</Typography>;
      } else {
        const date = new Date(element * 1000);
        return (
            '0'
            + date.getDate()
          ).slice(-2)
          + '/' + (
            '0' + (
              date.getMonth() + 1
            )
          ).slice(-2)
          + '/' + date.getFullYear();
      }
    } else if (typeof column.dateTime !== 'undefined' && column.dateTime) {
      const date = new Date(element * 1000);
      const hh = date.getHours();
      const mm = date.getMinutes();

      return (
          '0'
          + date.getDate()
        ).slice(-2)
        + '/' + (
          '0' + (
            date.getMonth() + 1
          )
        ).slice(-2)
        + '/' + date.getFullYear()
        + ' ' + (
          hh > 9
            ? ''
            : '0'
        ) + hh
        + ':' + (
          mm > 9
            ? ''
            : '0'
        ) + mm;
    } else if (typeof column.image !== 'undefined' && column.image) {
      if (element) {
        return (
          <img
            style={{
              maxHeight: '100px',
              maxWidth: '140px',
            }}
            src={element}
            alt=""
            height="auto"
            width="auto"
          />
        );
      } else {
        return (
          <img
            src="/images/no_picture.svg"
            align="middle"
            alt=""
            height="50px"
            width="auto"
          />
        );
      }
    } else if (typeof column.boolean !== 'undefined' && column.boolean) {
      if (typeof column.trueText !== 'undefined' && column.trueText) {
        if (element) {
          return <Typography
            variant={'body2'}
            style={{
              fontWeight: 900,
              color: 'black',
            }}
          >{column.trueText}</Typography>;
        }
      } else {
        return (
          <FormControlLabel disabled control={<Checkbox checked={element} />} />
        );
      }
    } else if (typeof column.description !== 'undefined' && column.description) {
      return (
        <div
          style={styles.text}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element) }}
        />
      );
    } else if (column.id === 'report') {
      if (action !== undefined && element !== undefined) {
        return (
          <Button
            onClick={() => action(element)}
            disabled={element.state < STEPS.PHONE_VALIDATED.value}
          >
            <Typography>Voir</Typography>
          </Button>
        );
      }
    }
    if (column.enum !== 'undefined' && column.enum) {
      if (column.callback !== 'undefined' && column.callback) {
        return (
          <Tooltip title="Changer le statut">
            <Button
              color="primary"
              type={'button'}
              onClick={(event) => column.callback(event, baseElement.id)}
              style={{ width: 'auto' }}
            >
              {column.data[element]}
            </Button>
          </Tooltip>
        );
      } else {
        return column.data[element];
      }
    } else if (typeof column.step !== 'undefined' && column.step) {
      switch (element) {
        case STEPS.ACCOUNT_CREATION_WAITING.value:
          return STEPS.ACCOUNT_CREATION_WAITING.label;
        case STEPS.CREATED.value:
          return STEPS.CREATED.label;
        case STEPS.CONSULTED.value:
          return STEPS.CONSULTED.label;
        case STEPS.PHONE_WAITING_SLOTS.value:
          return CustomTable.renderStep(
            STEPS.PHONE_WAITING_SLOTS.img,
            STEPS.PHONE_WAITING_SLOTS.label,
          );
        case STEPS.PHONE_VALIDATED.value:
          return CustomTable.renderStep(STEPS.PHONE_VALIDATED.img, STEPS.PHONE_VALIDATED.label);
        case STEPS.PHONE_WAITING.value:
          return CustomTable.renderStep(STEPS.PHONE_WAITING.img, STEPS.PHONE_WAITING.label);
        case STEPS.VISIO_WAITING_SLOTS.value:
          return CustomTable.renderStep(
            STEPS.VISIO_WAITING_SLOTS.img,
            STEPS.VISIO_WAITING_SLOTS.label,
          );
        case STEPS.VISIO_VALIDATED.value:
          return CustomTable.renderStep(STEPS.VISIO_VALIDATED.img, STEPS.VISIO_VALIDATED.label);
        case STEPS.VISIO_WAITING.value:
          return CustomTable.renderStep(STEPS.VISIO_WAITING.img, STEPS.VISIO_WAITING.label);
        case STEPS.CONSULT_WAITING_SLOTS.value:
          return CustomTable.renderStep(
            STEPS.CONSULT_WAITING_SLOTS.img,
            STEPS.CONSULT_WAITING_SLOTS.label,
          );
        case STEPS.CONSULT_VALIDATED.value:
          return CustomTable.renderStep(
            STEPS.CONSULT_VALIDATED.img,
            STEPS.CONSULT_VALIDATED.label,
          );
        case STEPS.CONSULT_WAITING.value:
          return CustomTable.renderStep(STEPS.CONSULT_WAITING.img, STEPS.CONSULT_WAITING.label);
        case STEPS.CLIENT_WAITING_SLOTS.value:
          return CustomTable.renderStep(
            STEPS.CLIENT_WAITING_SLOTS.img,
            STEPS.CLIENT_WAITING_SLOTS.label,
          );
        case STEPS.CLIENT_VALIDATED.value:
          return CustomTable.renderStep(STEPS.CLIENT_VALIDATED.img, STEPS.CLIENT_VALIDATED.label);
        case STEPS.CLIENT_WAITING.value:
          return CustomTable.renderStep(STEPS.CLIENT_WAITING.img, STEPS.CLIENT_WAITING.label);
        case STEPS.CANDIDATE_REJECTED.value:
          return CustomTable.renderStep(
            STEPS.CANDIDATE_REJECTED.img,
            STEPS.CANDIDATE_REJECTED.label,
          );
        case STEPS.CANDIDATE_ACCEPTED.value:
          return CustomTable.renderStep(
            STEPS.CANDIDATE_ACCEPTED.img,
            STEPS.CANDIDATE_ACCEPTED.label,
          );
        case STEPS.CANDIDATE_REMOVED.value:
          return CustomTable.renderStep(
            STEPS.CANDIDATE_REMOVED.img,
            STEPS.CANDIDATE_REMOVED.label,
          );
        default:
          return element;
      }
    } else {
      return element;
    }
  };

  static renderStep = (img, label) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          src={img}
          align="middle"
          alt=""
          height="30px"
          width="auto"
        />
        <Typography
          variant="subtitle2"
          style={{
            color: DEFAULT_GRAY,
            marginLeft: 10,
          }}
        >
          {label}
        </Typography>
      </div>
    );
  };

  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: props.defaultOrder,
      orderBy: props.canReorder
        ? 'order'
        : props.defaultOrderBy,
      data: [],
      // eslint-disable-next-line react/no-unused-state
      page: 0,
      // eslint-disable-next-line react/no-unused-state
      rowsPerPage: 25,
    };
  }

  // ----------------
  // Handler

  handleAdd = event => {
    event.stopPropagation();
    this.props.onAdd();
  };

  handleRequestSort = (event, property, doNotChangeTheOrder) => {
    const orderBy = property;
    let order = 'asc';
    if (doNotChangeTheOrder) {
      order = this.state.order;
    } else {
      if (this.state.orderBy === orderBy) {
        if (this.state.order === 'asc') {
          order = 'desc';
        } else {
          order = 'asc';
        }
      }
    }
    const data = this.state.data.sort((a, b) => ArrayService.sort(a, b, orderBy, order));
    this.setState({
      data,
      order,
      orderBy,
    });
  };

  handleChangePage = (event, page) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ rowsPerPage: event.target.value });
    this.handleRequestSort(event, this.state.orderBy, true);
  };

  // ----------------
  // Rendering

  render() {
    // Constants
    const {
      classes,
      onEdit,
      onSearch,
      onList,
      onLink,
      onClickRow,
      onDelete,
      onOptions,
      onAddCandidature,
      paginationLabel,
      emptyDataMessage,
      deleteDialogText,
      columnData,
      sending,
      onSwitchActive,
      handleNavigateToReportInterview,
    } = this.props;

    const {
      rowsPerPage,
      page,
      data,
      order,
      orderBy,
    } = this.state;

    const start = page * rowsPerPage;
    const end = (
      page * rowsPerPage
    ) + rowsPerPage;
    const show = data && data.length;

    // Elements

    const addButton = (
      <Fab
        className={classes.addButton}
        color="primary"
        onClick={this.handleAdd}
      >
        <AddIcon />
      </Fab>
    );

    const tableHeader = (
      <CustomTableHead
        onRequestSort={this.props.canReorder
          ? null
          : this.handleRequestSort}
        order={order}
        orderBy={orderBy}
        rowCount={data.length}
        columnData={columnData}
      />
    );

    const tableBody = (
      <TableBody>
        {data.slice(start, end).map(element => (
          <CustomTableRow
            key={element.id}
            onClickRow={onClickRow}
            onSearch={onSearch}
            onList={onList}
            onLink={onLink}
            onEdit={onEdit}
            onDelete={onDelete}
            onAddCandidature={onAddCandidature}
            onOptions={element.state < STEPS.CANDIDATE_REJECTED.value
              ? onOptions
              : null}
            element={element}
            deleteDialogText={deleteDialogText}
            sending={sending}
            withDeleteButton={this.props.showDelete && this.props.deleteExceptId !== element.id}
            onUpOrder={this.props.canReorder
              ? this.props.onUpOrder
              : null}
            onDownOrder={this.props.canReorder
              ? this.props.onDownOrder
              : null}
            shouldArchive={this.props.shouldArchive}
            dataLength={data.length}
            onSwitchActive={onSwitchActive}
          >
            {columnData.map(column => (
              <TableCell
                className={this.props.onSwitchActive && !element.is_active
                  ? classes.tableCellDisabled
                  : classes.tableCell}
                align={column.align}
                size={column.size || 'medium'}
                key={columnData.indexOf(column)}
                style={column.style}
              >
                {CustomTable.displayAttrOfElement(element, column,
                  (
                    column.id === 'report'
                      ? handleNavigateToReportInterview
                      : undefined
                  ),
                )}
              </TableCell>
            ))}
          </CustomTableRow>
        ))}
      </TableBody>
    );

    const tablePagination = (
      <CustomTablePagination
        numberOfItems={data.length}
        rowsPerPage={rowsPerPage}
        label={paginationLabel}
        page={page}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );

    const table = (
      <>
        {this.props.onAdd && addButton}
        <Table className={classes.table} size={'small'}>
          {tableHeader}
          {tableBody}
        </Table>
        {data.length > 10 && tablePagination}
        <Hidden mdUp>
          <div className={classes.mobilePadding} />
        </Hidden>
      </>
    );

    const emptyData = (
      <>
        {this.props.onAdd && addButton}
        <Typography
          variant="h5"
          color="primary"
          className={classes.EmptyData}
        >
          {emptyDataMessage}
        </Typography>
      </>
    );

    if (show) {
      return table;
    }
    return emptyData;
  }
}

export default withStyles(styles)(CustomTable);
