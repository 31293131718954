export const nextFields = [
  {
    id: 'extendor_next',
    type: 'extendor',
    gridMD: 12,
    label: 'Autres',
    noPadding: true,
  },
  {
    id: 'search',
    type: 'richText',
    gridMD: 12,
    label: 'Démarches initiées',
    dependances: ['extendor_next'],
    isMandatory: false,
  },
  {
    id: 'projet',
    type: 'richText',
    gridMD: 12,
    label: 'Adéquation du poste',
    dependances: ['extendor_next'],
    isMandatory: false,
  },
  {
    id: 'ambition',
    type: 'richText',
    gridMD: 12,
    label: 'Perspectives d\'évolution',
    dependances: ['extendor_next'],
    isMandatory: false,
  },
];