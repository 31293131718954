import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import StringService from '../../services/StringService';
import PropTypes from 'prop-types';

class OfferPreviewDialog extends Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  }

  handleCopy = () => {
    navigator.clipboard.writeText(
      StringService.stripHtml(this.props.content),
    );
  };

  render() {
    const {open, handleClose, content} = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted
      >
        <DialogTitle id="alert-dialog-title">
          Prévisualisation de l'offre
        </DialogTitle>
        <DialogContent>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCopy} color="secondary" variant="contained">
            Copier
          </Button>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default OfferPreviewDialog;


