export const hierarchyFormFields = [
  {
    id: 'extendor_hierarchy',
    type: 'extendor',
    gridMD: 12,
    label: 'Rattachement hiérarchique',
    noPadding: true,
  },
  {
    id: 'n_plus_one_lastname',
    type: 'textField',
    label: 'Nom N+1',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
  {
    id: 'n_plus_one_firstname',
    type: 'textField',
    label: 'Prénom N+1',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
  {
    id: 'n_plus_one_position',
    type: 'textField',
    label: 'Fonction N+1',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
  {
    id: 'n_plus_two_lastname',
    type: 'textField',
    label: 'Nom N+2',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
  {
    id: 'n_plus_two_firstname',
    type: 'textField',
    label: 'Prénom N+2',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
  {
    id: 'n_plus_two_position',
    type: 'textField',
    label: 'Fonction N+2',
    dependances: ['extendor_hierarchy'],
    isMandatory: false,
  },
];
