export const CLIENTS_PENDING = 'clients_pending';
export const CLIENTS_FAILED = 'clients_failed';
export const CLIENTS_DOWNLOADED = 'clients_downloaded';
export const CLIENTS_UNLOAD = 'clients_unload';
export const CLIENT_SENDING = 'client_sending';
export const CLIENT_DOWNLOADED = 'client_downloaded';
export const CLIENT_POST_DOWNLOADED = 'client_post_downloaded';
export const CLIENT_POST_REMOVED = 'client_post_removed';
export const CLIENT_CONTACT_DOWNLOADED = 'client_contact_downloaded';
export const CLIENT_CONTACT_REMOVED = 'client_contact_removed';
export const CLIENT_REMOVED = 'client_removed';
export const HR_PROFILS_DOWNLOADED = 'hr_profils_downloaded';
export const ADVANTAGES_DOWNLOADED = 'advantages_downloaded';
export const BROADCASTS_DOWNLOADED = 'broadcasts_downloaded';
