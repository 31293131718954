import {ENV_DEV, ENV_LOCAL, ENV_PREPROD} from "./env";

export const API_CLIENT_ID             = '/clients/:clientId';
export const API_CLIENT_IMAGE_ID       = '/consult/clients/:clientId/image';
export const API_CLIENT_PDF_ID       = '/consult/clients/:clientId/pdf';

export const API_CONTACT_ID             = '/contacts/:contactId';
export const API_POST_ID             = '/posts/:postId';
export const API_OFFER_ID             = '/offers/:offerId';

export const API_CANDIDATE_ID             = '/candidates/:candidateId';

export const API_CANDIDATURE_ID           = '/candidatures/:candidatureId';
export const API_CANDIDATURE_ID_SLOTS           = '/candidatures/:candidatureId/slots';

export const API_LINK_CANDIDATE_POST             = '/candidates/:candidateId/post/:postId';

export const FIREBASE_API_URL = "https://fcm.googleapis.com/fcm/send";

let cloudFunctionURL = "";
if (process.env.REACT_APP_ENV) {
  if (process.env.REACT_APP_ENV === ENV_LOCAL) {
    cloudFunctionURL = 'https://us-central1-adconseilsdev.cloudfunctions.net/testAPN';
  } else if (process.env.REACT_APP_ENV === ENV_DEV) {
    cloudFunctionURL = 'https://us-central1-adconseilsdev.cloudfunctions.net/testAPN';
  }else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
    cloudFunctionURL = 'https://us-central1-adconseilspreprod-34c61.cloudfunctions.net/testAPN';
  }
} else {
  cloudFunctionURL = 'https://us-central1-adconseilsprod.cloudfunctions.net/testAPN';
}

export const CLOUD_FUNCTION_API_URL = cloudFunctionURL;



export const GOOGLE_API_TOKEN_INFO = "https://iid.googleapis.com/iid/info/:pushToken?details=true";
