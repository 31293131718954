import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const contactsPendingAction = () => ({
  type: types.CONTACTS_PENDING,
});
export const contactSendingAction = () => ({
  type: types.CONTACT_SENDING,
});
export const contactsUnloadAction = () => ({
  type: types.CONTACTS_UNLOAD,
});

export const contactsDownloadedAction = contacts => ({
  type: types.CONTACTS_DOWNLOADED,
  payload: contacts,
});

export const contactDownloadedAction = (contact) => ({
  type: types.CONTACT_DOWNLOADED,
  payload: contact
});

export const contactRemovedAction = (contact) => ({
  type: types.CONTACT_REMOVED,
  payload: contact,
});

export const contactsFailedAction = error => ({
  type: types.CONTACTS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function unloadContacts() {
  return (dispatch) => {
    dispatch(contactsUnloadAction());
  }
}

export function getContacts(token, role) {
  return (dispatch) => {
    dispatch(contactsPendingAction());
    return ApiService
      .getContacts(token, role)
      .then((contacts) => {
        dispatch(contactsDownloadedAction(contacts));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postContact(token, role, contact) {
  return (dispatch) => {
    dispatch(contactSendingAction());

    return ApiService
      .postContact(token, role, contact)
      .then((data) => {
        dispatch(contactDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putContact(token, role, contactId, contact) {
  return (dispatch) => {
    dispatch(contactSendingAction());
    return ApiService
      .putContact(token, role, contactId, contact)
      .then((data) => {
        const updatedContact = {
          ...data,
          id: contactId,
        };
        dispatch(contactDownloadedAction(updatedContact));
        return data.id
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteContact(token, role, contactId) {
  return (dispatch) => {
    dispatch(contactSendingAction());

    return ApiService
      .deleteContact(token, role, contactId)
      .then(() => {
        dispatch(contactRemovedAction(contactId));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
