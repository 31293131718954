import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden/Hidden';
import Divider from '@material-ui/core/Divider/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CodeIcon from '@material-ui/icons/Code';
import { CANDIDATES, CLIENTS, OFFERS, TEMPLATES, USERS } from '../../constants/routes';
import ClientIcon from '../../components/icons/ClientIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import OfferIcon from '../../components/icons/OfferIcon';
import HomeIcon from '../../components/icons/HomeIcon';
import { getVariationTheme } from '../../themes/theme';
import { drawerWidth } from './Menu';
import { DEFAULT_WHITE } from '../../themes/defaultColors';

const colorTheme = getVariationTheme();
const headerIconSize = '37px';
export const headerHeight = 60;

const useStyles = makeStyles(theme => (
  {
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    appBar: {
      height: headerHeight,
      justifyContent: 'center',
      marginLeft: drawerWidth,
      color: colorTheme.text_light,
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      backgroundColor: DEFAULT_WHITE,
      background: colorTheme.app_bar_background,
      backgroundSize: 'cover',

    },
  }
));

const HeaderIcon = (item) => {
  return (
    <div>
      {(
        () => {
          if (item.includes(CLIENTS)) {
            return <ClientIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          } else if (item.includes(CANDIDATES)) {
            return <SearchIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          } else if (item.includes(OFFERS)) {
            return <OfferIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          } else if (item.includes(USERS)) {
            return <SupervisorAccountIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          } else if (item.includes(TEMPLATES)) {
            return <CodeIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          } else {
            return <HomeIcon style={{
              color: colorTheme.primary,
              height: headerIconSize,
              width: headerIconSize,
            }} />;
          }
        }
      )()}
    </div>
  );
}

const HeaderBar = (props) => {
  const {openDrawer} = props;
  const classes = useStyles();
  const location = useLocation();
  const { title } = useSelector(state => state.title);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={openDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Hidden mdDown>
          {HeaderIcon(location.pathname)}
        </Hidden>
        <Divider style={{ width: '10px' }} />
        <Typography variant="h1" color="inherit">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
