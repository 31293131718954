import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HomeIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M18.9902,19.9878 L16.0002,19.9878 L16.0002,12.9878 C16.0002,12.4348 15.5522,11.9878 15.0002,11.9878 L9.0002,11.9878 C8.4472,11.9878 8.0002,12.4348 8.0002,12.9878 L8.0002,19.9878 L5.0002,19.9878 L5.0062,11.5708 L11.9982,4.4198 L19.0002,11.6118 L18.9902,19.9878 Z M10.0002,19.9878 L10.0002,13.9878 L14.0002,13.9878 L14.0002,19.9878 L10.0002,19.9878 Z M20.4242,10.1728 L12.7152,2.2888 C12.3382,1.9038 11.6622,1.9038 11.2852,2.2888 L3.5752,10.1738 C3.2102,10.5488 3.0002,11.0728 3.0002,11.6118 L3.0002,19.9878 C3.0002,21.0908 3.8472,21.9878 4.8882,21.9878 L9.0002,21.9878 L15.0002,21.9878 L19.1112,21.9878 C20.1522,21.9878 21.0002,21.0908 21.0002,19.9878 L21.0002,11.6118 C21.0002,11.0728 20.7902,10.5488 20.4242,10.1728 L20.4242,10.1728 Z" />
    </SvgIcon>
  );
};

export default HomeIcon;
