import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import * as string from '../../constants/string';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { getVariationTheme } from '../../themes/theme';

const colorTheme = getVariationTheme();

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
};

const StopDialog = props => {
  // Constant
  const {
    openStopModal,
    handleToggleStopModal,
    handleReason,
    consultRejectComment,
    stop,
    handleComment,
    handleStop,
  } = props;

  const handleClickReason = (number) => {
    // eslint-disable-next-line
    switch (number) {
      case 1:
        handleReason(number - 1, string.REASON_REJECT_1, string.REJECT_1);
        break;
      case 2:
        handleReason(number - 1, string.REASON_REJECT_2, string.REJECT_2);
        break;
      case 3:
        handleReason(number - 1, string.REASON_REJECT_3, string.REJECT_3);
        break;
      case 4:
        handleReason(number - 1, string.REASON_REJECT_4, string.REJECT_4);
        break;
      case 5:
        handleReason(number - 1, string.REASON_REJECT_5, string.REJECT_5);
        break;
      case 6:
        handleReason(number - 1, string.REASON_REJECT_6, string.REJECT_6);
        break;
      case 7:
        handleReason(number - 1, string.REASON_REJECT_7, string.REJECT_7);
        break;
    }
  };

  return (

    <Dialog
      fullScreen={false}
      open={openStopModal}
      onClose={handleToggleStopModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle
        id="responsive-dialog-title"
        onClose={handleToggleStopModal}
      >
        Refuser la candidature
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" style={{ color: colorTheme.menu }}>
          Quelle est le motif ?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4} sm={4} md={4}>
            <List component="nav" aria-label="secondary mailbox folders">
              {[0, 1, 2, 3, 4, 5, 6].map((number) => (
                <ListItem
                  selected={stop === number}
                  onClick={() => handleClickReason(number + 1)}
                  button
                >
                  <ListItemText primary={string[`REASON_REJECT_${number + 1}`]} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            direction="column"
            className={props.classes.gridContainer}
          >
            <TextField
              id="consult_reject_comment"
              multiline
              value={consultRejectComment}
              style={{ width: '100%' }}
              margin="normal"
              variant="outlined"
              onChange={handleComment}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleStop}
          color="secondary"
        >
          Envoyer le refus
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StopDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openStopModal: PropTypes.bool,
  handleToggleStopModal: PropTypes.func,
  handleReason: PropTypes.func,
  consultRejectComment: PropTypes.string,
  stop: PropTypes.number,
  handleComment: PropTypes.func,
  handleStop: PropTypes.func,
};

export default withStyles(styles)(StopDialog);
