import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmailIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M19,18 L5,18 C4.449,18 4,17.552 4,17 L4,7.25 L11.4,12.8 C11.578,12.934 11.789,13 12,13 C12.211,13 12.422,12.934 12.6,12.8 L20,7.25 L20,17 C20,17.552 19.551,18 19,18 L19,18 Z M18.333,6 L12,10.75 L5.667,6 L18.333,6 Z M19,4 L5,4 C3.346,4 2,5.346 2,7 L2,17 C2,18.654 3.346,20 5,20 L19,20 C20.654,20 22,18.654 22,17 L22,7 C22,5.346 20.654,4 19,4 L19,4 Z" />
    </SvgIcon>
  );
};

export default EmailIcon;
