import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import Spinner from '../common/Spinner';
import CustomForm from '../common/form/CustomForm';
import { getUsers } from '../../stores/users/actions';
import { OFFERS } from '../../constants/routes';
import StringService from '../../services/StringService';
import ApiService from '../../services/ApiService';
import { getStaticJobs } from '../../stores/statics/actions';
import { getOffers, postOffer, putOffer } from '../../stores/offers/actions';
import { getClients } from '../../stores/clients/actions';
import OfferPreviewDialog from '../../containers/offers/OfferPreviewDialog';
import { computeOfferContent } from '../../services/OfferService';
import {
  CONTRACTS,
  POST_STATUS,
} from '../../constants/offer';
import { offerFields } from '../../constants/form/offer/offerFields';

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  status: '',
  status_comment: '',
  name: '',
  contract: '',
  contract_comment: '',
  client_post_name: '',
  reason: '',
  induction_date: StringService.dateTimestampToStringForForm(Date.now() / 1000 | 0),
  is_intern_possible: false,
  intern_info: '',
  has_transition_advisor: false,
  transition_info: '',
  n_plus_one_firstname: '',
  n_plus_one_lastname: '',
  n_plus_one_position: '',
  n_plus_two_firstname: '',
  n_plus_two_lastname: '',
  n_plus_two_position: '',
  department_name: '',
  team_size: 0,
  team_interactions: '',
  people_to_manage: '',
  people_to_operate: '',
  work_place: '',
  mobility_wanted: false,
  mobility_info: '',
  schedule: '',
  induction_period: '',
  training_intern: '',
  training_extern: '',
  advisors: '',
  referents: '',
  reference_departement: '',
  initiative_level: 0,
  autonomy_level: 0,
  main_tasks: '',
  lesser_tasks: '',
  schooling_level: '',
  diploma: '',
  training: '',
  authorization: '',
  level: '',
  level_comments: '',
  main_sector: '',
  experiences: '',
  experience_years: 0,
  openness: '',
  language1: '',
  language_level1: '',
  language2: '',
  language_level2: '',
  language3: '',
  language_level3: '',
  comments: '',
  salary12_month: false,
  salary13_month: false,
  salary14_month: false,
  salary: false,
  salary_info: '',
  variable_pay: false,
  variable_pay_info: '',
  annual_bonus: false,
  annual_bonus_by_quarter: false,
  ranking_bonus: false,
  ca_commission: false,
  ca_commission_percentage: 0.0,
  ca_commission_info: '',
  brut_commission: false,
  brut_commission_percentage: 0.0,
  brut_commission_info: '',
  net_commission: false,
  net_commission_percentage: 0.0,
  net_commission_info: '',
  stake: false,
  profit_sharing: false,
  company_vehicle: false,
  other_benefits: '',
  competition: false,
  competition_info: '',
  specific_advantages: '',
  skills: [],
  softskills: [],
  deptOptions: [],
  diplomaOptions: [],
  extendor_post: false,
  extendor_poste: true,
  extendor_contract: true,
  extendor_hierarchy: false,
  extendor_team: false,
  extendor_intern: false,
  extendor_contexte: false,
  extendor_mission: false,
  extendor_level: false,
  extendor_language: false,
  extendor_salary: false,
  extendor_client: false,
  extendor_other: false,
  salary_min: 0,
  salary_max: 0,
  has_diffusion: true,
  is_validate: false,
  content: '',
  excerpt: '',
  original_post: null,
  loadedFromPost: false,
  referent_name: '',
  waitingTalentPlug: false,
};

const stateWithOffer = (offer) => (
  {
    id: offer.id,
    salary_min: offer.salary_min,
    salary_max: offer.salary_max,
    has_diffusion: offer.has_diffusion,
    is_validate: offer.is_validate,
    link: offer.link,
    content: offer.content,
    excerpt: offer.excerpt,
    type: offer.type,
    offer_name: offer.offer_name,
    original_post_id: offer.original_post
      ? offer.original_post.id
      : null,
    referent_name: offer.original_post
      ? offer.original_post.client_referent_name
      : '',
    client_values: offer.client_values,
    client_vision: offer.client_vision,
    offerPreview: computeOfferContent(offer),
  }
);

const stateWithPost = (post, isCurrent = false) => (
  {
    current_post_id: isCurrent
      ? null
      : post.id,
    status: POST_STATUS.hasOwnProperty(post.status)
      ? post.status
      : 'Autre',
    status_comment: post.status_comment
      ? post.status_comment
      : '',
    name: {
      value: post.name
        ? post.name
        : '',
      label: post.name
        ? post.name
        : '',
    },
    profile: post.profile,
    contract: post.contract,
    contract_comment: post.contract_comment
      ? post.contract_comment
      : '',
    client_post_name: post.client_post_name,
    reason: post.reason,
    induction_date: StringService.dateTimestampToStringForForm(post.induction_date),
    is_intern_possible: post.is_intern_possible,
    intern_info: post.intern_info,
    has_transition_advisor: post.has_transition_advisor,
    transition_info: post.transition_info,
    n_plus_one_firstname: post.n_plus_one_firstname,
    n_plus_one_lastname: post.n_plus_one_lastname,
    n_plus_one_position: post.n_plus_one_position,
    n_plus_two_firstname: post.n_plus_two_firstname,
    n_plus_two_lastname: post.n_plus_two_lastname,
    n_plus_two_position: post.n_plus_two_position,
    department_name: {
      value: post.department_name
        ? post.department_name
        : '',
      label: post.department_name
        ? post.department_name
        : '',
    },
    team_size: post.team_size,
    team_interactions: post.team_interactions,
    people_to_manage: post.people_to_manage,
    people_to_operate: post.people_to_operate,
    work_place: post.work_place,
    mobility_wanted: post.mobility_wanted,
    mobility_info: post.mobility_info,
    schedule: post.schedule,
    induction_period: post.induction_period,
    training_intern: post.training_intern,
    training_extern: post.training_extern,
    advisors: post.advisors,
    referents: post.referents,
    reference_department: post.reference_department
      ? post.reference_department
      : '',
    initiative_level: post.initiative_level,
    autonomy_level: post.autonomy_level,
    main_tasks: post.main_tasks,
    lesser_tasks: post.lesser_tasks,
    schooling_level: post.schooling_level,
    diploma: {
      value: post.diploma
        ? post.diploma
        : '',
      label: post.diploma
        ? post.diploma
        : '',
    },
    training: post.training,
    authorization: post.authorization,
    level: post.level,
    level_comments: post.level_comments,
    main_sector: post.main_sector,
    experiences: post.experiences,
    experience_years: post.experience_years,
    openness: post.openness,
    language1: post.language1,
    language_level1: post.language_level1,
    language2: post.language2,
    language_level2: post.language_level2,
    language3: post.language3,
    language_level3: post.language_level3,
    comments: post.comments,
    salary12_month: post.salary12_month,
    salary13_month: post.salary13_month,
    salary14_month: post.salary14_month,
    salary: post.salary,
    salary_info: post.salary_info,
    variable_pay: post.variable_pay,
    variable_pay_info: post.variable_pay_info,
    annual_bonus: post.annual_bonus,
    annual_bonus_by_quarter: post.annual_bonus_by_quarter,
    ranking_bonus: post.ranking_bonus,
    ca_commission: post.ca_commission,
    ca_commission_percentage: post.ca_commission_percentage,
    ca_commission_info: post.ca_commission_info,
    brut_commission: post.brut_commission,
    brut_commission_percentage: post.brut_commission_percentage,
    brut_commission_info: post.brut_commission_info,
    net_commission: post.net_commission,
    net_commission_percentage: post.net_commission_percentage,
    net_commission_info: post.net_commission_info,
    stake: post.stake,
    profit_sharing: post.profit_sharing,
    company_vehicle: post.company_vehicle,
    other_benefits: post.other_benefits,
    competition: post.competition,
    competition_info: post.competition_info,
    skills: post.skills.map(skill =>
      (
        {
          value: skill.code_ogr,
          label: skill.libelle_competence,
        }
      ),
    ),
    softskills: post.softskills.map(softSkill =>
      (
        {
          value: softSkill.summary,
          label: softSkill.summary,
        }
      ),
    ),
    specific_advantages: post.specific_advantages
      ? post.specific_advantages
      : '',
    client_values: post.client_values,
    client_vision: post.client_vision,
  }
);

export class OfferForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    postClientPost: PropTypes.func.isRequired,
    putClientPost: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    posts: null,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (props.authUser && !props.clients && !props.pending && props.roles) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken().then(token => {
        props.getClients(token, role);
      });
    }
    if (props.authUser && !props.offers && !props.pending) {
      props.authUser.getIdToken().then(token => {
        props.getOffers(token);
      });
    }
    if (props.authUser && !props.jobs && !props.pending) {
      props.getStaticJobs();
    }
    if (props.clients
      && props.offers
      && props.match
      && props.match.params.offerId
      && state.id === INITIAL_STATE.id
    ) {
      const currentOfferId = props.match.params.offerId;
      const foundOffer = props.offers.find(offer => offer.id === currentOfferId);

      if (foundOffer) {
        newState = {
          ...newState,
          offer: foundOffer,
        };
        if (foundOffer.current_post) {
          let foundClient = null;
          if (foundOffer.original_post) {
            foundClient = props.clients.find(client => client.id
              === foundOffer.original_post.client_id);
          }
          if (foundClient) {
            newState = {
              ...newState,
              client_website: foundClient.website,
              client_sector: foundClient.sector,
              client_zipcode: foundClient.zip_code,
              client_address: foundClient.address_line1,
              client_business_name: foundClient.business_name,
            };
          } else {
            newState = {
              ...newState,
              client_website: '',
              client_sector: '',
              client_zipcode: '',
              client_address: '',
              client_business_name: '',
            };
          }
          newState = { ...newState, ...stateWithPost(foundOffer.current_post) };
        }
        newState = { ...newState, ...stateWithOffer(foundOffer) };
      }
    }
    if (props.clients && props.location && props.location.state && !state.loadedFromPost) {
      const originalPost = props.location.state;
      newState = {
        ...newState,
        original_post_id: originalPost.id,
      };
      newState = { ...newState, ...stateWithPost(originalPost, true) };
      const foundClient = props.clients.find(client => client.id === originalPost.client_id);
      if (foundClient) {
        newState = {
          ...newState,
          client_website: foundClient.website,
          client_sector: foundClient.sector,
          client_zipcode: foundClient.zip_code,
          client_address: foundClient.address_line1,
          client_business_name: foundClient.business_name,
        };
      }
      const posts = foundClient.posts;
      const depts = posts.map(post => post.department_name);
      newState = {
        ...newState,
        deptOptions: depts.map(dept =>
          (
            {
              value: dept,
              label: dept,
            }
          ),
        ),
      };
      const diplomas = posts.map(post => post.diploma);
      newState = {
        ...newState,
        diplomaOptions: diplomas.map(diploma =>
          (
            {
              value: diploma,
              label: diploma,
            }
          ),
        ),
      };
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      previewOpen: false,
      skillsLoading: false,
      softSkillsLoading: false,
      offerPreview: '',
      ...INITIAL_STATE,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== INITIAL_STATE && this.props.authUser && this.state.name) {
      if (!this.state.skillsDatas && !this.state.skillsLoading) {
        ApiService.getSkills(this.state.name.value.substring(0, 5))
          .then((data) => {
            this.setState({
              skillsLoading: false,
              skillsDatas: data,
            });
          });
        this.setState({ skillsLoading: true });
      }
      if (!this.state.softskillsDatas && !this.state.softSkillsLoading) {
        ApiService.getSoftSkills(this.state.name.value.substring(0, 5))
          .then((data) => {
            this.setState({
              softskillsDatas: data,
              softSkillsLoading: false,
            });
          });
        this.setState({ softSkillsLoading: true });
      }
    }
  }

  // onFormChange = async (formState) => {
  //   this.setState({ offerPreview: computeOfferContent(formState) });
  // };

  updateFormState = (formState) => (
    {
      current_post: {
        id: this.state.current_post_id
          ? this.state.current_post_id
          : null,
        induction_date: StringService.dateStringToTimestamp(formState.induction_date),
        team_size: parseInt(formState.team_size, 10),
        people_to_manage: formState.people_to_manage,
        people_to_operate: formState.people_to_operate,
        initiative_level: parseInt(formState.initiative_level, 10),
        autonomy_level: parseInt(formState.autonomy_level, 10),
        experience_years: parseInt(formState.experience_years, 10),
        ca_commission_percentage: formState.ca_commission_percentage !== ''
          ? parseFloat(formState.ca_commission_percentage)
          : 0,
        brut_commission_percentage: formState.brut_commission_percentage !== ''
          ? parseFloat(formState.brut_commission_percentage)
          : 0,
        net_commission_percentage: formState.net_commission_percentage !== ''
          ? parseFloat(formState.net_commission_percentage)
          : 0,
        status: formState.status,
        contract: formState.contract,
        name: formState.name
          ? formState.name.value
          : '',
        softskills: formState.softskills.map((softSkill) =>
          (
            {
              summary: softSkill.value,
              id: softSkill.id,
            }
          ),
        ),
        skills: formState.skills.map((skill) =>
          (
            { code_ogr: skill.value }
          ),
        ),
        contract_comment: formState.contract_comment,
        status_comment: formState.status_comment,
        client_post_name: formState.client_post_name,
        reason: formState.reason,
        is_intern_possible: formState.is_intern_possible,
        intern_info: formState.intern_info,
        has_transition_advisor: formState.has_transition_advisor,
        transition_info: formState.transition_info,
        n_plus_one_firstname: formState.n_plus_one_firstname,
        n_plus_one_lastname: formState.n_plus_one_lastname,
        n_plus_one_position: formState.n_plus_one_position,
        n_plus_two_firstname: formState.n_plus_two_firstname,
        n_plus_two_lastname: formState.n_plus_two_lastname,
        n_plus_two_position: formState.n_plus_two_position,
        department_name: formState.department_name.value,
        team_interactions: formState.team_interactions,
        work_place: formState.work_place,
        mobility_wanted: formState.mobility_wanted,
        mobility_info: formState.mobility_info,
        schedule: formState.schedule,
        induction_period: formState.induction_period,
        training_intern: formState.training_intern,
        training_extern: formState.training_extern,
        advisors: formState.advisors,
        referents: formState.referents,
        reference_department: formState.reference_department,
        main_tasks: formState.main_tasks,
        lesser_tasks: formState.lesser_tasks,
        schooling_level: formState.schooling_level,
        diploma: formState.diploma
          ? formState.diploma.value
          : '',
        training: formState.training,
        authorization: formState.authorization,
        level: formState.level,
        level_comments: formState.level_comments,
        main_sector: formState.main_sector,
        experiences: formState.experiences,
        openness: formState.openness,
        language1: formState.language1,
        language_level1: formState.language_level1,
        language2: formState.language2,
        language_level2: formState.language_level2,
        language3: formState.language3,
        language_level3: formState.language_level3,
        comments: formState.comments,
        salary12_month: formState.salary12_month,
        salary13_month: formState.salary13_month,
        salary14_month: formState.salary14_month,
        salary: formState.salary,
        salary_info: formState.salary_info,
        variable_pay: formState.variable_pay,
        variable_pay_info: formState.variable_pay_info,
        annual_bonus: formState.annual_bonus,
        annual_bonus_by_quarter: formState.annual_bonus_by_quarter,
        ranking_bonus: formState.ranking_bonus,
        ca_commission: formState.ca_commission,
        ca_commission_info: formState.ca_commission_info,
        brut_commission: formState.brut_commission,
        brut_commission_info: formState.brut_commission_info,
        net_commission: formState.net_commission,
        net_commission_info: formState.net_commission_info,
        stake: formState.stake,
        profit_sharing: formState.profit_sharing,
        company_vehicle: formState.company_vehicle,
        other_benefits: formState.other_benefits,
        competition: formState.competition,
        competition_info: formState.competition_info,
        profile: formState.profile.trim() === '<p><br></p>'
          ? ''
          : formState.profile,
      },
      salary_min: parseInt(formState.salary_min, 10),
      salary_max: parseInt(formState.salary_max, 10),
      has_diffusion: formState.has_diffusion,
      is_validate: formState.is_validate,
      content: formState.content,
      excerpt: formState.excerpt.trim() === '<p><br></p>'
        ? ''
        : formState.excerpt,
      offer_name: formState.offer_name,
      type: formState.type,
      client_values: formState.client_values,
      client_vision: formState.client_vision,
      original_post_id: this.state.original_post_id,
    }
  );

  checkData = (formState) => {
    let formErrors = [];
    // TODO add more verification
    [
      {
        id: 'offer_name',
        name: 'Nom de l\'offre',
      },
      {
        id: 'profile',
        name: 'Profil (max 2500 caractères)',
      },
      {
        id: 'excerpt',
        name: 'Résumé (max 2300 caractères)',
      },
    ].forEach(mandatoryFields => {
        console.log(mandatoryFields);
        if (
          formState[mandatoryFields.id] === null
          || !formState[mandatoryFields.id]
          || formState[mandatoryFields.id] === ''
          || formState[mandatoryFields.id] === "<p><br></p>") {
          formErrors.push(mandatoryFields.name);
        }
      },
    );

    return formErrors;
  };

  onSubmit = (event, formState) => {
    event.preventDefault();
    const errorFields = this.checkData(formState);
    if (errorFields.length === 0) {
      this.props.authUser.getIdToken().then(token => {
        let updatedFormState = this.updateFormState(formState);
        if (this.state.id === INITIAL_STATE.id) {
          this.props.postOffer(
            token,
            updatedFormState,
          ).then((offerId) => {
            if (offerId) {
              this.props.history.push(OFFERS);
            }
          }).catch((error) => {
            this.props.handleError(error);
          });
        } else {
          this.props.putOffer(
            token,
            this.state.id,
            updatedFormState,
          ).then((offerId) => {
            if (offerId) {
              this.props.history.push(OFFERS);
            }
          }).catch((error) => {
            this.props.handleError(error);
          });
        }
      });
    } else {
      this.props.handleError({ 'message': 'Certains champs sont incorrectes : ' + errorFields[0] });
    }
  };

  computeContent = (form) => {
    let content = form.excerpt || '';
    const variables = content.match(/(\$\S*?\$)/g);
    if (variables) {
      variables.forEach((variable) => {
        if (variable === '$salary_slider$') {
          content = content.replace(variable, form.salary_min + ' à ' + form.salary_max);
        } else {
          const value = form[variable.replace(/\$/g, '')];
          if (typeof value !== 'undefined') {
            if (Array.isArray(value)) {
              let newContent = '';
              value.forEach(object => {
                newContent += form.summary || object.libelle_competence;
                newContent += ', ';
              });
              content = content.replace(variable, newContent);
            } else {
              content = content.replace(variable, value);
            }
          } else {
            const value = form[variable.replace(/\$/g, '')];
            if (typeof value !== 'undefined') {
              content = content.replace(variable, value);
            }
          }
        }
      });
    }
    return content;
  };

  sendTalentPlugRequest = (token) => {
    this.setState({ waitingTalentPlug: true });
    ApiService.postMyTalentPlugOfferSendToUbipostingLite(this.state.id, token)
      .then((talentPlug) => {
        if (talentPlug.includes('Error')) {
          this.props.handleError({ message: talentPlug });
        } else {
          window.open(talentPlug, "_blank");
        }
        this.setState({ waitingTalentPlug: false });
      });
  };

  onShare = (event, formState) => {
    event.preventDefault();
    this.props.authUser.getIdToken().then(token => {
      let updatedFormState = this.updateFormState(formState);
      if (this.state.id === INITIAL_STATE.id) {
        this.props.postOffer(
          token,
          updatedFormState,
        ).then((offerId) => {
          if (offerId) {
            this.sendTalentPlugRequest(token);
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      } else {
        this.props.putOffer(
          token,
          this.state.id,
          updatedFormState,
        ).then((offerId) => {
          if (offerId) {
            this.sendTalentPlugRequest(token);
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      }
    });
  };

  computeLinkedInContent = (form) => {
    return 'AD Conseils recrute pour son client '
      + form.client_business_name
      + ' un(e) '
      + form.client_post_name
      + '. Statut : '
      + POST_STATUS[form.status]
      + ' / Contrat : '
      + CONTRACTS[form.contract]
      + '.\nPour candidater et voir le détail de l\'offre cliquez sur l\'image ';
  };

  onShareLinkedIn = (event, formState) => {

    this.props.getUsers().then(users => {
      const currentUserId = this.props.authUser.uid;
      const foundUser = users.find(user => user.uid === currentUserId);

      if (foundUser.accessToken && foundUser.linkedInId
        && foundUser.expiresAt > Math.floor(Date.now() / 1000)) {

        this.props.authUser.getIdToken().then(token => {
          ApiService.shareOfferLinkedIn(
            foundUser.accessToken,
            foundUser.linkedInId,
            StringService.stripHtml(this.computeLinkedInContent(formState)),
            formState.link,
            token,
          ).then(() => {
            this.props.dispatchInfo('Partage LinkedIn terminé');
          }).catch((error) => {
            this.props.handleError(error);
          });
        });
      } else {

        ApiService.getLinkedInAuthorization();
      }

    });
    event.preventDefault();

  };

  onChangeObservable = (changedState) => {
    if (changedState.name) {
      if (this.props.authUser) {
        ApiService.getSkills(changedState.name.value.substring(0, 5)).then((data) => {
          this.setState({ skillsDatas: data });
        });
        ApiService.getSoftSkills(changedState.name.value.substring(0, 5)).then((data) => {
          this.setState({ softskillsDatas: data });
        });
      }
    } else if (changedState.content !== undefined) {
      this.setState({
        variable: null,
        content: changedState.content,
      });
    } else {
      this.setState({ variable: null });
    }
  };

  onAddVariable = (variable) => {
    if (variable !== 'status_comment' && variable !== 'contract_comment') {
      this.setState({ variable: '$' + variable + '$' });
    }
  };

  onChange = (formState) => {
    this.setState(formState);
  };

  handleOpenPreview = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.setState({
      offerPreview: computeOfferContent(this.state),
      previewOpen: true,
    });
  };
  handleClosePreview = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.setState({ previewOpen: false });
  };

  render() {

    const {
      jobs,
      pending,
      sending,
    } = this.props;

    const {
      id,
      skillsDatas,
      softskillsDatas,
      deptOptions,
      diplomaOptions,
    } = this.state;

    let jobsOptions = [];
    if (jobs) {
      jobsOptions = jobs.map(job => {
        const str = `${job.code_rome} - ${job.libelle_rome}`;
        return {
          value: str,
          label: str,
        };
      });
    }
    let skillsOptions = [];
    if (skillsDatas) {
      skillsOptions = skillsDatas.map(skill =>
        (
          {
            value: skill.code_ogr,
            label: skill.libelle_competence,
          }
        ),
      );
    }
    let softSkillsOptions = [];
    if (softskillsDatas) {
      softSkillsOptions = softskillsDatas.map(softSkill =>
        (
          {
            value: softSkill.summary,
            label: softSkill.summary,
          }
        ),
      );
    }

    if (pending) {
      return (
        <Spinner />
      );
    } else {
      return (
        <div>
          <CustomForm
            data={this.state}
            formFields={offerFields(
              jobsOptions,
              skillsOptions,
              softSkillsOptions,
              deptOptions,
              diplomaOptions,
            )}
            buttonLabel={
              sending
                ? (
                  <Spinner variant="nomargin" />
                )
                : (
                  id === ''
                    ? 'Créer'
                    : 'Éditer'
                )
            }
            onSubmit={this.onSubmit}
            onShare={this.state.id
              ? this.onShare
              : null}
            onShareLinkedIn={this.state.id
              ? this.onShareLinkedIn
              : null}
            onChangeObservable={this.onChangeObservable}
            onAddVariable={this.onAddVariable}
            sending={sending || this.state.waitingTalentPlug}
            shouldSplit={true}
            shouldUseFastList={true}
            onPreview={this.handleOpenPreview}
            onChange={this.onChange}
          />
          <OfferPreviewDialog
            open={this.state.previewOpen}
            handleClose={this.handleClosePreview}
            content={this.state.offerPreview}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    offers: state.offers.content,
    pending: state.clients.pending || state.offers.pending || state.statics.pending,
    sending: state.offers.sending,
    jobs: state.statics.jobs,
  };
}

export default compose(
  connect(mapStateToProps, {
    getUsers,
    getClients,
    getOffers,
    postOffer,
    putOffer,
    getStaticJobs,
    handleError,
    dispatchInfo,
  }),
)(OfferForm);
