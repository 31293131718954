import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as ReactDOM from 'react-dom';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider/Divider';
import Typography from '@material-ui/core/Typography/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { quillFormats, quillModules } from '../../../constants/quill';
import CustomFormContainer from './CustomFormContainer';
import CustomTextField from './CustomTextField';
import CustomPasswordField from './CustomPasswordField';
import CustomSelectField from './CustomSelectField';
import CustomPictureDropzone from './CustomPictureDropzone';
import CustomCheckboxField from './CustomCheckboxField';
import { handleError } from '../../../stores/infos/actions';
import CustomPDFDropzone from './CustomPDFDropzone';
import CustomSliderField from './CustomSliderField';
import CustomExtendor from './CustomExtendor';
import CustomCheckboxSelector from './CustomCheckboxSelector';
import CustomCheckboxNormal from './CustomCheckboxNormal';
import { getVariationTheme, defaultStyles } from '../../../themes/theme';
import SaveIcon from '../../icons/SaveIcon';
import ShareIcon from '../../icons/ShareIcon';
import LinkedInIcon from '../../icons/LinkedInIcon';
import EyeIcon from '../../icons/EyeIcon';

const colorTheme = getVariationTheme();

/**
 * Style of Form elements
 *
 * @returns json
 */
export const styles = (theme) => (
  {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      float: 'left',
      marginTop: '1em',
      fontWeight: 'bold',
    },
    noPadding: {
      paddingBottom: '0!important',
      paddingTop: '0!important',
    },
    richTextLabel: {
      fontWeight: 'bold',
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1,
      float: 'left',
      margin: '8px',
      paddingTop: '8px',
    },
    saveButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '1em',
      right: '1em',
      zIndex: '5',
    },
    previewButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '1em',
      right: '6.3em',
      zIndex: '5',
    },
    shareButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '1em',
      [theme.breakpoints.down('lg')]: {
        left: '1em',
      },
      [theme.breakpoints.up('lg')]: {
        left: `calc(2em + 240px)`,
      },
      zIndex: '5',
    },
    shareLinkedInButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '1em',
      [theme.breakpoints.down('lg')]: {
        left: `calc(1em + 60px)`,
      },
      [theme.breakpoints.up('lg')]: {
        left: `calc(3em + 300px)`,
      },
      zIndex: '5',
    },
    card: {
      width: '100%',
      padding: '8px',
      overflow: 'unset',
      marginBottom: '20px',
    },
  }
);

class CustomForm extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        picture: PropTypes.string, // Only mandatory for pictureDropzone.
        pdf: PropTypes.string, // Only mandatory for pdfDropzone.
        type: PropTypes.string, //TODO : Only in define array [textField, richText, pictureDropzone, checkboxField, selectField, passwordField, sliderField]
        subtype: PropTypes.string, //TODO : Only in define array [time, date, number]
        condition: PropTypes.bool,
        dependances: PropTypes.array,
        disabled: PropTypes.bool,
        autocomplete: PropTypes.bool, // Only for selectField
        multiple: PropTypes.bool, // Only for selectField
        gridMD: PropTypes.number,
        label: PropTypes.string,
        options: PropTypes.object,
        noOptions: PropTypes.string, // Only for selectField
        minLength: PropTypes.number, // Only for selectField
        creatable: PropTypes.bool, // Only for selectField
        isMandatory: PropTypes.bool,
        isPartialMandatory: PropTypes.bool,
        errorRegex: PropTypes.object, // TODO : Make it more genec to use in other component than CustomPasswordField
        onChange: PropTypes.func,
        noPadding: PropTypes.bool,
        step: PropTypes.number, // Only for sliderField
        min: PropTypes.number, // Only for sliderField
        max: PropTypes.number, // Only for sliderField
        marks: PropTypes.array, // Only for sliderField
        observable: PropTypes.bool,
        maxSize: PropTypes.number,
        InputProps: PropTypes.object,
      }),
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onShare: PropTypes.func,
    onShareLinkedIn: PropTypes.func,
    onChangeObservable: PropTypes.func,
    onAddVariable: PropTypes.func,
    buttonLabel: PropTypes.string,
    handleError: PropTypes.func.isRequired,
    typeForm: PropTypes.number,
    sending: PropTypes.bool,
    shouldSplit: PropTypes.bool,
    shouldUseFastList: PropTypes.bool,
    onPreview: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    sending: false,
    typeForm: 0,
    buttonLabel: 'OK',
    shouldSplit: false,
    shouldUseFastList: false,
    onShare: null,
    onShareLinkedIn: null,
    formFields: {
      maxSize: 0,
    },
  };
  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);
    this.state = { ...props.data };
  }

  // ----------------
  // Computing

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onChange !== undefined
      && JSON.stringify(this.state) !== JSON.stringify(prevState)
    ) {
      this.props.onChange(this.state);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };
    if (props.typeForm !== 0
      && JSON.stringify(props.data) !== JSON.stringify(state) && props.onUpdate) {
      props.onUpdate(newState);
    }
    if (props.data.variable && !state.content.includes(props.data.variable)) {
      newState = {
        ...newState,
        content: state.content + props.data.variable,
      };
    }
    return newState;
  }

  static filterObjectByKeys = (object, keys) => {
    return Object.keys(object)
      .filter(key => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
      }, {});
  };
  isValidFormFields = (formFields) => {
    let isValid = true;
    let isPartialValid = !formFields.find(field => field.isPartialMandatory);
    formFields.forEach(field => {
      if (field.isMandatory) {
        isValid = isValid && this.state[field.id] !== null && this.state[field.id] !== '';
        if (field.errorRegex) {
          isValid = isValid && this.state[field.id].match(field.errorRegex);
        }
      }
      if (field.isPartialMandatory) {
        isPartialValid = isPartialValid || (
          this.state[field.id] !== null && this.state[field.id] !== ''
        );
      }
    });
    return isValid && isPartialValid;
  };

  handleMediaDrop = (accepted, rejected, path, picture) => {
    if (rejected.length) {
      this.props.handleError({
        message: 'Le fichier attendu doit être une image, le fichier envoyé est de type '
          + rejected[0].type,
      });
    } else {
      this.setState({
        [path]: URL.createObjectURL(accepted[0]),
        [picture]: accepted[0],
      });
    }
  };

  displayFormField = (field, classes) => {
    if (typeof field.id === 'undefined') {
      return;
    }

    if (field.dependances) { //TODO : make more generic in order to handle other kind of conditions
      let dependanceNotMatched = false;
      field.dependances.forEach((dependance) => {
        const dependances = dependance.split('===');
        if (dependances.length === 2 && this.state[dependances[0]] !== dependances[1]) {
          dependanceNotMatched = true;
        } else if (dependances.length === 1) {
          if (dependances[0].includes('!')) {
            if (this.state[dependances[0].replace('!', '')]) {
              dependanceNotMatched = true;
            }
          } else if (!this.state[dependances[0]]) {
            dependanceNotMatched = true;
          }
        }
      });
      if (dependanceNotMatched) {
        return;
      }
    }

    if (typeof (
      field.condition
    ) !== 'undefined' && !field.condition) {
      return;
    }

    let gridMD = field.gridMD || 4;

    if (field.type) {
      if (field.type === 'divider') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={gridMD || 12}
            className={field.noPadding && classes.noPadding}
            key={field.id}
          >
            <Divider id={field.id} variant="middle" className={classes.divider} />
          </Grid>
        );
      } else if (field.type === 'title') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={gridMD || 12}
            className={field.noPadding && classes.noPadding}
            key={field.id}
          >
            <Typography
              variant={field.variant || 'h6'}
              id={field.id}
              className={classes.title}
            >
              {field.label}
            </Typography>
          </Grid>
        );
      } else if (field.type === 'extendor') {
        return (
          <CustomExtendor
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            onChange={extended => this.setState({ [field.id]: extended })}
            value={this.state[field.id]}
          />
        );
      } else if (field.id === 'link' && (
        this.state.link === undefined || this.state.link === null
      )) {
        return (
          <Typography style={{ marginBottom: '2rem' }}>
            Le lien sera disponible après la sauvegarde de l'offre.
          </Typography>
        );
      } else if (field.type === 'textField') {
        return (
          <CustomTextField
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            type={field.subtype || 'text'}
            label={field.label}
            onChange={event => {
              this.setState({ [field.id]: event.target.value });
              field.onchange && field.onChange({
                ...this.state,
                [field.id]: event.target.value,
              });
            }}
            onClick={this.props.onAddVariable
              ? () => this.props.onAddVariable(field.id)
              : null}
            value={this.state[field.id]}
            disabled={field.disabled}
            isMandatory={field.isMandatory}
            InputProps={field.InputProps}
          />
        );
      } else if (field.type === 'passwordField') {
        return (
          <CustomPasswordField
            className={classes.input}
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            onChange={event => this.setState({ [field.id]: event.target.value })}
            value={this.state[field.id]}
            isMandatory={field.isMandatory}
            error={this.state[field.id] !== null &&
              this.state[field.id].match(field.errorRegex) === null}
          />
        );
      } else if (field.type === 'selectField') {
        return (
          <CustomSelectField
            onClick={this.props.onAddVariable
              ? () => this.props.onAddVariable(field.id)
              : null}
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            disabled={field.disabled}
            onChange={event => {
              let newState = {
                [field.id]: (
                  field.autocomplete || field.multiple
                )
                  ? event
                  : event.target.value,
              };
              this.setState(newState);
              if (field.observable) {
                this.props.onChangeObservable(newState);
              }
            }}
            value={this.state[field.id]}
            isMandatory={field.isMandatory}
            autocomplete={field.autocomplete}
            multiple={field.multiple}
            options={field.options}
            noOptions={field.noOptions}
            minLength={field.minLength}
            creatable={field.creatable}
          >
            {
              Object.keys(field.options).map(
                function (value) {
                  return (
                    <MenuItem key={value} value={value}>
                      {field.options[value]}
                    </MenuItem>
                  );
                },
              )
            }
          </CustomSelectField>
        );
      } else if (field.type === 'pictureDropzone') {
        return (
          <CustomPictureDropzone
            gridMD={gridMD}
            key={field.id}
            imagePath={this.state[field.id]}
            onDrop={(accepted, rejected) =>
              this.handleMediaDrop(accepted, rejected, field.id, field.picture)
            }
          />
        );
      } else if (field.type === 'pdfDropzone') {
        return (
          <CustomPDFDropzone
            gridMD={gridMD}
            key={field.id}
            pdfPath={this.state[field.id]}
            labelDropPDF={field.labelDropPDF}
            isMandatory={field.isMandatory}
            onDrop={(accepted, rejected) =>
              this.handleMediaDrop(accepted, rejected, field.id, field.pdf)
            }
          />
        );
      } else if (field.type === 'checkboxNormal') {
        return (
          <CustomCheckboxNormal
            gridMD={gridMD}
            key={field.id}
            disabled={field.disabled}
            onChange={event => this.setState({ [field.id]: event.target.checked })}
            value={this.state[field.id]}
            label={field.label}
            onClick={this.props.onAddVariable
              ? () => this.props.onAddVariable(field.id)
              : null}
            tooltip={field.tooltip}
          />
        );
      } else if (field.type === 'checkboxField') {
        return (
          <CustomCheckboxField
            gridMD={gridMD}
            key={field.id}
            disabled={field.disabled}
            onChange={value => this.setState({ [field.id]: value })}
            value={this.state[field.id]}
            label={field.label}
          />
        );
      } else if (field.type === 'checkboxSelector') {
        return (
          <CustomCheckboxSelector
            gridMD={gridMD}
            key={field.id}
            id={field.id}
            label={field.label}
            disabled={field.disabled}
            onChange={event => this.setState({ [event.target.value]: true })}
            isMandatory={field.isMandatory}
          >
            {
              Object.keys(field.options).filter(option => !this.state[option]).map(
                function (value) {
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      onClick={() => this.setState({ [value]: true })}
                    >
                      {field.options[value]}
                    </MenuItem>
                  );
                }.bind(this),
              )
            }
          </CustomCheckboxSelector>
        );
      } else if (field.type === 'sliderField') {
        return (
          <CustomSliderField
            gridMD={gridMD}
            key={field.id}
            disabled={field.disabled}
            onChange={(event, value) => this.setState({ [field.id]: value })}
            value={field.options
              ? [this.state[field.options[0]], this.state[field.options[1]]]
              : this.state[field.id]}
            label={field.label}
            step={field.step}
            min={field.min}
            max={field.max}
            marks={field.marks}
            onClick={this.props.onAddVariable
              ? () => this.props.onAddVariable(field.id)
              : null}
          />
        );
      } else if (field.type === 'richText') {
        return (
          <Grid
            item
            key={field.id}
            xs={12}
            sm={12}
            md={gridMD}
            style={defaultStyles.gridQuill}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label
              htmlFor={field.id}
              className={classes.richTextLabel}
              style={{
                color: field.isMandatory
                  ? colorTheme.secondary
                  : colorTheme.menu,
              }}
            >
              {field.label}
              {field.isMandatory
                ? '*'
                : ''}
            </label>
            <ReactQuill
              id={field.id}
              modules={quillModules}
              formats={quillFormats}
              value={this.state[field.id] || ''}
              onFocus={!field.observable && this.props.onAddVariable
                ? () => this.props.onAddVariable(field.id)
                : null}
              onChange={value => {
                if (field.maxSize > 0 && value.length > field.maxSize) {
                  let newState = { [field.id]: this.state[field.id] };
                  this.setState(newState);
                  this.props.handleError({
                    message: 'La taille du contenu limitée à ' + field.maxSize + ' est dépassée.',
                  });
                } else {
                  if (field.onChange !== undefined) {
                    field.onChange({
                      ...this.state,
                      [field.id]: value,
                    });
                  }
                  let newState = { [field.id]: value };
                  this.setState(newState);
                  if (field.observable) {
                    this.props.onChangeObservable(newState);
                  }
                }
              }}
            />
          </Grid>
        );
      } else if (field.type === 'input') {
        return (
          <Grid
            item
            key={field.id}
            xs={12}
            sm={12}
            md={gridMD}
          >
            <FormControl variant="outlined">
              <InputLabel
                ref={ref => {
                  if (!this.state.ref || !this.state.ref[field.label]) {
                    this.setState({
                      ref: {
                        ...this.state.ref,
                        // eslint-disable-next-line react/no-find-dom-node
                        [field.label]: ReactDOM.findDOMNode(ref),
                      },
                    });
                  }
                }}
                htmlFor={field.id}
              >
                {field.label}
              </InputLabel>
              <OutlinedInput
                type="color"
                id={field.id}
                labelWidth={this.state.ref && this.state.ref[field.label]
                  ? this.state.ref[field.label].offsetWidth
                  : 0}
                style={defaultStyles.colorPicker}
                onChange={event => this.setState({ [field.id]: event.target.value })}
                value={this.state[field.id]}
              />
            </FormControl>
          </Grid>
        );
      } else if (field.type === 'subform') {
        let onSubformUpdate = (state) => {
          let fieldOptions = [];
          field.options.map(option =>
            fieldOptions.push(option['id']),
          );
          if (
            JSON.stringify(CustomForm.filterObjectByKeys(this.state, fieldOptions))
            !== JSON.stringify(CustomForm.filterObjectByKeys(state, fieldOptions))
          ) {
            this.setState(CustomForm.filterObjectByKeys(state, fieldOptions));
          }
        };
        let extendor = field.options.find(option => option.id.includes('extendor'));
        const content = (
          <CustomForm
            key={field.id}
            typeForm={1}
            data={this.state}
            formFields={field.options}
            onChangeObservable={this.props.onChangeObservable}
            onAddVariable={this.props.onAddVariable}
            onUpdate={state => onSubformUpdate(state)}
            sending={this.props.sending}
            classes={this.props.classes}
            handleError={this.props.handleError}
            onSubmit={this.props.onSubmit}
          />
        );
        if (this.state[extendor.id]) {
          return (
            <>
              <div id={extendor.id.trim().toLowerCase().replace(' ', '_')} />
              <Card className={classes.card}>
                {content}
              </Card>
            </>
          );
        }
        return (
          <>
            <div id={extendor.id.trim().toLowerCase().replace(' ', '_')} />
            {content}
          </>
        );
      }
    }
  };

  // Render

  render() {

    const {
      classes,
      formFields,
      onSubmit,
      onShare,
      onShareLinkedIn,
      sending,
      shouldSplit,
      shouldUseFastList,
      onPreview,
    } = this.props;

    const isValid = this.isValidFormFields(formFields) && !sending;

    const subFormRender = (
      <Grid container style={defaultStyles.fromGridContainer} spacing={4}>
        {formFields.filter(formField => !formField.split).map(field => (
          this.displayFormField(field, classes)
        ))}
      </Grid>
    );
    const subSplitFormRender = (
      <Grid container style={defaultStyles.fromGridContainer} spacing={4}>
        {formFields.filter(formField => formField.split).map(field => (
          this.displayFormField(field, classes)
        ))}
      </Grid>
    );

    const extendorList = formFields.filter(formField => formField.options
      && formField.options.length
      > 0).map(field => {
        let extendor = field.options[0];
        const elementId = extendor.id
          ? extendor.id.trim().toLowerCase().replace(' ', '_')
          : '';
        return (
          <a
            href={`#${elementId}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <ListItem
              button
              onClick={() => {
                this.setState({ [extendor.id]: true });
              }}
            >
              <ListItemText primary={extendor.label} />
            </ListItem>
          </a>
        );
      },
    );

    const formRender = (
      <CustomFormContainer
        onSubmit={event => onSubmit(
          event,
          {
            ...this.state,
            ref: null,
          },
        )}
      >
        {!shouldSplit && subFormRender}
        {shouldSplit &&
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              md={shouldUseFastList
                ? 4
                : 6}
            >
              {subSplitFormRender}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {subFormRender}
            </Grid>
            {shouldUseFastList &&
              <Grid item xs={12} sm={12} md={2}>
                <List>
                  {extendorList}
                </List>
              </Grid>
            }
          </Grid>
        }
        <Fab
          className={classes.saveButton}
          color="primary"
          type="submit"
          disabled={!isValid}
        >
          {sending && <CircularProgress className={classes.progress} color="secondary" />}
          {!sending && <SaveIcon />}
        </Fab>
        {onPreview &&
          <Fab
            className={classes.previewButton}
            color="primary"
            onClick={onPreview}
          >
            {sending && <CircularProgress className={classes.progress} color="secondary" />}
            {!sending && <EyeIcon />}
          </Fab>
        }
        {onShare &&
          <Fab
            className={classes.shareButton}
            color="primary"
            disabled={!isValid}
            onClick={event => onShare(
              event,
              {
                ...this.state,
                ref: null,
              },
            )}
          >
            {sending && <CircularProgress className={classes.progress} color="secondary" />}
            {!sending && <ShareIcon />}
          </Fab>

        }
        {onShareLinkedIn &&
          <Fab
            className={classes.shareLinkedInButton}
            color="primary"
            disabled={!isValid}
            onClick={event => onShareLinkedIn(
              event,
              {
                ...this.state,
                ref: null,
              },
            )}
          >
            {sending && <CircularProgress className={classes.progress} color="secondary" />}
            {!sending && <LinkedInIcon />}
          </Fab>
        }
      </CustomFormContainer>
    );

    if (this.props.typeForm === 0) {
      return formRender;
    } else {
      return subFormRender;
    }
  }
}

export default compose(
  withStyles(styles),
  connect(null, { handleError }),
)(CustomForm);
