import React from 'react';
import PropTypes from "prop-types";
import withTitle from "../../hocs/withTitle";
import ClientForm from "../../components/forms/ClientForm";

export const ClientEditPage = props => (
      <ClientForm history={props.history} match={props.match} editing={true}/>
);

ClientEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTitle({title: 'Clients', subtitle: 'Éditer un Client'})(ClientEditPage);
