import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import withTitle from '../../hocs/withTitle';
import { putCandidate } from '../../stores/candidates/actions';
import {
  putCandidature,
} from '../../stores/candidatures/actions';
import { connect } from 'react-redux';
import CustomForm from '../../components/common/form/CustomForm';
import { CANDIDATE_VIEW } from '../../constants/routes';
import StringService from '../../services/StringService';
import FinishModal from '../../components/dialogs/FinishDialog';
import StopModal from '../../components/dialogs/StopDialog';
import ValidateModal from '../../components/dialogs/ValidateDialog';
import CandidatePreview from './CandidatePreview';
import { interviewFormFields } from '../../constants/form/interview/interviewFormFields';
import { stateWithInterview } from '../../constants/state/stateWithInterview';
import { stateWithCandidate } from '../../constants/state/stateWithCandidate';
import { INITIAL_STATE } from '../../constants/state/initialStateReportForm';

export class ClientPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    authUser: PropTypes.object.isRequired,
    putCandidate: PropTypes.func.isRequired,
    putCandidature: PropTypes.func.isRequired,
    sending: PropTypes.bool.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.location.state.candidature && !state.candidature) {
      if (props.location.state.candidature.interviews) {
        let currentInterview = props.location.state.candidature.interviews
          .find(interview => interview.stage === 4);
        if (currentInterview) {
          newState = {
            ...newState,
            ...stateWithInterview(currentInterview),
            candidature: props.location.state.candidature,
          };
        } else {
          let previousInterview = props.location.state.candidature.interviews
            .sort((a, b) => b.stage - a.stage)[0];
          if (previousInterview) {
            newState = {
              ...newState,
              ...stateWithInterview(previousInterview),
              candidature: props.location.state.candidature,
            };
          }
        }
      }
    }
    if (props.location.state.candidate && !state.candidate) {
      newState = { ...newState, ...stateWithCandidate(props.location.state.candidate) };
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleToggleFinishedModal = event => {
    event.stopPropagation();
    this.setState({ openFinishedModal: !this.state.openFinishedModal });
  };
  handleToggleStopModal = event => {
    event.stopPropagation();
    this.setState({ openStopModal: !this.state.openStopModal });
  };
  handleToggleValidateModal = event => {
    event.stopPropagation();
    this.setState({ openValidateModal: !this.state.openValidateModal });
  };

  handleReject = event => {
    console.log('reject');
    this.setState({
      openFinishedModal: !this.state.openFinishedModal,
      openStopModal: !this.state.openStopModal,
    });
  };

  handleValidate = event => {
    console.log('validate');
    this.setState({
      candidature: {
        ...this.state.candidature,
        state: 15,
      },
      openFinishedModal: !this.state.openFinishedModal,
      openValidateModal: !this.state.openValidateModal,
    });
  };

  handleRecord = () => {
    this.setState({ openFinishedModal: !this.state.openFinishedModal });
    this.props.history.push(CANDIDATE_VIEW.replace(':candidateId',
      this.props.match.params.candidateId,
    ));
  };

  handleValidateAndPush = event => {

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.props.match.params.candidatureId,
        {
          state: 15,
          comment_client: this.state.comment,
          comment_client_date: Date.now() / 1000 | 0,
        },
      );
    }).then(() => {
      this.setState({ openValidateModal: !this.state.openValidateModal });
      this.props.history.push(CANDIDATE_VIEW.replace(':candidateId',
        this.props.match.params.candidateId,
      ));
    }).catch((error) => {
      this.props.handleError(error);
    });

    this.handleToggleValidateModal(event);
  };

  handleStop = event => {

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.props.match.params.candidatureId,
        {
          state: 14,
          consult_reject_reason: this.state.consult_reject_reason,
          consult_reject_comment: this.state.consult_reject_comment,
          comment_client: this.state.consult_reject_comment,
          comment_client_date: Date.now() / 1000 | 0,
        },
      );
    })
      .then(() => {
        this.setState({ openStopModal: !this.state.openStopModal });
        this.props.history.push(CANDIDATE_VIEW.replace(':candidateId',
          this.props.match.params.candidateId,
        ));
      }).catch((error) => {
      this.props.handleError(error);
    });
    this.handleToggleStopModal(event);
  };

  onSubmit = (event, formState) => {
    event.preventDefault();

    this.props.authUser.getIdToken(true).then(token => {
      this.props.putCandidate(
        token,
        this.props.match.params.candidateId,
        {
          availability: StringService.dateStringToTimestamp(formState.availability),
          mobility: formState.mobility,
          salary: formState.salary,
          wanted_salary: formState.wanted_salary,
        },
      );
      this.props.putCandidature(
        token,
        this.props.match.params.candidatureId,
        {
          new_interview: {
            stage: 4,
            presentation: formState.presentation,
            formation: formState.formation,
            competences: formState.competences,
            experiences: formState.experiences,
            coherence: formState.coherence,
            motivation: formState.motivation,
            diction: formState.diction,
            rythme: formState.rythme,
            voix: formState.voix,
            expressions: formState.expressions,
            formulation: formState.formulation,
            courtoisie: formState.courtoisie,
            disponibilite: formState.disponibilite,
            comprehension: formState.comprehension,
            decision: formState.decision,
            note: formState.note,
            reformulation: formState.reformulation,
            poste: formState.poste,
            personnelles: formState.personnelles,
            objectif: formState.objectif,
            communication: formState.communication,
            temps: formState.temps,
            point: formState.point,
            arret: formState.arret,
            avis: formState.avis,
            search: formState.search,
            projet: formState.projet,
            ambition: formState.ambition,
            free: formState.free || '',
            auto_eval: formState.auto_eval || '',
            control_reference: formState.control_reference || '',
            personnal_context: formState.personnal_context || '',
          },
        },
      );
    });
    this.handleToggleFinishedModal(event);
  };

  render() {
    const { sending } = this.props;

    return (
      <>
        <Grid container>
          <CandidatePreview candidate={this.state.candidate} />
          <Grid item xs={12} sm={12} md={7}>
            <CustomForm
              data={this.state}
              formFields={interviewFormFields}
              buttonLabel={'Terminer'}
              onSubmit={this.onSubmit}
              sending={sending}
            />
          </Grid>
        </Grid>
        <FinishModal
          sending={sending}
          openFinishModal={this.state.openFinishedModal}
          handleReject={this.handleReject}
          handleValidate={this.handleValidate}
          handleRecord={this.handleRecord}
          handleToggleFinishedModal={this.handleToggleFinishedModal}
        />
        <StopModal
          openStopModal={this.state.openStopModal}
          handleToggleStopModal={this.handleToggleStopModal}
          handleReason={(number, reason, comment) =>
            this.setState({
              stop: number,
              consult_reject_reason: reason,
              consult_reject_comment: comment,
            })}
          consultRejectComment={this.state.consult_reject_comment}
          stop={this.state.stop}
          handleComment={event => this.setState({ consult_reject_comment: event.target.value })}
          handleStop={event => this.handleStop(event)}
        />
        <ValidateModal
          openValidateModal={this.state.openValidateModal}
          handleToggleValidateModal={this.handleToggleValidateModal}
          candidature={this.state.candidature}
          step={this.state.step}
          comment={this.state.comment}
          handleValidate={this.handleValidateAndPush}
          handleMessage={event => this.setState({ comment: event.target.value })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    sending: state.candidatures.sending,
  };
}

export default compose(
  connect(mapStateToProps, {
    putCandidate,
    putCandidature,
  }),
  withTitle({ title: 'Entretien face à face client' }),
)(ClientPage);
