import { SUB_TITLE_CHANGE, TITLE_CHANGE } from './types';

export const titleChangeAction = title => ({
  type: TITLE_CHANGE,
  payload: title,
});

export const subTitleChangeAction = subTitle => ({
  type: SUB_TITLE_CHANGE,
  payload: subTitle,
});

export function changeTitle(title) {
  return (dispatch) => {
    dispatch(titleChangeAction(title));
  }
}

export function changeSubTitle(subTitle) {
  return (dispatch) => {
    dispatch(subTitleChangeAction(subTitle));
  }
}
