import decode from 'jwt-decode';

export default class AuthService {

  static isGranted(neededRole, authUser, roles) {
    if (!authUser || !roles) {
      return false;
    }
    let userRole = roles[authUser.uid];
    return userRole >= neededRole;
  }

  // Check if token is expired
  static isTokenExpired() {
    try {
      const decoded = decode(localStorage.getItem('token'));
      return decoded.exp < (Date.now() / 1000);
    } catch (err) {
      return false;
    }
  }

  // Get user's session
  static getSession() {
    if (this.isAuthenticated()) {
      return {
        id: localStorage.getItem('id'),
        email: localStorage.getItem('email'),
        token: localStorage.getItem('token'),
        role: localStorage.getItem('role'),
        refresh_token: localStorage.getItem('refresh_token')
      }
    }

    return null;
  }

  // Save user'departments session
  static setSession(user) {
    if (user.id) {
      localStorage.setItem('id', user.id);
    }
    if (user.email) {
      localStorage.setItem('email', user.email);
    }
    if (user.token) {
      localStorage.setItem('token', user.token);
    }
    if (user.role) {
      localStorage.setItem('role', user.role);
    }
    if (user.refresh_token) {
      localStorage.setItem('refresh_token', user.refresh_token);
    }
  }

  // Check if user'departments session exist
  static isAuthenticated() {
    return localStorage.getItem('id') !== null
      && localStorage.getItem('email') !== null
      && localStorage.getItem('token') !== null
      && localStorage.getItem('role') !== null
      && localStorage.getItem('refresh_token') !== null
      && !this.isTokenExpired(localStorage.getItem('token'));
  }

  // Destroy user'departments session
  static logout() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
  }
}
