import { ENV_DEMO, ENV_DEV, ENV_LOCAL, ENV_PREPROD } from './env';

let backendHost = 'https://ad-conseils-api.openium.fr';
const apiVersion = 'v1';

if (process.env.REACT_APP_ENV) {
  if (process.env.REACT_APP_ENV === ENV_LOCAL) {
    backendHost = 'https://ad-conseils-api-master.openium.fr';
  } else if (process.env.REACT_APP_ENV === ENV_DEV) {
    backendHost = 'https://ad-conseils-api-dev.openium.fr';
  } else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
    backendHost = 'https://ad-conseils-api-preprod.openium.fr';
  } else if (process.env.REACT_APP_ENV === ENV_DEMO) {
    backendHost = 'https://ad-conseils-api-demo.openium.fr';
  }
} else {
  backendHost = 'https://api.ava-talent.com';
}

const API_ROOT = `${backendHost}/api/${apiVersion}`;
export default API_ROOT;
