import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { getVariationTheme } from '../../../themes/theme';

const colorTheme = getVariationTheme();
/**
 * Style of Custom Text Field elements
 *
 */
const styles = () => ({
  textField: {
    width: '100%',
    backgroundColor: colorTheme.text_light,
    fontWeight: 'bold'
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: colorTheme.primary,
    },
  },
  cssLabel: {
    color: colorTheme.menu,
    fontWeight: 'bold',
    '&$cssFocused': {
      color: colorTheme.menu
    },
  },
  cssLabelMandatory: {
    color: colorTheme.secondary,
    fontWeight: 'bold',
    '&$cssFocused': {
      color: colorTheme.secondary
    },
  },
  cssFocused: {},
});

/**
 * Class CustomTextField
 *
 * @Component : <CustomTextField />
 */
const CustomTextField = props => {
  // Constant
  const {
    id,
    label,
    onChange,
    onClick,
    value,
    type,
    isMandatory,
    classes,
    gridMD,
    noLabel,
    error,
    disabled,
    InputProps,
  } = props;

  // Element
  const textField = (
    <TextField
      className={classes.textField}
      id={id}
      type={type ? type : "text"}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      value={value ? value : ''}
      label={noLabel ? null : `${label}` + (isMandatory ? '*' : '')}
      error={error}

      margin="normal"
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.textField
        }
      }}
      inputProps={InputProps}
      InputLabelProps={{
        classes: {
          root: isMandatory ? classes.cssLabelMandatory : classes.cssLabel,
          focused: classes.cssFocused,
        }
      }}
    />
  );

  return (
    <Grid item xs={12} sm={12} md={gridMD}>
      {textField}
    </Grid>
  );
};


CustomTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  gridMD: PropTypes.number.isRequired,
  noLabel: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object
};

CustomTextField.defaultProps = {
  value: null,
  label: '',
  type: 'text',
  isMandatory: false,
  noLabel: false,
  error: false,
  disabled: false,
  gridMd: 4,
  InputProps: {}
};

export default withStyles(styles)(CustomTextField);
