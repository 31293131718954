import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import OfferForm from '../../components/forms/OfferForm';

export const OfferAddPage = props => (
  <OfferForm history={props.history} location={props.location} />
);

OfferAddPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Ajouter une offre',
  subtitle: 'Ajouter une offre',
})(OfferAddPage);
