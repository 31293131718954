import { postFormFields } from './postFormFields';
import { contractFormFields } from './contractFormFields';
import { hierarchyFormFields } from './hierarchyFormFields';
import { teamFormFields } from './teamFormFields';
import { internFormFields } from './internFormFields';
import { contextFormFields } from './contextFormFields';
import { missionFormFields } from './missionFormFields';
import { levelFormFields } from './levelFormFields';
import { languageFormFields } from './languageFormFields';
import { salaryFormFields } from './salaryFormFields';
import { clientFormFields } from './clientFormFields';
import { otherFormFields } from './otherFormFields';

export const offerFields = (jobsOptions, skillsOptions, softSkillsOptions, deptOptions, diplomaOptions) => ([
  {
    id: 'offer_name',
    split: true,
    type: 'textField',
    gridMD: 12,
    label: 'Nom de l\'offre',
    isMandatory: true,
  },
  {
    id: 'salary_min',
    split: true,
    type: 'textField',
    subtype: 'number',
    gridMD: 6,
    label: 'Salaire min (en K€)',
    isMandatory: false,
  },
  {
    id: 'salary_max',
    split: true,
    type: 'textField',
    subtype: 'number',
    gridMD: 6,
    label: 'Salaire max (en K€)',
    isMandatory: false,
  },
  {
    id: 'is_validate',
    split: true,
    gridMD: 12,
    type: 'checkboxNormal',
    label: 'Lien actif',
    isMandatory: false,
  },
  {
    id: 'link',
    split: true,
    gridMD: 12,
    type: 'textField',
    dependances: ['is_validate'],
    label: 'Lien de l\'offre',
    isMandatory: false,
    disabled: true,
  },
  {
    id: 'content',
    split: true,
    type: 'richText',
    gridMD: 12,
    label: 'Annonce',
    isMandatory: false,
    observable: true,
  },
  {
    id: 'excerpt',
    split: true,
    type: 'richText',
    maxSize: 2300,
    gridMD: 12,
    label: 'Résumé (max 2300 caractères)',
    isMandatory: true,
    observable: true,
  },

  {
    id: 'post_subform',
    type: 'subform',
    options: postFormFields(jobsOptions, skillsOptions, softSkillsOptions),
  },
  {
    id: 'contract_subform',
    type: 'subform',
    options: contractFormFields,
  },
  {
    id: 'hierarchy_subform',
    type: 'subform',
    options: hierarchyFormFields,
  },
  {
    id: 'team_subform',
    type: 'subform',
    options: teamFormFields(deptOptions),
  },
  {
    id: 'intern_subform',
    type: 'subform',
    options: internFormFields,
  },
  {
    id: 'context_subform',
    type: 'subform',
    options: contextFormFields(),
  },
  {
    id: 'mission_subform',
    type: 'subform',
    options: missionFormFields,
  },
  {
    id: 'level_subform',
    type: 'subform',
    options: levelFormFields(diplomaOptions),
  },
  {
    id: 'language_subform',
    type: 'subform',
    options: languageFormFields,
  },
  {
    id: 'salary_subform',
    type: 'subform',
    options: salaryFormFields,
  },
  {
    id: 'client_subform',
    type: 'subform',
    options: clientFormFields,
  },
  {
    id: 'other_subform',
    type: 'subform',
    options: otherFormFields,
  },
]);
