export const POST_STATUS = {
  30: 'Employé',
  31: 'Opérateur',
  33: 'Ouvrier',
  34: 'Ouvrier spécialisé',
  35: 'Technicien',
  36: 'Agent de maîtrise',
  37: 'Ingénieur',
  38: 'Cadre',
  39: 'Cadre dirigeant',
};
export const CONTRACTS = {
  21: 'CDI',
  22: 'CDD',
  26: 'Alternance',
  23: 'Intérim',
};
export const SCHOOLING_LEVELS = {
  12: 'BAC',
  13: 'Bac Professionnel',
  14: 'Bac+2',
  15: 'BAC+3',
  16: 'BAC+4',
  17: 'BAC+5',
  18: 'BAC+6',
  19: 'Doctorat',
  20: 'Chercheur',
};
export const LEVELS = {
  'Débutant': 'Débutant',
  'Confirmé': 'Confirmé',
  'Manager': 'Manager',
};
export const LANGUAGE_LEVELS = {
  'Notions': 'Notions',
  'Débutant': 'Débutant',
  'Bon': 'Bon',
  'Courant': 'Courant',
  'Courant et technique': 'Courant et technique',
};
