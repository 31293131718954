export const candidateFields = [
  {
    id: 'extendor_candidate',
    type: 'extendor',
    gridMD: 12,
    label: 'Candidat',
    noPadding: true,
  },
  {
    id: 'availability',
    type: 'textField',
    subtype: 'date',
    gridMD: 6,
    label: 'Disponibilité',
    dependances: ['extendor_candidate'],
    isMandatory: false,
  },
  {
    id: 'mobility',
    type: 'textField',
    gridMD: 6,
    label: 'Mobilité',
    dependances: ['extendor_candidate'],
    isMandatory: false,
  },
  {
    id: 'salary',
    type: 'textField',
    gridMD: 6,
    label: 'Rémunération actuelle',
    dependances: ['extendor_candidate'],
    isMandatory: false,
  },
  {
    id: 'wanted_salary',
    type: 'textField',
    gridMD: 6,
    label: 'Prétentions salariales',
    dependances: ['extendor_candidate'],
    isMandatory: false,
  },
  {
    id: 'personnal_context',
    type: 'richText',
    gridMD: 12,
    label: 'Contexte Personnel',
    dependances: ['extendor_candidate'],
    isMandatory: false,
  },
];