export const attitudesFields = [
  {
    id: 'extendor_attitudes',
    type: 'extendor',
    gridMD: 12,
    label: 'Attitudes',
    noPadding: true,
  },
  {
    id: 'courtoisie',
    type: 'richText',
    gridMD: 12,
    label: 'Courtoisie',
    dependances: ['extendor_attitudes'],
    isMandatory: false,
  },
  {
    id: 'disponibilite',
    type: 'richText',
    gridMD: 12,
    label: 'Disponibilité',
    dependances: ['extendor_attitudes'],
    isMandatory: false,
  },
  {
    id: 'comprehension',
    type: 'richText',
    gridMD: 12,
    label: 'Compréhension',
    dependances: ['extendor_attitudes'],
    isMandatory: false,
  },
  {
    id: 'decision',
    type: 'richText',
    gridMD: 12,
    label: 'Capacité de décision',
    dependances: ['extendor_attitudes'],
    isMandatory: false,
  },
];