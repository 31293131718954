import React from 'react';
import PropTypes from "prop-types";
import withTitle from "../../hocs/withTitle";
import ClientForm from "../../components/forms/ClientForm";

export const ClientAddPage = props => (
  <ClientForm history={props.history} editing={true}/>
);

ClientAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withTitle({title: 'Clients', subtitle:"Ajouter un Client"})(ClientAddPage);
