import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import infosReducer from './infos/reducers';
import rolesReducer from './roles/reducers';
import usersReducer from './users/reducers';
import clientsReducer from './clients/reducers';
import contactsReducers from './contacts/reducers';
import staticsReducers from './statics/reducers';
import candidatesReducers from './candidates/reducers';
import candidaturesReducers from './candidatures/reducers';
import offersReducers from './offers/reducers';
import titleReducers from './title/reducers';

const reducers = combineReducers({
  infos: infosReducer,
  roles: rolesReducer,
  users: usersReducer,
  clients: clientsReducer,
  contacts: contactsReducers,
  statics: staticsReducers,
  candidates: candidatesReducers,
  candidatures: candidaturesReducers,
  offers: offersReducers,
  title: titleReducers,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
