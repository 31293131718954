export const teamFormFields = (deptOptions) => ([
  {
    id: 'extendor_team',
    type: 'extendor',
    gridMD: 12,
    label: 'Equipe et conditions et travail',
    noPadding: true,
  },
  {
    id: 'department_name',
    type: 'selectField',
    options: deptOptions,
    autocomplete: true,
    noOptions: 'Rentrer au moins 3 caractères pour avoir des suggestions',
    minLength: 2,
    creatable: true,
    label: 'Département de rattachement',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'team_size',
    type: 'textField',
    subtype: 'number',
    label: 'Effectif',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'team_interactions',
    type: 'textField',
    label: 'Interraction autres services',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'people_to_manage',
    type: 'richText',
    gridMD: 6,
    label: 'Management hiérarchique',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'people_to_operate',
    type: 'richText',
    gridMD: 6,
    label: 'Management fonctionnel',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'work_place',
    type: 'textField',
    gridMD: 6,
    label: 'Lieu de travail',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'schedule',
    type: 'textField',
    gridMD: 6,
    label: 'Horaires de travail',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'training_intern',
    type: 'textField',
    gridMD: 6,
    label: 'Formation interne',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'training_extern',
    type: 'textField',
    gridMD: 6,
    label: 'Formation externe',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'induction_period',
    type: 'richText',
    gridMD: 12,
    label: 'Parcours d\'intégration',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'mobility_wanted',
    type: 'checkboxNormal',
    gridMD: 12,
    label: 'Mobilité souhaitée',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
  {
    id: 'mobility_info',
    type: 'richText',
    gridMD: 12,
    label: 'Commentaires',
    dependances: ['extendor_team'],
    isMandatory: false,
  },
]);
