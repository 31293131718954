import React, {Component} from 'react';
import PropTypes from "prop-types";
import {compose} from "recompose";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";
import {resetError, resetInfo} from "../../stores/infos/actions";
import { getVariationTheme } from '../../themes/theme';

const colorTheme = getVariationTheme();

const styles = () => ({
  info: {
    backgroundColor: colorTheme.primary,
  },
  error: {
    backgroundColor: colorTheme.secondary
  }
});

export class SnackBar extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    resetError: PropTypes.func.isRequired,
    resetInfo: PropTypes.func.isRequired,
    error: PropTypes.string,
    info: PropTypes.string
  };

  static defaultProps = {
    error: null,
    info: null
  };

  handleErrorSnackbarClose = () => {
    this.props.resetError()
  };

  handleInfoSnackbarClose = () => {
    this.props.resetInfo()
  };

  handleReload = () => {
    window.location.reload();
  };

  // Render

  render() {
    const {error, info, classes} = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={error !== null}
          message={error || ''}
          onClose={this.handleErrorSnackbarClose}
          ContentProps={{
            classes: {
              root: classes.error
            }
          }}
          action={[
            <IconButton key="close_error" color="inherit" onClick={this.handleErrorSnackbarClose}>
              <CloseIcon />
            </IconButton>
          ]}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={info !== null}
          message={info || ''}
          autoHideDuration={4000}
          onClose={this.handleInfoSnackbarClose}
          ContentProps={{
            classes: {
              root: classes.info
            }
          }}
          action={[
            <IconButton key="close_info" color="inherit" onClick={this.handleInfoSnackbarClose}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.infos.error,
    info: state.infos.info
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    resetError,
    resetInfo,
  })
)(SnackBar);
