import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CustomForm from '../common/form/CustomForm';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ApiService from '../../services/ApiService';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/index';
import Button from '@material-ui/core/Button';
import { dispatchInfo } from '../../stores/infos/actions';
import { getVariationTheme } from '../../themes/theme';
import AppStoreDownload from '../../resources/images/download_appstore.png';
import PlayStoreDownload from '../../resources/images/download_googleplay.png';
import OfferPreviewDialog from '../../containers/offers/OfferPreviewDialog';

// Intial State of the Component
const INITIAL_STATE = {
  mail: '',
  mailVerify: '',
  firstname: '',
  skills: [],
  softskills: [],
  pdf_path: '',
  motivation_path: '',
  dialogOpen: false,
  sendCandidature: false,
  errorMessage: '',
};
const colorTheme = getVariationTheme();

const styles = () => (
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginLeft: 20,
      marginRight: 20,
    },
    indicator: {
      color: colorTheme.secondary,
      position: 'relative',
      width: '100%',
      textAlign: 'left',
      padding: '0 1rem',
    },
    container: {
      width: '45%',
      padding: '3rem',
      margin: '0 auto',
      textAlign: 'left',
    },
    imgContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '2rem',
      alignItems: 'center',
      marginBottom: '2rem',
    },
    imgSize: {
      height: '65px',
      width: '200px',
    },
  }
);

export class CandidateForm extends Component {
  static propTypes = {
    offer: PropTypes.object.isRequired,
  };

  shuffle = (array) => {
    let j = 0;
    let temp = null;

    for (let i = array.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (
        i + 1
      ));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    if (this.props.offer) {
      ApiService.getSkills(this.props.offer.current_post.name.substring(0, 5)).then((data) => {
        let offerSkills = this.props.offer.current_post.skills;
        let notOfferSkills = data.filter(x => !offerSkills.includes(x));
        notOfferSkills = this.shuffle(notOfferSkills);
        this.setState({ skillsDatas: [...offerSkills, ...notOfferSkills.slice(0, 5)] });
      });

      ApiService.getSoftSkills(this.props.offer.current_post.name.substring(0, 5)).then((data) => {
        let offerSoftskills = this.props.offer.current_post.softskills;
        let notOfferSoftskills = data.filter(x => !offerSoftskills.includes(x));
        notOfferSoftskills = this.shuffle(notOfferSoftskills);
        this.setState({ softskillsDatas: [...offerSoftskills, ...notOfferSoftskills.slice(0, 5)] });
      });
    }
  }

  updateFormState = (formState) => {
    return {
      mail: formState.mail,
      ...(
        formState.firstname && { firstname: formState.firstname }
      ),
      mailVerify: formState.mailVerify,
      code_rome: this.props.offer.current_post.name.substring(0, 5),
      softskills: formState.softskills.map(softskill =>
        (
          { summary: softskill.value }
        ),
      ),
      skills: formState.skills.map(skill =>
        (
          { code_ogr: skill.value }
        ),
      ),
      pdfToUpload: formState.pdfToUpload,
      motivationToUpload: formState.motivationToUpload,
    };
  };

  validateEmail(email) {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSubmit = (event, formState) => {
    event.preventDefault();
    let updatedFormState = this.updateFormState(formState);
    let errorCount = 0;
    if (updatedFormState.mail !== updatedFormState.mailVerify) {
      errorCount++;
      this.setState({
        errorMessage: 'Les 2 emails ne sont pas identiques, merci de vérifier vos informations.',
      });
    }
    if (!this.validateEmail(updatedFormState.mail)
      || !this.validateEmail(updatedFormState.mailVerify)) {
      errorCount++;
      this.setState({
        errorMessage: `L'email sélectionné ne correspond pas au bon format.`,
      });
    }
    if (errorCount === 0) {
      ApiService.postCandidate(
        this.props.offer.id,
        updatedFormState,
      ).then((candidate) => {
        this.props.dispatchInfo('Votre candidature a bien été prise en compte.');
        this.setState({ sendCandidature: true });
        if (formState.pdfToUpload) {
          ApiService.postCandidatePDF(
            candidate.id,
            formState.pdfToUpload,
          ).then(() => {
            if (formState.motivationToUpload) {
              ApiService.postCandidateMotivation(
                candidate.id,
                formState.motivationToUpload,
              );
            }
          });
        } else if (formState.motivationToUpload) {
          ApiService.postCandidateMotivation(
            candidate.id,
            formState.motivationToUpload,
          );
        }
      });
    }
  };

  handleShowOffer = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {

    const { sending } = this.props;

    const {
      skillsDatas,
      softskillsDatas,
    } = this.state;

    let skillsOptions = [];
    if (skillsDatas) {
      skillsOptions = skillsDatas.map(skill =>
        (
          {
            value: skill.code_ogr,
            label: skill.libelle_competence,
          }
        ),
      );
    }
    let softskillsOptions = [];
    if (softskillsDatas) {
      softskillsOptions = softskillsDatas.map(softskill =>
        (
          {
            value: softskill.summary,
            label: softskill.summary,
          }
        ),
      );
    }

    let formFields = [

      {
        id: 'block_perso',
        split: true,
        type: 'title',
        gridMD: 12,
        label: 'Informations personnelles',
        noPadding: true,
      },
      {
        id: 'mail',
        split: true,
        type: 'textField',
        gridMD: 12,
        label: 'Email',
        isMandatory: true,
      },
      {
        id: 'mailVerify',
        split: true,
        type: 'textField',
        gridMD: 12,
        label: 'Vérification email',
        isMandatory: true,
      },
      {
        id: 'firstname',
        split: true,
        type: 'textField',
        gridMD: 12,
        label: 'Prénom',
        isMandatory: false,
      },

      {
        id: 'block_more',
        type: 'title',
        gridMD: 12,
        label: 'Informations complémentaires',
        noPadding: true,
      },
      {
        id: 'skills',
        type: 'selectField',
        gridMD: 12,
        options: skillsOptions,
        autocomplete: true,
        noOptions: 'Choisir d\'abord un métier (Intitulé)',
        multiple: true,
        label: 'Savoir-faire',
        isMandatory: true,
      },
      {
        id: 'softskills',
        type: 'selectField',
        gridMD: 12,
        options: softskillsOptions,
        autocomplete: true,
        noOptions: 'Choisir d\'abord un métier (Intitulé)',
        multiple: true,
        label: 'Savoir-être',
        isMandatory: true,
      },
      {
        id: 'pdf_path',
        pdf: 'pdfToUpload',
        gridMD: 12,
        type: 'pdfDropzone',
        labelDropPDF: 'Insérer votre CV',
        isMandatory: true,
      },
      {
        id: 'motivation_path',
        pdf: 'motivationToUpload',
        gridMD: 12,
        type: 'pdfDropzone',
        labelDropPDF: 'Insérer votre lettre de motivation',
        isMandatory: false,
      },
    ];

    return (
      <>
        <Grid container>
          <Grid item xs={12} lg={12} className={this.props.classes.root}>
            <Typography
              variant="h2"
              gutterBottom
            >
              Candidater à {this.props.offer.current_post.client_post_name || 'une offre'}
            </Typography>
            {this.props.offer.current_post && this.props.offer.current_post.client &&
              <Typography
                variant="subtitle1"
                gutterBottom
              >
                {this.props.offer.current_post.client.business_name || 'Une entreprise'}
              </Typography>
            }
            <Button variant="contained" size="small" color="primary" onClick={this.handleShowOffer}>
              Visualiser l'offre
            </Button>
          </Grid>
        </Grid>
        {this.state.sendCandidature &&
          <div className={this.props.classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  gutterBottom={true}
                >
                  Accéder à votre espace dès maintenant!
                </Typography>
                <br />
                <Typography
                  variant="subtitle1"
                >
                  Bonjour
                </Typography>
                <Typography
                  variant="subtitle1"
                >
                  Nous avons bien pris en compte votre candidature, nous reviendrons vers vous dans
                  les plus brefs délais.
                </Typography>
                <br />
                <Typography
                  variant="subtitle1"
                  gutterBottom={true}
                >
                  <em
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bolder',
                    }}
                  >Les prochaines étapes se dérouleront sur notre application AVA-Talent. </em>
                  Afin de suivre l'avancement de votre candidature, nous vous invitons à la télécharger dès à présent sur les stores:
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container className={this.props.classes.imgContainer}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="https://apps.apple.com/fr/app/id1481471817">
                  <img
                    src={AppStoreDownload}
                    alt="Google Play"
                    className={this.props.classes.imgSize}
                  />
                </a>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="https://play.google.com/store/apps/details?id=fr.adconseils.avatalent.prod">
                  <img
                    src={PlayStoreDownload}
                    alt="Google Play"
                    className={this.props.classes.imgSize}
                  />
                </a>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
            >
              A bientôt sur AVA Talent.
            </Typography>
          </div>
        }
        {!this.state.sendCandidature &&
          <>
            {this.state.errorMessage !== '' && <Typography
              variant="subtitle1"
              className={this.props.classes.indicator}
            >
              {this.state.errorMessage}
            </Typography>
            }
            <CustomForm
              data={this.state}
              formFields={formFields}
              buttonLabel={'Candidater'}
              onSubmit={this.onSubmit}
              sending={sending}
              shouldSplit={true}
              error={this.state.errorMessage}
            />
          </>
        }
        <OfferPreviewDialog
          open={this.state.dialogOpen}
          handleClose={this.handleClose}
          content={this.props.offer.description}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    candidates: state.candidates.content,
    pending: state.candidates.pending,
    sending: state.candidates.sending,
    classes: PropTypes.object,
  };
}

export default compose(
  connect(mapStateToProps, { dispatchInfo }),
  withStyles(styles),
)(CandidateForm);
