import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from "recompose";
import {withStyles} from "@material-ui/core/styles/index";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import { getVariationTheme, defaultStyles } from '../../../themes/theme';
import { DEFAULT_GRAY_LIGHT } from '../../../themes/defaultColors';

const colorTheme = getVariationTheme();

/**
 * Style of Row elements
 *
 * @param theme
 * @returns json
 */
const styles = theme => ({
  Row: {
    webkitTransform: 'translateZ(0)',
    webkitTransition: 'all 0.2s ease-out',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light + ' !important',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  tableRow: {
    hover: DEFAULT_GRAY_LIGHT
  },
  tableRowDisabled: {
    hover: colorTheme.text_light
  }
});

function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M5,11 C5,7.691 7.691,5 11,5 C14.309,5 17,7.691 17,11 C17,14.309 14.309,17 11,17 C7.691,17 5,14.309 5,11 M20.707,19.293 L17.312,15.897 C18.365,14.543 19,12.846 19,11 C19,6.589 15.411,3 11,3 C6.589,3 3,6.589 3,11 C3,15.411 6.589,19 11,19 C12.846,19 14.543,18.365 15.897,17.312 L19.293,20.707 C19.488,20.902 19.744,21 20,21 C20.256,21 20.512,20.902 20.707,20.707 C21.098,20.316 21.098,19.684 20.707,19.293" />
    </SvgIcon>
  );
}

function LinkIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14,13 L13,13 L13,12 C13,11.448 12.553,11 12,11 C11.447,11 11,11.448 11,12 L11,13 L10,13 C9.447,13 9,13.448 9,14 C9,14.552 9.447,15 10,15 L11,15 L11,16 C11,16.552 11.447,17 12,17 C12.553,17 13,16.552 13,16 L13,15 L14,15 C14.553,15 15,14.552 15,14 C15,13.448 14.553,13 14,13 M17.4443,20 L6.5553,20 C6.2493,20 6.0003,19.776 6.0003,19.5 L6.0003,4.5 C6.0003,4.224 6.2493,4 6.5553,4 L12.0003,4 L12.0003,7.15 C12.0003,8.722 13.2173,10 14.7143,10 L18.0003,10 L18.0003,19.5 C18.0003,19.776 17.7513,20 17.4443,20 L17.4443,20 Z M14.0003,4.978 L16.7423,8 L14.7143,8 C14.3203,8 14.0003,7.619 14.0003,7.15 L14.0003,4.978 Z M19.7403,8.328 L14.2963,2.328 C14.1063,2.119 13.8383,2 13.5553,2 L6.5553,2 C5.1463,2 4.0003,3.122 4.0003,4.5 L4.0003,19.5 C4.0003,20.878 5.1463,22 6.5553,22 L17.4443,22 C18.8533,22 20.0003,20.878 20.0003,19.5 L20.0003,9 C20.0003,8.751 19.9073,8.512 19.7403,8.328 L19.7403,8.328 Z" />
    </SvgIcon>
  );
}

function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16.0186,10.6787 L13.3236,7.9837 L15.2716,6.0347 L17.9656,8.7287 L16.0186,10.6787 Z M9.0796,17.6247 L6.1026,17.8957 L6.3666,14.9397 L11.9836,9.3227 L14.6796,12.0187 L9.0796,17.6247 Z M19.4036,7.3377 L19.4026,7.3367 L16.6646,4.5987 C15.9236,3.8597 14.6506,3.8247 13.9486,4.5297 L4.9526,13.5257 C4.6266,13.8507 4.4246,14.2827 4.3826,14.7397 L4.0036,18.9097 C3.9776,19.2047 4.0826,19.4967 4.2926,19.7067 C4.4816,19.8957 4.7366,19.9997 4.9996,19.9997 C5.0306,19.9997 5.0606,19.9987 5.0906,19.9957 L9.2606,19.6167 C9.7186,19.5747 10.1496,19.3737 10.4746,19.0487 L19.4716,10.0517 C20.1996,9.3217 20.1686,8.1037 19.4036,7.3377 L19.4036,7.3377 Z" />
    </SvgIcon>
  );
}

function ActivateIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M8,12 C8,11.447 8.448,11 9,11 L15,11 C15.552,11 16,11.447 16,12 C16,12.553 15.552,13 15,13 L9,13 C8.448,13 8,12.553 8,12 Z M9,16 C9.552,16 10,16.447 10,17 C10,17.553 9.552,18 9,18 L7,18 C3.432,18 0.582,14.869 1.051,11.211 C1.439,8.187 4.16,6 7.209,6 L9,6 C9.552,6 10,6.447 10,7 C10,7.553 9.552,8 9,8 L7,8 C4.673,8 2.804,9.996 3.017,12.366 C3.204,14.465 5.104,16 7.21,16 L9,16 Z M22.9531,11.2422 C23.4021,14.8882 20.5581,18.0002 17.0001,18.0002 L15.0001,18.0002 C14.4481,18.0002 14.0001,17.5532 14.0001,17.0002 C14.0001,16.4472 14.4481,16.0002 15.0001,16.0002 L17.0001,16.0002 C19.3271,16.0002 21.1961,14.0042 20.9831,11.6342 C20.7961,9.5352 18.8961,8.0002 16.7891,8.0002 L15.0001,8.0002 C14.4481,8.0002 14.0001,7.5532 14.0001,7.0002 C14.0001,6.4472 14.4431,6.0002 15.2491,6.0002 L16.7591,6.0002 C19.8261,6.0002 22.5781,8.1972 22.9531,11.2422 Z" i/>
    </SvgIcon>
  );
}

function DesactivateIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9,16 C9.552,16 10,16.447 10,17 C10,17.553 9.552,18 9,18 L7,18 C3.432,18 0.582,14.869 1.051,11.211 C1.439,8.187 4.16,6 7.209,6 L9,6 C9.552,6 10,6.447 10,7 C10,7.553 9.552,8 9,8 L7,8 C4.673,8 2.804,9.996 3.017,12.366 C3.204,14.465 5.104,16 7.21,16 L9,16 Z M22.9531,11.2422 C23.4021,14.8882 20.5581,18.0002 17.0001,18.0002 L15.0001,18.0002 C14.4481,18.0002 14.0001,17.5532 14.0001,17.0002 C14.0001,16.4472 14.4481,16.0002 15.0001,16.0002 L17.0001,16.0002 C19.3271,16.0002 21.1961,14.0042 20.9831,11.6342 C20.7961,9.5352 18.8961,8.0002 16.7891,8.0002 L15.0001,8.0002 C14.4481,8.0002 14.0001,7.5532 14.0001,7.0002 C14.0001,6.4472 14.4431,6.0002 15.2491,6.0002 L16.7591,6.0002 C19.8261,6.0002 22.5781,8.1972 22.9531,11.2422 Z M8,12 C8,11.447 8.448,11 9,11 L15,11 C15.552,11 16,11.447 16,12 C16,12.553 15.552,13 15,13 L9,13 C8.448,13 8,12.553 8,12 Z M3.86860933,4.13493635 C4.25913362,3.74441206 4.8922986,3.74441206 5.2828229,4.13493635 L19.4249585,18.277072 C19.8154828,18.6675963 19.8154828,19.3007612 19.4249585,19.6912855 C19.0344342,20.0818098 18.4012692,20.0818098 18.010745,19.6912855 L3.86860933,5.54914992 C3.47808504,5.15862562 3.47808504,4.52546065 3.86860933,4.13493635 Z" />
    </SvgIcon>
  );
}

function ArchiveIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.8662,14 L14.1342,14 C14.6102,14 15.0002,13.61 15.0002,13.134 L15.0002,12.866 C15.0002,12.39 14.6102,12 14.1342,12 L9.8662,12 C9.3892,12 9.0002,12.39 9.0002,12.866 L9.0002,13.134 C9.0002,13.61 9.3892,14 9.8662,14 M18,18 C18,18.551 17.552,19 17,19 L7,19 C6.449,19 6,18.551 6,18 L6,9 L18,9 L18,18 Z M6,5 L18,5 C18.552,5 19,5.449 19,6 C19,6.551 18.552,7 18,7 L6,7 C5.449,7 5,6.551 5,6 C5,5.449 5.449,5 6,5 L6,5 Z M21,6 C21,4.346 19.654,3 18,3 L6,3 C4.346,3 3,4.346 3,6 C3,6.883 3.391,7.67 4,8.22 L4,18 C4,19.654 5.346,21 7,21 L17,21 C18.654,21 20,19.654 20,18 L20,8.22 C20.609,7.67 21,6.883 21,6 L21,6 Z" />
    </SvgIcon>
  );
}

function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18,19 C18,19.551 17.552,20 17,20 L7,20 C6.448,20 6,19.551 6,19 L6,8 L18,8 L18,19 Z M10,4.328 C10,4.173 10.214,4 10.5,4 L13.5,4 C13.786,4 14,4.173 14,4.328 L14,6 L10,6 L10,4.328 Z M21,6 L20,6 L16,6 L16,4.328 C16,3.044 14.879,2 13.5,2 L10.5,2 C9.121,2 8,3.044 8,4.328 L8,6 L4,6 L3,6 C2.45,6 2,6.45 2,7 C2,7.55 2.45,8 3,8 L4,8 L4,19 C4,20.654 5.346,22 7,22 L17,22 C18.654,22 20,20.654 20,19 L20,8 L21,8 C21.55,8 22,7.55 22,7 C22,6.45 21.55,6 21,6 L21,6 Z" />
    </SvgIcon>
  );
}

function OptionsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,10 C13.104,10 14,10.896 14,12 C14,13.104 13.104,14 12,14 C10.896,14 10,13.104 10,12 C10,10.896 10.896,10 12,10 Z M12,7 C10.896,7 10,6.104 10,5 C10,3.896 10.896,3 12,3 C13.104,3 14,3.896 14,5 C14,6.104 13.104,7 12,7 Z M12,17 C13.104,17 14,17.896 14,19 C14,20.104 13.104,21 12,21 C10.896,21 10,20.104 10,19 C10,17.896 10.896,17 12,17 Z" />
    </SvgIcon>
  );
}

function ListIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M4,6 C4.552,6 5,6.448 5,7 C5,7.552 4.552,8 4,8 C3.448,8 3,7.552 3,7 C3,6.448 3.448,6 4,6 Z M4,11 C4.552,11 5,11.448 5,12 C5,12.552 4.552,13 4,13 C3.448,13 3,12.552 3,12 C3,11.448 3.448,11 4,11 Z M4,16 C4.552,16 5,16.448 5,17 C5,17.552 4.552,18 4,18 C3.448,18 3,17.552 3,17 C3,16.448 3.448,16 4,16 Z M20.0645,11 C20.5795,11 21.0005,11.421 21.0005,11.936 L21.0005,12.064 C21.0005,12.579 20.5795,13 20.0645,13 L7.9355,13 C7.4205,13 7.0005,12.579 7.0005,12.064 L7.0005,11.936 C7.0005,11.421 7.4205,11 7.9355,11 L20.0645,11 Z M20.0645,16 C20.5795,16 21.0005,16.421 21.0005,16.936 L21.0005,17.064 C21.0005,17.579 20.5795,18 20.0645,18 L7.9355,18 C7.4205,18 7.0005,17.579 7.0005,17.064 L7.0005,16.936 C7.0005,16.421 7.4205,16 7.9355,16 L20.0645,16 Z M20.0645,6 C20.5795,6 21.0005,6.421 21.0005,6.936 L21.0005,7.064 C21.0005,7.579 20.5795,8 20.0645,8 L7.9355,8 C7.4205,8 7.0005,7.579 7.0005,7.064 L7.0005,6.936 C7.0005,6.421 7.4205,6 7.9355,6 L20.0645,6 Z" />
    </SvgIcon>
  );
}

/**
 * Class CustomTableRow
 *
 * @Component : <CustomTableRow />
 * @Extend : React.Component
 */
class CustomTableRow extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,
    deleteDialogText: PropTypes.string,
    onEdit: PropTypes.func.isRequired,
    onClickRow: PropTypes.func,
    onDelete: PropTypes.func,
    sending: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    onUpOrder: PropTypes.func,
    onDownOrder: PropTypes.func,
    onOptions: PropTypes.func,
    onAddCandidature: PropTypes.func,
    withDeleteButton: PropTypes.bool,
    dataLength: PropTypes.number.isRequired,
    onSwitchActive: PropTypes.func,
    shouldArchive: PropTypes.bool,
    switchActiveDialogText: PropTypes.string
  };

  // ----------------
  // Default Values

  static defaultProps = {
    deleteDialogText: 'Êtes-vous sur de vouloir supprimer cet élément ?',
    onUpOrder: null,
    onDownOrder: null,
    onClickRow: null,
    onDelete: null,
    onSearch: null,
    onList: null,
    onLink: null,
    onOptions: null,
    onAddCandidature: null,
    withDeleteButton: true,
    onSwitchActive: null,
    shouldArchive: false,
    switchActiveDialogText: 'Êtes-vous sur de vouloir désactiver cet élément ?',
  };

  static getDerivedStateFromProps(props) {
    let newState = null;
    if (!props.sending) {
      newState = {
        ...newState,
        deletingItemId: '',
        upItemId: '',
        downItemId: '',
        switchingItemId: ''
      }
    }
    return newState;
  }

  // ----------------
  // Constructor

  constructor(props, context) {
    super(props, context);
    this.state = {
      openDeleteModal: false,
      deletingItemId: '',
      upItemId: '',
      downItemId: '',
      addingCandidatureItemId: '',
      openSwitchModal: false,
      switchingItemId: '',
      qualification: null,
    };
  }

  // ----------------
  // Handler
  handleSelectQualification = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({selectQualification: event.target.value});
  };

  handleSelect = () => {
    if (!this.state.openDeleteModal && !this.state.openSwitchModal && this.props.onClickRow) {
      this.props.onClickRow(this.props.element);
    }
  };

  handleEdit = event => {
    event.stopPropagation();
    this.props.onEdit(this.props.element);
  };

  handleSearch = event => {
    event.stopPropagation();
    this.props.onSearch(this.props.element);
  };

  handleList = event => {
    event.stopPropagation();
    this.props.onList(this.props.element);
  };

  handleLink = event => {
    event.stopPropagation();
    this.props.onLink(this.props.element);
  };

  handleToggleDeleteModal = event => {
    event.stopPropagation();
    this.setState({openDeleteModal: !this.state.openDeleteModal});
  };

  handleDelete = event => {
    event.stopPropagation();
    event.preventDefault();

    // Prepare State
    this.setState({openDeleteModal: false});
    this.setState({deletingItemId: this.props.element.id});

    // Delete
    if (this.props.shouldArchive)
    {
      this.props.onDelete(this.props.element,
        this.state.selectQualification === "Autre" ?
          this.state.qualification : this.state.selectQualification);
    } else {
      this.props.onDelete(this.props.element);
    }
  };

  handleToggleSwitchModal = event => {
    event.stopPropagation();
    this.setState({openSwitchModal: !this.state.openSwitchModal});
  };

  handleSwitchActive = event => {
    event.stopPropagation();
    event.preventDefault();

    // Prepare State
    this.setState({openSwitchModal: false});
    this.setState({switchingItemId: this.props.element.id});

    // Delete
    this.props.onSwitchActive(this.props.element);
  };

  handleUp = event => {
    event.preventDefault();
    this.setState({upItemId: this.props.element.id});
    this.props.onUpOrder(this.props.element);
  };

  handleDown = event => {
    event.preventDefault();
    this.setState({downItemId: this.props.element.id});
    this.props.onDownOrder(this.props.element);
  };

  handleOptions = event => {
    event.preventDefault();
    this.setState({optionsItemId: this.props.element.id});
    this.props.onOptions(event, this.props.element);
  };

  handleAddCandidature = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({addingCandidatureItemId: this.props.element.id});
    this.props.onAddCandidature(this.props.element);
  };


  // ----------------
  // Rendering

  render() {
    // Constant
    const {
      element,
      deleteDialogText,
      switchActiveDialogText,
      fullScreen,
      sending,
      classes
    } = this.props;

    // Element
    const elementIsNotActive = this.props.onSwitchActive && !element.is_active;

    const searchButton = (
      <Tooltip title='Rechercher'>
        <IconButton
          id="search"
          aria-label="update"
          onClick={this.handleSearch}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    );
    const listButton = (
      <Tooltip title='Liste des candidatures'>
        <IconButton
          id="list"
          aria-label="update"
          onClick={this.handleList}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
    );

    const linkButton = (
      <Tooltip title='Créer une offre'>
        <IconButton
          id="link"
          aria-label="update"
          onClick={this.handleLink}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
    );

    const addCandidatureButton = (
      <Tooltip title={ element.isAttachedToPost ? 'Détacher du besoin' : 'Rattacher au besoin' } >
        <IconButton
          id="link"
          aria-label="update"
          onClick={this.handleAddCandidature}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          { element.isAttachedToPost ?
            <DesactivateIcon style={{ color: colorTheme.secondary }}/> : <ActivateIcon /> }
          {sending && this.state.addingCandidatureItemId === element.id &&
          <CircularProgress size={50} style={defaultStyles.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const editButton = (
      <Tooltip title='Editer'>
        <IconButton
          id="edit"
          aria-label="update"
          onClick={this.handleEdit}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );

    const deleteButton = (
      <Tooltip title={this.props.shouldArchive ? 'Archiver' : 'Supprimer'}>
        <IconButton
          id="delete"
          aria-label="update"
          onClick={this.handleToggleDeleteModal}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          {this.props.shouldArchive ?
            <ArchiveIcon/> : <DeleteIcon/>
          }
          {sending && this.state.deletingItemId === element.id &&
          <CircularProgress size={50} style={defaultStyles.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const upButton = (
      <Tooltip title='Remonter dans la liste'>
        <IconButton
          id="up"
          aria-label="update"
          onClick={this.handleUp}
          color="primary"
          disabled={elementIsNotActive ||sending || element.order < 1}
        >
          <UpIcon />
          {sending && this.state.upItemId === element.id &&
          <CircularProgress size={50} style={defaultStyles.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const downButton = (
      <Tooltip title='Descendre dans la liste'>
        <IconButton
          id="down"
          aria-label="update"
          onClick={this.handleDown}
          color="primary"
          disabled={elementIsNotActive ||sending || !(element.order < this.props.dataLength -1)}
        >
          <DownIcon />
          {sending && this.state.downItemId === element.id &&
          <CircularProgress size={50} style={defaultStyles.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const switchButton = (
      <Tooltip title={element.is_active ? 'Désactiver' : "Activer"}>
        <IconButton
          id="down"
          aria-label="update"
          onClick={element.is_active ? this.handleToggleSwitchModal : this.handleSwitchActive}
          color="primary"
        >
          {element.is_active ?
          <DesactivateIcon /> : <ActivateIcon style={{ color: colorTheme.secondary }}/> }
          {sending && this.state.switchingItemId === element.id &&
          <CircularProgress size={50} style={defaultStyles.fabProgress} />}
        </IconButton>
      </Tooltip>
    );

    const optionsButton = (
      <Tooltip title='Options'>
        <IconButton
          id="options"
          aria-label="options"
          onClick={this.handleOptions}
          color="primary"
          disabled={elementIsNotActive ||sending}
        >
          <OptionsIcon />
        </IconButton>
      </Tooltip>
    );

    const deleteModal = (
      <Dialog
        fullScreen={fullScreen}
        open={this.state.openDeleteModal}
        onClose={this.handleToggleDeleteModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
        >
          Attention
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteDialogText}
          </DialogContentText>
          {this.props.shouldArchive &&
          <Select
            value={this.state.selectQualification}
            onChange={this.handleSelectQualification}
            inputProps={{
              name: 'Qualification',
              id: 'select-qualification',
            }}
          >
            <MenuItem value={"Cessation d’activité / de paiement"}>Cessation d’activité / de paiement</MenuItem>
            <MenuItem value={"Liquidation judiciaire"}>Liquidation judiciaire</MenuItem>
            <MenuItem value={"Redressement Judiciaire"}>Redressement Judiciaire</MenuItem>
            <MenuItem value={"Client Perdu"}>Client Perdu</MenuItem>
            <MenuItem value={"Autre"}>Autre</MenuItem>
          </Select>}
          {this.props.shouldArchive && this.state.selectQualification === "Autre" &&
          <TextField
            className={classes.textField}
            margin="dense"
            id="other-qualification"
            value={this.state.qualification}
            onChange={event => {this.setState({qualification: event.target.value})}}
            fullWidth
          />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleDelete}
            color="primary"
            autoFocus
            disabled={this.props.shouldArchive && !(this.state.selectQualification &&
            (this.state.selectQualification !== "Autre" || this.state.qualification))}
          >
            Oui
          </Button>
          <Button
            onClick={this.handleToggleDeleteModal}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    );

    const switchActiveModal = (
      <Dialog
        fullScreen={fullScreen}
        open={this.state.openSwitchModal}
        onClose={this.handleToggleSwitchModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
        >
          Attention
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {switchActiveDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleSwitchActive}
            color="primary"
            autoFocus
          >
            Oui
          </Button>
          <Button
            onClick={this.handleToggleSwitchModal}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <TableRow
        key={element.id}
        hover={!elementIsNotActive}
        onClick={this.handleSelect}
      >
        {this.props.children}
        <TableCell style={{minWidth: '176px'}} size={'small'}>
          {this.props.onUpOrder && upButton}
          {this.props.onDownOrder && downButton}
          {this.props.onSearch && searchButton}
          {this.props.onList && listButton}
          {this.props.onLink && linkButton}
          {this.props.onEdit && editButton}
          {this.props.onOptions && optionsButton}
          {this.props.onSwitchActive && switchButton}
          {this.props.onSwitchActive && switchActiveModal}
          {this.props.withDeleteButton && deleteButton}
          {this.props.withDeleteButton && deleteModal}
          {this.props.onAddCandidature && addCandidatureButton}
        </TableCell>
      </TableRow>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles),
)(CustomTableRow);
