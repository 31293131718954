import { POST_STATUS } from '../../offer';

export const postFormFields = (jobsOptions, skillsOptions, softSkillsOptions) => ([
  {
    id: 'extendor_poste',
    type: 'extendor',
    gridMD: 12,
    label: 'Poste',
    noPadding: true,
  },
  {
    id: 'client_post_name',
    type: 'textField',
    label: 'Intitulé chez le client',
    dependances: ['extendor_poste'],
    isMandatory: true,
  },
  {
    id: 'status',
    type: 'selectField',
    options: POST_STATUS,
    label: 'Statut',
    dependances: ['extendor_poste'],
    isMandatory: false,
  },
  {
    id: 'status_comment',
    type: 'textField',
    label: 'Autre statut',
    dependances: ['extendor_poste'],
    isMandatory: false,
  },
  {
    id: 'name',
    type: 'selectField',
    gridMD: 12,
    options: jobsOptions,
    autocomplete: true,
    label: 'Intitulé normé',
    dependances: ['extendor_poste'],
    isMandatory: true,
    observable: true,
  },
  {
    id: 'skills',
    type: 'selectField',
    gridMD: 6,
    options: skillsOptions,
    autocomplete: true,
    noOptions: 'Choisir d\'abord un métier (Intitulé)',
    multiple: true,
    label: 'Savoir-faire',
    dependances: ['extendor_poste'],
    isMandatory: true,
  },
  {
    id: 'softskills',
    type: 'selectField',
    gridMD: 6,
    options: softSkillsOptions,
    autocomplete: true,
    noOptions: 'Choisir d\'abord un métier (Intitulé)',
    multiple: true,
    label: 'Savoir-être',
    dependances: ['extendor_poste'],
    isMandatory: true,
  },
  {
    id: 'profile',
    type: 'richText',
    maxSize: 2500,
    gridMD: 12,
    label: 'Profil (max 2500 caractères)',
    dependances: ['extendor_poste'],
    isMandatory: true,
  },
]);
