import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CONTACTS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CONTACT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.CONTACTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CONTACTS_UNLOAD: {
      return INITIAL_STATE;
    }
    case types.CONTACT_DOWNLOADED: {
      const contactDownloaded = action.payload;
      let contacts = [];
      let contactIndex = -1;
      if (state.content) {
        contacts = state.content.slice();
        contactIndex = contacts.findIndex(contact => contact.id === contactDownloaded.id);
      }
      if (contactIndex === -1) {
        contacts.push(contactDownloaded);
      } else {
        contacts[contactIndex] = contactDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: contacts,
      };
    }
    case types.CONTACT_REMOVED: {
      const contactId = action.payload;
      let contacts = [];
      if (state.content !== null) {
        contacts = state.content.slice();
        let isContact = (contactId) => {
          return function (element) {
            return element.id !== contactId;
          }
        };
        contacts = state.content.filter(isContact(contactId));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: contacts,
      };
    }
    case types.CONTACTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
