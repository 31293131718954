export const contextFormFields = () => {
  const sliderMarks = [1, 5, 10].map((num) => (
    {
      value: num,
      label: num.toString(),
    }
  ));
  return [
    {
      id: 'extendor_contexte',
      type: 'extendor',
      gridMD: 12,
      label: 'Contexte du poste',
      noPadding: true,
    },
    {
      id: 'initiative_level',
      type: 'sliderField',
      gridMD: 6,
      marks: sliderMarks,
      step: 1,
      min: 1,
      max: 10,
      label: 'Niveau d\'initiative',
      dependances: ['extendor_contexte'],
      isMandatory: false,
    },
    {
      id: 'autonomy_level',
      type: 'sliderField',
      gridMD: 6,
      marks: sliderMarks,
      step: 1,
      min: 1,
      max: 10,
      label: 'Niveau d\'autonomie',
      dependances: ['extendor_contexte'],
      isMandatory: false,
    },
  ];
};
