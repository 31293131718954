import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ShareIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M18,19 C17.448,19 17,18.552 17,18 C17,17.448 17.448,17 18,17 C18.552,17 19,17.448 19,18 C19,18.552 18.552,19 18,19 M5,13 C4.448,13 4,12.552 4,12 C4,11.448 4.448,11 5,11 C5.552,11 6,11.448 6,12 C6,12.552 5.552,13 5,13 M18,5 C18.552,5 19,5.448 19,6 C19,6.552 18.552,7 18,7 C17.448,7 17,6.552 17,6 C17,5.448 17.448,5 18,5 M18,15 C17.183,15 16.443,15.33 15.901,15.861 L7.966,12.335 C7.979,12.224 8,12.114 8,12 C8,11.886 7.979,11.776 7.966,11.665 L15.901,8.139 C16.443,8.67 17.183,9 18,9 C19.654,9 21,7.654 21,6 C21,4.346 19.654,3 18,3 C16.346,3 15,4.346 15,6 C15,6.114 15.021,6.224 15.034,6.335 L7.099,9.861 C6.557,9.33 5.817,9 5,9 C3.346,9 2,10.346 2,12 C2,13.654 3.346,15 5,15 C5.817,15 6.557,14.67 7.099,14.139 L15.034,17.665 C15.021,17.776 15,17.886 15,18 C15,19.654 16.346,21 18,21 C19.654,21 21,19.654 21,18 C21,16.346 19.654,15 18,15" />
    </SvgIcon>
  );
};

export default ShareIcon;
