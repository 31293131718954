export const otherFormFields = [
  {
    id: 'extendor_other',
    type: 'extendor',
    gridMD: 12,
    label: 'Autres informations',
    noPadding: true,
  },
  {
    id: 'competition',
    type: 'checkboxNormal',
    label: 'Mise en concurrence sur le besoin',
    dependances: ['extendor_other'],
    isMandatory: false,
  },
  {
    id: 'competition_info',
    type: 'richText',
    gridMD: 6,
    label: 'Information concurrence',
    dependances: ['extendor_other'],
    isMandatory: false,
  },
  {
    id: 'comments',
    type: 'richText',
    gridMD: 12,
    label: 'Autres commentaires',
    dependances: ['extendor_other'],
    isMandatory: false,
  },
];
