import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { defaultStyles } from '../../themes/theme';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import * as auth from '../../services/firebase/auth';
import { SIGN_IN } from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles/index';

const INITIAL_STATE = {
  email: '',
};

const styles = () => (
  {
    field: {
      width: '298px',
    },
    link: {
      marginTop: '8px',
      marginBottom: '8px',
      width: 'auto',
      textAlign: 'right',
    },
  }
);

export class PasswordForgetForm extends Component {
  static propTypes = {
    handleError: PropTypes.func.isRequired,
    dispatchInfo: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    auth.doPasswordReset(this.state.email)
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
    event.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const {
      email,
    } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit} style={defaultStyles.center}>
        <TextField
          placeholder="Email"
          id="changepassword-email"
          className={classes.field}
          type="text"
          onChange={event => this.setState({ email: event.target.value })}
          margin="normal"
          value={email}
        />
        <Typography
          className={classes.link}
          variant="caption"
          gutterBottom
        >
          <Link to={SIGN_IN}>Se connecter</Link>
        </Typography>
        <Fab
          variant="extended"
          color="primary"
          disabled={isInvalid}
          type="submit"
        >
          Envoyer
        </Fab>
      </form>
    );
  }
}

export default compose(
  connect(null,
    {
      handleError,
      dispatchInfo,
    },
  ),
  withStyles(styles),
)(PasswordForgetForm);
