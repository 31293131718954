import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  hrprofils: null,
  advantages: null,
  broadcasts: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLIENTS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CLIENT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.CLIENTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CLIENTS_UNLOAD: {
      return INITIAL_STATE;
    }
    case types.HR_PROFILS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        hrprofils: action.payload,
      };
    }
    case types.ADVANTAGES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        advantages: action.payload,
      };
    }
    case types.BROADCASTS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        broadcasts: action.payload,
      };
    }
    case types.CLIENT_DOWNLOADED: {
      const clientDownloaded = action.payload;
      let clients = [];
      let clientIndex = -1;
      if (state.content) {
        clients = state.content.slice();
        clientIndex = clients.findIndex(client => client.id === clientDownloaded.id);
      }
      if (clientIndex === -1) {
        clients.push(clientDownloaded);
      } else {
        clients[clientIndex] = clientDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENT_CONTACT_DOWNLOADED: {
      const contactDownloaded = action.payload;
      let clients = [];
      let contactIndex = -1;
      if (state.content) {
        clients = state.content.slice();
        let client = clients.find(client => client.id === contactDownloaded.client_id);
        contactIndex = client.contacts.findIndex(contact => contact.id === contactDownloaded.id);
        if (contactIndex === -1) {
          client.contacts.push(contactDownloaded);
        } else {
          client.contacts[contactIndex] = contactDownloaded;
        }
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENT_CONTACT_REMOVED: {
      const contactId = action.payload.contactId;
      const clientId = action.payload.clientId;
      let clients = [];
      if (state.content) {
        clients = state.content.slice();
        let client = clients.find(client => client.id === clientId);
        let isContact = (contactId) => {
          return function (element) {
            return element.id !== contactId;
          }
        };
        client.contacts = client.contacts.filter(isContact(contactId));
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENT_POST_DOWNLOADED: {
      const postDownloaded = action.payload;
      let clients = [];
      let postIndex = -1;
      if (state.content) {
        clients = state.content.slice();
        let client = clients.find(client => client.id === postDownloaded.client_id);
        postIndex = client.posts.findIndex(post => post.id === postDownloaded.id);
        if (postIndex === -1) {
          client.posts.push(postDownloaded);
        } else {
          client.posts[postIndex] = postDownloaded;
        }
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENT_POST_REMOVED: {
      const postId = action.payload.postId;
      const clientId = action.payload.clientId;
      let clients = [];
      if (state.content) {
        clients = state.content.slice();
        let client = clients.find(client => client.id === clientId);
        let isPost = (postId) => {
          return function (element) {
            return element.id !== postId;
          }
        };
        client.posts = client.posts.filter(isPost(postId));
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENT_REMOVED: {
      const client = action.payload;
      let RemovedClientOrder = client.order;
      let clients = state.content.slice();
      if (state.content !== null) {
        let isClient = (clientId) => {
          return function (element) {
            return element.id !== clientId;
          }
        };
        clients = state.content.filter(isClient(client.id));
      }
      clients.forEach(function (element) {
        if (element.order > RemovedClientOrder) {
          element.order = element.order - 1;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: clients,
      };
    }
    case types.CLIENTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
