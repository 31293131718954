import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';

export const CGU = () => {
  return (
    <Typography
      variant="subtitle"
      gutterBottom
    >
      <p className="p1" style={{ textAlign: 'center' }}><span
        className="s1"><strong>Conditions g&eacute;n&eacute;rales d'utilisation</strong></span>
      </p>
      <p className="p2" style={{ textAlign: 'center' }}><span
        className="s1"><em>En vigueur au 01/12/2019</em></span>
      </p>
      <p className="p2" style={{ textAlign: 'left' }}><span className="s1">&nbsp;</span></p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d'utilisation (dites &laquo; CGU &raquo;) ont pour objet l'encadrement juridique des modalit&eacute;s de mise &agrave; disposition de l&rsquo;application et des services par AD Conseils et de d&eacute;finir les conditions d&rsquo;acc&egrave;s et d&rsquo;utilisation des services par &laquo; l'Utilisateur &raquo;.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Les pr&eacute;sentes CGU sont accessibles sur l&rsquo;application &agrave; la rubrique &laquo;CGU&raquo;.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Toute inscription ou utilisation de l&rsquo;application implique l'acceptation sans aucune r&eacute;serve ni restriction des pr&eacute;sentes CGU par l&rsquo;utilisateur. Lors de l'inscription sur l&rsquo;application via le Formulaire d&rsquo;inscription, chaque utilisateur accepte express&eacute;ment les pr&eacute;sentes CGU en cochant la case pr&eacute;c&eacute;dant le texte suivant : &laquo;
        <em>Je reconnais avoir lu et compris les CGU et je les accepte</em> &raquo;.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">En cas de non-acceptation des CGU stipul&eacute;es dans le pr&eacute;sent contrat, l'Utilisateur se doit de renoncer &agrave; l'acc&egrave;s des services propos&eacute;s par l&rsquo;application.</span>
      </p>
      <p className="p4" style={{ textAlign: 'left' }}><span
        className="s1">AVA Talent se r&eacute;serve le droit de modifier unilat&eacute;ralement et &agrave; tout moment le contenu des pr&eacute;sentes CGU.</span>
      </p>
      <p className="p5" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 1 : Les mentions l&eacute;gales</strong></span></p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L'&eacute;dition de l&rsquo;application AVA Talent est assur&eacute;e par AD CONSEILS au capital de 1000 &euro;, immatricul&eacute;e au RCS de Clermont-Ferrand sous le num&eacute;ro B 501&nbsp;472&nbsp;831 Code APE 7490B, dont le si&egrave;ge social est situ&eacute; au 15 Rue Jean Claret &ndash; 63000 Clermont-Ferrand</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Num&eacute;ro de t&eacute;l&eacute;phone 04 44 05 30 34</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Adresse e-mail : recrutement@ava-talent.com</span></p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Le Directeur de la publication est : Laurent CHAMBARON, Dirigeant d&rsquo;AD CONSEILS.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L'h&eacute;bergeur de l&rsquo;application AVA Talent Recrutement est la soci&eacute;t&eacute; OPENIUM, dont le si&egrave;ge social est situ&eacute; au 15 rue Jean Claret - 63000 Clermont-Ferrand</span>
      </p>
      <p className="p6">&nbsp;</p>
      <p className="p5" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 2&nbsp;: Acc&egrave;s &agrave; l&rsquo;application</strong></span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L&rsquo;application <em>AVA Talent</em> permet &agrave; l'Utilisateur un acc&egrave;s gratuit aux services suivants :</span>
      </p>
      <ul className="ul1">
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Cr&eacute;ation de compte / profil utilisateur</span>
        </li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Modification de son mot de passe</span></li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">D&eacute;p&ocirc;t de documents relevant de l&rsquo;exp&eacute;rience professionnelle de type CV / lettres de motivation / extractions en format PDF de profils types Linkedin, Indeed, P&ocirc;le Emploi&hellip;.</span>
        </li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Candidature en ligne aux offres d&rsquo;emploi</span></li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Planification / acceptation de rendez-vous li&eacute;s &agrave; ses candidatures</span>
        </li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Consultation de l&rsquo;&eacute;tat d&rsquo;avancement de ses candidatures</span>
        </li>
      </ul>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L&rsquo;application est accessible gratuitement en tout lieu &agrave; tout Utilisateur ayant un smartphone et un acc&egrave;s &agrave; Internet. Tous les frais support&eacute;s par l'Utilisateur pour acc&eacute;der au service (t&eacute;l&eacute;phone, mat&eacute;riel informatique, logiciels, connexion Internet, etc.) sont &agrave; sa charge.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s2">Pour acc&eacute;der aux services, l&rsquo;Utilisateur doit cr&eacute;er un compte en indiquant une adresse e-mail et un mot de passe. Un email de validation lui sera ensuite envoyer avec un lien d&rsquo;activation de son compte. Apr&egrave;s avoir cliqu&eacute; sur le lien, le compte est activ&eacute; et l&rsquo;Utilisateur peut acc&eacute;der aux services de l&rsquo;application.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s2">Tout Utilisateur pourra &eacute;galement solliciter la suppression de son compte en envoyant sa demande &agrave; l&rsquo;adresse compte@ava-talent.com.</span><span
        className="s1"> Celle-ci sera effective dans un d&eacute;lai raisonnable.</span></p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Tout &eacute;v&eacute;nement d&ucirc; &agrave; un cas de force majeure ayant pour cons&eacute;quence un dysfonctionnement de l&rsquo;application ou serveur et sous r&eacute;serve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilit&eacute; d&rsquo;AD CONSEILS<em>. </em>Dans ces cas, l&rsquo;Utilisateur accepte ainsi ne pas tenir rigueur &agrave; l&rsquo;&eacute;diteur de toute interruption ou suspension de service, m&ecirc;me sans pr&eacute;avis.</span>
      </p>
      <p className="p4" style={{ textAlign: 'left' }}><span
        className="s1">L'Utilisateur a la possibilit&eacute; de contacter l&rsquo;application par messagerie &eacute;lectronique &agrave; l&rsquo;adresse email de l&rsquo;&eacute;diteur communiqu&eacute; &agrave; l&rsquo;ARTICLE 1.</span>
      </p>
      <p className="p7" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 3 : Collecte des donn&eacute;es personnelles</strong></span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L&rsquo;application assure &agrave; l'Utilisateur une collecte et un traitement d'informations personnelles en conformit&eacute; avec la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e, relative &agrave; l'informatique, aux fichiers et aux libert&eacute;s. L&rsquo;application assure &eacute;galement un traitement des donn&eacute;es personnelles en conformit&eacute; avec le R&egrave;glement Europ&eacute;en n&deg;2016/679UE du 27 Avril 2016.</span>
      </p>
      <p className="p4" style={{ textAlign: 'left' }}><span
        className="s1">En vertu de la loi Informatique et Libert&eacute;s, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'acc&egrave;s, de rectification, de suppression et d'opposition de ses donn&eacute;es personnelles. L'Utilisateur exerce ce droit en </span><span
        className="s2">envoyant sa demande &agrave; l&rsquo;adresse compte@ava-talent.com.</span>
      </p>
      <p className="p7" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 4&nbsp;: Propri&eacute;t&eacute; intellectuelle</strong></span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Les marques, logos, signes ainsi que tous les contenus de l&rsquo;application (textes, images, son&hellip;) font l'objet d'une protection par le Code de la propri&eacute;t&eacute; intellectuelle et plus particuli&egrave;rement par le droit d'auteur.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">La marque AVA Talent est une marque d&eacute;pos&eacute;e par AD Conseils. Toute repr&eacute;sentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohib&eacute;e.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L'Utilisateur doit solliciter l'autorisation pr&eacute;alable de l&rsquo;application pour toute reproduction, publication, copie des diff&eacute;rents contenus. Il s'engage &agrave; une utilisation des contenus de l&rsquo;application dans un cadre strictement priv&eacute;, toute utilisation &agrave; des fins commerciales et publicitaires est strictement interdite.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Toute repr&eacute;sentation totale ou partielle de cette application par quelque proc&eacute;d&eacute; que ce soit, sans l&rsquo;autorisation expresse de l&rsquo;exploitant de l&rsquo;application constituerait une contrefa&ccedil;on sanctionn&eacute;e par l&rsquo;article L 335-2 et suivants du Code de la propri&eacute;t&eacute; intellectuelle.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Il est rappel&eacute; conform&eacute;ment &agrave; l&rsquo;article L122-5 du Code de propri&eacute;t&eacute; intellectuelle que l&rsquo;Utilisateur qui reproduit, copie ou publie le contenu prot&eacute;g&eacute; doit citer l&rsquo;auteur et sa source.</span>
      </p>
      <p className="p8" style={{ textAlign: 'left' }}><span className="s1">&nbsp;</span></p>
      <p className="p7" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 5&nbsp;: Responsabilit&eacute;</strong></span></p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Les sources des informations diffus&eacute;es sur l&rsquo;application AVA Talent sont r&eacute;put&eacute;es fiables mais l&rsquo;application ne garantit pas qu&rsquo;il soit exempt de d&eacute;fauts, d&rsquo;erreurs ou d&rsquo;omissions.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Les informations communiqu&eacute;es sont pr&eacute;sent&eacute;es &agrave; titre indicatif et g&eacute;n&eacute;ral sans valeur contractuelle. Malgr&eacute; des mises &agrave; jour r&eacute;guli&egrave;res, l&rsquo;application AVA Talent<em>&nbsp;</em>ne peut &ecirc;tre tenue responsable de la modification des dispositions administratives et juridiques survenant apr&egrave;s la publication. De m&ecirc;me, l&rsquo;application ne peut &ecirc;tre tenue responsable de l&rsquo;utilisation et de l&rsquo;interpr&eacute;tation de l&rsquo;information contenue dans l&rsquo;application.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques li&eacute;s &agrave; l'utilisation de son identifiant et mot de passe. L&rsquo;application d&eacute;cline toute responsabilit&eacute;.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span className="s1">L&rsquo;application AVA Talent<em>&nbsp;</em>ne peut &ecirc;tre tenue pour responsable d&rsquo;&eacute;ventuels virus qui pourraient infecter l&rsquo;ordinateur ou tout mat&eacute;riel informatique et t&eacute;l&eacute;phonique de </span><span
        className="s2">l&rsquo;Utilisateur</span><span
        className="s1">, suite &agrave; une utilisation, &agrave; l&rsquo;acc&egrave;s, ou au t&eacute;l&eacute;chargement provenant de cette application.</span>
      </p>
      <p className="p4" style={{ textAlign: 'left' }}><span
        className="s1">La responsabilit&eacute; de l&rsquo;application ne peut &ecirc;tre engag&eacute;e en cas de force majeure ou du fait impr&eacute;visible et insurmontable d'un tiers.</span>
      </p>
      <p className="p7" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 6&nbsp;: Publication par l&rsquo;Utilisateur</strong></span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L&rsquo;application permet &agrave; l&rsquo;utilisateur de publier les contenus suivants :</span>
      </p>
      <ul className="ul1">
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">CV, lettres de motivation, tout document requis dans le cadre d&rsquo;une candidature &agrave; un poste publi&eacute; par AVA Talent</span>
        </li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Faire des demandes de rendez-vous / accepter des demandes de rendez-vous</span>
        </li>
        <li className="li3" style={{ textAlign: 'left' }}><span
          className="s1">Ecrire des messages au recruteur dans le cadre de ses dossiers de candidatures</span>
        </li>
      </ul>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s2">Dans ses publications, l&rsquo;utilisateur est seul responsable de ses contenus et s&rsquo;engage &agrave; respecter les r&egrave;gles de bonne conduite et les r&egrave;gles de droit en vigueur.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">L'Utilisateur s'engage &agrave; ne pas mettre en ligne de contenus pouvant porter atteinte aux int&eacute;r&ecirc;ts de tierces personnes. Tout recours en justice engag&eacute; par un tiers l&eacute;s&eacute; contre l&rsquo;application sera pris en charge par l'Utilisateur.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Le contenu de l'Utilisateur peut &ecirc;tre &agrave; tout moment et pour n'importe quelle raison supprim&eacute; ou modifi&eacute; par l&rsquo;&eacute;diteur, sans pr&eacute;avis.</span>
      </p>
      <p className="p4" style={{ textAlign: 'left' }}><span className="s1">&nbsp;</span></p>
      <p className="p7" style={{ textAlign: 'left' }}><span
        className="s1"><strong>ARTICLE 7<em>&nbsp;</em>: Droit applicable et juridiction comp&eacute;tente</strong></span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">La l&eacute;gislation fran&ccedil;aise s'applique au pr&eacute;sent contrat. En cas d'absence de r&eacute;solution amiable d'un litige n&eacute; entre les parties, les tribunaux fran&ccedil;ais seront seuls comp&eacute;tents pour en conna&icirc;tre.</span>
      </p>
      <p className="p3" style={{ textAlign: 'left' }}><span
        className="s1">Pour toute question relative &agrave; l&rsquo;application des pr&eacute;sentes CGU, vous pouvez joindre l&rsquo;&eacute;diteur aux coordonn&eacute;es inscrites &agrave; l&rsquo;ARTICLE 1.</span>
      </p>
      <p className="p9">&nbsp;</p>
    </Typography>
  );
};

export default CGU;
