export const verbalFormFields = [
  {
    id: 'extendor_verbal',
    type: 'extendor',
    gridMD: 12,
    label: 'Comportement verbal',
    noPadding: true,
  },
  {
    id: 'diction',
    type: 'richText',
    gridMD: 12,
    label: 'Diction',
    dependances: ['extendor_verbal'],
    isMandatory: false,
  },
  {
    id: 'rythme',
    type: 'richText',
    gridMD: 12,
    label: 'Rythme',
    dependances: ['extendor_verbal'],
    isMandatory: false,
  },
  {
    id: 'voix',
    type: 'richText',
    gridMD: 12,
    label: 'Voix',
    dependances: ['extendor_verbal'],
    isMandatory: false,
  },
  {
    id: 'expressions',
    type: 'richText',
    gridMD: 12,
    label: 'Expressions',
    dependances: ['extendor_verbal'],
    isMandatory: false,
  },
  {
    id: 'formulation',
    type: 'richText',
    gridMD: 12,
    label: 'Formulation',
    dependances: ['extendor_verbal'],
    isMandatory: false,
  },
];
