import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import LargePaper from '../../components/common/LargePaper';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { deleteClientPost, getClients } from '../../stores/clients/actions';
import { getUsers } from '../../stores/users/actions';
import {
  CLIENT_POST_ADD,
  CLIENT_POST_CANDIDATES,
  CLIENT_POST_EDIT,
  OFFER_ADD,
} from '../../constants/routes';

const INITIAL_STATE = {
  datas: [],
};

const contracts = {
  21: 'CDI',
  22: 'CDD',
  26: 'Alternance',
  23: 'Intérim',
};

export class ClientPostsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    clientError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.clients && !props.pending && props.roles && !props.clientError) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken(true).then(token => {
        props.getClients(token, role);
      });
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.clients && props.users) {
      const currentClient = props.clients
        .find(client => client.id === props.match.params.clientId);

      let posts = currentClient.posts;
      posts.forEach((post) => {
        post.contract_value = contracts[post.contract];

      });
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: currentClient.posts,
        };
      }
      if (props.contacts !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: currentClient.posts,
        };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(CLIENT_POST_ADD
      .replace(':clientId', this.props.match.params.clientId));
  };

  handleSearch = element => {
    this.props.history.push(
      CLIENT_POST_CANDIDATES.replace(':clientId', this.props.match.params.clientId).replace(
        ':postId',
        element.id,
      ),
      element,
    );
  };

  handleLink = element => {
    this.props.history.push(OFFER_ADD, element);
  };

  handleEdit = element => {
    this.props.history.push(CLIENT_POST_EDIT
      .replace(':clientId', this.props.match.params.clientId).replace(':postId', element.id));
  };

  handleDelete = element => {
    const role = this.props.roles[this.props.authUser.uid];
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.deleteClientPost(
        token,
        role,
        element.id,
        this.props.match.params.clientId,
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {
        id: 'contract_value',
        label: 'Type de contrat',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'client_post_name',
        label: 'Intitulé du poste',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'induction_date',
        label: 'Date d\'intégration',
        date: true,
        numeric: false,
        align: 'left',
        disablePadding: false,
      },
    ];
    return (
      <LargePaper title="Gestion des Besoins">
        <div>
          {this.props.pending &&
            <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              buttonLabel="Ajouter un besoin"
              paginationLabel="Besoins par page"
              emptyDataMessage="Aucun Besoin"
              deleteDialogText="Êtes-vous sûr de vouloir supprimer ce besoin ?"
              columnData={columnData}
              sending={this.props.sending}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              onSearch={this.handleSearch}
              onLink={this.handleLink}
              defaultOrderBy={'induction_date'}
              defaultOrder={'desc'}
            />
          )}
        </div>
      </LargePaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    pending: state.users.pending || state.clients.pending,
    sending: state.clients.sending,
    clientError: state.clients.error,
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      getClients,
      getUsers,
      deleteClientPost,
      handleError,
      dispatchInfo,
    },
  ),
)(ClientPostsPage);
