import phoneDisabled from '../resources/images/icon_voice_disabled.svg';
import phoneEnabled from '../resources/images/icon_voice_enabled.svg';
import visioDisabled from '../resources/images/icon_visio_disabled.svg';
import visioEnabled from '../resources/images/icon_visio_enabled.svg';
import faceDisabled from '../resources/images/icon_face_disabled.svg';
import faceEnabled from '../resources/images/icon_face_enabled.svg';

export const STEPS = {
  ACCOUNT_CREATION_WAITING: {
    value: -1,
    label: 'En attente de création de compte',
  },
  CREATED: {
    value: 0,
    label: 'Candidature faite',
  },
  CONSULTED: {
    value: 1,
    label: 'Candidature consultée',
  },
  PHONE_WAITING_SLOTS: {
    value: 2,
    label: 'Entretien téléphone - en attente de créneaux',
    img: phoneDisabled,
  },
  PHONE_VALIDATED: {
    value: 3,
    label: 'Entretien téléphone - créneau validé',
    img: phoneEnabled,
  },
  PHONE_WAITING: {
    value: 4,
    label: 'Entretien téléphone - en attente de validation',
    img: phoneDisabled,
  },
  VISIO_WAITING_SLOTS: {
    value: 5,
    label: 'Entretien visio - en attente de créneaux',
    img: visioDisabled,
  },
  VISIO_VALIDATED: {
    value: 6,
    label: 'Entretien visio - créneau validé',
    img: visioEnabled,
  },
  VISIO_WAITING: {
    value: 7,
    label: 'Entretien visio - en attente de validation',
    img: visioDisabled,
  },
  CONSULT_WAITING_SLOTS: {
    value: 8,
    label: 'Entretien recruteur - en attente de créneaux',
    img: faceDisabled,
  },
  CONSULT_VALIDATED: {
    value: 9,
    label: 'Entretien recruteur - créneau validé',
    img: faceEnabled,
  },
  CONSULT_WAITING: {
    value: 10,
    label: 'Entretien recruteur - en attente de validation',
    img: faceDisabled,
  },
  CLIENT_WAITING_SLOTS: {
    value: 11,
    label: 'Entretien client - en attente de créneaux',
    img: faceDisabled,
  },
  CLIENT_VALIDATED: {
    value: 12,
    label: 'Entretien client - créneau validé',
    img: faceEnabled,
  },
  CLIENT_WAITING: {
    value: 13,
    label: 'Entretien client - en attente de validation',
    img: faceDisabled,
  },
  CANDIDATE_REJECTED: {
    value: 14,
    label: 'Candidature rejetée',
    img: faceDisabled,
  },
  CANDIDATE_ACCEPTED: {
    value: 15,
    label: 'Candidature acceptée',
    img: faceDisabled,
  },
  CANDIDATE_REMOVED: {
    value: 16,
    label: 'Candidature retirée',
    img: faceDisabled,
  },
};