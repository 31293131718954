export const globalFields = [
  {
    id: 'extendor_global',
    type: 'extendor',
    gridMD: 12,
    label: 'Appréciation globale',
    noPadding: true,
  },
  {
    id: 'auto_eval',
    type: 'richText',
    gridMD: 12,
    label: 'Auto-évaluation',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'objectif',
    type: 'richText',
    gridMD: 12,
    label: 'L\'objectif de la communication a-t-il été atteint ?',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'communication',
    type: 'richText',
    gridMD: 12,
    label: 'La communication a-t-elle semblé longue ?',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'temps',
    type: 'richText',
    gridMD: 12,
    label: 'Y\'a-t-il eu des temps morts ?',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'point',
    type: 'richText',
    gridMD: 12,
    label: 'A-t-on longuement développé un point ?',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'arret',
    type: 'richText',
    gridMD: 12,
    label: 'La communication s\'est-elle arrêtée trop vite ?',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'free',
    type: 'richText',
    gridMD: 12,
    label: 'Champ libre',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
  {
    id: 'avis',
    type: 'richText',
    gridMD: 12,
    label: 'AVIS DU CONSULTANT',
    dependances: ['extendor_global'],
    isMandatory: false,
  },
];