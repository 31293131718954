import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CloseIcon = (props) => {

  return (
    <SvgIcon {...props}>
        <path d="M13.4141,12 L17.7071,7.707 C18.0981,7.316 18.0981,6.684 17.7071,6.293 C17.3161,5.902 16.6841,5.902 16.2931,6.293 L12.0001,10.586 L7.7071,6.293 C7.3161,5.902 6.6841,5.902 6.2931,6.293 C5.9021,6.684 5.9021,7.316 6.2931,7.707 L10.5861,12 L6.2931,16.293 C5.9021,16.684 5.9021,17.316 6.2931,17.707 C6.4881,17.902 6.7441,18 7.0001,18 C7.2561,18 7.5121,17.902 7.7071,17.707 L12.0001,13.414 L16.2931,17.707 C16.4881,17.902 16.7441,18 17.0001,18 C17.2561,18 17.5121,17.902 17.7071,17.707 C18.0981,17.316 18.0981,16.684 17.7071,16.293 L13.4141,12 Z" />
    </SvgIcon>
  );
};

export default CloseIcon;
