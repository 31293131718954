import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const staticsPendingAction = () => ({
  type: types.STATICS_PENDING,
});

export const staticsLegalEntitiesDownloadedAction = legalEntities => ({
  type: types.STATICS_LEGAL_ENTITIES_DOWNLOADED,
  payload: legalEntities,
});

export const staticsSectorsDownloadedAction = sectors => ({
  type: types.STATICS_SECTORS_DOWNLOADED,
  payload: sectors,
});

export const staticsTalentPlugSectorsDownloadedAction = sectors => ({
  type: types.STATICS_TALENT_PLUG_SECTORS_DOWNLOADED,
  payload: sectors,
});

export const staticsCiblesDownloadedAction = cibles => ({
  type: types.STATICS_CIBLES_DOWNLOADED,
  payload: cibles,
});

export const staticsStatusDownloadedAction = status => ({
  type: types.STATICS_STATUS_DOWNLOADED,
  payload: status,
});

export const staticsJobsDownloadedAction = jobs => ({
  type: types.STATICS_JOBS_DOWNLOADED,
  payload: jobs,
});

export const staticsFailedAction = error => ({
  type: types.STATICS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function getStaticLegalEntities() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticLegalEntities()
      .then((statics) => {
        dispatch(staticsLegalEntitiesDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getStaticSectors() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticCodeNAF()
      .then((statics) => {
        dispatch(staticsSectorsDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getStaticTalentPlugSectors() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticSectors()
      .then((statics) => {
        dispatch(staticsTalentPlugSectorsDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getStaticCibles() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticCibles()
      .then((statics) => {
        dispatch(staticsCiblesDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getStaticStatus() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticStatus()
      .then((statics) => {
        dispatch(staticsStatusDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getStaticJobs() {
  return (dispatch) => {
    dispatch(staticsPendingAction());
    return ApiService
      .getStaticJobs()
      .then((statics) => {
        dispatch(staticsJobsDownloadedAction(statics));
      })
      .catch((error) => {
        dispatch(staticsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

