export const analysisFormFields = [
  {
    id: 'extendor_analysis',
    type: 'extendor',
    gridMD: 12,
    label: 'Analyse du CV',
    noPadding: true,
  },
  {
    id: 'presentation',
    type: 'richText',
    gridMD: 12,
    label: 'Présentation',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'formation',
    type: 'richText',
    gridMD: 6,
    label: 'Formation',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'experiences',
    type: 'richText',
    gridMD: 6,
    label: 'Expériences',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'softskill',
    type: 'richText',
    gridMD: 12,
    label: 'Savoir-être',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'skill',
    type: 'richText',
    gridMD: 12,
    label: 'Savoir-faire',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'competences',
    type: 'richText',
    gridMD: 12,
    label: 'Connaissances spécifiques',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'coherence',
    type: 'richText',
    gridMD: 12,
    label: 'Cohérence',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
  {
    id: 'control_reference',
    type: 'richText',
    gridMD: 12,
    label: 'Elément contrôle de référence',
    dependances: ['extendor_analysis'],
    isMandatory: false,
  },
];