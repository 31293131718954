import {
  ROLES_DOWNLOADED,
  ROLE_DOWNLOADED,
  ROLES_FAILED,
  ROLES_PENDING,
  ROLES_RESET,
  ROLE_SENDING } from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ROLES_RESET:
      return INITIAL_STATE;
    case ROLES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case ROLES_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case ROLE_DOWNLOADED: {
      const roleDownloaded = action.payload;
      let roles = [];
      let roleIndex = -1;
      if (state.content) {
        roles = state.content.slice();
        roleIndex = roles.findIndex(role => role.key === roleDownloaded.key);
      }
      if (roleIndex === -1) {
        roles.push(roleDownloaded);
      } else {
        roles[roleIndex] = roleDownloaded;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: roles,
      };
    }
    case ROLES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case ROLE_SENDING:
      return {
        ...state,
        sending: true,
      };
    default:
      break;
  }
  return state;
}
