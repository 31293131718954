import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OfferIcon = (props) => {

  return (
    <SvgIcon {...props}>
        <path d="M17.7603,15.54 L12.0003,18.847 L6.2403,15.54 L7.9343,14.819 L11.5023,16.867 C11.6563,16.956 11.8283,17 12.0003,17 C12.1723,17 12.3433,16.956 12.4983,16.867 L16.0663,14.818 L17.7603,15.54 Z M11.5023,12.867 C11.6563,12.956 11.8283,13 12.0003,13 C12.1723,13 12.3433,12.956 12.4983,12.867 L16.0663,10.818 L17.7603,11.54 L12.0003,14.847 L6.2403,11.54 L7.9343,10.818 L11.5023,12.867 Z M12.0003,5.087 L17.7603,7.54 L12.0003,10.847 L6.2403,7.54 L12.0003,5.087 Z M20.9983,11.348 C20.9753,10.969 20.7403,10.636 20.3913,10.487 L18.2393,9.571 L20.4983,8.274 C20.8273,8.086 21.0203,7.727 20.9983,7.348 C20.9753,6.97 20.7403,6.636 20.3913,6.487 L12.3913,3.08 C12.1413,2.973 11.8583,2.973 11.6083,3.08 L3.6083,6.487 C3.2593,6.636 3.0243,6.97 3.0023,7.348 C2.9793,7.727 3.1733,8.086 3.5023,8.274 L5.7603,9.571 L3.6083,10.487 C3.2593,10.636 3.0243,10.969 3.0023,11.348 C2.9793,11.727 3.1733,12.086 3.5023,12.274 L5.7603,13.571 L3.6083,14.487 C3.2593,14.636 3.0243,14.969 3.0023,15.348 C2.9793,15.727 3.1733,16.086 3.5023,16.274 L11.5023,20.867 C11.6563,20.956 11.8283,21 12.0003,21 C12.1723,21 12.3433,20.956 12.4983,20.867 L20.4983,16.274 C20.8273,16.086 21.0203,15.727 20.9983,15.348 C20.9753,14.97 20.7403,14.636 20.3923,14.487 L18.2403,13.57 L20.4983,12.274 C20.8273,12.086 21.0203,11.727 20.9983,11.348 L20.9983,11.348 Z" />
    </SvgIcon>
  );
};

export default OfferIcon;
