import React from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import * as routes from '../constants/routes';
import SnackBar from '../components/common/SnackBar';
import SignInPage from './SignInPage';
import Error404 from './Error404';
import HomePage from './HomePage';
import PasswordForgetPage from './PasswordForgetPage';
import ClientsPage from './clients/ClientsPage';
import ClientAddPage from './clients/ClientAddPage';
import ClientEditPage from './clients/ClientEditPage';
import ContactsPage from './contacts/ContactsPage';
import ContactAddPage from './contacts/ContactAddPage';
import ContactEditPage from './contacts/ContactEditPage';
import ClientViewPage from './clients/ClientViewPage';
import ClientContactAddPage from './clients/ClientContactAddPage';
import ClientContactEditPage from './clients/ClientContactEditPage';
import ClientPostAddPage from './clients/ClientPostAddPage';
import ClientPostEditPage from './clients/ClientPostEditPage';
import SearchPage from './search/SearchPage';
import OffersPage from './offers/OffersPage';
import OfferAddPage from './offers/OfferAddPage';
import OfferEditPage from './offers/OfferEditPage';
import OfferCandidatePage from './offers/OfferCandidatePage';
import CandidatePage from './candidates/CandidatePage';
import VisioPage from './candidates/VisioPage';
import PhonePage from './candidates/PhonePage';
import ConsultPage from './candidates/ConsultPage';
import ClientPage from './candidates/ClientPage';
import CGU from './CGU';
import Confidential from './Confidential';
import OfferPage from './offers/OfferPage';
import ClientPostSearchPage from './clients/ClientPostSearchPage';
import UsersPage from './users/UsersPage';
import UserAddPage from './users/UserAddPage';
import UserEditPage from './users/UserEditPage';
import TemplatesPage from './TemplatesPage';
import LinkedInAuth from './LinkedInAuth';
import BackOfficeLayout from './layout/BackOfficeLayout';
import LoginLayout from './layout/LoginLayout';
import AnonymousLayout from './layout/AnonymousLayout';
import ReportEdit from './candidates/ReportEdit';

const App = () => {
  return (
    <div style={{ flexGrow: 1 }}>
      <SnackBar />
      <Switch>
        <Route path={[routes.NOT_FOUND, routes.OFFER_CANDIDATE, routes.CGU, routes.CONFIDENTIAL]}>
          <AnonymousLayout>
            <Route exact path={routes.CGU} component={CGU} />
            <Route exact path={routes.CONFIDENTIAL} component={Confidential} />
            <Route exact path={routes.OFFER_CANDIDATE} component={OfferCandidatePage} />
            <Route exact path={routes.NOT_FOUND} component={Error404} />
          </AnonymousLayout>
        </Route>
        <Route path={[
          routes.HOMEPAGE,
          routes.CANDIDATES,
          routes.CLIENTS,
          routes.CLIENT_ADD,
          routes.CLIENT_EDIT,
          routes.CLIENT_VIEW,
          routes.CLIENT_CONTACT_ADD,
          routes.CLIENT_CONTACT_EDIT,
          routes.CLIENT_POST_ADD,
          routes.CLIENT_POST_CANDIDATES,
          routes.CLIENT_POST_EDIT,
          routes.CONTACTS,
          routes.CONTACT_ADD,
          routes.CONTACT_EDIT,
          routes.OFFERS,
          routes.OFFER_CANDIDATURES,
          routes.OFFER_ADD,
          routes.OFFER_EDIT,
          routes.NOT_FOUND,
          routes.CANDIDATE_VIEW,
          routes.CANDIDATE_PHONE,
          routes.CANDIDATE_VISIO,
          routes.CANDIDATE_CONSULT,
          routes.CANDIDATE_CLIENT,
          routes.USERS,
          routes.USER_ADD,
          routes.USER_EDIT,
          routes.LINKEDIN_AUTH,
          routes.TEMPLATES,
        ]}>
          <BackOfficeLayout>
            <Route exact path={routes.HOMEPAGE} component={HomePage} />
            <Route exact path={routes.CANDIDATES} component={SearchPage} />
            <Route exact path={routes.CLIENTS} component={ClientsPage} />
            <Route exact path={routes.CLIENT_ADD} component={ClientAddPage} />
            <Route exact path={routes.CLIENT_EDIT} component={ClientEditPage} />
            <Route exact path={routes.CLIENT_VIEW} component={ClientViewPage} />
            <Route exact path={routes.CLIENT_CONTACT_ADD} component={ClientContactAddPage} />
            <Route exact path={routes.CLIENT_CONTACT_EDIT} component={ClientContactEditPage} />
            <Route exact path={routes.CLIENT_POST_ADD} component={ClientPostAddPage} />
            <Route exact path={routes.CLIENT_POST_CANDIDATES} component={ClientPostSearchPage} />
            <Route exact path={routes.CLIENT_POST_EDIT} component={ClientPostEditPage} />
            <Route exact path={routes.CONTACTS} component={ContactsPage} />
            <Route exact path={routes.CONTACT_ADD} component={ContactAddPage} />
            <Route exact path={routes.CONTACT_EDIT} component={ContactEditPage} />
            <Route exact path={routes.OFFERS} component={OffersPage} />
            <Route exact path={routes.OFFER_CANDIDATURES} component={OfferPage} />
            <Route exact path={routes.OFFER_ADD} component={OfferAddPage} />
            <Route exact path={routes.OFFER_EDIT} component={OfferEditPage} />
            <Route exact path={routes.CANDIDATE_VIEW} component={CandidatePage} />
            <Route exact path={routes.CANDIDATE_VIEW_CR} component={ReportEdit} />
            <Route exact path={routes.CANDIDATE_PHONE} component={PhonePage} />
            <Route exact path={routes.CANDIDATE_VISIO} component={VisioPage} />
            <Route exact path={routes.CANDIDATE_CONSULT} component={ConsultPage} />
            <Route exact path={routes.CANDIDATE_CLIENT} component={ClientPage} />
            <Route exact path={routes.USERS} component={UsersPage} />
            <Route exact path={routes.USER_ADD} component={UserAddPage} />
            <Route exact path={routes.USER_EDIT} component={UserEditPage} />
            <Route exact path={routes.LINKEDIN_AUTH} component={LinkedInAuth} />
            <Route exact path={routes.TEMPLATES} component={TemplatesPage} />
          </BackOfficeLayout>
        </Route>
        <Route path={[routes.SIGN_IN, routes.PASSWORD_FORGET]}>
          <LoginLayout>
            <Route exact path={routes.SIGN_IN} component={SignInPage} />
            <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
          </LoginLayout>
        </Route>
        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default App;
