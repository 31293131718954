import React, {Component} from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";

/**
 * Style of Custom Password Field elements
 *
 * @returns json
 */
const styles = () => ({
  extendor: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'left'
  }
});

function UpArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16,14.5 C15.744,14.5 15.488,14.402 15.293,14.207 L11.988,10.902 L8.695,14.082 C8.297,14.466 7.665,14.456 7.281,14.057 C6.897,13.66 6.908,13.026 7.305,12.643 L11.305,8.781 C11.698,8.402 12.322,8.407 12.707,8.793 L16.707,12.793 C17.098,13.184 17.098,13.816 16.707,14.207 C16.512,14.402 16.256,14.5 16,14.5" />
    </SvgIcon>
  );
}

function DownArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,15.5 C11.744,15.5 11.488,15.402 11.293,15.207 L7.293,11.207 C6.902,10.816 6.902,10.184 7.293,9.793 C7.684,9.402 8.316,9.402 8.707,9.793 L12.012,13.098 L15.305,9.918 C15.704,9.535 16.335,9.546 16.719,9.943 C17.103,10.34 17.092,10.974 16.695,11.357 L12.695,15.219 C12.5,15.407 12.25,15.5 12,15.5" />
    </SvgIcon>
  );
}

/**
 * Class CustomExtendor
 *
 * @Component : <CustomExtendor />
 * @Extend : React.Component
 */
export class CustomExtendor extends Component {

  // ----------------
  // Type of each Props

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    isMandatory: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    noLabel: PropTypes.bool,
    error: PropTypes.bool,
  };

  // ----------------
  // Default Values

  static defaultProps = {
    value: true,
    label: '',
    isMandatory: false
  };


  // ----------------
  // Constructor

  constructor(props) {
    super(props);
    this.state = { extended: props.value };
  }


  // ----------------
  // Handler

  handleClick = () => {
    this.props.onChange(!this.state.extended);
    this.setState({extended: !this.state.extended});
  };


  // ----------------
  // Rendering

  render() {
    // Contants
    const {
      id,
      label,
      classes,
      gridMD
    } = this.props;
    const {extended} = this.state;

    return (
      <Grid item xs={12} sm={12} md={gridMD}>
        <Button
          className={classes.extendor}
          key={id}
          onClick={this.handleClick}
        >
          {extended ? <DownArrowIcon className={classes.arrow}/> : <UpArrowIcon className={classes.arrow}/> }
          {label}
        </Button>
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomExtendor);
