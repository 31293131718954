import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core//MenuList';
import PropTypes from 'prop-types';
import { getVariationTheme } from '../../../themes/theme';

const colorTheme = getVariationTheme();

/**
 * Style of Custom Text Field elements
 *
 */
const styles = () => (
  {
    button: {
      backgroundColor: colorTheme.text_light,
      fontSize: 16,
      fontWeight: 'bold',
      color: colorTheme.primary,
      margin: 'auto',
    },
    popup: {
      zIndex: 1,
    },
  }
);

/**
 * Class CustomSelectField
 *
 * @Component : <CustomCheckboxSelector />
 */
export class CustomCheckboxSelector extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    children: PropTypes.array.isRequired,
    isMandatory: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number,
    noLabel: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    label: '',
    isMandatory: false,
    noLabel: false,
    disabled: false,
    gridMd: 4,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };
    if (props.children.length !== state.nbChildren) {
      newState = {
        ...state,
        anchorRef: null,
        nbChildren: props.children.length,
      };
    }
    return newState;
  }

  // ----------------
  // Constructor

  constructor(props) {
    super(props);
    this.state = {
      anchorRef: null,
      nbChildren: 0,
      open: false,
    };
  }

  // ----------------
  // Handler

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = event => {
    if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target)) {
      console.log('no close');
      return;
    }
    this.setState({ open: false });
  };

  render() {
    // Constant
    const {
      label,
      classes,
      gridMD,
    } = this.props;

    const {
      anchorRef,
      open,
    } = this.state;

    // Element

    return (
      <Grid item xs={12} sm={12} md={gridMD}
            ref={ref => {
              if (!this.state.anchorRef) {
                this.setState({ anchorRef: ref });
              }
            }
            }>
        <Button
          className={classes.button}
          aria-owns={open
            ? 'menu-list-grow'
            : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          {label}
        </Button>
        {this.state.anchorRef &&
        <Popper className={classes.popup} open={this.state.open} anchorEl={anchorRef} transition
                disablePortal>
          {({
              TransitionProps,
              placement,
            }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {this.props.children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomCheckboxSelector);
