import { CONTRACTS } from '../../offer';

export const contractFormFields = [
  {
    id: 'extendor_contract',
    type: 'extendor',
    gridMD: 12,
    label: 'Contrat',
    noPadding: true,
  },
  {
    id: 'contract',
    type: 'selectField',
    options: CONTRACTS,
    label: 'Type de contrat',
    dependances: ['extendor_contract'],
    isMandatory: true,
  },
  {
    id: 'contract_comment',
    type: 'textField',
    label: 'Commentaires liés au Type de Contrat',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
  {
    id: 'induction_date',
    type: 'textField',
    subtype: 'date',
    label: 'Date d\'intégration souhaitée',
    dependances: ['extendor_contract'],
    isMandatory: true,
  },
  {
    id: 'reason',
    type: 'richText',
    gridMD: 12,
    label: 'Motif / Contexte du recrutement',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
  {
    id: 'is_intern_possible',
    type: 'checkboxNormal',
    gridMD: 6,
    label: 'Poste ou promotion interne',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
  {
    id: 'has_transition_advisor',
    type: 'checkboxNormal',
    gridMD: 6,
    label: 'Consultant de transition',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
  {
    id: 'intern_info',
    type: 'richText',
    gridMD: 6,
    label: 'Commentaire',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
  {
    id: 'transition_info',
    type: 'richText',
    gridMD: 6,
    label: 'Précisions',
    dependances: ['extendor_contract'],
    isMandatory: false,
  },
];
