import React from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Slider from '@material-ui/core/Slider';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";

const styles = {
  grid: {
    padding: '1.5em!important'
  }
};

/**
 * Class CustomCheckboxField
 *
 * @Component : <CustomCheckboxField />
 */
const CustomSliderField = props => {
  // Constant
  const {
    onChange,
    value,
    gridMD,
    label,
    disabled,
    step,
    min,
    max,
    marks
  } = props;

  return (
    <Grid item xs={12} sm={12} md={gridMD} className={props.classes.grid} onClick={props.onClick}>
      <Typography id="discrete-slider" gutterBottom>
        {label}
      </Typography>
      <Slider
        defaultValue={value}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={step}
        marks={ marks }
        min={min}
        max={max}
        onChange={onChange}
        disabled={disabled}
      />
    </Grid>
  );
};

CustomSliderField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  gridMD: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

CustomSliderField.defaultProps = {
  value: false,
  disabled: false
};

export default withStyles(styles) (CustomSliderField);
