import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTitle } from '../stores/title/actions';

const withTitle = title => (Component) => {
  const WithTitle = (props) => {

    const users = useSelector(state => state.users);
    const titleState = useSelector(state => state.title);
    const dispatch = useDispatch();

    if (title.title !== titleState.title) {
      dispatch(changeTitle(title));
    }

    if (users.authUser !== null) {
      const newProps = {
        ...props,
        authUser: users.authUser,
      };
      return (
        <Component {...newProps} />
      );
    }
    return null;
  };

  return WithTitle;
};

export default withTitle;
