export const quillModules = {
  toolbar: {
    container : [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'ordered'}, {'list': 'bullet'},],
      ['link'],
      ['clean']
    ]
  },
  clipboard: {
    matchVisual: false
  }
};

export const quillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

