import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NotifyIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M5.5146,16 L6.6946,14.818 C7.0726,14.44 7.2806,13.938 7.2806,13.404 L7.2806,8.727 C7.2806,7.37 7.8706,6.073 8.9006,5.171 C9.9386,4.261 11.2606,3.861 12.6376,4.042 C14.9646,4.351 16.7196,6.455 16.7196,8.937 L16.7196,13.404 C16.7196,13.938 16.9276,14.44 17.3046,14.817 L18.4856,16 L5.5146,16 Z M13.9996,18.341 C13.9996,19.24 13.0836,20 11.9996,20 C10.9156,20 9.9996,19.24 9.9996,18.341 L9.9996,18 L13.9996,18 L13.9996,18.341 Z M20.5206,15.208 L18.7196,13.404 L18.7196,8.937 C18.7196,5.456 16.2176,2.499 12.8996,2.06 C10.9776,1.804 9.0376,2.391 7.5826,3.667 C6.1186,4.949 5.2806,6.793 5.2806,8.727 L5.2796,13.404 L3.4786,15.208 C3.0096,15.678 2.8706,16.377 3.1246,16.99 C3.3796,17.604 3.9726,18 4.6366,18 L7.9996,18 L7.9996,18.341 C7.9996,20.359 9.7936,22 11.9996,22 C14.2056,22 15.9996,20.359 15.9996,18.341 L15.9996,18 L19.3626,18 C20.0266,18 20.6186,17.604 20.8726,16.991 C21.1276,16.377 20.9896,15.677 20.5206,15.208 L20.5206,15.208 Z" />
    </SvgIcon>
  );
};

export default NotifyIcon;
