import React from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles/index";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import StringService from "../../../services/StringService";
import { getVariationTheme } from '../../../themes/theme';

const colorTheme = getVariationTheme();

const styles = () => ({
  grid: {
    padding: '1.5em!important'
  },
  tooltipText: {
    color: colorTheme.menu
  }
});

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

/**
 * Class CustomCheckboxField
 *
 * @Component : <CustomCheckboxField />
 */
const CustomCheckboxField = props => {
  // Constant
  const {
    onChange,
    value,
    gridMD,
    label,
    disabled,
    tooltip
  } = props;

  // Element
  const checkboxWithTooltip = (
    <FormControlLabel
      control={
        (
          <LightTooltip title={
            <React.Fragment>
              <Typography className={props.classes.tooltipText}>{tooltip}</Typography>
            </React.Fragment>
            }
          >
            <Checkbox
              checked={value}
              onChange={onChange}
              color="primary"
              disabled={disabled}
            />
          </LightTooltip>
        )
      }
      label={label}
    />
  );


  const checkbox = (
    <FormControlLabel
      control={
        (
          <Checkbox
            checked={value}
            onChange={onChange}
            color="primary"
            disabled={disabled}
          />
        )
      }
      label={label}
    />
  );

  return (
    <Grid item xs={12} sm={12} md={gridMD} className={props.classes.grid} onClick={props.onClick}>
      {StringService.isEmpty(tooltip) ? checkbox : checkboxWithTooltip}
    </Grid>
  );
};

CustomCheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  gridMD: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

CustomCheckboxField.defaultProps = {
  value: false,
  disabled: false
};

export default withStyles(styles) (CustomCheckboxField);
