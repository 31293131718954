import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VisibilityOffIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.8676,12.4976 C21.4526,13.2206 20.4766,14.7386 18.9546,16.1266 L17.5436,14.7156 C18.6036,13.7646 19.3706,12.7186 19.8266,12.0046 C18.8946,10.5856 16.0716,6.8936 11.7806,7.0026 C11.1806,7.0176 10.6176,7.1116 10.0816,7.2536 L8.5016,5.6736 C9.4736,5.2896 10.5446,5.0336 11.7296,5.0036 C17.7046,4.8166 21.2296,10.3906 21.8676,11.5026 C22.0436,11.8106 22.0436,12.1896 21.8676,12.4976 L21.8676,12.4976 Z M13.9176,16.7466 L15.4976,18.3266 C14.5266,18.7106 13.4546,18.9666 12.2706,18.9966 C12.1886,18.9986 12.1066,18.9996 12.0246,18.9996 C6.1616,18.9996 2.7616,13.5946 2.1326,12.4976 C1.9556,12.1896 1.9556,11.8106 2.1326,11.5026 C2.5466,10.7796 3.5226,9.2616 5.0446,7.8726 L6.4556,9.2846 C5.3956,10.2356 4.6296,11.2816 4.1726,11.9956 C5.1046,13.4146 7.9146,17.0976 12.2196,16.9976 C12.8196,16.9826 13.3826,16.8886 13.9176,16.7466 L13.9176,16.7466 Z M11.9996,13.4996 C11.1726,13.4996 10.4996,12.8276 10.4996,11.9996 C10.4996,11.9746 10.5106,11.9516 10.5116,11.9256 L12.0746,13.4886 C12.0486,13.4896 12.0256,13.4996 11.9996,13.4996 L11.9996,13.4996 Z M4.7066,3.2926 C4.3166,2.9026 3.6836,2.9026 3.2926,3.2926 C2.9026,3.6836 2.9026,4.3166 3.2926,4.7066 L8.9236,10.3376 C8.6466,10.8456 8.4996,11.4106 8.4996,11.9996 C8.4996,13.9296 10.0706,15.4996 11.9996,15.4996 C12.5896,15.4996 13.1546,15.3526 13.6626,15.0766 L19.2926,20.7066 C19.4886,20.9026 19.7436,20.9996 19.9996,20.9996 C20.2556,20.9996 20.5116,20.9026 20.7066,20.7066 C21.0976,20.3166 21.0976,19.6836 20.7066,19.2926 L4.7066,3.2926 Z" />
    </SvgIcon>
  );
};

export default VisibilityOffIcon;
