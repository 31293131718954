import { TITLE_CHANGE, SUB_TITLE_CHANGE } from './types';
import { isString } from 'react-pdf/dist/shared/utils';

export const INITIAL_STATE = {
  title: null,
  subtitle: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TITLE_CHANGE:
      if (isString(action.payload)) {
        return {
          ...state,
          title: action.payload
        }
      } else {
        return {
          ...state,
          title: action.payload.title || null,
          subtitle: action.payload.subtitle || null
        }
      }
    case SUB_TITLE_CHANGE:
      return {
        ...state,
        subtitle: action.payload
      }
    default:
      break;
  }
  return state;
}
