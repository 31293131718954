import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { PASSWORD_FORGET } from '../../constants/routes';
import { defaultStyles } from '../../themes/theme';
import { auth, db, firebase } from '../../services/firebase';
import { ROLE_CONSULTANT } from '../../constants/roles';
import { handleError } from '../../stores/infos/actions';
import Spinner from '../common/Spinner';
import VisibilityOffIcon from '../icons/VisibilityOffIcon';
import VisibilityIcon from '../icons/VisibilityIcon';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => (
  {
    field: {
      width: '298px',
    },
    link: {
      marginTop: '8px',
      marginBottom: '8px',
      width: '100%',
      textAlign: 'right',
    },
  }
));

const SignInForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const firebaseSignOut = (role) => {
    if (role < ROLE_CONSULTANT) {
      setPending(false);
      firebase.auth.signOut()
        .then(() => {
          dispatch(handleError({ error: { message: 'Access forbidden' } }));
          setPassword('');
        })
        .catch((error) => {
          dispatch(handleError(error));
          setPassword('');
        });
    } else {
      setEmail('');
      setPassword('');
      setShowPassword(false);
      history.push(routes.HOMEPAGE);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setPending(true);
    auth.doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        db.getRole(authUser.user.uid)
          .then((doc) => {
            firebaseSignOut(doc.data().role);
          })
          .catch((err) => {
            setPending(false);
            console.log('Error getting documents', err);
          });
      })
      .catch((error) => {
        setPending(false);
        setPassword('');
        dispatch(handleError(error));
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isInvalid = password === '' || email === '' || pending;

  const buttonLabel = pending
    ?
    (
      <Spinner variant="nomargin" />
    )
    : 'Me connecter';

  return (
    <form onSubmit={onSubmit} style={defaultStyles.center}>

      <TextField
        placeholder="Email"
        id="signin-email"
        className={classes.field}
        type="text"
        onChange={event => setEmail(event.target.value)}
        margin="normal"
        value={email}
      />
      <Input
        placeholder="Mot de passe"
        id="signin-password"
        className={classes.field}
        type={showPassword
          ? 'text'
          : 'password'}
        value={password}
        onChange={event => setPassword(event.target.value)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword
                ?
                <VisibilityOffIcon color="secondary" />
                : <VisibilityIcon color="secondary" />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <Typography
        className={classes.link}
        variant="caption"
        gutterBottom
      >
        <Link to={PASSWORD_FORGET} style={{ textDecoration: 'none' }}>Mot de passe oublié ?</Link>
      </Typography>
      <Fab
        variant="extended"
        color="primary"
        disabled={isInvalid}
        type="submit"
      >
        {buttonLabel}
      </Fab>
    </form>
  );
};

export default SignInForm;
