const USERS_PENDING = 'users_pending';
const USERS_FAILED = 'users_failed';
const USERS_DOWNLOADED = 'users_downloaded';
const USERS_RESET = 'users_reset';
const USER_LOGIN = 'user_login';
const USER_SENDING = 'user_sending';
const USER_DOWNLOADED = 'user_downloaded';

export {
  USERS_PENDING,
  USERS_FAILED,
  USERS_DOWNLOADED,
  USERS_RESET,
  USER_LOGIN,
  USER_SENDING,
  USER_DOWNLOADED
};
