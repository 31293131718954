import { candidateFields } from './candidateFields';
import { analysisFormFields } from './analysisFormFields';
import { verbalFormFields } from './verbalFormFields';
import { attitudesFields } from './attitudesFields';
import { organisationFields } from './organisationFields';
import { motivationsFields } from './motivationsFields';
import { globalFields } from './globalFields';
import { nextFields } from './nextFields';

export const interviewFormFields = [
  {
    id: 'candidate_subform',
    type: 'subform',
    options: candidateFields,
  },
  {
    id: 'analysis_subform',
    type: 'subform',
    options: analysisFormFields,
  },
  {
    id: 'verbal_subform',
    type: 'subform',
    options: verbalFormFields,
  },
  {
    id: 'attitude_subform',
    type: 'subform',
    options: attitudesFields,
  },
  {
    id: 'organisation_subform',
    type: 'subform',
    options: organisationFields,
  },
  {
    id: 'motivations_subform',
    type: 'subform',
    options: motivationsFields,
  },
  {
    id: 'global_subform',
    type: 'subform',
    options: globalFields,
  },
  {
    id: 'next_subform',
    type: 'subform',
    options: nextFields,
  },
];
