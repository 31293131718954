import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HeaderBar from './HeaderBar';
import Menu from './Menu';
import Spinner from '../../components/common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import LargePaper from '../../components/common/LargePaper';
import { useHistory } from 'react-router';
import { firebase } from '../../services/firebase';
import { getUsers, userLoginAction } from '../../stores/users/actions';
import * as routes from '../../constants/routes';
import { getRoles } from '../../stores/roles/actions';
import { ROLE_CONSULTANT } from '../../constants/roles';
import { handleError } from '../../stores/infos/actions';
import ErrorPage from '../ErrorPage';

const drawerWidth = 240;
const headerHeight = 60;

const useStyles = makeStyles(theme => (
  {
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      paddingTop: headerHeight,
      maxWidth: '100%',
    },
  }
));

const BackOfficeLayout = (props) => {
  const { children } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [saveMatches, setSaveMatches] = useState(matches);
  const [drawerOpen, setDrawerOpen] = useState(!matches);

  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const roles = useSelector(state => state.roles);
  const [authUserPending, setAuthUserPending] = useState(false);

  if (matches !== saveMatches) {
    // avoid open drawer on start in mobile size
    setDrawerOpen(!matches);
    setSaveMatches(matches);
  }
  if (users.authUser === null && !users.pending && users.error === null) {
    if (!authUserPending) {
      setAuthUserPending(true);
      firebase.auth.onAuthStateChanged((firebaseAuthUser) => {
        if (firebaseAuthUser) {
          dispatch(userLoginAction(firebaseAuthUser));
          setAuthUserPending(false);
        } else {
          history.push(routes.SIGN_IN);
        }
      });
    }
  }
  if (!roles.content && !roles.pending && roles.error === null) {
    dispatch(getRoles());
  }
  if (roles.content && users.authUser) {
    const role = roles.content[users.authUser.uid]
      ? roles.content[users.authUser.uid]
      : 0;
    if (role < ROLE_CONSULTANT) {
      firebase.auth.signOut()
        .then(() => {
          history.push(routes.SIGN_IN);
        })
        .catch((error) => {
          dispatch(handleError(error));
        });
    }
  }

  if (users.content === null && !users.pending && users.error === null) {
    dispatch(getUsers());
    if (users.content) {
      const user = users.content.find(user => user.uid === users.authUser.uid);
      if (!user.is_active) {
        firebase.auth.signOut()
          .then(() => {
            history.push(routes.SIGN_IN);
          })
          .catch((error) => {
            dispatch(handleError(error));
          });
      }
    }
  }

  const { pending } = useSelector(state => state.users);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const onOpenDrawer = () => {
    setDrawerOpen(true);
  };

  if (users.pending || roles.pending) {
    return (
      <div className={classes.root}>
        <Spinner/>
      </div>);
  }
  if (users.error || roles.error) {
    return (<ErrorPage error={users.error ?? roles.error} />);
  }
  return (

    <div className={classes.root}>
      <HeaderBar openDrawer={onOpenDrawer} />
      <nav className={classes.drawer}>
        <Menu open={drawerOpen} onClose={onCloseDrawer} />
      </nav>

      <main className={classes.content}>
        <LargePaper>
          <>
            {pending && <Spinner />}
            {!pending && children}
          </>
        </LargePaper>
      </main>
    </div>
  );
};

export default BackOfficeLayout;
