import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';

export const Confidential = () => (
  <>
    <Typography
      variant="h2"
      gutterBottom
    >
      Classified
    </Typography>
    <br />
    <Typography
      variant="subtitle"
      gutterBottom
    >
      Les règles de confidentialités
    </Typography>
  </>
);

export default Confidential;
