import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => (
  {
    layout: {
      maxWidth: '100%',
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  }
));

export const LargePaper = (props) => {

  const classes = useStyles();

  return props.children
    ? (
      <div className={classes.layout} style={props.style}>
        {props.children}
      </div>
    )
    : null;
};

LargePaper.propTypes = {
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default LargePaper;
