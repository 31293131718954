const ROLES_PENDING = 'roles_pending';
const ROLES_FAILED = 'roles_failed';
const ROLES_DOWNLOADED = 'roles_downloaded';
const ROLES_RESET = 'roles_reset';
const ROLE_SENDING = 'role_sending';
const ROLE_DOWNLOADED = 'role_downloaded';

export {
  ROLES_PENDING,
  ROLES_FAILED,
  ROLES_DOWNLOADED,
  ROLES_RESET,
  ROLE_SENDING,
  ROLE_DOWNLOADED,
};
