import { LEVELS, SCHOOLING_LEVELS } from '../../offer';

export const levelFormFields = (diplomaOptions) => ([
  {
    id: 'extendor_level',
    type: 'extendor',
    gridMD: 12,
    label: 'Niveau de qualifications attendu',
    noPadding: true,
  },
  {
    id: 'schooling_level',
    type: 'selectField',
    options: SCHOOLING_LEVELS,
    label: 'Niveau d\'étude',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'diploma',
    type: 'selectField',
    options: diplomaOptions,
    autocomplete: true,
    noOptions: 'Rentrer au moins 3 caractères pour avoir des suggestions',
    minLength: 2,
    creatable: true,
    label: 'Diplome',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'training',
    type: 'textField',
    label: 'Formation continue',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'authorization',
    type: 'textField',
    label: 'Habilitation',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'level',
    type: 'selectField',
    options: LEVELS,
    label: 'Qualification',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'main_sector',
    type: 'textField',
    label: 'Secteur de référence',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'experiences',
    type: 'textField',
    label: 'Fonctions de référence',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'experience_years',
    type: 'textField',
    subtype: 'number',
    label: 'Années d\'expérience',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'openness',
    type: 'textField',
    label: 'Degré d\'ouverture à d\'autres formations',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
  {
    id: 'level_comments',
    type: 'richText',
    gridMD: 12,
    label: 'Commentaires',
    dependances: ['extendor_level'],
    isMandatory: false,
  },
]);
