import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HangUpIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M19.7393,4.2607 C20.0873,4.6077 20.0873,5.1697 19.7393,5.5177 L5.5173,19.7397 C5.1703,20.0867 4.6073,20.0867 4.2603,19.7397 C4.0873,19.5667 4.0003,19.3387 4.0003,19.1117 C4.0003,18.8837 4.0873,18.6567 4.2603,18.4827 L18.4823,4.2607 C18.8303,3.9127 19.3923,3.9127 19.7393,4.2607 Z M6.6982,14.6299 L5.2712,16.0569 C3.2292,13.4429 2.0002,10.1659 2.0002,6.5999 C2.0002,4.0639 4.0632,1.9999 6.5992,1.9999 C6.8602,1.9999 7.1162,2.0229 7.3652,2.0659 C7.6082,2.1049 7.8542,2.1659 8.0922,2.2489 C8.4172,2.3609 8.6612,2.6339 8.7382,2.9699 L10.1072,8.9339 C10.1822,9.2619 10.0882,9.6069 9.8542,9.8499 C9.7182,9.9919 9.7142,9.9949 8.4742,10.6449 C8.7012,11.1389 8.9712,11.6089 9.2732,12.0549 L7.8442,13.4839 C7.1862,12.5759 6.6412,11.5839 6.2652,10.5139 C5.9432,9.7109 6.5722,9.3819 7.4432,8.9269 C7.6212,8.8329 7.8122,8.7339 7.9882,8.6419 L6.9272,4.0219 C6.8172,4.0069 6.7092,3.9999 6.5992,3.9999 C5.1662,3.9999 4.0002,5.1659 4.0002,6.5999 C4.0002,9.6129 5.0122,12.3889 6.6982,14.6299 Z M21.9355,16.6387 C21.9775,16.8867 21.9995,17.1417 21.9995,17.4007 C21.9995,19.9367 19.9365,21.9997 17.4005,21.9997 C13.8335,21.9997 10.5565,20.7707 7.9435,18.7287 L9.3705,17.3017 C11.6115,18.9877 14.3865,19.9997 17.4005,19.9997 C18.8335,19.9997 19.9995,18.8337 19.9995,17.4007 C19.9995,17.2887 19.9935,17.1797 19.9785,17.0727 L15.3585,16.0117 C15.2665,16.1877 15.1665,16.3787 15.0735,16.5567 C14.6185,17.4267 14.2915,18.0587 13.4465,17.7197 C12.3935,17.3507 11.4135,16.8107 10.5125,16.1587 L11.9425,14.7287 C12.3895,15.0327 12.8605,15.3027 13.3545,15.5277 C14.0045,14.2847 14.0075,14.2827 14.1505,14.1457 C14.3935,13.9117 14.7375,13.8197 15.0665,13.8927 L21.0305,15.2617 C21.3665,15.3387 21.6385,15.5827 21.7505,15.9087 C21.8325,16.1437 21.8955,16.3887 21.9355,16.6387 Z" />
    </SvgIcon>
  );
};

export default HangUpIcon;
