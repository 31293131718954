import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.OFFERS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.OFFER_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.OFFERS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.OFFERS_UNLOAD: {
      return INITIAL_STATE;
    }
    case types.OFFER_DOWNLOADED: {
      const offerDownloaded = action.payload;
      let offers = [];
      let offerIndex = -1;
      if (state.content) {
        offers = state.content.slice();
        offerIndex = offers.findIndex(offer => offer.id === offerDownloaded.id);
      }
      if (offerIndex === -1) {
        offers.push(offerDownloaded);
      } else {
        offers[offerIndex] = offerDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: offers,
      };
    }
    case types.OFFER_REMOVED: {
      const offerId = action.payload;
      let offers = [];
      if (state.content !== null) {
        offers = state.content.slice();
        let isOffer = (offerId) => {
          return function (element) {
            return element.id !== offerId;
          }
        };
        offers = state.content.filter(isOffer(offerId));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: offers,
      };
    }
    case types.OFFERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
