import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  info: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ERROR_RESET:
      return {
        ...state,
        error: null
      };
    case types.ERROR:
      return {
        ...state,
        error: action.payload
      };
    case types.INFO_RESET:
      return {
        ...state,
        info: null
      };
    case types.INFO:
      return {
        ...state,
        info: action.payload
      };
    default:
      break;
  }

  return state;
}
