import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withTitle from '../hocs/withTitle';
import CustomHTMLDropzone from '../components/common/form/CustomHTMLDropzone';
import ApiService from '../services/ApiService';
import { dispatchInfo, handleError } from '../stores/infos/actions';
import { getRoles } from '../stores/roles/actions';
import { getUsers, postUser, putUser } from '../stores/users/actions';

export class TemplatesPage extends Component {

  static propTypes = {
    // eslint-disable-next-line
    history: PropTypes.object.isRequired,
    authUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRecruitDrop = (accepted, rejected) => {
    if (rejected.length) {
      this.props.handleError({
        message: 'Le fichier attendu doit être un fichier xlsx, le fichier envoyé est de type '
          + rejected[0].type,
      });
    } else {
      this.props.authUser.getIdToken(true).then(token => {
        return ApiService.postRecruitTemplate(
          token,
          accepted[0],
        );
      })
        .then(() => {
          this.props.dispatchInfo('Modèle mis à jour');
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
  };

  handleClientDrop = (accepted, rejected) => {
    if (rejected.length) {
      this.props.handleError({
        message: 'Le fichier attendu doit être un fichier xlsx, le fichier envoyé est de type '
          + rejected[0].type,
      });
    } else {
      this.props.authUser.getIdToken(true).then(token => {
        return ApiService.postClientTemplate(
          token,
          accepted[0],
        );
      })
        .then(() => {
          this.props.dispatchInfo('Modèle mis à jour');
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
  };

  handleCandidateDrop = (accepted, rejected) => {
    if (rejected.length) {
      this.props.handleError({
        message: 'Le fichier attendu doit être un fichier xlsx, le fichier envoyé est de type '
          + rejected[0].type,
      });
    } else {
      this.props.authUser.getIdToken(true).then(token => {
        return ApiService.postCandidateTemplate(
          token,
          accepted[0],
        );
      })
        .then(() => {
          this.props.dispatchInfo('Modèle mis à jour');
        })
        .catch((error) => {
          this.props.handleError(error);
        });
    }
  };

  handleSendTestRecruitPDF = () => {
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getTestRecruitPDF(token);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleSendTestClientPDF = () => {
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getTestClientPDF(token);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleSendTestCandidatePDF = () => {
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getTestCandidatePDF(token);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  // eslint-disable-next-line
  render() {

    return (
      <Grid container>
        <CustomHTMLDropzone
          key={'template_recruit'}
          labelDropPDF={'Ajouter un modèle Excel Recruteur'}
          onDrop={(accepted, rejected) =>
            this.handleRecruitDrop(accepted, rejected, 'template_recruit', 'recruit_excel')
          }
        />
        <CustomHTMLDropzone
          key={'template_client'}
          labelDropPDF={'Ajouter un modèle Excel Client'}
          onDrop={(accepted, rejected) =>
            this.handleClientDrop(accepted, rejected, 'template_client', 'client_excel')
          }
        />
        <CustomHTMLDropzone
          key={'template_candidate'}
          labelDropPDF={'Ajouter un modèle Excel Candidat'}
          onDrop={(accepted, rejected) =>
            this.handleCandidateDrop(accepted, rejected, 'template_candidate', 'candidate_excel')
          }
        />
        <Grid item xs={4} sm={4} md={4}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ margin: 'auto' }}
            onClick={this.handleSendTestRecruitPDF}
          >
            Tester le Excel Recruteur
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ margin: 'auto' }}
            onClick={this.handleSendTestClientPDF}
          >
            Tester le Excel Client
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ margin: 'auto' }}
            onClick={this.handleSendTestCandidatePDF}
          >
            Tester le Excel Candidat
          </Button>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    users: state.users.content,
    pending: state.users.pending,
    sending: state.users.sending,
  };
}

export default compose(
  withTitle({
    title: 'Modèles Excel',
    subtitle: 'Accès aux entrées du menu',
  }),
  connect(mapStateToProps, {
    getUsers,
    postUser,
    putUser,
    handleError,
    getRoles,
    dispatchInfo,
  }),
)(TemplatesPage);
