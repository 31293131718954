export const internFormFields = [
  {
    id: 'extendor_intern',
    type: 'extendor',
    gridMD: 12,
    label: 'Ressources internes pour aider à la prise de poste',
    noPadding: true,
  },
  {
    id: 'advisors',
    type: 'textField',
    label: 'Tuteurs',
    dependances: ['extendor_intern'],
    isMandatory: false,
  },
  {
    id: 'referents',
    type: 'textField',
    label: 'Référents',
    dependances: ['extendor_intern'],
    isMandatory: false,
  },
  {
    id: 'reference_department',
    type: 'textField',
    label: 'Département en point d\'appui',
    dependances: ['extendor_intern'],
    isMandatory: false,
  },
];
