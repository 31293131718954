import StringService from '../../services/StringService';

export const stateWithCandidate = (candidate) => (
  {
    candidate: candidate,
    availability: (
      candidate.availability
    )
      ? StringService.dateTimestampToStringForForm(candidate.availability)
      : StringService.dateTimestampToStringForForm(Date.now() / 1000 | 0),
    salary: candidate.salary || '',
    wanted_salary: candidate.wanted_salary || '',
    mobility: candidate.mobility || '',
  }
);