import * as types from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

// --------------------------------
// Action creators

export const clientsPendingAction = () => (
  {
    type: types.CLIENTS_PENDING,
  }
);
export const clientSendingAction = () => (
  {
    type: types.CLIENT_SENDING,
  }
);
export const clientsUnloadAction = () => (
  {
    type: types.CLIENTS_UNLOAD,
  }
);

export const clientsDownloadedAction = clients => (
  {
    type: types.CLIENTS_DOWNLOADED,
    payload: clients,
  }
);

export const hrProfilsDownloadedAction = hrProfils => (
  {
    type: types.HR_PROFILS_DOWNLOADED,
    payload: hrProfils,
  }
);

export const advantagesDownloadedAction = advantages => (
  {
    type: types.ADVANTAGES_DOWNLOADED,
    payload: advantages,
  }
);

export const broadcastsDownloadedAction = broadcasts => (
  {
    type: types.BROADCASTS_DOWNLOADED,
    payload: broadcasts,
  }
);

export const clientDownloadedAction = (client) => (
  {
    type: types.CLIENT_DOWNLOADED,
    payload: client,
  }
);

export const clientPostDownloadedAction = (post) => (
  {
    type: types.CLIENT_POST_DOWNLOADED,
    payload: post,
  }
);

export const clientPostRemovedAction = ({
  postId,
  clientId,
}) => (
  {
    type: types.CLIENT_POST_REMOVED,
    payload: {
      postId,
      clientId,
    },
  }
);

export const clientContactDownloadedAction = (contact) => (
  {
    type: types.CLIENT_CONTACT_DOWNLOADED,
    payload: contact,
  }
);

export const clientContactRemovedAction = ({
  contactId,
  clientId,
}) => (
  {
    type: types.CLIENT_CONTACT_REMOVED,
    payload: {
      contactId,
      clientId,
    },
  }
);

export const clientRemovedAction = (client) => (
  {
    type: types.CLIENT_REMOVED,
    payload: client,
  }
);

export const clientsFailedAction = error => (
  {
    type: types.CLIENTS_FAILED,
    payload: error,
  }
);

// --------------------------------
// Actions

export function unloadClients() {
  return (dispatch) => {
    dispatch(clientsUnloadAction());
  };
}

export function pendingClients() {
  return (dispatch) => {
    dispatch(clientsPendingAction());
  }
}

export function getClients(token, role) {
  return (dispatch) => {
    dispatch(clientsPendingAction());
    return ApiService
      .getClients(token, role)
      .then((clients) => {
        ApiService
          .getHRProfils()
          .then((profils) => {
            dispatch(hrProfilsDownloadedAction(profils));
          })
          .catch((error) => {
            dispatch(clientsFailedAction(error));
            dispatch(handleError(error));
          });
        ApiService
          .getAdvantages()
          .then((advantages) => {
            dispatch(advantagesDownloadedAction(advantages));
          })
          .catch((error) => {
            dispatch(clientsFailedAction(error));
            dispatch(handleError(error));
          });
        ApiService
          .getBroadcasts()
          .then((broadcasts) => {
            dispatch(broadcastsDownloadedAction(broadcasts));
          }).catch((error) => {
          dispatch(clientsFailedAction(error));
          dispatch(handleError(error));
        });
        dispatch(clientsDownloadedAction(clients));
        return clients;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function postClient(token, role, client) {
  return (dispatch) => {
    dispatch(clientSendingAction());

    return ApiService
      .postClient(token, role, client)
      .then((data) => {
        dispatch(clientDownloadedAction(data));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

// Ajout d'un image
export function postClientPicture(token, clientId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(clientSendingAction());
    return ApiService
      .postClientPicture(token, clientId, picture, onUploadProgress)
      .then((result) => {
        dispatch(clientDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function postClientPDF(token, clientId, pdf, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(clientSendingAction());
    return ApiService
      .postClientPDF(token, clientId, pdf, onUploadProgress)
      .then((result) => {
        dispatch(clientDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function putClient(token, role, clientId, client) {
  return (dispatch) => {
    dispatch(clientSendingAction());
    return ApiService
      .putClient(token, role, clientId, client)
      .then((data) => {
        const updatedClient = {
          ...data,
          id: clientId,
        };
        dispatch(clientDownloadedAction(updatedClient));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

// Contacts

export function postClientContact(token, role, contact) {
  return (dispatch) => {
    dispatch(clientSendingAction());

    return ApiService
      .postContact(token, role, contact)
      .then((data) => {
        dispatch(clientContactDownloadedAction(data));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function putClientContact(token, role, contactId, contact) {
  return (dispatch) => {
    dispatch(clientSendingAction());
    return ApiService
      .putContact(token, role, contactId, contact)
      .then((data) => {
        const updatedContact = {
          ...data,
          id: contactId,
        };
        dispatch(clientContactDownloadedAction(updatedContact));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function deleteClientContact(token, role, contactId, clientId) {
  return (dispatch) => {
    dispatch(clientSendingAction());

    return ApiService
      .deleteContact(token, role, contactId)
      .then(() => {
        dispatch(clientContactRemovedAction({
          contactId,
          clientId,
        }));
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

// Posts

export function postClientPost(token, role, post) {
  return (dispatch) => {
    dispatch(clientSendingAction());

    return ApiService
      .postPost(token, role, post)
      .then((data) => {
        dispatch(clientPostDownloadedAction(data));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function putClientPost(token, role, postId, post) {
  return (dispatch) => {
    dispatch(clientSendingAction());
    return ApiService
      .putPost(token, role, postId, post)
      .then((data) => {
        const updatedPost = {
          ...data,
          id: postId,
        };
        dispatch(clientPostDownloadedAction(updatedPost));
        return data.id;
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}

export function deleteClientPost(token, role, postId, clientId) {
  return (dispatch) => {
    dispatch(clientSendingAction());

    return ApiService
      .deletePost(token, role, postId)
      .then(() => {
        dispatch(clientPostRemovedAction({
          postId,
          clientId,
        }));
      })
      .catch((error) => {
        dispatch(clientsFailedAction(error));
        dispatch(handleError(error));
        return error;
      });
  };
}
