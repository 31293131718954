export const clientFormFields = [
  {
    id: 'extendor_client',
    type: 'extendor',
    gridMD: 12,
    label: 'Informations Client',
    noPadding: true,
  },
  {
    id: 'client_values',
    type: 'richText',
    gridMD: 6,
    label: 'Valeurs',
    dependances: ['extendor_client'],
    isMandatory: false,
  },
  {
    id: 'client_vision',
    type: 'richText',
    gridMD: 6,
    label: 'Description',
    dependances: ['extendor_client'],
    isMandatory: false,
  },
];
