import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,6 C11.448,6 11,5.552 11,5 C11,4.448 11.448,4 12,4 C12.552,4 13,4.448 13,5 C13,5.552 12.552,6 12,6 M15,5 C15,3.346 13.654,2 12,2 C10.346,2 9,3.346 9,5 C9,6.302 9.839,7.401 11,7.815 L11,21 C11,21.553 11.447,22 12,22 C12.553,22 13,21.553 13,21 L13,7.815 C14.161,7.401 15,6.302 15,5 M6,18 C5.448,18 5,17.552 5,17 C5,16.448 5.448,16 6,16 C6.552,16 7,16.448 7,17 C7,17.552 6.552,18 6,18 M7,14.185 L7,3 C7,2.447 6.553,2 6,2 C5.447,2 5,2.447 5,3 L5,14.185 C3.839,14.599 3,15.698 3,17 C3,18.302 3.839,19.401 5,19.815 L5,21 C5,21.553 5.447,22 6,22 C6.553,22 7,21.553 7,21 L7,19.815 C8.161,19.401 9,18.302 9,17 C9,15.698 8.161,14.599 7,14.185 M18,14 C17.448,14 17,13.552 17,13 C17,12.448 17.448,12 18,12 C18.552,12 19,12.448 19,13 C19,13.552 18.552,14 18,14 M21,13 C21,11.698 20.161,10.599 19,10.185 L19,3 C19,2.447 18.553,2 18,2 C17.447,2 17,2.447 17,3 L17,10.185 C15.839,10.599 15,11.698 15,13 C15,14.302 15.839,15.401 17,15.815 L17,21 C17,21.553 17.447,22 18,22 C18.553,22 19,21.553 19,21 L19,15.815 C20.161,15.401 21,14.302 21,13" />
    </SvgIcon>
  );
};

export default FilterIcon;
