import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  legal_entities: null,
  sectors: null,
  talent_plug_sectors: null,
  cibles: null,
  status: null,
  jobs: null,
  pending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.STATICS_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.STATICS_LEGAL_ENTITIES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        legal_entities: action.payload,
      };
    }
    case types.STATICS_SECTORS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sectors: action.payload,
      };
    }
    case types.STATICS_TALENT_PLUG_SECTORS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        talent_plug_sectors: action.payload,
      };
    }
    case types.STATICS_CIBLES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        cibles: action.payload,
      };
    }
    case types.STATICS_STATUS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        status: action.payload,
      };
    }
    case types.STATICS_JOBS_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        jobs: action.payload,
      };
    }
    case types.STATICS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    default:
      break;
  }
  return state;
}
