import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/index';
import ClientForm from '../../components/forms/ClientForm';
import withTitle from '../../hocs/withTitle';
import ClientContactsPage from './ClientContactsPage';
import ClientPostsPage from './ClientPostsPage';
import { defaultStyles } from '../../themes/theme';

const styles = () => (
  {
    main: {
      width: '100%',
      marginTop: 68,
      zIndex: 5,
      overflow: 'auto',
    },
  }
);

export class ClientViewPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { selectedTab: 0 };
  }

  handleChangeTab = (event, value) => {
    this.setState({ selectedTab: value });
  };

  renderMainPage = () => {
    switch (this.state.selectedTab) {
      case 0:
        return <ClientForm
          history={this.props.history}
          match={this.props.match}
          editing={false}
          viewing={true}
        />;
      case 1:
        return <ClientContactsPage
          history={this.props.history}
          match={this.props.match}
          authUser={this.props.authUser}
        />;
      case 2:
        return <ClientPostsPage
          history={this.props.history}
          match={this.props.match}
          authUser={this.props.authUser}
        />;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <Paper style={defaultStyles.tabs}>
          <Tabs
            value={this.state.selectedTab}
            onChange={this.handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="FICHE" />
            <Tab label="CONTACTS" />
            <Tab label="BESOINS" />
          </Tabs>
        </Paper>
        <div style={{ marginTop: 64 }}>
          {this.renderMainPage()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
  };
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withTitle({
    title: 'Clients',
    subtitle: 'Consultation d\'un client',
  }),
)(ClientViewPage);
