import React from 'react';
import Typography from '@material-ui/core/Typography';
import SignInForm from '../components/forms/SignInForm';
import { useHistory } from 'react-router';

export const SignInPage = () => {
  const history = useHistory();
  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        Connexion
      </Typography>
      <SignInForm history={history} />
    </>
  );
};

export default SignInPage;
