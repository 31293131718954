import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/index';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withTitle from '../../hocs/withTitle';
import { getCandidates, putCandidate } from '../../stores/candidates/actions';
import { getUsers } from '../../stores/users/actions';
import StringService from '../../services/StringService';
import CustomTable from '../../components/common/table/CustomTable';
import Spinner from '../../components/common/Spinner';
import {
  getCandidaturesForCandidate,
  postCandidatureSlots,
  putCandidature,
} from '../../stores/candidatures/actions';
import {
  CANDIDATE_CLIENT,
  CANDIDATE_CONSULT,
  CANDIDATE_PHONE,
  CANDIDATE_VIEW_CR,
  CANDIDATE_VISIO,
} from '../../constants/routes';
import StopModal from '../../components/dialogs/StopDialog';
import ValidateDialog from '../../components/dialogs/ValidateDialog';
import CandidatePreview from './CandidatePreview';
import ApiService from '../../services/ApiService';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import MessageIcon from '../../components/icons/MessageIcon';
import EditIcon from '../../components/icons/EditIcon';
import CheckMarkCircleIcon from '../../components/icons/CheckMarkCircleIcon';
import MessageCircleIcon from '../../components/icons/MessageCircleIcon';
import { getVariationTheme } from '../../themes/theme';
import { DEFAULT_GRAY, DEFAULT_GRAY_LIGHT } from '../../themes/defaultColors';
import { STEPS } from '../../constants/interviewSteps';
import {
  canLaunchCandidature, canValidateOrStopCandidature,
  commentDependingOnCandidatureState,
} from '../../services/candidateService';
import ScoringModal from '../../components/dialogs/ScoringModal';
import RemovedModal from '../../components/dialogs/RemovedModal';
import AppDialogTitle from '../../components/dialogs/AppDialogTitle';

const colorTheme = getVariationTheme();

const styles = (theme) => (
  {
    card: {
      width: 65,
      height: 65,
      borderRadius: 65,
      margin: 20,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    profilDetails: {
      marginLeft: 105,
      marginBottom: 20,
    },
    gridItem: {
      width: '100%',
      borderBottom: '1px solid',
      borderBottomColor: DEFAULT_GRAY_LIGHT,
    },
    gridContainer: {
      width: '100%',
      borderLeft: '1px solid',
      borderLeftColor: DEFAULT_GRAY_LIGHT,
    },
    container: {
      height: 'calc(100vh - 68px)',
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    button: {
      position: 'absolute',
      right: 10,
    },
    buttonCV: {
      margin: 20,
      height: 50,
      backgroundColor: colorTheme.text_light,
      width: 'calc(100% - 40px)',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    textField: {
      width: 500,
    },
    dateField: {
      marginBottom: 10,
    },
  }
);

// Intial State of the Component
const INITIAL_STATE = {
  id: null,
  see_by_admin: false,
  candidate: null,
  consult_comment: '',
  consult_comment_date: '',
  consult_name: '',
  selectedTab: 0,
  datas: [],
  openPDFModal: false,
  openCommentModal: false,
  anchor: null,
  optionsElement: null,
  openScoringModal: false,
  openValidateModal: false,
  step: 0,
  openStopModal: false,
  openRemovedModal: false,
  openRejectedModal: false,
};

const stateWithCandidate = (candidate) => (
  {
    id: candidate.id,
    candidate: candidate,
    consult_comment: candidate.consult_comment,
    consult_comment_date: candidate.consult_comment_date,
    consult_name: candidate.consult_name,
  }
);

export class CandidatePage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser
      && !props.candidates
      && !props.candidatesPending
      && !props.error
      && !props.candidaturesError) {
      props.authUser.getIdToken(true).then(token => {
        props.getCandidates(token);
      });
    }
    if (!props.users && !props.usersPending) {
      props.getUsers();
    }

    if (
      props.candidates
      && props.match
      && props.match.params.candidateId
      && state.id === INITIAL_STATE.id
    ) {
      const candidates = props.candidates;
      const currentCandidateId = props.match.params.candidateId;
      const foundCandidate = candidates.find(candidate => candidate.id === currentCandidateId);
      if (foundCandidate
        && !props.pending
        && !props.error
        && !props.candidaturesError
        && state.candidate
        === null) {
        props.authUser.getIdToken().then(token => {
          props.getCandidaturesForCandidate(token, foundCandidate);
        });
        newState = {
          ...newState, ...stateWithCandidate(foundCandidate),
          see_by_admin: foundCandidate.see_by_admin,
        };
      }
    }
    if (state.candidate
      && !props.error
      && !props.candidaturesError
      && !state.see_by_admin
      && !props.sending
      && !props.candidatesPending) {
      // silent put to update user without rerender page
      props.authUser.getIdToken(true).then(token => {
        props.putCandidate(
          token,
          state.candidate.id,
          { see_by_admin: true },
          true,
        );
      });
      newState = {
        ...newState,
        see_by_admin: true,
      };
    }
    if (state.candidate && props.candidatures && !props.error && !props.candidaturesError) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.candidatures,
        };
      }
      if (props.candidatures !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.candidatures,
        };
      }
    }
    return newState;
  }

  handleToggleCommentModal = event => {
    event.stopPropagation();
    this.setState({ openCommentModal: !this.state.openCommentModal });
  };
  handleToggleScoringModal = event => {
    event.stopPropagation();
    this.setState({ openScoringModal: !this.state.openScoringModal });
  };
  handleToggleValidateModal = event => {
    event.stopPropagation();
    this.setState({ openValidateModal: !this.state.openValidateModal });
  };
  handleToggleStopModal = event => {
    event.stopPropagation();
    this.setState({ openStopModal: !this.state.openStopModal });
  };
  handleToggleRemovedModal = () => {
    this.setState({ openRemovedModal: !this.state.openRemovedModal });
  };
  handleToggleRejectedModal = () => {
    this.setState({ openRejectedModal: !this.state.openRejectedModal });
  };

  handleUpdateComment = event => {
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidate(
        token,
        this.props.match.params.candidateId,
        {
          consult_comment: this.state.consult_comment,
          consult_comment_date: Date.now() / 1000 | 0,
        },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
    this.handleToggleCommentModal(event);
  };

  handleUpdateScoring = event => {
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.state.optionsElement.id,
        { rate: this.state.rate },
      );
    }).catch((error) => {
      this.props.handleError(error);
    });
    this.handleToggleScoringModal(event);
  };

  handleValidate = event => {
    let newSlots = [];
    if (!this.state.step) {
      this.props.handleError({ message: 'Veuillez sélectionner une nouvelle étape.' });
      return;
    }
    if (!this.state.slot_1) {
      this.props.handleError({ message: 'Veuillez sélectionner un créneau. Attention à bien préciser l\'heure.' });
      return;
    }
    if (this.state.slot_1) {
      newSlots.push({ date: this.state.slot_1 });
    }
    if (this.state.slot_2) {
      newSlots.push({ date: this.state.slot_2 });
    }
    if (this.state.slot_3) {
      newSlots.push({ date: this.state.slot_3 });
    }
    const candidatureState = commentDependingOnCandidatureState(
      this.state.optionsElement.state,
      this.state.comment,
    );

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.postCandidatureSlots(
        token,
        this.state.optionsElement.id,
        {
          state: this.state.step,
          slots: newSlots,
          ...candidatureState,
        },
      );
    }).catch((error) => {
      this.props.handleError(error);
    });
    this.handleToggleValidateModal(event);
  };

  handleStop = event => {
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.state.optionsElement.id,
        {
          state: STEPS.CANDIDATE_REJECTED.value,
          consult_reject_reason: this.state.consult_reject_reason,
          consult_reject_comment: this.state.consult_reject_comment,
        },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
    this.handleToggleStopModal(event);
  };

  handleSelect = (event, element) => {
    this.setState({
      anchor: event.currentTarget,
      optionsElement: element,
      step: element.state,
    });
  };

  handleClickRow = (element) => {
    if (element.state === STEPS.CANDIDATE_REMOVED.value) {
      this.setState({
        optionsElement: element,
        step: element.state,
      });
      this.handleToggleRemovedModal();
    } else if (element.state === STEPS.CANDIDATE_REJECTED.value) {
      this.setState({
        optionsElement: element,
        step: element.state,
      });
      this.handleToggleRejectedModal();
    }
  };

  replaceRoutesParams = (route) => {
    return route
      .replace(':candidateId', this.props.match.params.candidateId)
      .replace(':candidatureId', this.state.optionsElement.id);
  };

  handleLaunchStepCandidature = () => {
    const historyVariables = {
      candidature: this.state.optionsElement,
      candidate: this.state.candidate,
    };
    // eslint-disable-next-line
    switch (this.state.step) {
      case STEPS.PHONE_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_PHONE),
          historyVariables,
        );
      case STEPS.VISIO_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_VISIO),
          historyVariables,
        );
      case STEPS.CONSULT_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_CONSULT),
          historyVariables,
        );
      case STEPS.CLIENT_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_CLIENT),
          historyVariables,
        );
    }
  };

  handleValidateCandidature = event => {
    let currentCandidatureState = this.state.optionsElement.state;
    if (currentCandidatureState < STEPS.PHONE_WAITING_SLOTS.value) {
      this.setState({
        anchor: null,
        step: 2,
      });
    } else if (currentCandidatureState < STEPS.VISIO_WAITING_SLOTS.value) {
      this.setState({
        anchor: null,
        step: 5,
      });
    } else if (currentCandidatureState < STEPS.CONSULT_WAITING_SLOTS.value) {
      this.setState({
        anchor: null,
        step: 8,
      });
    } else if (currentCandidatureState < STEPS.CLIENT_WAITING_SLOTS.value) {
      this.setState({
        anchor: null,
        step: 11,
      });
    }
    this.handleToggleValidateModal(event);
  };

  handleStopCandidature = event => {
    this.setState({ anchor: null });
    this.handleToggleStopModal(event);
  };

  handleModifyScoringCandidature = event => {
    this.setState({ anchor: null });
    this.handleToggleScoringModal(event);
  };

  handleSendRecruitPDF = event => {
    this.setState({ anchor: null });
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getRecruitPDF(token, this.state.optionsElement.id);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleSendClientPDF = event => {
    this.setState({ anchor: null });
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getClientPDF(token, this.state.optionsElement.id);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleSendCandidatePDF = event => {
    this.setState({ anchor: null });
    this.props.authUser.getIdToken(true).then(token => {
      return ApiService.getCandidatePDF(token, this.state.optionsElement.id);
    })
      .then(() => {
        this.props.dispatchInfo('Email envoyé');
      })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  lastComment = (element) => {
    if (element.comment_client !== null) {
      return element.comment_client;
    } else if (element.comment_consult !== null) {
      return element.comment_consult;
    } else if (element.comment_visio !== null) {
      return element.comment_visio;
    } else if (element.comment_phone !== null) {
      return element.comment_phone;
    }
    return '-';
  };

  handleNavigateToReportInterview = (candidature) => {
    this.props.history.push(
      CANDIDATE_VIEW_CR
        .replace(':candidateId', this.props.match.params.candidateId)
        .replace(':candidatureId', candidature.id),
      {
        candidature: candidature,
        candidate: this.state.candidate,
      },
    );
  };

  render() {
    const {
      classes,
    } = this.props;

    const disabledButton = !canValidateOrStopCandidature(this.state.optionsElement);
    const commentModal = (
      <Dialog
        fullScreen={false}
        open={this.state.openCommentModal}
        onClose={this.handleToggleCommentModal}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <AppDialogTitle
          id="responsive-dialog-title"
          onClose={this.handleToggleCommentModal}
        >
          Commentaire sur le candidat
        </AppDialogTitle>
        <DialogContent>
          <TextField
            id="consult_comment"
            multiline
            defaultValue={this.state.consult_comment}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            onChange={event => this.setState({ consult_comment: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleUpdateComment}
            color="primary"
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );

    const rejectedModal = (
      <Dialog
        fullScreen={false}
        open={this.state.openRejectedModal}
        onClose={this.handleToggleRejectedModal}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <AppDialogTitle
          id="responsive-dialog-title"
          onClose={this.handleToggleRejectedModal}
        >
          Candidature rejetée
        </AppDialogTitle>
        <DialogContent>
          <div className={classes.row}>
            <CheckMarkCircleIcon className={classes.icon} style={{ color: colorTheme.primary }} />
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Motif
            </Typography>
          </div>
          <Typography
            component="p"
            variant="subtitle2"
            style={{
              marginLeft: 32,
              color: DEFAULT_GRAY,
            }}
          >
            {(
              this.state.optionsElement !== null
            )
              ? this.state.optionsElement.consult_reject_reason
              : '-'}
          </Typography>
          <br />
          <div className={classes.row}>
            <MessageCircleIcon className={classes.icon} style={{ color: colorTheme.primary }} />
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Commentaire du refus
            </Typography>
          </div>
          <Typography
            component="p"
            variant="subtitle2"
            style={{
              marginLeft: 32,
              color: DEFAULT_GRAY,
            }}
          >
            {(
              this.state.optionsElement !== null
            )
              ? this.state.optionsElement.consult_reject_comment
              : '-'}
          </Typography>
          <div className={classes.row}>
            <MessageCircleIcon className={classes.icon} style={{ color: colorTheme.primary }} />
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Dernier commentaire de la candidature
            </Typography>
          </div>
          <Typography
            component="p"
            variant="subtitle2"
            style={{
              marginLeft: 32,
              color: DEFAULT_GRAY,
            }}
          >
            {(
              this.state.optionsElement !== null
            )
              ? this.lastComment(this.state.optionsElement)
              : '-'}
          </Typography>
        </DialogContent>
      </Dialog>
    );

    const columnData = [
      {
        id: 'post_name',
        label: 'Offre',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'company_name',
        label: 'Entreprise',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'state',
        label: 'Etape de candidature',
        step: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'report',
        label: 'Compte rendu',
        align: 'center',
        disablePadding: false,
      },
    ];

    const optionsMenu = (
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchor}
        keepMounted
        open={Boolean(this.state.anchor)}
        onClose={() => this.setState({ anchor: null })}
      >
        <MenuItem
          disabled={!canLaunchCandidature(this.state.optionsElement)}
          onClick={event => this.handleLaunchStepCandidature(event)}
        >
          Lancer l'étape
        </MenuItem>
        <MenuItem onClick={event => this.handleModifyScoringCandidature(event)}>
          Modifier le scoring
        </MenuItem>
        <MenuItem onClick={event => this.handleSendRecruitPDF(event)}>
          Envoyer le CR au Recruteur
        </MenuItem>
        <MenuItem onClick={event => this.handleSendClientPDF(event)}>
          Envoyer le CR au Client
        </MenuItem>
        <MenuItem onClick={event => this.handleSendCandidatePDF(event)}>
          Envoyer le CR au Candidat
        </MenuItem>
        <MenuItem
          disabled={disabledButton}
          onClick={event => this.handleValidateCandidature(event)}
        >
          Valider la candidature
        </MenuItem>
        <MenuItem
          disabled={disabledButton}
          onClick={event => this.handleStopCandidature(event)}
        >
          Refuser la candidature
        </MenuItem>
      </Menu>
    );

    return (
      <Grid container className={classes.container}>
        <CandidatePreview
          candidate={this.state.candidate}
          error={this.props.error}
          pending={this.props.pending}
        />
        <Grid
          container xs={12} sm={12} md={7}
          direction="column"
          className={classes.gridContainer}
        >
          <Grid className={classes.gridItem}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 'bold',
                margin: 20,
              }}
            >
              Liste des candidatures
            </Typography>
            <div style={{ margin: 20 }}>
              {(
                  this.props.pending || this.props.sending
                ) &&
                <Spinner />
              }
              {this.state.datas &&
                <CustomTable
                  datas={this.state.datas}
                  emptyDataMessage="Aucune Candidature"
                  columnData={columnData}
                  sending={this.props.sending}
                  onOptions={this.handleSelect}
                  onClickRow={this.handleClickRow}
                  showDelete={false}
                  handleNavigateToReportInterview={this.handleNavigateToReportInterview}
                />
              }
            </div>
          </Grid>
          <Grid>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 'bold',
                margin: 20,
              }}
            >
              Commentaire
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                margin: 20,
                color: DEFAULT_GRAY,
              }}
            >
              {this.state.consult_comment}
            </Typography>
            {!this.props.pending
              && !this.props.candidaturesError
              && !this.props.error
              && (
                <div className={classes.row} style={{ margin: 20 }}>
                  <MessageIcon className={classes.icon} style={{ color: colorTheme.primary }} />
                  <Typography
                    variant="subtitle2" style={{
                    alignSelf: 'center',
                    color: DEFAULT_GRAY,
                  }}
                  >
                    {this.state.consult_comment_date
                      ?
                      StringService.dateTimestampToString(this.state.consult_comment_date)
                      : ''}
                  </Typography>
                  <Button className={classes.button} onClick={this.handleToggleCommentModal}>
                    <EditIcon className={classes.leftIcon} style={{ color: colorTheme.primary }} />
                    Éditer
                  </Button>
                </div>
              )
            }
          </Grid>
        </Grid>
        <StopModal
          openStopModal={this.state.openStopModal}
          handleToggleStopModal={this.handleToggleStopModal}
          handleReason={(number, reason, comment) =>
            this.setState({
              stop: number,
              consult_reject_reason: reason,
              consult_reject_comment: comment,
            })}
          consultRejectComment={this.state.consult_reject_comment}
          stop={this.state.stop}
          handleComment={event => this.setState({ consult_reject_comment: event.target.value })}
          handleStop={event => this.handleStop(event)}
        />
        <ValidateDialog
          openValidateModal={this.state.openValidateModal}
          handleToggleValidateModal={this.handleToggleValidateModal}
          candidature={this.state.optionsElement}
          step={this.state.step}
          handleStepPhone={() => this.setState({ step: STEPS.PHONE_WAITING_SLOTS.value })}
          handleStepVisio={() => this.setState({ step: STEPS.VISIO_WAITING_SLOTS.value })}
          handleStepConsult={() => this.setState({ step: STEPS.CONSULT_WAITING_SLOTS.value })}
          handleStepClient={() => this.setState({ step: STEPS.CLIENT_WAITING_SLOTS.value })}
          comment={this.state.comment}
          handleFirstSlot={event => this.setState({
            slot_1: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleSecondSlot={event => this.setState({
            slot_2: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleThirdSlot={event => this.setState({
            slot_3: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleValidate={this.handleValidate}
          handleMessage={event => this.setState({ comment: event.target.value })}
        />
        {commentModal}
        <ScoringModal
          openScoringModal={this.state.openScoringModal}
          candidature={this.state.optionsElement}
          onChange={(event, value) => this.setState({ rate: value })}
          handleToggleScoringModal={this.handleToggleScoringModal}
          handleUpdateScoring={this.handleUpdateScoring}
        />
        <RemovedModal
          openRemovedModal={this.state.openRemovedModal}
          handleToggleRemovedModal={this.handleToggleRemovedModal}
          candidature={this.state.optionsElement}
          classes={classes}
        />
        {rejectedModal}
        {optionsMenu}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    candidatesPending: state.candidates.pending,
    candidaturesPending: state.candidatures.pending,
    usersPending: state.users.pending,
    pending: state.candidates.pending || state.candidatures.pending || state.users.pending,
    sending: state.candidates.sending || state.candidatures.sending,
    candidates: state.candidates.content,
    error: state.candidates.error,
    candidaturesError: state.candidatures.error,
    candidatures: state.candidatures.content,
  };
}

export default compose(
  withStyles(styles),
  withTitle({
    title: 'Candidat',
    subtitle: 'Accueil/CVthèque/Candidat',
  }),
  connect(mapStateToProps, {
    getCandidates,
    putCandidate,
    getCandidaturesForCandidate,
    putCandidature,
    postCandidatureSlots,
    getUsers,
    dispatchInfo,
    handleError,
  }),
)(CandidatePage);
