import React from 'react';
import PropTypes from "prop-types";
import Spinner from "../../components/common/Spinner";
import {withStyles} from "@material-ui/core/styles/index";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { DEFAULT_GRAY_LIGHT } from '../../themes/defaultColors';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    margin: 20
  }
};

const FinishDialog = props => {
  // Constant
  const {
    sending,
    handleReject,
    handleToggleFinishedModal,
    openFinishModal,
    handleValidate,
    handleRecord
  } = props;

  return (

    <Dialog
      fullScreen={false}
      open={openFinishModal}
      onClose={handleToggleFinishedModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
    >
      <DialogTitle
        id="responsive-dialog-title"
        onClose={handleToggleFinishedModal}
      >
        Entretien terminé
      </DialogTitle>
      <DialogContent>

        {sending && <Spinner />}
        {!sending &&
        <div>
          <div className={props.classes.row}>
            <Button
              className={props.classes.button}
              variant="contained"
              onClick={handleReject}
              color="secondary"
            >
              Refuser la candidature
            </Button>
            <Button
              className={props.classes.button}
              variant="contained"
              onClick={handleValidate}
              color="primary"
            >
              Accepter la candidature
            </Button>
          </div>
          <div className={props.classes.row}>
            <Button
              variant="contained"
              onClick={handleRecord}
              style={{backgroundColor: DEFAULT_GRAY_LIGHT, width: '60%'}}
            >
              Enregistrer et terminer plus tard
            </Button>
          </div>
        </div>
        }

      </DialogContent>
    </Dialog>
  );
};

FinishDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles) (FinishDialog);
