import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const candidaturesPendingAction = () => ({
  type: types.CANDIDATURES_PENDING,
});
export const candidatureSendingAction = () => ({
  type: types.CANDIDATURE_SENDING,
});

export const candidaturesUnloadAction = () => ({
  type: types.CANDIDATURES_UNLOAD,
});

export const candidaturesDownloadedAction = candidates => ({
  type: types.CANDIDATURES_DOWNLOADED,
  payload: candidates,
});

export const candidaturesFailedAction = error => ({
  type: types.CANDIDATURES_FAILED,
  payload: error,
});

export const candidatureDownloadedAction = (candidature) => ({
  type: types.CANDIDATURE_DOWNLOADED,
  payload: candidature
});


// --------------------------------
// Actions

export function unloadCandidatures() {
  return (dispatch) => {
    dispatch(candidaturesUnloadAction());
  }
}

export function getCandidaturesForCandidate(token, candidate) {
  return (dispatch) => {
    dispatch(candidaturesPendingAction());
    return ApiService
      .getCandidaturesForCandidate(token, candidate)
      .then((candidatures) => {
        dispatch(candidaturesDownloadedAction(candidatures));
      })
      .catch((error) => {
        dispatch(candidaturesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getCandidaturesForOffer(token, offer) {
  return (dispatch) => {
    dispatch(candidaturesPendingAction());
    return ApiService
      .getCandidaturesForOffer(token, offer)
      .then((candidatures) => {
        dispatch(candidaturesDownloadedAction(candidatures));
      })
      .catch((error) => {
        dispatch(candidaturesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putCandidature(token, candidatureId, candidature) {
  return (dispatch) => {
    dispatch(candidatureSendingAction());
    return ApiService
      .putCandidature(token, candidatureId, candidature)
      .then((data) => {
        const updatedCandidature = {
          ...data,
          id: candidatureId,
        };
        dispatch(candidatureDownloadedAction(updatedCandidature));
        return data.id
      })
      .catch((error) => {
        dispatch(candidaturesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function postCandidatureSlots(token, candidatureId, candidature) {
  return (dispatch) => {
    dispatch(candidatureSendingAction());
    return ApiService
      .postCandidatureSlots(token, candidatureId, candidature)
      .then((data) => {
        const updatedCandidature = {
          ...data,
          id: candidatureId,
        };
        dispatch(candidatureDownloadedAction(updatedCandidature));
        return data.id
      })
      .catch((error) => {
        dispatch(candidaturesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}
