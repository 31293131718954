import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UploadIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M12.9971647,11.9241041 C12.9990437,11.9491575 13,11.9744675 13,12 L13,20 C13,20.552 12.553,21 12,21 C11.447,21 11,20.552 11,20 L11,12.0001755 L8.6,13.8 C8.42,13.935 8.21,14.0000099 8.001,14.0000099 C7.696,14.0000099 7.397,13.862 7.2,13.6 C6.868,13.158 6.958,12.531 7.4,12.2 L11.4,9.2 C11.746,8.94 12.222,8.933 12.575,9.182 L16.575,11.996 C17.027,12.314 17.136,12.938 16.818,13.389 C16.5,13.84 15.877,13.95 15.425,13.632 L12.9971647,11.9241041 Z M4.29375,4.29375 C4.475,4.1125 4.725,4 5,4 L19,4 C19.275,4 19.525,4.1125 19.70625,4.29375 C19.8875,4.475 20,4.725 20,5 L20,7 C20,7.55 19.55,8 19,8 C18.45,8 18,7.55 18,7 L18,6 L6,6 L6,7 C6,7.55 5.55,8 5,8 C4.45,8 4,7.55 4,7 L4,5 C4,4.725 4.1125,4.475 4.29375,4.29375 Z" />
    </SvgIcon>
  );
};

export default UploadIcon;
