import { LANGUAGE_LEVELS } from '../../offer';

export const languageFormFields = [
  {
    id: 'extendor_language',
    type: 'extendor',
    gridMD: 12,
    label: 'Langues',
    noPadding: true,
  },
  {
    id: 'language1',
    type: 'textField',
    label: 'Langue 1',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
  {
    id: 'language2',
    type: 'textField',
    label: 'Langue 2',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
  {
    id: 'language3',
    type: 'textField',
    label: 'Langue 3',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
  {
    id: 'language_level1',
    type: 'selectField',
    options: LANGUAGE_LEVELS,
    label: 'Niveau',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
  {
    id: 'language_level2',
    type: 'selectField',
    options: LANGUAGE_LEVELS,
    label: 'Niveau',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
  {
    id: 'language_level3',
    type: 'selectField',
    options: LANGUAGE_LEVELS,
    label: 'Niveau',
    dependances: ['extendor_language'],
    isMandatory: false,
  },
];
