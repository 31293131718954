import React, {Component} from 'react';
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import {Document, Page} from 'react-pdf';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { getVariationTheme } from '../../../themes/theme';
import { DEFAULT_GRAY_LIGHT, DEFAULT_GRAY_DARK } from '../../../themes/defaultColors';
import UploadIcon from '../../icons/UploadIcon';
import EyeIcon from '../../icons/EyeIcon';

const colorTheme = getVariationTheme();

const styles = () => ({
  dropzone: {
    maxHeight: '6.5em',
    width: 'auto',
    padding: 'auto'

  },
  grid: {
    border: '1px dashed black',
    borderRadius: '10px',
    margin: '10px'
  },
  icon: {
    color: DEFAULT_GRAY_LIGHT,
    width: 51,
    height: 51
  }
});

export class CustomPDFDropzone extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    openPDFModal: false
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    labelTitle: PropTypes.string,
    labelAddPDF: PropTypes.string,
    labelDropPDF: PropTypes.string,
    pdfPath: PropTypes.string,
    isMandatory: PropTypes.bool,
    onDrop: PropTypes.func.isRequired
  };

  static defaultProps = {
    pdfPath: null,
    labelTitle: "PDF",
    isMandatory: false,
    labelAddPDF: "Ajouter un PDF",
    labelDropPDF: "Insérer le contrat"
  };

  static supportedDropFiles = [
    'application/pdf',
  ].toString();

  // Handler

  handleAddMedia = () => {
    this.dropzoneRef.open();
  };

  handleTogglePDFModal = event => {
    event.stopPropagation();
    this.setState({openPDFModal: !this.state.openPDFModal});
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;
    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }
    this.setState({
      pageNumber: nextPageNumber
    });
  };


  render() {
    const { pageNumber, numPages } = this.state;

    const {gridMD, labelDropPDF,
      pdfPath, onDrop, classes} = this.props;

    const pdfViewerModal = (
      <Dialog
        fullScreen={false}
        open={this.state.openPDFModal}
        onClose={this.handleTogglePDFModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
        >
          Prévisualisation
        </DialogTitle>
        <DialogContent>
          <Document file={pdfPath} onLoadSuccess={this.onDocumentLoadSuccess} >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleTogglePDFModal}
            color="primary"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );

    const previewButton = (
      <Tooltip title={'Visualiser'}>
        <IconButton
          id="delete"
          aria-label="update"
          onClick={this.handleTogglePDFModal}
          color="primary"
        >
          <EyeIcon style={{color: colorTheme.menu}}/>
        </IconButton>
      </Tooltip>
    );

    const pdfDropzone = (
      <Dropzone
        className={classes.dropzone}
        ref={(node) => {
          this.dropzoneRef = node
        }}
        accept={CustomPDFDropzone.supportedDropFiles}
        onDrop={onDrop}
      >
        {({getRootProps, getInputProps}) => (
          pdfPath ?
            (
              <div {...getRootProps()} onClick={this.nextPage}>
                <input {...getInputProps()} />
                <UploadIcon className={classes.icon} />
                <Typography variant="h4" style={{color: this.props.isMandatory ? getVariationTheme().secondary : DEFAULT_GRAY_DARK}}>
                  {labelDropPDF}{this.props.isMandatory ? '*' : ''}
                </Typography>
                {previewButton}
              </div>
            ) : (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadIcon style={{display: "inline-block"}} className={classes.icon} />
                <Typography variant="h4" style={{color: this.props.isMandatory ? getVariationTheme().secondary : DEFAULT_GRAY_DARK, display: "inline-block"}}>
                  {labelDropPDF}{this.props.isMandatory ? '*' : ''}
                </Typography>
              </div>
            )
        )}
      </Dropzone>
    );
    return (
      <Grid item xs={12} sm={12} md={gridMD} className={classes.grid}>
        {pdfViewerModal}
        {pdfDropzone}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomPDFDropzone);
