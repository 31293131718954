export const missionFormFields = [
  {
    id: 'extendor_mission',
    type: 'extendor',
    gridMD: 12,
    label: 'Missions',
    noPadding: true,
  },
  {
    id: 'main_tasks',
    type: 'richText',
    gridMD: 6,
    label: 'Missions principales',
    dependances: ['extendor_mission'],
    isMandatory: false,
  },
  {
    id: 'lesser_tasks',
    type: 'richText',
    gridMD: 6,
    label: 'Missions secondaires',
    dependances: ['extendor_mission'],
    isMandatory: false,
  },
];
