import React from 'react';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid/Grid";
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import { defaultStyles } from '../../../themes/theme';

export const CustomFormContainer = (props) =>
  (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <MandatoryFieldIndicator />
      </Grid>
      <Grid item xs={12} lg={12}>
        <form
          onSubmit={props.onSubmit}
          style={defaultStyles.center}
        >
          {props.children}
        </form>
      </Grid>
    </Grid>
  );

CustomFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default (CustomFormContainer);
