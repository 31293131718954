import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import withStyles from "@material-ui/core/styles/withStyles";
import { DEFAULT_GRAY_DARK, DEFAULT_GRAY_LIGHT } from '../../../themes/defaultColors';

const styles = () => ({
  dropzone: {
    maxHeight: '6.5em',
    width: 'auto',
    padding: 'auto'
  },
  grid: {
    border: '1px dashed black',
    borderRadius: '10px',
    padding: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center'
  },
  text: {
    color: DEFAULT_GRAY_DARK,
    margin: '10px',
    display: "inline-block"
  },
  icon: {
    color: DEFAULT_GRAY_LIGHT,
    width: 51,
    height: 51
  }
});

export class CustomHTMLDropzone extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    gridMD: PropTypes.number.isRequired,
    labelTitle: PropTypes.string,
    labelAddHTML: PropTypes.string,
    labelDropHTML: PropTypes.string,
    onDrop: PropTypes.func.isRequired
  };

  static defaultProps = {
    pdfPath: null,
    labelTitle: "Excel",
    labelAddPDF: "Ajouter un modèle xlsx",
    labelDropPDF: "Insérer un modèle xlsx"
  };

  static supportedDropFiles = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ].toString();

  render() {

    const {labelDropPDF, onDrop, classes} = this.props;


    const htmlDropzone = (
      <Dropzone
        className={classes.dropzone}
        ref={(node) => {
          this.dropzoneRef = node
        }}
        accept={CustomHTMLDropzone.supportedDropFiles}
        onDrop={onDrop}
      >
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="h4" className={classes.text}>
              {labelDropPDF}
            </Typography>
          </div>
        )}
      </Dropzone>
    );
    return (
      <Grid item xs={4} sm={4} md={4} className={classes.grid}>
        {htmlDropzone}
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomHTMLDropzone);
