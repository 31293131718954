export const INITIAL_STATE = {
  id: '',
  extendor_candidate: true,
  extendor_analysis: false,
  extendor_verbal: false,
  extendor_attitudes: false,
  extendor_organisation: false,
  extendor_motivations: false,
  extendor_global: false,
  openFinishedModal: false,
  openStopModal: false,
  openValidateModal: false,
};