export default class StringService {
  static dateTimestampToString = (timestamp) => {
    const newDate = new Date(timestamp * 1000);
    const mm = newDate.getMonth() + 1; // getMonth() is zero-based
    const dd = newDate.getDate();
    return dd+'/'+mm+'/'+newDate.getFullYear();
  };

  static dateTimestampToStringForForm = (timestamp) => {
    const newDate = new Date(timestamp * 1000);
    const mm = newDate.getMonth() + 1; // getMonth() is zero-based
    const dd = newDate.getDate();
    return newDate.getFullYear()+'-'+(mm>9 ? '' : '0')+mm+'-'+(dd>9 ? '' : '0')+dd;
  };

  static timeTimestampToString = (timestamp) => {
    const newDate = new Date(timestamp * 1000);
    const hh = newDate.getHours();
    const mm = newDate.getMinutes();
    return (hh>9 ? '' : '0')+hh+':'+(mm>9 ? '' : '0')+mm;
  };

  static dateStringToTimestamp = (date) => {
    const splitDate=date.split("-");
    return Math.floor(new Date(splitDate).getTime() / 1000)
  };

  static dateTimeStringToTimestamp = (date) => {
    const split=date.split("T");
    const splitDate=split[0].split("-");
    splitDate[1]--;
    const splitTime=split[1].split(":");
    return Math.floor(new Date(...splitDate.concat(splitTime)).getTime() / 1000)
  };

  static isEmpty = (str) => {
    return (!str || 0 === str.length);
  }
  static stripHtml = (html) => {
    // Create a new div element
    let temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = (
      html || ''
    ).replace(/<\/p><p>/g, '\r').replace(/<\/p><p><br>/g, '\n\r');
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  };
}
