import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SearchIcon = (props) => {

  return (
    <SvgIcon {...props}>
        <path d="M5,11 C5,7.691 7.691,5 11,5 C14.309,5 17,7.691 17,11 C17,14.309 14.309,17 11,17 C7.691,17 5,14.309 5,11 M20.707,19.293 L17.312,15.897 C18.365,14.543 19,12.846 19,11 C19,6.589 15.411,3 11,3 C6.589,3 3,6.589 3,11 C3,15.411 6.589,19 11,19 C12.846,19 14.543,18.365 15.897,17.312 L19.293,20.707 C19.488,20.902 19.744,21 20,21 C20.256,21 20.512,20.902 20.707,20.707 C21.098,20.316 21.098,19.684 20.707,19.293" />
    </SvgIcon>
  );
};

export default SearchIcon;
