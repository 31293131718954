import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles/index';
import { getClients, postClientPost, putClientPost } from '../../stores/clients/actions';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import Spinner from '../common/Spinner';
import CustomForm from '../common/form/CustomForm';
import { getUsers } from '../../stores/users/actions';
import { CLIENT_VIEW } from '../../constants/routes';
import StringService from '../../services/StringService';
import ApiService from '../../services/ApiService';
import { getStaticJobs } from '../../stores/statics/actions';
import BackIcon from '../icons/BackIcon';

// Intial State of the Component
const INITIAL_STATE = {
  id: '',
  status: '',
  status_comment: '',
  name: '',
  contract: '',
  contract_comment: '',
  client_post_name: '',
  reason: '',
  induction_date: StringService.dateTimestampToStringForForm(Date.now() / 1000 | 0),
  is_intern_possible: false,
  intern_info: '',
  has_transition_advisor: false,
  transition_info: '',
  n_plus_one_firstname: '',
  n_plus_one_lastname: '',
  n_plus_one_position: '',
  n_plus_two_firstname: '',
  n_plus_two_lastname: '',
  n_plus_two_position: '',
  department_name: '',
  team_size: 0,
  team_interactions: '',
  people_to_manage: '',
  people_to_operate: '',
  work_place: '',
  mobility_wanted: false,
  mobility_info: '',
  schedule: '',
  induction_period: '',
  training_intern: '',
  training_extern: '',
  advisors: '',
  referents: '',
  reference_departement: '',
  initiative_level: 0,
  autonomy_level: 0,
  main_tasks: '',
  lesser_tasks: '',
  schooling_level: '',
  diploma: '',
  training: '',
  authorization: '',
  level: '',
  level_comments: '',
  main_sector: '',
  experiences: '',
  experience_years: 0,
  openness: '',
  language1: '',
  language_level1: '',
  language2: '',
  language_level2: '',
  language3: '',
  language_level3: '',
  comments: '',
  salary12_month: false,
  salary13_month: false,
  salary14_month: false,
  salary: false,
  salary_info: '',
  variable_pay: false,
  variable_pay_info: '',
  annual_bonus: false,
  annual_bonus_by_quarter: false,
  ranking_bonus: false,
  ca_commission: false,
  ca_commission_percentage: 0.0,
  ca_commission_info: '',
  brut_commission: false,
  brut_commission_percentage: 0.0,
  brut_commission_info: '',
  net_commission: false,
  net_commission_percentage: 0.0,
  net_commission_info: '',
  stake: false,
  profit_sharing: false,
  company_vehicle: false,
  other_benefits: '',
  competition: false,
  competition_info: '',
  specific_advantages: '',
  skills: [],
  softskills: [],
  deptOptions: [],
  diplomaOptions: [],
  extendor_poste: true,
  extendor_contract: true,
  extendor_hierarchy: false,
  extendor_team: false,
  extendor_intern: false,
  extendor_contexte: false,
  extendor_mission: false,
  extendor_level: false,
  extendor_language: false,
  extendor_salary: false,
  extendor_client: false,
  extendor_other: false,
};

const stateWithPost = (post) => (
  {
    id: post.id,
    status: postStatus.hasOwnProperty(post.status)
      ? post.status
      : 'Autre',
    status_comment: post.status_comment
      ? post.status_comment
      : '',
    name: {
      value: post.name
        ? post.name
        : '',
      label: post.name
        ? post.name
        : '',
    },
    contract: contracts.hasOwnProperty(post.contract)
      ? post.contract
      : 'Autre',
    contract_comment: post.contract_comment
      ? post.contract_comment
      : '',
    client_post_name: post.client_post_name,
    client_values: post.client_values,
    client_vision: post.client_vision,
    reason: post.reason,
    induction_date: StringService.dateTimestampToStringForForm(post.induction_date),
    is_intern_possible: post.is_intern_possible,
    intern_info: post.intern_info,
    has_transition_advisor: post.has_transition_advisor,
    transition_info: post.transition_info,
    n_plus_one_firstname: post.n_plus_one_firstname,
    n_plus_one_lastname: post.n_plus_one_lastname,
    n_plus_one_position: post.n_plus_one_position,
    n_plus_two_firstname: post.n_plus_two_firstname,
    n_plus_two_lastname: post.n_plus_two_lastname,
    n_plus_two_position: post.n_plus_two_position,
    department_name: {
      value: post.department_name
        ? post.department_name
        : '',
      label: post.department_name
        ? post.department_name
        : '',
    },
    team_size: post.team_size,
    team_interactions: post.team_interactions,
    people_to_manage: post.people_to_manage,
    people_to_operate: post.people_to_operate,
    work_place: post.work_place,
    mobility_wanted: post.mobility_wanted,
    mobility_info: post.mobility_info,
    schedule: post.schedule,
    induction_period: post.induction_period,
    training_intern: post.training_intern,
    training_extern: post.training_extern,
    advisors: post.advisors,
    referents: post.referents,
    reference_department: post.reference_department
      ? post.reference_department
      : '',
    initiative_level: post.initiative_level,
    autonomy_level: post.autonomy_level,
    main_tasks: post.main_tasks,
    lesser_tasks: post.lesser_tasks,
    schooling_level: post.schooling_level,
    diploma: {
      value: post.diploma
        ? post.diploma
        : '',
      label: post.diploma
        ? post.diploma
        : '',
    },
    training: post.training,
    authorization: post.authorization,
    level: post.level,
    level_comments: post.level_comments,
    main_sector: post.main_sector,
    experiences: post.experiences,
    experience_years: post.experience_years,
    openness: post.openness,
    language1: post.language1,
    language_level1: post.language_level1,
    language2: post.language2,
    language_level2: post.language_level2,
    language3: post.language3,
    language_level3: post.language_level3,
    comments: post.comments,
    salary12_month: post.salary12_month,
    salary13_month: post.salary13_month,
    salary14_month: post.salary14_month,
    salary: post.salary,
    salary_info: post.salary_info,
    variable_pay: post.variable_pay,
    variable_pay_info: post.variable_pay_info,
    annual_bonus: post.annual_bonus,
    annual_bonus_by_quarter: post.annual_bonus_by_quarter,
    ranking_bonus: post.ranking_bonus,
    ca_commission: post.ca_commission,
    ca_commission_percentage: post.ca_commission_percentage,
    ca_commission_info: post.ca_commission_info,
    brut_commission: post.brut_commission,
    brut_commission_percentage: post.brut_commission_percentage,
    brut_commission_info: post.brut_commission_info,
    net_commission: post.net_commission,
    net_commission_percentage: post.net_commission_percentage,
    net_commission_info: post.net_commission_info,
    stake: post.stake,
    profit_sharing: post.profit_sharing,
    company_vehicle: post.company_vehicle,
    other_benefits: post.other_benefits,
    competition: post.competition,
    competition_info: post.competition_info,
    skills: post.skills.map(skill =>
      (
        {
          value: skill.code_ogr,
          label: skill.libelle_competence,
        }
      ),
    ),
    softskills: post.softskills.map(softskill =>
      (
        {
          value: softskill.summary,
          label: softskill.summary,
        }
      ),
    ),
    specific_advantages: post.specific_advantages
      ? post.specific_advantages
      : '',
  }
);

const postStatus = {
  30: 'Employé',
  31: 'Opérateur',
  33: 'Ouvrier',
  34: 'Ouvrier spécialisé',
  35: 'Technicien',
  36: 'Agent de maîtrise',
  37: 'Ingénieur',
  38: 'Cadre',
  39: 'Cadre dirigeant',
};
const contracts = {
  21: 'CDI',
  22: 'CDD',
  26: 'Alternance',
  23: 'Intérim',
};
const schoolingLevels = {
  12: 'BAC',
  13: 'Bac Professionnel',
  14: 'Bac+2',
  15: 'BAC+3',
  16: 'BAC+4',
  17: 'BAC+5',
  18: 'BAC+6',
  19: 'Doctorat',
  20: 'Chercheur',
};
const levels = {
  'Débutant': 'Débutant',
  'Confirmé': 'Confirmé',
  'Manager': 'Manager',
};
const languageLevels = {
  'Notions': 'Notions',
  'Débutant': 'Débutant',
  'Bon': 'Bon',
  'Courant': 'Courant',
  'Courant et technique': 'Courant et technique',
};

/**
 * Style of Form elements
 *
 * @returns json
 */
export const styles = () => (
  {
    shareLinkedInButton: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '3vh',
      right: '10vh',
      zIndex: '5',
    },
  }
);

export class ClientPostForm extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    pending: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    postClientPost: PropTypes.func.isRequired,
    putClientPost: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    posts: null,
  };

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (props.authUser && !props.clients && !props.pending && props.roles) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken().then(token => {
        props.getClients(token, role);
      });
    }
    if (props.authUser && !props.jobs && !props.pending) {
      props.getStaticJobs();
    }
    if (props.clients
      && props.match
      && props.match.params.postId
      && state.id
      === INITIAL_STATE.id) {
      const currentClientId = props.match.params.clientId;
      const foundClient = props.clients.find(client => client.id === currentClientId);
      if (foundClient) {
        newState = {
          ...newState,
          client_values: foundClient.values,
          client_vision: foundClient.vision,
        };
      }
      const posts = foundClient.posts;
      const currentPostId = props.match.params.postId;
      const foundPost = posts.find(post => post.id === currentPostId);

      if (foundPost) {
        newState = { ...newState, ...stateWithPost(foundPost) };
      }
    }
    if (props.clients && props.match.params.clientId) {
      const currentClientId = props.match.params.clientId;
      const foundClient = props.clients.find(client => client.id === currentClientId);
      if (foundClient) {
        newState = {
          ...newState,
          client_values: foundClient.values,
          client_vision: foundClient.vision,
        };
      }
      const posts = foundClient.posts;
      const depts = posts.map(post => post.department_name);
      newState = {
        ...newState,
        deptOptions: depts.map(dept =>
          (
            {
              value: dept,
              label: dept,
            }
          ),
        ),
      };
      const diplomas = posts.map(post => post.diploma);
      newState = {
        ...newState,
        diplomaOptions: diplomas.map(diploma =>
          (
            {
              value: diploma,
              label: diploma,
            }
          ),
        ),
      };
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    if (this.state.name) {
      ApiService.getSkills(this.state.name.value.substring(0, 5)).then((data) => {
        this.setState({ skillsDatas: data });
      });
      ApiService.getSoftSkills(this.state.name.value.substring(0, 5)).then((data) => {
        this.setState({ softskillsDatas: data });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== INITIAL_STATE && this.state.name) {
      if (!this.state.skillsDatas) {
        ApiService.getSkills(this.state.name.value.substring(0, 5)).then((data) => {
          this.setState({ skillsDatas: data });
        });
      }
      if (!this.state.softskillsDatas) {
        ApiService.getSoftSkills(this.state.name.value.substring(0, 5)).then((data) => {
          this.setState({ softskillsDatas: data });
        });
      }
    }
  }

  updateFormState = (formState) => {
    // Date
    formState.induction_date = StringService.dateStringToTimestamp(formState.induction_date);
    // Change Type
    formState.team_size = parseInt(formState.team_size, 10);
    formState.initiative_level = parseInt(formState.initiative_level, 10);
    formState.autonomy_level = parseInt(formState.autonomy_level, 10);
    formState.experience_years = parseInt(formState.experience_years, 10);
    formState.ca_commission_percentage = formState.ca_commission_percentage !== ''
      ? parseFloat(formState.ca_commission_percentage)
      : 0;
    formState.brut_commission_percentage = formState.brut_commission_percentage !== ''
      ? parseFloat(formState.brut_commission_percentage)
      : 0;
    formState.net_commission_percentage = formState.net_commission_percentage !== ''
      ? parseFloat(formState.net_commission_percentage)
      : 0;

    formState.name = formState.name
      ? formState.name.value
      : '';

    formState.department_name = formState.department_name.value;
    formState.diploma = formState.diploma.value;

    formState.softskills = formState.softskills.map(softskill =>
      (
        { summary: softskill.value }
      ),
    );
    formState.skills = formState.skills.map(skill =>
      (
        { code_ogr: skill.value }
      ),
    );

    const selectedClient = this.props.clients.find(client => client.id
      === this.props.match.params.clientId);
    formState.client_id = selectedClient.id;

    return formState;
  };

  onSubmit = (event, formState) => {
    event.preventDefault();
    const newFormState = this.updateFormState(formState);
    this.props.authUser.getIdToken().then(token => {
      const role = this.props.roles[this.props.authUser.uid];
      if (this.state.id === INITIAL_STATE.id) {
        this.props.postClientPost(
          token,
          role,
          newFormState,
        ).then((postId) => {
          if (postId) {
            this.props.dispatchInfo('Création du besoin OK');
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      } else {
        this.props.putClientPost(
          token,
          role,
          this.state.id,
          newFormState,
        ).then((postId) => {
          if (postId) {
            this.props.dispatchInfo('Mise à jour du besoin OK');
          }
        }).catch((error) => {
          this.props.handleError(error);
        });
      }
    });
  };

  onBack = () => {
    this.props.history.push(CLIENT_VIEW.replace(':clientId', this.props.match.params.clientId));
  };

  onChangeObservable = (changedState) => {
    if (changedState.name) {
      ApiService.getSkills(changedState.name.value.substring(0, 5)).then((data) => {
        this.setState({ skillsDatas: data });
      });
      ApiService.getSoftSkills(changedState.name.value.substring(0, 5)).then((data) => {
        this.setState({ softskillsDatas: data });
      });
    }
  };

  render() {

    const {
      jobs,
      pending,
      sending,
      classes,
    } = this.props;

    const {
      id,
      skillsDatas,
      softskillsDatas,
      deptOptions,
      diplomaOptions,
    } = this.state;

    let jobsOptions = {};
    if (jobs) {
      jobsOptions = jobs.map(job =>
        (
          {
            value: job.code_rome + ' - ' + job.libelle_rome,
            label: job.code_rome + ' - ' + job.libelle_rome,
          }
        ),
      );
    }
    let skillsOptions = [];
    if (skillsDatas) {
      skillsOptions = skillsDatas.map(skill =>
        (
          {
            value: skill.code_ogr,
            label: skill.libelle_competence,
          }
        ),
      );
    }
    let softskillsOptions = [];
    if (softskillsDatas) {
      softskillsOptions = softskillsDatas.map(softskill =>
        (
          {
            value: softskill.summary,
            label: softskill.summary,
          }
        ),
      );
    }

    let sliderMarks = [
      {
        value: 1,
        label: '1',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 10,
        label: '10',
      },
    ];

    const buttonLabel = sending
      ?
      (
        <Spinner variant="nomargin" />
      )
      : id === ''
        ? 'Créer'
        : 'Éditer';

    const load = (
      <Spinner />
    );

    const postFormFields = [
      {
        id: 'extendor_poste',
        type: 'extendor',
        gridMD: 12,
        label: 'Poste',
        noPadding: true,
      },
      {
        id: 'client_post_name',
        type: 'textField',
        label: 'Intitulé chez le client',
        dependances: ['extendor_poste'],
        isMandatory: true,
      },
      {
        id: 'status',
        type: 'selectField',
        options: postStatus,
        label: 'Statut',
        dependances: ['extendor_poste'],
        isMandatory: false,
      },
      {
        id: 'status_comment',
        type: 'textField',
        label: 'Autre statut',
        dependances: ['extendor_poste'],
        isMandatory: false,
      },
      {
        id: 'name',
        type: 'selectField',
        options: jobsOptions,
        autocomplete: true,
        label: 'Intitulé normé',
        dependances: ['extendor_poste'],
        isMandatory: true,
        observable: true,
      },
      {
        id: 'skills',
        type: 'selectField',
        options: skillsOptions,
        autocomplete: true,
        noOptions: 'Choisir d\'abord un métier (Intitulé)',
        multiple: true,
        label: 'Savoir-faire',
        dependances: ['extendor_poste'],
        isMandatory: true,
      },
      {
        id: 'softskills',
        type: 'selectField',
        options: softskillsOptions,
        autocomplete: true,
        noOptions: 'Choisir d\'abord un métier (Intitulé)',
        multiple: true,
        label: 'Savoir-être',
        dependances: ['extendor_poste'],
        isMandatory: true,
      },
    ];

    const contractFormFields = [
      {
        id: 'extendor_contract',
        type: 'extendor',
        gridMD: 12,
        label: 'Contrat',
        noPadding: true,
      },
      {
        id: 'contract',
        type: 'selectField',
        options: contracts,
        label: 'Type de contrat',
        dependances: ['extendor_contract'],
        isMandatory: true,
      },
      {
        id: 'contract_comment',
        type: 'textField',
        label: 'Commentaires liés au Type de Contrat',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
      {
        id: 'induction_date',
        type: 'textField',
        subtype: 'date',
        label: 'Date d\'intégration souhaitée',
        dependances: ['extendor_contract'],
        isMandatory: true,
      },
      {
        id: 'reason',
        type: 'richText',
        gridMD: 12,
        label: 'Motif / Contexte du recrutement',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
      {
        id: 'is_intern_possible',
        type: 'checkboxNormal',
        gridMD: 6,
        label: 'Poste ou promotion interne',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
      {
        id: 'has_transition_advisor',
        type: 'checkboxNormal',
        gridMD: 6,
        label: 'Consultant de transition',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
      {
        id: 'intern_info',
        type: 'richText',
        gridMD: 6,
        label: 'Commentaire',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
      {
        id: 'transition_info',
        type: 'richText',
        gridMD: 6,
        label: 'Précisions',
        dependances: ['extendor_contract'],
        isMandatory: false,
      },
    ];

    const hierarchyFormFields = [
      {
        id: 'extendor_hierarchy',
        type: 'extendor',
        gridMD: 12,
        label: 'Rattachement hiérarchique',
        noPadding: true,
      },
      {
        id: 'n_plus_one_lastname',
        type: 'textField',
        label: 'Nom N+1',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
      {
        id: 'n_plus_one_firstname',
        type: 'textField',
        label: 'Prénom N+1',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
      {
        id: 'n_plus_one_position',
        type: 'textField',
        label: 'Fonction N+1',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
      {
        id: 'n_plus_two_lastname',
        type: 'textField',
        label: 'Nom N+2',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
      {
        id: 'n_plus_two_firstname',
        type: 'textField',
        label: 'Prénom N+2',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
      {
        id: 'n_plus_two_position',
        type: 'textField',
        label: 'Fonction N+2',
        dependances: ['extendor_hierarchy'],
        isMandatory: false,
      },
    ];

    const teamFormFields = [
      {
        id: 'extendor_team',
        type: 'extendor',
        gridMD: 12,
        label: 'Equipe et conditions et travail',
        noPadding: true,
      },
      {
        id: 'department_name',
        type: 'selectField',
        options: deptOptions,
        autocomplete: true,
        noOptions: 'Rentrer au moins 3 caractères pour avoir des suggestions',
        minLength: 2,
        creatable: true,
        label: 'Département de rattachement',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'team_size',
        type: 'textField',
        subtype: 'number',
        label: 'Effectif',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'team_interactions',
        type: 'textField',
        label: 'Interraction autres services',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'people_to_manage',
        type: 'richText',
        gridMD: 6,
        label: 'Management hiérarchique',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'people_to_operate',
        type: 'richText',
        gridMD: 6,
        label: 'Management fonctionnel',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'work_place',
        type: 'textField',
        gridMD: 6,
        label: 'Lieu de travail',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'schedule',
        type: 'textField',
        gridMD: 6,
        label: 'Horaires de travail',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'training_intern',
        type: 'textField',
        gridMD: 6,
        label: 'Formation interne',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'training_extern',
        type: 'textField',
        gridMD: 6,
        label: 'Formation externe',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'induction_period',
        type: 'richText',
        gridMD: 12,
        label: 'Parcours d\'intégration',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'mobility_wanted',
        type: 'checkboxNormal',
        gridMD: 12,
        label: 'Mobilité souhaitée',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
      {
        id: 'mobility_info',
        type: 'richText',
        gridMD: 12,
        label: 'Commentaires',
        dependances: ['extendor_team'],
        isMandatory: false,
      },
    ];

    const internFormFields = [
      {
        id: 'extendor_intern',
        type: 'extendor',
        gridMD: 12,
        label: 'Ressources internes pour aider à la prise de poste',
        noPadding: true,
      },
      {
        id: 'advisors',
        type: 'textField',
        label: 'Tuteurs',
        dependances: ['extendor_intern'],
        isMandatory: false,
      },
      {
        id: 'referents',
        type: 'textField',
        label: 'Référents',
        dependances: ['extendor_intern'],
        isMandatory: false,
      },
      {
        id: 'reference_department',
        type: 'textField',
        label: 'Département en point d\'appui',
        dependances: ['extendor_intern'],
        isMandatory: false,
      },
    ];

    const contextFormFields = [
      {
        id: 'extendor_contexte',
        type: 'extendor',
        gridMD: 12,
        label: 'Contexte du poste',
        noPadding: true,
      },
      {
        id: 'initiative_level',
        type: 'sliderField',
        gridMD: 6,
        marks: sliderMarks,
        step: 1,
        min: 1,
        max: 10,
        label: 'Niveau d\'initiative',
        dependances: ['extendor_contexte'],
        isMandatory: false,
      },
      {
        id: 'autonomy_level',
        type: 'sliderField',
        gridMD: 6,
        marks: sliderMarks,
        step: 1,
        min: 1,
        max: 10,
        label: 'Niveau d\'autonomie',
        dependances: ['extendor_contexte'],
        isMandatory: false,
      },
    ];

    const missionFormFields = [
      {
        id: 'extendor_mission',
        type: 'extendor',
        gridMD: 12,
        label: 'Missions',
        noPadding: true,
      },
      {
        id: 'main_tasks',
        type: 'richText',
        gridMD: 6,
        label: 'Missions principales',
        dependances: ['extendor_mission'],
        isMandatory: false,
      },
      {
        id: 'lesser_tasks',
        type: 'richText',
        gridMD: 6,
        label: 'Missions secondaires',
        dependances: ['extendor_mission'],
        isMandatory: false,
      },
    ];

    const levelFormFields = [
      {
        id: 'extendor_level',
        type: 'extendor',
        gridMD: 12,
        label: 'Niveau de qualifications attendu',
        noPadding: true,
      },
      {
        id: 'schooling_level',
        type: 'selectField',
        options: schoolingLevels,
        label: 'Niveau d\'étude',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'diploma',
        type: 'selectField',
        options: diplomaOptions,
        autocomplete: true,
        noOptions: 'Rentrer au moins 3 caractères pour avoir des suggestions',
        minLength: 2,
        creatable: true,
        label: 'Diplome',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'training',
        type: 'textField',
        label: 'Formation continue',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'authorization',
        type: 'textField',
        label: 'Habilitation',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'level',
        type: 'selectField',
        options: levels,
        label: 'Qualification',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'main_sector',
        type: 'textField',
        label: 'Secteur de référence',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'experiences',
        type: 'textField',
        label: 'Fonctions de référence',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'experience_years',
        type: 'textField',
        subtype: 'number',
        label: 'Années d\'expérience',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'openness',
        type: 'textField',
        label: 'Degré d\'ouverture à d\'autres formations',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
      {
        id: 'level_comments',
        type: 'richText',
        gridMD: 12,
        label: 'Commentaires',
        dependances: ['extendor_level'],
        isMandatory: false,
      },
    ];

    const languageFormFields = [
      {
        id: 'extendor_language',
        type: 'extendor',
        gridMD: 12,
        label: 'Langues',
        noPadding: true,
      },
      {
        id: 'language1',
        type: 'textField',
        label: 'Langue 1',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
      {
        id: 'language2',
        type: 'textField',
        label: 'Langue 2',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
      {
        id: 'language3',
        type: 'textField',
        label: 'Langue 3',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
      {
        id: 'language_level1',
        type: 'selectField',
        options: languageLevels,
        label: 'Niveau',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
      {
        id: 'language_level2',
        type: 'selectField',
        options: languageLevels,
        label: 'Niveau',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
      {
        id: 'language_level3',
        type: 'selectField',
        options: languageLevels,
        label: 'Niveau',
        dependances: ['extendor_language'],
        isMandatory: false,
      },
    ];

    const salaryFormFields = [
      {
        id: 'extendor_salary',
        type: 'extendor',
        gridMD: 12,
        label: 'Rémunération',
        noPadding: true,
      },
      {
        id: 'salary12_month',
        type: 'checkboxNormal',
        label: 'Rémunération fixe sur 12 mois',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'salary13_month',
        type: 'checkboxNormal',
        label: 'Rémunération fixe sur 13 mois',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'salary14_month',
        type: 'checkboxNormal',
        label: 'Rémunération fixe sur 14 mois',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'salary',
        type: 'checkboxNormal',
        label: 'Rémunération fixe',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'salary_info',
        type: 'richText',
        gridMD: 8,
        label: 'Commentaires',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'variable_pay',
        type: 'checkboxNormal',
        label: 'Variable',
        dependances: ['extendor_salary'],
        isMandatory: false,
        tooltip: 'Le variable individuel constitue un complément de rémunération, imposé et soumis aux charges sociales selon les règles de droit commun',
      },
      {
        id: 'variable_pay_info',
        type: 'richText',
        gridMD: 8,
        label: 'Commentaires',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'annual_bonus',
        type: 'checkboxNormal',
        label: 'Primes sur objectifs annuels',
        dependances: ['extendor_salary'],
        isMandatory: false,
        tooltip: 'Le versement de cette prime est subordonné à la réalisation de certains objectifs, devant être fixés avec un minimum de précision. En général , un seuil de déclenchement est prévu, situé en deca de l’objectif. Lorsque celui-ci est dépassé, le montant de la prime peut augmenter, de manière linéaire ou progressive',
      },
      {
        id: 'annual_bonus_by_quarter',
        type: 'checkboxNormal',
        label: 'Primes sur objectifs annuels/trimestre',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'ranking_bonus',
        type: 'checkboxNormal',
        label: 'Prime de classement',
        dependances: ['extendor_salary'],
        isMandatory: false,
        tooltip: 'Le montant versé dépend du classement du salarié, déterminé en fonction de critères précis et objectifs (chiffre d’affaires réalisé). Pour l’entreprise, le coût total d’un tel dispositif est fixe, puisqu’une somme déterminée à l’avance et allouée à chaque place du classement. Toutefois, des modulations peuvent intervenir ; ainsi par exemple, un coefficient multiplicateur peut être prévu lorsqu’un certain seuil de chiffre d’affaires est atteint',
      },
      {
        id: 'ca_commission',
        type: 'checkboxNormal',
        label: 'Commissions sur CA',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'ca_commission_percentage',
        type: 'textField',
        subtype: 'number',
        label: '%',
        dependances: ['extendor_salary'],
        isMandatory: false,
        InputProps: {
          step: 0.1,
          min: 0.0,
          max: 100.0
        }
      },
      {
        id: 'ca_commission_info',
        type: 'richText',
        gridMD: 4,
        label: 'Commentaire',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'brut_commission',
        type: 'checkboxNormal',
        label: 'Commissions sur marge brute',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'brut_commission_percentage',
        type: 'textField',
        subtype: 'number',
        label: '%',
        dependances: ['extendor_salary'],
        isMandatory: false,
        InputProps: {
          step: 0.1,
          min: 0.0,
          max: 100.0
        }
      },
      {
        id: 'brut_commission_info',
        type: 'richText',
        gridMD: 4,
        label: 'Commentaire',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'net_commission',
        type: 'checkboxNormal',
        label: 'Commissions sur Résultat Net',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'net_commission_percentage',
        type: 'textField',
        subtype: 'number',
        label: '%',
        dependances: ['extendor_salary'],
        isMandatory: false,
        InputProps: {
          step: 0.1,
          min: 0.0,
          max: 100.0
        }
      },
      {
        id: 'net_commission_info',
        type: 'richText',
        gridMD: 4,
        label: 'Commentaire',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'stake',
        type: 'checkboxNormal',
        label: 'Participation',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'profit_sharing',
        type: 'checkboxNormal',
        label: 'Intéressement',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'company_vehicle',
        type: 'checkboxNormal',
        label: 'Véhicule de fonction',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
      {
        id: 'other_benefits',
        type: 'richText',
        gridMD: 12,
        label: 'Autres',
        dependances: ['extendor_salary'],
        isMandatory: false,
      },
    ];

    const clientFormFields = [
      {
        id: 'extendor_client',
        type: 'extendor',
        gridMD: 12,
        label: 'Informations Client',
        noPadding: true,
      },
      {
        id: 'client_values',
        type: 'richText',
        gridMD: 6,
        label: 'Valeurs',
        dependances: ['extendor_client'],
        isMandatory: false,
      },
      {
        id: 'client_vision',
        type: 'richText',
        gridMD: 6,
        label: 'Description',
        dependances: ['extendor_client'],
        isMandatory: false,
      },
    ];

    const otherFormFields = [
      {
        id: 'extendor_other',
        type: 'extendor',
        gridMD: 12,
        label: 'Autres informations',
        noPadding: true,
      },
      {
        id: 'competition',
        type: 'checkboxNormal',
        label: 'Mise en concurrence sur le besoin',
        dependances: ['extendor_other'],
        isMandatory: false,
      },
      {
        id: 'competition_info',
        type: 'richText',
        gridMD: 6,
        label: 'Information concurrence',
        dependances: ['extendor_other'],
        isMandatory: false,
      },
      {
        id: 'comments',
        type: 'richText',
        gridMD: 12,
        label: 'Autres commentaires',
        dependances: ['extendor_other'],
        isMandatory: false,
      },
    ];

    const formFields = [
      {
        id: 'post_subform',
        type: 'subform',
        options: postFormFields,
      },
      {
        id: 'contract_subform',
        type: 'subform',
        options: contractFormFields,
      },
      {
        id: 'hierarchy_subform',
        type: 'subform',
        options: hierarchyFormFields,
      },
      {
        id: 'team_subform',
        type: 'subform',
        options: teamFormFields,
      },
      {
        id: 'intern_subform',
        type: 'subform',
        options: internFormFields,
      },
      {
        id: 'context_subform',
        type: 'subform',
        options: contextFormFields,
      },
      {
        id: 'mission_subform',
        type: 'subform',
        options: missionFormFields,
      },
      {
        id: 'level_subform',
        type: 'subform',
        options: levelFormFields,
      },
      {
        id: 'language_subform',
        type: 'subform',
        options: languageFormFields,
      },
      {
        id: 'msalary_subform',
        type: 'subform',
        options: salaryFormFields,
      },
      {
        id: 'client_subform',
        type: 'subform',
        options: clientFormFields,
      },
      {
        id: 'other_subform',
        type: 'subform',
        options: otherFormFields,
      },
    ];

    const customForm = (
      <div>
        <Fab
          className={classes.shareLinkedInButton}
          color="primary"
          onClick={this.onBack}
        >
          <BackIcon />
        </Fab>
        <CustomForm
          data={this.state}
          formFields={formFields}
          buttonLabel={buttonLabel}
          onSubmit={this.onSubmit}
          onChangeObservable={this.onChangeObservable}
          sending={sending}
        />
      </div>
    );

    if (pending) {
      return (
        load
      );
    } else {
      return (
        customForm
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    pending: state.clients.pending || state.statics.pending,
    sending: state.clients.sending,
    jobs: state.statics.jobs,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getUsers,
    getClients,
    postClientPost,
    putClientPost,
    getStaticJobs,
    handleError,
    dispatchInfo,
  }),
)(ClientPostForm);
