import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CANDIDATURES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CANDIDATURE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.CANDIDATURES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CANDIDATURES_UNLOAD: {
      return INITIAL_STATE;
    }
    case types.CANDIDATURES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case types.CANDIDATURE_DOWNLOADED: {
      const candidatureDownloaded = action.payload;
      let candidatures = [];
      let candidatureIndex = -1;
      if (state.content) {
        candidatures = state.content.slice();
        candidatureIndex = candidatures.findIndex(candidature => candidature.id === candidatureDownloaded.id);
      }
      if (candidatureIndex === -1) {
        candidatures.push(candidatureDownloaded);
      } else {
        candidatures[candidatureIndex] = candidatureDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: candidatures,
      };
    }
    default:
      break;
  }
  return state;
}
