import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { getUsers } from '../../stores/users/actions';
import { OFFER_ADD, OFFER_CANDIDATURES, OFFER_EDIT } from '../../constants/routes';
import { deleteOffer, getOffers, putOffer } from '../../stores/offers/actions';
import ApiService from '../../services/ApiService';
import OfferPreviewDialog from './OfferPreviewDialog';

const INITIAL_STATE = {
  datas: [],
  dialogOpen: false,
  selectedOffer: null,
  uid: '',
  accessToken: '',
  linkedInId: '',
  expiresAt: 0,
  anchorStatusMenu: null,
  idForStatusMenu: null,
  statusMessageDialogOpen: false,
  tempOfferStatus: null,
  statusMessage: '',
};

const stateWithUser = (user) => (
  {
    uid: user.uid,
    accessToken: user.accessToken,
    linkedInId: user.linkedInId,
    expiresAt: user.expiresAt,
  }
);

const statusEnum = {
  'draft': 'Brouillon',
  'in_progress': 'En cours',
  'provided': 'Pourvu',
  'cancel': 'Annulé',
  'not_provided': 'Non pourvu',
};

export class OffersPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getOffers: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.offers && !props.pending && !props.error) {
      props.authUser.getIdToken(true).then(token => {
        props.getOffers(token);
      });
    }
    if (props.offers) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.offers,
        };
      }
      if (props.offers !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.offers,
        };
      }
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.users && props.authUser && state.uid === INITIAL_STATE.uid) {
      const users = props.users;
      const currentUserId = props.authUser.uid;
      const foundUser = users.find(user => user.uid === currentUserId);
      if (foundUser) {
        newState = { ...newState, ...stateWithUser(foundUser) };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(OFFER_ADD);
  };

  handleEdit = element => {
    this.props.history.push(OFFER_EDIT.replace(':offerId', element.id));
  };

  handleList = element => {
    this.props.history.push(OFFER_CANDIDATURES.replace(':offerId', element.id));
  };

  handleSelect = element => {
    this.setState({
      dialogOpen: true,
      selectedOffer: element,
      selectedOfferContent: element.description,
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  stripHtml = (html) => {
    // Create a new div element
    var temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html.replace(/<\/p><p>/g, '\r').replace(/<\/p><p><br>/g, '\n\r');
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  };

  shareLinkedIn = () => {
    if (this.state.accessToken && this.state.linkedInId
      && this.state.expiresAt > Math.floor(Date.now() / 1000)) {

      this.props.authUser.getIdToken().then(token => {
        ApiService.shareOfferLinkedIn(
          this.state.accessToken,
          this.state.linkedInId,
          this.stripHtml(this.state.selectedOfferContent),
          token,
        ).then(() => {
          this.props.dispatchInfo('Partage LinkedIn terminé');
        }).catch((error) => {
          this.props.handleError(error);
        });
      });
    } else {
      ApiService.getLinkedInAuthorization();
    }
  };

  handleDelete = element => {

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.deleteOffer(
        token,
        element.id,
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleMenuStatusClose = () => {
    this.setState({
      anchorStatusMenu: null,
      idForStatusMenu: null,
    });
  };

  handleStatusMessageClose = () => {
    this.setState({
      statusMessageDialogOpen: false,
      anchorStatusMenu: null,
      idForStatusMenu: null,
    });
  };

  handleStatusMessageClick = () => {
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putOffer(
        token,
        this.state.idForStatusMenu,
        {
          status: this.state.tempOfferStatus,
          status_message: this.state.statusMessage,
        },
      );
    })
      .then(() => {
        this.setState({
          tempOfferStatus: null,
          idForStatusMenu: null,
          statusMessageDialogOpen: false,
          statusMessage: null,
        });
      }).catch((error) => {
      this.props.handleError(error);
    });
  };

  handleMenuStatusItemClick = (newStatus) => {

    if (['cancel', 'not_provided'].includes(newStatus)) {
      this.setState({
        anchorStatusMenu: null,
        tempOfferStatus: newStatus,
        statusMessageDialogOpen: true,
      });
    } else {
      this.setState({ tempOfferStatus: newStatus });
      this.props.authUser.getIdToken(true).then(token => {
        return this.props.putOffer(
          token,
          this.state.idForStatusMenu,
          { status: newStatus },
        );
      })
        .then(() => {
          this.setState({
            tempOfferStatus: null,
            anchorStatusMenu: null,
            idForStatusMenu: null,
          });
        }).catch((error) => {
        this.props.handleError(error);
      });
    }
  };

  render() {

    const handleClickStatusMenu = (event, id) => {
      this.setState({
        anchorStatusMenu: event.currentTarget,
        idForStatusMenu: id,
      });
      event.stopPropagation();
      event.preventDefault();
    };

    const columnData = [
      {
        id: 'current_post.client_post_name',
        description: true,
        label: 'Poste',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'original_post.client_business_name',
        description: true,
        label: 'Client',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'original_post.client_zip_code',
        description: true,
        label: 'Code postal',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'updated_at',
        date: true,
        label: 'Date de modification',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'salary_min',
        label: 'Rému. min (K€)',
        numeric: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'original_post.client_referent_initials',
        label: 'Consultant',
        numeric: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'nb_candidatures',
        label: 'Nb de candidats',
        numeric: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'status',
        label: 'Statut',
        align: 'center',
        disablePadding: false,
        enum: true,
        data: statusEnum,
        callback: handleClickStatusMenu,
      },
    ];
    return (
      <>
        <div>
          {this.props.pending && <Spinner />}
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              buttonLabel="Ajouter une offre"
              paginationLabel="Offres par page"
              emptyDataMessage="Aucune offre"
              columnData={columnData}
              sending={this.props.sending}
              onAdd={this.handleAdd}
              onClickRow={this.handleSelect}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              onList={this.handleList}
              defaultOrderBy={'updated_at'}
              defaultOrder={'desc'}
            />
          )}
          <OfferPreviewDialog
            open={this.state.dialogOpen}
            handleClose={this.handleClose}
            content={this.state.selectedOfferContent}
          />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorStatusMenu}
          keepMounted
          open={Boolean(this.state.anchorStatusMenu)}
          onClose={this.handleMenuStatusClose}
        >
          {Object.keys(statusEnum).map((key) => {
            return (
              <MenuItem key={key} onClick={() => this.handleMenuStatusItemClick(key)}
                        disabled={this.props.sending}>
                {(
                  !this.props.sending || this.state.tempOfferStatus !== key
                ) && statusEnum[key]}
                {this.props.sending && this.state.tempOfferStatus === key &&
                <Spinner variant={'nomargin'} />}
              </MenuItem>
            );
          })}
        </Menu>
        <Dialog open={this.state.statusMessageDialogOpen} onClose={this.handleStatusMessageClose}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Changement de statut</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veillez indiquez la raison du changement de statut :
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="offer_status_message"
              label="Raison"
              type="text"
              value={this.state.statusMessage}
              onChange={(event) => this.setState({ statusMessage: event.target.value })}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleStatusMessageClose} color="primary"
                    disabled={this.props.sending}>
              Annuler
            </Button>
            <Button onClick={this.handleStatusMessageClick} color="primary"
                    disabled={this.props.sending}>
              {!this.props.sending && 'Envoyer'}
              {this.props.sending && <Spinner variant={'nomargin'} />}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    offers: state.offers.content,
    pending: state.offers.pending || state.users.pending,
    sending: state.offers.sending,
    error: state.offers.error,
  };
}

export default compose(
  withTitle({
    title: 'Offres',
    subtitle: 'Gestion des offres',
  }),
  connect(mapStateToProps,
    {
      getOffers,
      putOffer,
      deleteOffer,
      getUsers,
      handleError,
      dispatchInfo,
    },
  ),
)(OffersPage);
