import * as firebase from 'firebase';
import { ENV_DEMO, ENV_DEV, ENV_LOCAL, ENV_PREPROD } from '../../constants/env';

const prodConfig = {
  apiKey: "AIzaSyBUqpaVEhN_2G3gP2v9sTm8g3O-C5hi898",
  authDomain: "adconseilsprod.firebaseapp.com",
  databaseURL: "https://adconseilsprod.firebaseio.com",
  projectId: "adconseilsprod",
  storageBucket: "adconseilsprod.appspot.com",
  messagingSenderId: "TODO",
  serverKey:"AAAACjsGHy8:APA91bFeEC3iqDuEmDanndCJnr_Fukuz0v0AwMPjHK5So0Kkl665PT5BCSdB1_FKaVQvfFl0_hZxc4Bgt2YqTF7UAt4nyb_-nLg31ngwjdPnbcWjEQvMHNFqCNw4-VNLNmGA6hOwjfXI"
};

const preprodConfig = {
  apiKey: "AIzaSyCPOTQHdCqYAmN1TceeE2VgA0P7RmZD3vY",
  authDomain: "adconseilspreprod-34c61.firebaseapp.com",
  databaseURL: "https://adconseilspreprod-34c61.firebaseio.com",
  projectId: "adconseilspreprod-34c61",
  storageBucket: "adconseilspreprod-34c61.appspot.com",
  messagingSenderId: "???",
  serverKey:"AAAAJYXeeQI:APA91bEpLYPnCBB-NqoCMPLolZv2YcY36HyFSMmegUZtNdzS5Mm6r3i_I63gzw0PSe-uCE3yEzN5ryMTbUxL1pKTyOA3i3_cfTy-kzJvGO_dCs7wtvFUaOfLlGWJ4R19crNtS_9uNnJf"
};

const devConfig = {
  apiKey: "AIzaSyAikjtVy3Xx8Vf26VhSVN7kgdyEVLGjnmc",
  authDomain: "adconseilsdev.firebaseapp.com",
  databaseURL: "https://adconseilsdev.firebaseio.com",
  projectId: "adconseilsdev",
  storageBucket: "adconseilsdev.appspot.com",
  messagingSenderId: "???",
  serverKey:"AAAAx0d86Ec:APA91bHRer0MPOHQGNEZnuNeV9y6vEESS6aloenIT0C6Bs_l4vAyANw0mBNFgpEId08lNt4ZYMt5Y-bQ7PBA5h9PL78fUTsy3xMZqS2Hst9eSZNop5sU7gq-7BlWxzzzM3sV9WkZQzt4"
};

const demoConfig = {
  apiKey: "AIzaSyAgYqhiPuqsDnSPRG3a_b-4P5MTLvQoWm8",
  authDomain: "adconseilsdemo.firebaseapp.com",
  projectId: "adconseilsdemo",
  storageBucket: "adconseilsdemo.appspot.com",
  messagingSenderId: "563217091440",
  appId: "1:563217091440:web:7025f0d31621afc32cddb5",
  databaseURL: "https://adconseilsdemo.firebaseio.com",
  serverKey:" AAAAgyJay3A:APA91bEbHb7yi-wvfLHNmZi9LOsYadsS0N1bS9bM77i_fHA6FTADwEdvJaIxFDlLYnw7MC7crRVCyENj6AQwSmi7IHMHyQD3Gqi0881ys2FdWGsX7DCttgk9-3YuefiHN-OUe9o0ZDol"
};

let config;
if (process.env.REACT_APP_ENV === ENV_DEV || process.env.REACT_APP_ENV === ENV_LOCAL) {
  config = devConfig
} else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
  config = preprodConfig
} else if (process.env.REACT_APP_ENV === ENV_DEMO) {
  config = demoConfig
} else {
  config = prodConfig
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();
const auth = firebase.auth();

export {
  auth,
  db,
  devConfig,
  config
};
