import React from 'react';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography/Typography';
import { HOMEPAGE, NOT_FOUND } from '../constants/routes';
import { useHistory, useLocation } from 'react-router';
import AnonymousLayout from './layout/AnonymousLayout';

export const ErrorPage = (props) => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname === '/') {
    history.replace(HOMEPAGE);
  } else if (location.pathname !== NOT_FOUND) {
    history.push(NOT_FOUND);
  }

  return (
    <AnonymousLayout>
      <>
        <Typography
          variant="h2"
          gutterBottom
        >
          Une erreur est survenue
        </Typography>
        {props.error & (
          <>
            <br />
            <Typography
              variant="subtitle"
              gutterBottom
            >
              {props.error}
            </Typography>
            <br />
          </>
        )}
        <br />
        <Fab
          component={Link}
          to={HOMEPAGE}
          variant="extended"
          color="primary"
        >
          Retour à l'accueil
        </Fab>
      </>
    </AnonymousLayout>
  );
};

export default ErrorPage;
