import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { getVariationTheme } from '../../themes/theme';
import { STEPS } from '../../constants/interviewSteps';
import { waitingOrAcceptedCandidature } from '../../services/candidateService';

const colorTheme = getVariationTheme();

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
};

const ValidateDialog = props => {
  // Constant
  const {
    openValidateModal,
    handleToggleValidateModal,
    candidature,
    step,
    handleStepPhone,
    handleStepVisio,
    handleStepConsult,
    handleStepClient,
    comment,
    handleFirstSlot,
    handleSecondSlot,
    handleThirdSlot,
    handleValidate,
    handleMessage,
  } = props;

  return (
    <Dialog
      fullScreen={false}
      open={openValidateModal}
      onClose={handleToggleValidateModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle
        id="responsive-dialog-title"
        onClose={handleToggleValidateModal}
      >
        Valider la candidature
      </DialogTitle>
      <DialogContent>
        {candidature && candidature.state < STEPS.CANDIDATE_ACCEPTED.value &&
          <>
            <Typography variant="subtitle1" style={{ color: colorTheme.menu }}>
              Quelle est la prochaine étape ?
            </Typography>
            <Grid container spacing={4} style={{ minWidth: 800 }}>
              <Grid item xs={4} sm={4} md={4}>
                <List component="nav" aria-label="secondary mailbox folders">
                  <ListItem
                    disabled={candidature && candidature.state >= STEPS.PHONE_WAITING.value}
                    selected={step === STEPS.PHONE_WAITING_SLOTS.value}
                    onClick={handleStepPhone}
                    button
                  >
                    <ListItemText primary="Entretien téléphone" />
                  </ListItem>
                  <ListItem
                    disabled={candidature && candidature.state >= STEPS.VISIO_WAITING.value}
                    selected={step === STEPS.VISIO_WAITING_SLOTS.value}
                    onClick={handleStepVisio}
                    button
                  >
                    <ListItemText primary="Entretien visio" />
                  </ListItem>
                  <ListItem
                    disabled={candidature && candidature.state >= STEPS.CONSULT_WAITING.value}
                    selected={step === STEPS.CONSULT_WAITING_SLOTS.value}
                    button
                    onClick={handleStepConsult}
                  >
                    <ListItemText primary="Entretien face à face recruteur" />
                  </ListItem>
                  <ListItem
                    selected={step === STEPS.CLIENT_WAITING_SLOTS.value}
                    button
                    onClick={handleStepClient}
                  >
                    <ListItemText primary="Entretien face à face client" />
                  </ListItem>
                </List>
              </Grid>

              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                direction="column"
                className={props.classes.gridContainer}
              >
                {waitingOrAcceptedCandidature(candidature) &&
                  <TextField
                    id="consult_comment"
                    label="Message au candidat"
                    multiline
                    rows={6}
                    maxRows={6}
                    fullWidth={true}
                    style={{ marginBottom: 20 }}
                    value={comment}
                    margin="normal"
                    variant="outlined"
                    onChange={handleMessage}
                  />
                }
                {candidature && candidature.state < STEPS.CANDIDATE_ACCEPTED.value &&
                  <div>
                    <TextField
                      id="first_slot"
                      className={props.classes.dateField}
                      type={'datetime-local'}
                      margin="normal"
                      variant="outlined"
                      onChange={handleFirstSlot}
                    />
                    <br />
                    <TextField
                      id="second_slot"
                      className={props.classes.dateField}
                      type={'datetime-local'}
                      margin="normal"
                      variant="outlined"
                      onChange={handleSecondSlot}
                    />
                    <br />
                    <TextField
                      id="third_slot"
                      className={props.classes.dateField}
                      type={'datetime-local'}
                      margin="normal"
                      variant="outlined"
                      onChange={handleThirdSlot}
                    />
                  </div>
                }
              </Grid>
            </Grid>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleValidate}
          color="primary"
        >
          {candidature && candidature.state < STEPS.CANDIDATE_ACCEPTED.value
            ? 'Envoyer l\'invitation'
            : 'Accepter la candidature'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ValidateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ValidateDialog);
