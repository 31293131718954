import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import withTitle from '../hocs/withTitle';
import ApiService from '../services/ApiService';
import { handleError } from '../stores/infos/actions';
import { getRoles } from '../stores/roles/actions';
import { getUsers, postUser, putUser } from '../stores/users/actions';
import { OFFERS } from '../constants/routes';

// Intial State of the Component
const INITIAL_STATE = {
  uid: '',
  mail: '',
  phone: '',
  firstname: '',
  lastname: '',
  role: '',
  accessToken: '',
  linkedInId: '',
  expiresAt: 0,
};

const stateWithUser = (user) => (
  {
    uid: user.uid,
    mail: user.mail,
    phone: user.phone,
    firstname: user.firstname,
    lastname: user.lastname,
    role: user.role,
    is_active: user.is_active,
    accessToken: user.accessToken,
    expiresAt: user.expiresAt,
    linkedInId: user.linkedInId,
  }
);

export class LinkedInAuth extends Component {

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.users && props.currentUser && state.uid === INITIAL_STATE.uid) {
      const users = props.users;
      const currentUserId = props.currentUser.uid;
      const foundUser = users.find(user => user.uid === currentUserId);
      if (foundUser) {
        newState = { ...newState, ...stateWithUser(foundUser) };
      }
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  signInLinkedIn = (event) => {
    ApiService.getLinkedInAuthorization();
    event.preventDefault();
  };

  retrieveLinkedInAccessToken = event => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    this.props.currentUser.getIdToken(true).then(token => {
      ApiService.retrieveLinkedInAccessToken(code, token)
        .then(data => {
          if (data && data.access_token && data.expires_in) {
            let accessToken = data.access_token;
            let expiresAt = Math.floor(Date.now() / 1000) + data.expires_in;
            ApiService.getMeLinkedIn(accessToken, token)
              .then(data => {
                if (data && data.id) {
                  this.props.putUser(
                    this.state.uid,
                    {
                      firstname: this.state.firstname,
                      lastname: this.state.lastname,
                      mail: this.state.mail,
                      phone: this.state.phone,
                      is_active: this.state.is_active,
                      accessToken: accessToken,
                      linkedInId: data.id,
                      expiresAt: expiresAt,
                    },
                  ).then(() => {
                    this.props.history.push(OFFERS);
                  });
                }
              });
          }
        });
    });
    event.preventDefault();
  };

  shareOffer = event => {
    ApiService.shareOfferLinkedIn(
      this.state.accessToken,
      this.state.linkedInId,
      'Hello World! This is my first Share on LinkedIn!',
    );
    event.preventDefault();
  };

  // eslint-disable-next-line
  render() {
    if (this.props.location.search) {
      return (
        <Button
          variant="contained"
          onClick={this.retrieveLinkedInAccessToken}
          color="primary"
        >
          Autoriser le partage et revenir aux offres
        </Button>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.users.authUser,
    users: state.users.content,
    pending: state.users.pending,
    sending: state.users.sending,
  };
}

export default compose(
  connect(mapStateToProps,
    {
      getUsers,
      postUser,
      putUser,
      handleError,
      getRoles,
    },
  ),
  withTitle({
    title: 'Autorisation LinkedIn',
    subtitle: 'Accès aux entrées du menu',
  }),
)(LinkedInAuth);
