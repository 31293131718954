import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import Spinner from '../../components/common/Spinner';
import { USER_ADD, USER_EDIT } from '../../constants/routes';
import CustomTable from '../../components/common/table/CustomTable';
import { getUsers, putUser } from '../../stores/users/actions';
import { getRoles } from '../../stores/roles/actions';

const INITIAL_STATE = {
  datas: [],
};

export class UsersPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getUsers: PropTypes.func.isRequired,
    putUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (!props.roles && !props.pending) {
      props.getRoles();
    }
    if (props.users && props.roles) {
      let users = props.users;
      let roles = props.roles;
      users.forEach(user => {
        let role = roles[user.uid];
        user['role'] = role;
        switch (role) {
          case 10:
            user['roleStr'] = 'Administrateur';
            break;
          case 5:
            user['roleStr'] = 'Consultant';
            break;
          default:
            user['roleStr'] = 'Utilisateur';
        }
      });
      users = users.filter(user => user.role > 0);
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: users,
        };
      }
      if (props.users !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: users,
        };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(USER_ADD);
  };

  handleEdit = element => {
    this.props.history.push(USER_EDIT.replace(':userId', element.uid));
  };

  handleSwitchActive = element => {
    this.props.putUser(
      element.uid,
      {
        firstname: element.firstname,
        lastname: element.lastname,
        mail: element.mail,
        is_active: !element.is_active,
      },
    ).catch((error) => {
      this.props.handleError(error);
    });
  };

  render() {

    const columnData = [
      {
        id: 'mail',
        label: 'Mail',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'phone',
        label: 'Phone',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'firstname',
        label: 'Prénom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'lastname',
        label: 'Nom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'roleStr',
        label: 'Role',
        align: 'left',
        disablePadding: false,
      },
    ];
    return (
      <>
        {this.props.pending && <Spinner />}
        {!this.props.pending && (
          <CustomTable
            datas={this.state.datas}
            onAdd={this.handleAdd}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
            buttonLabel="Ajouter un utilisateur"
            paginationLabel="Utilisateur par page"
            emptyDataMessage="Aucun utilisateur"
            columnData={columnData}
            sending={this.props.sending}
            onSwitchActive={this.handleSwitchActive}
            showDelete={false}
            defaultOrderBy={'mail'}
            defaultOrder={'asc'}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.users.authUser,
    roles: state.roles.content,
    users: state.users.content,
    pending: state.users.pending,
    sending: state.users.sending,
    error: state.users.error,
  };
}

export default compose(
  withTitle({ title: 'Utilisateurs' }),
  connect(mapStateToProps,
    {
      getUsers,
      putUser,
      getRoles,
      handleError,
    },
  ),
)(UsersPage);
