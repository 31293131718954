export const organisationFields = [
  {
    id: 'extendor_organisation',
    type: 'extendor',
    gridMD: 12,
    label: 'Organisation',
    noPadding: true,
  },
  {
    id: 'note',
    type: 'richText',
    gridMD: 12,
    label: 'Prise de note',
    dependances: ['extendor_organisation'],
    isMandatory: false,
  },
  {
    id: 'reformulation',
    type: 'richText',
    gridMD: 12,
    label: 'Reformulation',
    dependances: ['extendor_organisation'],
    isMandatory: false,
  },
];
