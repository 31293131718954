import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slider from '@material-ui/core/Slider';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import RemovedModal from './RemovedModal';
import AppDialogTitle from './AppDialogTitle';
const ScoringModal = (props) => {
  const {
    handleToggleScoringModal,
    handleUpdateScoring,
    openScoringModal,
    candidature,
    onChange,
  } = props;

  return (
    <Dialog
      fullScreen={false}
      open={openScoringModal}
      onClose={handleToggleScoringModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
    >
      <AppDialogTitle
        id="responsive-dialog-title"
        onClose={handleToggleScoringModal}
      >
        Score personnalisé
      </AppDialogTitle>
      <DialogContent>
        <Slider
          defaultValue={candidature
            ? candidature.rate
            : 0}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          marks={[1, 5, 10].map(
            (num) => (
              {
                value: num,
                label: num.toString(),
              }
            ))}
          min={0}
          max={10}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleUpdateScoring}
          color="primary"
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoringModal;

RemovedModal.propTypes = {
  handleToggleScoringModal: PropTypes.func.isRequired,
  handleUpdateScoring: PropTypes.func.isRequired,
  openScoringModal: PropTypes.bool.isRequired,
  candidature: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
