import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getPublicValidateOffers } from '../../stores/offers/actions';
import Spinner from '../../components/common/Spinner';
import CandidateForm from '../../components/forms/CandidateForm';
import { NOT_FOUND } from '../../constants/routes';

export const OfferCandidatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { offerId } = useParams();
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.offers);
  const [displayOffer, setDisplayOffer] = useState(null);

  if (!content && !pending && !error) {
    dispatch(getPublicValidateOffers());
  }
  if (content && offerId && !pending) {
    const foundOffer = content.find(offer => offer.id === offerId);
    if (foundOffer && foundOffer.is_validate && displayOffer === null) {
      setDisplayOffer(foundOffer);
    }
    if (!foundOffer || error) {
      history.replace(NOT_FOUND);
    }
  }

  return (
    <div>
      {pending && <Spinner />}
      {!pending && displayOffer && <CandidateForm offer={displayOffer} />}
    </div>
  );

};

function mapStateToProps(state) {
  return {
    offers: state.offers.content,
    pending: state.offers.pending,
    sending: state.offers.sending,
    error: state.offers.error,
  };
}

export default connect(mapStateToProps, { getPublicValidateOffers })(OfferCandidatePage);
