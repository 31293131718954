import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { deleteContact, getContacts, putContact } from '../../stores/contacts/actions';
import { getUsers } from '../../stores/users/actions';
import { CONTACT_ADD, CONTACT_EDIT } from '../../constants/routes';
import { getClients } from '../../stores/clients/actions';

const INITIAL_STATE = {
  datas: [],
};

export class ContactsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getContacts: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.contacts && !props.pending && props.roles) {
      const role = props.roles[props.authUser.uid];

      props.authUser.getIdToken(true).then(token => {
        props.getContacts(token, role);
      });
    }
    if (props.authUser && !props.clients && !props.pending && props.roles) {
      const role = props.roles[props.authUser.uid];

      props.authUser.getIdToken(true).then(token => {
        props.getClients(token, role);
      });
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.contacts && props.clients && props.users) {
      props.contacts.forEach((contact) => {
        const client = props.clients.find(client => client.id === contact.client_id);
        if (client) {
          contact.client = client.business_name;
        }
        if (contact.phone) {
          contact.table_phone = contact.phone;
        } else if (contact.mobile_phone) {
          contact.table_phone = contact.mobile_phone;
        } else if (contact.assistant_phone) {
          contact.table_phone = contact.assistant_phone;
        }

      });
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.contacts,
        };
      }
      if (props.contacts !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.contacts,
        };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(CONTACT_ADD);
  };

  handleEdit = element => {
    this.props.history.push(CONTACT_EDIT.replace(':contactId', element.id));
  };

  handleDelete = element => {
    const role = this.props.roles[this.props.authUser.uid];
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.deleteContact(
        token,
        role,
        element.id,
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {
        id: 'lastname',
        label: 'Nom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'firstname',
        label: 'Prénom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'mail',
        label: 'Mail',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'table_phone',
        label: 'Téléphone',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'profession',
        label: 'Métier',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'client',
        label: 'Client',
        align: 'left',
        disablePadding: false,
      },
    ];
    return (
      <>
        {this.props.pending && <Spinner />}
        {!this.props.pending && (
          <CustomTable
            datas={this.state.datas}
            buttonLabel="Ajouter un contact"
            paginationLabel="Contacts par page"
            emptyDataMessage="Aucun Contact"
            deleteDialogText="Êtes-vous sûr de vouloir supprimer ce contact ?"
            columnData={columnData}
            sending={this.props.sending}
            onAdd={this.handleAdd}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    contacts: state.contacts.content,
    pending: state.contacts.pending || state.users.pending || state.clients.pending,
    sending: state.contacts.sending,
    error: state.contacts.error,
  };
}

export default compose(
  withTitle({
    title: 'Contacts',
    subtitle: 'Gestion des contacts',
  }),
  connect(mapStateToProps,
    {
      getContacts,
      getClients,
      getUsers,
      putContact,
      deleteContact,
      handleError,
      dispatchInfo,
    },
  ),
)(ContactsPage);
