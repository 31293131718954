import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import ClientPostForm from '../../components/forms/ClientPostForm';

export const ClientPostAddPage = props => (
  <ClientPostForm history={props.history} match={props.match} />
);

ClientPostAddPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Clients',
  subtitle: 'Ajouter un Besoin',
})(ClientPostAddPage);
