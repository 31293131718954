import DOMPurify from 'dompurify';
import { CONTRACTS } from '../constants/offer';

export const computeOfferContent = (offer) => {
  let content = offer.content;
  const variables = content.match(/(\$\S*?\$)/g);
  if (variables) {
    variables.forEach((variable) => {
      const key = variable.replace(/\$/g, '');
      if (variable === '$salary_slider$') {
        content = content.replace(variable, offer.salary_min + ' à ' + offer.salary_max);
      } else if (variable === '$contract$') {
        if (typeof offer[key] !== 'undefined') {
          const contractId = Number(offer[key]);
          if (typeof CONTRACTS[contractId] !== 'undefined') {
            content = replaceInContent(content, variable, CONTRACTS[contractId]);
          } else {
            content = replaceInContent(content, variable, contractId);
          }
        }
      } else if (offer && offer.current_post) {
        if (offer.current_post[key] !== 'undefined') {
          content = replaceInContent(content, variable, offer.current_post[key]);
        } else if (offer) {
          content = replaceInContent(content, variable, offer[key]);
        }
      } else if (offer) {
        content = replaceInContent(content, variable, offer[key]);
      }
    });
  }
  return DOMPurify.sanitize(content);
};

const replaceInContent = (content, key, value) => {
  if (value !== null && typeof value !== 'undefined') {
    if (typeof value === 'object' && value.value !== undefined) {
      content = content.replace(key, value.value);
    } else if (Array.isArray(value)) {
      content = content.replace(key, arrayToString(value));
    } else if (typeof value === 'boolean') {
      content = content.replace(key, boolToString(value));
    } else {
      content = content.replace(key, value);
    }
  }
  return content;
};

const arrayToString = (value) => {
  let newContent = '';
  value.forEach(object => {
    newContent += object.summary || object.libelle_competence || object.label || object.title;
    newContent += ', ';
  });
  return newContent;
};

const boolToString = (value) => {
  return value
    ? 'oui'
    : 'non';
};
