import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/index';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import StringService from '../../services/StringService';
import user from '../../resources/icons/user.svg';
import Spinner from '../../components/common/Spinner';
import { getVariationTheme } from '../../themes/theme';
import { DEFAULT_GRAY, DEFAULT_GRAY_LIGHT } from '../../themes/defaultColors';
import HomeIcon from '../../components/icons/HomeIcon';
import PhoneIcon from '../../components/icons/PhoneIcon';
import EmailIcon from '../../components/icons/EmailIcon';
import CalendarIcon from '../../components/icons/CalendarIcon';
import FileIcon from '../../components/icons/FileIcon';
import ArrowRightIcon from '../../components/icons/ArrowRightIcon';

const colorTheme = getVariationTheme();

const styles = (theme) => (
  {
    card: {
      width: 65,
      height: 65,
      borderRadius: 65,
      margin: 20,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    profilDetails: {
      marginLeft: 105,
      marginBottom: 20,
    },
    gridItem: {
      width: '100%',
      borderBottom: '1px solid',
      borderBottomColor: DEFAULT_GRAY_LIGHT,
    },
    gridContainer: {
      width: '100%',
      borderLeft: '1px solid',
      borderLeftColor: DEFAULT_GRAY_LIGHT,
    },
    container: {
      height: 'calc(100vh - 68px)',
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    button: {
      position: 'absolute',
      right: 10,
    },
    buttonCV: {
      margin: 20,
      height: 50,
      backgroundColor: colorTheme.text_light,
      width: 'calc(100% - 40px)',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    textField: {
      width: 500,
    },
    dateField: {
      marginBottom: 10,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: colorTheme.menu,
    },
  }
);

// Intial State of the Component
const INITIAL_STATE = {
  openPDFModal: false,
  selectedTab: 0,
  numPages: null,
  pageNumber: 1,
};

export class CandidatePreview extends Component {

  static propTypes = {
    candidate: PropTypes.object,
    classes: PropTypes.object.isRequired,
    error: PropTypes.any,
    pending: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleChangeTab = (event, value) => {
    this.setState({ selectedTab: value });
  };

  handleToggleCVModal = event => {
    event.stopPropagation();
    window.open(this.props.candidate.pdf_path);
    //this.setState({openPDFModal: !this.state.openPDFModal});
  };

  handleToggleMotivationModal = event => {
    event.stopPropagation();
    window.open(this.props.candidate.motivation_path);
    //this.setState({openPDFModal: !this.state.openPDFModal});
  };

  render() {
    const {
      candidate,
      classes,
      error,
      pending,
    } = this.props;

    return (
      <Grid container direction="column" xs={12} sm={12} md={5}>
        {pending && <Spinner />}
        {!pending && candidate
          ? (
            <>
              <Grid>
                <div className={classes.row}>
                  <Card className={classes.card}>
                    <CardMedia
                      component="img"
                      image={candidate.image_path || user}
                      title="Image"
                    />
                  </Card>
                  <Typography variant="h3" style={{ alignSelf: 'center' }}>
                    {candidate.firstname} {candidate.lastname}
                  </Typography>
                </div>
                <Grid container className={classes.profilDetails}>
                  <Grid item xs={6} sm={6} md={6} direction="column">
                    <Typography variant="subtitle1" style={{ color: colorTheme.menu }}>
                      {this.state.job}
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: DEFAULT_GRAY }}>
                      {candidate.code_rome !== null
                        ? candidate.code_rome.libelle_rome
                        : ''}
                      {candidate.code_rome !== null
                        ? ' - '
                        : ''}
                      {candidate.code_rome !== null
                        ? candidate.code_rome.code_rome
                        : ''}
                    </Typography>
                    <Typography variant="subtitle2">
                      {this.state.diploma}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} direction="column">
                    <Typography variant="h1" style={{ color: colorTheme.secondary }}>
                      {candidate.experience_years}
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: DEFAULT_GRAY }}>
                      Années
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: DEFAULT_GRAY }}>
                      d'expérience
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.gridItem}>
                <Tabs
                  value={this.state.selectedTab}
                  onChange={this.handleChangeTab}
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab label="SAVOIR-FAIRE" />
                  <Tab label="SAVOIR-ÊTRE" />
                </Tabs>
              </Grid>
              <Grid>
                <List>
                  {this.state.selectedTab
                    ?
                    candidate.softskills.map(softskill => (
                        <ListItem key={softskill.id}>
                          <ListItemText primary={softskill.summary} />
                        </ListItem>
                      ))
                    : candidate.skills.map(skill => (
                        <ListItem key={skill.code_ogr}>
                          <ListItemText primary={skill.libelle_competence} />
                        </ListItem>
                      ))
                  }
                </List>
              </Grid>
              <Button
                variant="contained"
                className={classes.buttonCV}
                onClick={this.handleToggleCVModal}
                disabled={this.props.candidate.pdf_path == null}
              >
                <FileIcon className={classes.leftIcon} style={{ color: colorTheme.primary }} />
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  CV
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: DEFAULT_GRAY,
                    marginLeft: 10,
                  }}
                >
                  (PDF)
                </Typography>
                <ArrowRightIcon
                  style={{
                    float: 'right',
                    position: 'relative',
                    marginLeft: 'auto',
                    marginRight: 0,
                  }}
                />
              </Button>
              <Button
                variant="contained"
                className={classes.buttonCV}
                onClick={this.handleToggleMotivationModal}
                disabled={this.props.candidate.motivation_path == null}
              >
                <FileIcon className={classes.leftIcon} style={{ color: colorTheme.primary }} />
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Lettre de motivation
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: DEFAULT_GRAY,
                    marginLeft: 10,
                  }}
                >
                  (PDF)
                </Typography>
                <ArrowRightIcon
                  style={{
                    float: 'right',
                    position: 'relative',
                    marginLeft: 'auto',
                    marginRight: 0,
                  }}
                />
              </Button>
              <Grid style={{ marginLeft: 20 }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Coordonnées
                </Typography>
                <br />
                <div className={classes.row}>
                  <HomeIcon className={classes.icon} style={{ color: colorTheme.primary }} />
                  <Typography
                    variant="subtitle2"
                    style={{
                      alignSelf: 'center',
                      color: DEFAULT_GRAY,
                    }}
                  >
                    {candidate.address_street}<br />
                    {candidate.address_zip_code} {candidate.address_city}
                  </Typography>
                </div>
                <br />
                <div className={classes.row}>
                  <PhoneIcon className={classes.icon} style={{ color: colorTheme.primary }} />
                  <Typography
                    variant="subtitle2"
                    style={{
                      alignSelf: 'center',
                      color: DEFAULT_GRAY,
                    }}
                  >
                    {candidate.phone}
                  </Typography>
                </div>
                <br />
                <div className={classes.row}>
                  <EmailIcon className={classes.icon} style={{ color: colorTheme.primary }} />
                  <Typography
                    variant="subtitle2"
                    style={{
                      alignSelf: 'center',
                      color: DEFAULT_GRAY,
                    }}
                  >
                    {candidate.mail}
                  </Typography>
                </div>
                <br />
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  Informations complémentaires
                </Typography>
                <br />
                <div className={classes.row}>
                  <CalendarIcon className={classes.icon} style={{ color: colorTheme.primary }} />
                  <Typography
                    variant="subtitle2"
                    style={{
                      alignSelf: 'center',
                      color: DEFAULT_GRAY,
                    }}
                  >
                    {candidate.birth_date
                      ?
                      StringService.dateTimestampToString(candidate.birth_date)
                      : ''}
                  </Typography>
                </div>
              </Grid>
              <Dialog
                fullScreen={false}
                open={this.state.openPDFModal}
                onClose={this.handleTogglePDFModal}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle
                  id="responsive-dialog-title"
                  onClose={this.handleTogglePDFModal}
                >
                  Prévisualisation
                </DialogTitle>
                <DialogContent>
                  <Document
                    file={candidate.pdf_path}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    <Page pageNumber={this.state.pageNumber} />
                  </Document>
                  <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                </DialogContent>
              </Dialog>
            </>
          )
          : null
        }
        {error && <Typography>Une erreur est survenue</Typography>}
      </Grid>
    );
  }
}

export default withStyles(styles)(CandidatePreview);
