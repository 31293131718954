import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core';
import { getVariationTheme } from '../../themes/theme';
import Grid from '@material-ui/core/Grid';
import ClientIcon from '../icons/ClientIcon';
import { makeStyles } from '@material-ui/core/styles';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

const colorTheme = getVariationTheme();

const useStyles = makeStyles(theme => (
  {
    button: {
      margin: 'auto',
    },
    parent: {
      display: 'inline-block',
      width: 'auto',
      minWidth: '130px',
      height: 'auto',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        minWidth: 0
      }
    },
    card: {
      display: 'flex',
      margin: '5px',
      width: 'auto',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      height: '135px',
    },
    content: {
      height: '100%',
    },
    cover: {
      backgroundColor: colorTheme.menu,
      padding: 0,
    },
    coverIcon: {
      color: colorTheme.text_light,
      width: '10vw',
      height: '10vw',
      maxWidth: '130px',
      maxHeight: '130px',
      paddingTop: '1vw',
    },
  }
));

const getIconByName = (name, classes) => {
  switch (name) {
    case 'ClientIcon':
      return (<ClientIcon className={classes.coverIcon} />);
    default:
     return (<NotificationImportantIcon className={classes.coverIcon} />);
  }
}

export const DashboardCard = props => {
  const {label, link, icon} = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.parent}>
      <Card className={classes.card}>
        <Hidden smDown>
          <CardContent className={classes.cover}>
            {getIconByName(icon, classes)}
          </CardContent>
        </Hidden>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid
              container
              justifyContent="space-between"
              direction="column"
              className={classes.content}
            >
              <Grid item>
                <Typography variant="h4" component="h2">
                  {label}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  href={link}
                  className={classes.button}
                >
                  {/* eslint-disable-next-line */}
                  Gestion des {label.toLowerCase()}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

DashboardCard.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default DashboardCard;
