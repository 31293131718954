import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckMarkCircleIcon from '../icons/CheckMarkCircleIcon';
import { DEFAULT_GRAY } from '../../themes/defaultColors';
import MessageCircleIcon from '../icons/MessageCircleIcon';
import colorTheme from '../../themes/variations/avaVariation';

const RemovedModal = (props) => {
  const {
    openRemovedModal,
    handleToggleRemovedModal,
    candidature,
    classes,
  } = props;

  return (
    <Dialog
      fullScreen={false}
      open={openRemovedModal}
      onClose={handleToggleRemovedModal}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
    >
      <DialogTitle
        id="responsive-dialog-title"
        onClose={handleToggleRemovedModal}
      >
        Candidature retirée
      </DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <CheckMarkCircleIcon className={classes.icon} style={{ color: colorTheme.primary }} />
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Motif
          </Typography>
        </div>
        <Typography
          variant="subtitle2"
          style={{
            marginLeft: 32,
            color: DEFAULT_GRAY,
          }}
        >
          {candidature && candidature.candidate_reject_reason}
        </Typography>
        <br />
        <div className={classes.row}>
          <MessageCircleIcon className={classes.icon} style={{ color: colorTheme.primary }} />
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Commentaire
          </Typography>
        </div>
        <Typography
          variant="subtitle2" style={{
          marginLeft: 32,
          color: DEFAULT_GRAY,
        }}
        >
          {candidature && candidature.candidate_reject_comment}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default RemovedModal;

RemovedModal.propTypes = {
  openRemovedModal: PropTypes.bool.isRequired,
  handleToggleRemovedModal: PropTypes.func.isRequired,
  candidature: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
