import { STEPS } from '../constants/interviewSteps';

export const commentDependingOnCandidatureState = (state, comment) => {
  if (!comment) {
    return {};
  }
  switch (state) {
    case STEPS.PHONE_WAITING.value:
      return {
        comment_phone: comment,
        comment_phone_date: Math.round(Date.now() / 1000),
      };
    case STEPS.VISIO_WAITING.value:
      return {
        comment_visio: comment,
        comment_visio_date: Math.round(Date.now() / 1000),
      };
    case STEPS.CONSULT_WAITING.value:
      return {
        comment_consult: comment,
        comment_consult_date: Math.round(Date.now() / 1000),
      };
    case STEPS.CLIENT_WAITING.value:
      return {
        comment_client: comment,
        comment_client_date: Math.round(Date.now() / 1000),
      };
    default:
      return {};
  }
};

export const waitingOrAcceptedCandidature = (candidature) => {
  return candidature && (
    candidature.state === STEPS.PHONE_WAITING.value ||
    candidature.state === STEPS.VISIO_WAITING.value ||
    candidature.state === STEPS.CONSULT_WAITING.value ||
    candidature.state === STEPS.CLIENT_WAITING.value ||
    candidature.state === STEPS.CANDIDATE_ACCEPTED.value
  );
};

export const canLaunchCandidature = (candidature) => {
  return candidature && (
    candidature.state === STEPS.PHONE_VALIDATED.value ||
    candidature.state === STEPS.VISIO_VALIDATED.value ||
    candidature.state === STEPS.CONSULT_VALIDATED.value ||
    candidature.state === STEPS.CLIENT_VALIDATED.value
  );
};

export const canValidateOrStopCandidature = (candidature) => {
  return candidature && (
    candidature.state === STEPS.CREATED.value ||
    candidature.state === STEPS.CONSULTED.value ||
    candidature.state === STEPS.PHONE_WAITING_SLOTS.value ||
    candidature.state === STEPS.PHONE_WAITING.value ||
    candidature.state === STEPS.VISIO_WAITING_SLOTS.value ||
    candidature.state === STEPS.VISIO_WAITING.value ||
    candidature.state === STEPS.CONSULT_WAITING_SLOTS.value ||
    candidature.state === STEPS.CONSULT_WAITING.value ||
    candidature.state === STEPS.CLIENT_WAITING_SLOTS.value ||
    candidature.state === STEPS.CLIENT_WAITING.value
  );
};
