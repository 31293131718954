import React from 'react';
import { withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import colorTheme from '../../themes/variations/avaVariation';

const styles = (theme) => (
  {
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: colorTheme.menu,
    },
  }
);
const AppDialogTitle = withStyles(styles)(props => {
  const {
    children,
    classes,
    onClose,
  } = props;
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )
        : null}
    </MuiDialogTitle>
  );
});

export default AppDialogTitle;