import { createTheme } from '@material-ui/core/styles'
import ava_variation from './variations/avaVariation';
import carrefour_variation from './variations/carrefourVariation';
import { DEFAULT_GRAY, DEFAULT_GRAY_DARK, DEFAULT_GRAY_LIGHT } from './defaultColors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const THEME_AVA = "ava";
export const THEME_CARREFOUR = "carrefour";

export const DEFAULT_MARGIN = '4px';
const breakpoints = createBreakpoints({});

export const jsonTheme = (variation) => {
  return {
    palette: {
      primary: {
        light: variation.primary,
        main: variation.primary,
        dark: variation.table_head,
        contrastText: variation.text_light,
      },
      secondary: {
        light: variation.secondary,
        main: variation.secondary,
        dark: variation.secondary,
        contrastText: variation.text_light,
      },
      info: {
        light: variation.label,
        main: variation.info,
        dark: variation.background,
        contrastText: variation.text_light,
      },
      success: {
        light: variation.success,
        main: variation.success,
        dark: variation.success,
        contrastText: variation.text_light,
      },
      error: {
        light: variation.error,
        main: variation.error,
        dark: variation.error,
        contrastText: variation.text_light,
      },
      warning: {
        light: variation.warning,
        main: variation.warning,
        dark: variation.warning,
        contrastText: variation.text_light,
      },
      text: {
        primary: variation.text_dark,
        secondary: DEFAULT_GRAY_DARK,
        disabled: DEFAULT_GRAY,
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [variation.font],
      h1: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: variation.text_light,
      },
      h2: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: variation.text_dark,
      },
      h3: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '18px',
      },
      subtitle1: {
        fontSize: '15px',
      },
      subtitle2: {
        fontSize: '13px',
      },
    },
    overrides: {
      MuiInput: {
        root: {
          display: 'flex',
          width: '100%',
          fontSize: '13px',
          fontWeight: 'bold',
          marginTop: DEFAULT_MARGIN,
          marginBottom: DEFAULT_MARGIN,
          padding: '4px 4px',
          flexDirection: 'row',
          '&$error': {
            color: variation.error,
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: variation.text_light,
        },
      },
      MuiButton: {
        root: {
          display: 'flex',
          width: '213px',
          fontSize: '15px',
          fontWeight: 'bold',
          marginTop: DEFAULT_MARGIN,
          marginBottom: DEFAULT_MARGIN,
          padding: '4px 16px',
          flexDirection: 'row',
          textTransform: 'none',
          [breakpoints.down('md')]: {
            width: 'auto',
          },
        },
      },
      MuiFab: {
        root: {
          marginTop: DEFAULT_MARGIN,
          marginBottom: DEFAULT_MARGIN,
        },
      },
      MuiPaper: {
        root: {
          elevation: 4,
        },
      },
      MuiDialogContent: {
        root: {
          overflowY: 'none',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '14px',
          color: variation.menu,
        },
      },
      MuiTableSortLabel: {
        active: {
          color: DEFAULT_GRAY_LIGHT,
        },
        root: {
          '&:hover': {
            color: DEFAULT_GRAY_LIGHT,
          },
          '&:focus': {
            color: DEFAULT_GRAY_LIGHT,
          },
        },
      },
    },
  };
};

export const getVariationTheme = () => {
  switch (process.env.REACT_APP_THEME) {
    case THEME_CARREFOUR:
      return carrefour_variation;
    case THEME_AVA:
      return ava_variation;
    default:
      return ava_variation;
  }
}

export const defaultStyles = {
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    textAlign: 'center',
  },
  tabs: {
    width: `calc(100% - 304px)`, // drawer width + 2 x theme.spacing(4) (LargePaper)
    position:'fixed',
    zIndex:10,
    top: 60
  },
  clientTabs: {
    marginTop: '30px',
    padding: 16,
  },
  smallLayout: {
    margin: '25vh 1.5em 1.5em 1.5em',
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    padding: '5% 20%',
  },
  logoSmall: {
    display: 'block',
    width: '70%',
    padding: '4px 16px',
    margin: 'auto'
  },
  defaultButton: {
    display: 'flex',
    width: '220px',
    fontWeight: 'bold',
    marginTop: DEFAULT_MARGIN,
    marginBottom: DEFAULT_MARGIN,
    padding: '4px 16px',
    flexDirection: 'row'
  },
  titleUnderline: {
    backgroundColor: getVariationTheme().menu,
    height:1,
    width: '80%',
    display: 'inline-block',
  },
  fabProgress: {
    color: DEFAULT_GRAY_LIGHT,
    position: 'absolute',
    zIndex: 1,
  },
  addButton: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: '3vh',
    right: '3vh',
  },
  gridQuill: {
    backgroundColor: getVariationTheme().text_light,
    width: '100%',
    margin: 'auto'
  },
  emptyDiv: {
    height:10,
  },
  fromGridContainer: {
    width: '100%',
    margin: 0
  },
  background: {
    padding: '0',
    overflow: 'hidden',
    textAlign: 'center',
    width: '80vw',
    height: '80vh',
    position: "absolute",
    zIndex: 0
  },
  backgroundImage: {
    height: '100vh',
  },
  backgroundLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20vh',
    marginBottom: '10vh',
    textAlign: 'center'
  },
  smallLogoBlock: {
    maxHeight: '15em',
    maxWidth: '100%',
    overflow: 'hidden',
    textAlign: 'center'
  },
  smallLogo: {
    maxHeight: '15em',
  },
  smallRightlayout: {
    overflow: 'hidden',
    padding: '2em',
    textAlign: 'center',
    zIndex: 10,
    width: 'auto',
    right: '20vw',
    height: 'auto',
    top: '40vh',
    position: "absolute",
  },
  colorPicker: {
    width: '200px',
  }
};

const theme = createTheme(jsonTheme(getVariationTheme()));

export default theme;
