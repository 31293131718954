export const ROLE_CONSULTANT = 5;
export const ROLE_ADMIN = 10;
export const ROLE_SUPER_ADMIN = 15;

export const ROLES = {
  consultant: [
    ROLE_CONSULTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN
  ],
  admin: [
    ROLE_ADMIN, ROLE_SUPER_ADMIN
  ],
  super_admin: [
    ROLE_SUPER_ADMIN
  ]
};
