import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleError } from '../../stores/infos/actions';
import LargePaper from '../../components/common/LargePaper';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { deleteClientContact, getClients } from '../../stores/clients/actions';
import { getUsers } from '../../stores/users/actions';
import { CLIENT_CONTACT_ADD, CLIENT_CONTACT_EDIT } from '../../constants/routes';

const INITIAL_STATE = {
  datas: [],
};

export class ClientContactsPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    clientError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.clients && !props.pending && props.roles && !props.clientError) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken(true).then(token => {
        props.getClients(token, role);
      });
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.clients && props.users) {
      const currentClient = props.clients
        .find(client => client.id === props.match.params.clientId);
      if (currentClient.contacts) {
        currentClient.contacts.forEach((contact) => {
          const client = props.clients.find(client => client.id === contact.client_id);
          if (client) {
            contact.client = client.business_name;
          }
          if (contact.phone) {
            contact.table_phone = contact.phone;
          } else if (contact.mobile_phone) {
            contact.table_phone = contact.mobile_phone;
          } else if (contact.assistant_phone) {
            contact.table_phone = contact.assistant_phone;
          }

        });
      }
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: currentClient.contacts,
        };
      }
      if (props.contacts !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: currentClient.contacts,
        };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(CLIENT_CONTACT_ADD
      .replace(':clientId', this.props.match.params.clientId));
  };

  handleEdit = element => {
    this.props.history.push(CLIENT_CONTACT_EDIT
      .replace(':clientId', this.props.match.params.clientId).replace(':contactId', element.id));
  };

  handleDelete = element => {
    const role = this.props.roles[this.props.authUser.uid];
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.deleteClientContact(
        token,
        role,
        element.id,
        this.props.match.params.clientId,
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {
        id: 'lastname',
        label: 'Nom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'firstname',
        label: 'Prénom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'mail',
        label: 'Mail',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'table_phone',
        label: 'Téléphone',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'profession',
        label: 'Métier',
        align: 'left',
        disablePadding: false,
      },
    ];
    return (
      <LargePaper title="Gestion des Contacts">
        <div>
          {this.props.pending &&
            <Spinner />
          }
          {!this.props.pending && (
            <CustomTable
              datas={this.state.datas}
              buttonLabel="Ajouter un contact"
              paginationLabel="Contacts par page"
              emptyDataMessage="Aucun Contact"
              deleteDialogText="Êtes-vous sûr de vouloir supprimer ce contact ?"
              columnData={columnData}
              sending={this.props.sending}
              onAdd={this.handleAdd}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              defaultOrderBy={'lastname'}
              defaultOrder={'asc'}

            />
          )}
        </div>
      </LargePaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    pending: state.users.pending || state.clients.pending,
    sending: state.clients.sending,
    clientError: state.clients.error,
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      getClients,
      getUsers,
      deleteClientContact,
      handleError,
    },
  ),
)(ClientContactsPage);
