import axios from 'axios';
import API_ROOT from '../constants/api';
import RoleService from './RoleService';
import * as api from '../constants/apiRoutes';
import { CLOUD_FUNCTION_API_URL, FIREBASE_API_URL } from '../constants/apiRoutes';
import { config } from './firebase/firebase';
import { ENV_DEV, ENV_LOCAL, ENV_PREPROD } from '../constants/env';

const ApiService = {

  /**
   * Génére le header pour les flux admin
   *
   * @param token
   * @returns Object
   */
  headers(token = null) {
    const header = {};
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }
    return header;
  },

  /**
   * Génére le header pour les flux firebase
   *
   * @param apiKey
   * @returns Object
   */
  headersFirebase(apiKey = null) {
    const header = {};
    if (apiKey) {
      header.Authorization = `key=${apiKey}`;
    }
    return header;
  },

  /**
   * Users
   */

  postUser(token, data) {
    return axios
      .post(`${API_ROOT}/admin/users`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Users
   */

  postTwilioToken(token, data) {
    return axios
      .post(`${API_ROOT}/user/twilio/token`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Templates
   */

  postRecruitTemplate(token, excel) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('excel', excel);
    return axios
      .post(`${API_ROOT}/admin/templates/recruit`, data, {
        headers: this.headers(token),
      })
      .then(response => {
        return response.data;
      });
  },

  getTestRecruitPDF(token) {
    return axios
      .get(`${API_ROOT}/admin/templates/recruit`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getRecruitPDF(token, candidatureId) {
    return axios
      .get(`${API_ROOT}/admin/templates/recruit/${candidatureId}`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postClientTemplate(token, excel) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('excel', excel);
    return axios
      .post(`${API_ROOT}/admin/templates/client`, data, {
        headers: this.headers(token),
      })
      .then(response => {
        return response.data;
      });
  },

  getTestClientPDF(token) {
    return axios
      .get(`${API_ROOT}/admin/templates/client`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getClientPDF(token, candidatureId) {
    return axios
      .get(`${API_ROOT}/admin/templates/client/${candidatureId}`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postCandidateTemplate(token, excel) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('excel', excel);
    return axios
      .post(`${API_ROOT}/admin/templates/candidate`, data, {
        headers: this.headers(token),
      })
      .then(response => {
        return response.data;
      });
  },

  getTestCandidatePDF(token) {
    return axios
      .get(`${API_ROOT}/admin/templates/candidate`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getCandidatePDF(token, candidatureId) {
    return axios
      .get(`${API_ROOT}/admin/templates/candidate/${candidatureId}`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Clients
   */

  getClients(token, role) {
    return axios
      .get(`${API_ROOT}/${RoleService.getRoleString(role)}/clients`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getHRProfils() {
    return axios
      .get(`${API_ROOT}/public/hrprofils`)
      .then(response => response.data);
  },

  getAdvantages() {
    return axios
      .get(`${API_ROOT}/public/advantages`)
      .then(response => response.data);
  },

  getBroadcasts() {
    return axios
      .get(`${API_ROOT}/public/broadcasts`)
      .then(response => response.data);
  },

  postClient(token, role, data) {
    return axios
      .post(`${API_ROOT}/${RoleService.getRoleString(role)}/clients`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postClientPicture(token, id, picture, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('image', picture);

    return axios
      .post(`${API_ROOT}` + api.API_CLIENT_IMAGE_ID.replace(':clientId', id), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress,
      })
      .then(response => {
        return response.data;
      });
  },

  postClientPDF(token, id, pdf, onUploadProgress = null) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('pdf', pdf);

    return axios
      .post(`${API_ROOT}` + api.API_CLIENT_PDF_ID.replace(':clientId', id), data, {
        headers: this.headers(token),
        onUploadProgress: onUploadProgress,
      })
      .then(response => {
        return response.data;
      });
  },

  putClient(token, role, id, data) {
    return axios
      .put(`${API_ROOT}/${RoleService.getRoleString(role)}`
        + api.API_CLIENT_ID.replace(':clientId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Candidates
   */

  getCandidates(token) {
    return axios
      .get(`${API_ROOT}/consult/candidates`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getCandidatesWithFilters(token, filters) {
    let filtersURL = '?';
    Object.keys(filters).forEach((
      filterKey => {
        const filtervalue = filters[filterKey];
        if (filtervalue !== '') {
          filtersURL += filterKey + '=' + filtervalue + '&';
        }
      }
    ));
    return axios
      .get(`${API_ROOT}/consult/candidates${filtersURL}`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putCandidate(token, id, data) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_CANDIDATE_ID.replace(':candidateId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  linkCandidateWithPost(token, candidateId, postId) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_LINK_CANDIDATE_POST
          .replace(':candidateId', candidateId)
          .replace(':postId', postId)
        , null, {
          headers: this.headers(token),
        })
      .then(response => response.data);
  },

  unlinkCandidateWithPost(token, candidateId, postId) {
    return axios
      .delete(`${API_ROOT}/consult`
        + api.API_LINK_CANDIDATE_POST
          .replace(':candidateId', candidateId)
          .replace(':postId', postId)
        , {
          headers: this.headers(token),
        })
      .then(response => response.data);
  },

  /**
   * Candidatures
   */

  getCandidaturesForCandidate(token, candidate) {
    return axios
      .get(`${API_ROOT}/consult/candidatures/${candidate.id}/candidate`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getCandidaturesForOffer(token, offer) {
    return axios
      .get(`${API_ROOT}/consult/candidatures/${offer.id}/offer`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putCandidature(token, id, data) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_CANDIDATURE_ID.replace(':candidatureId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postCandidatureSlots(token, id, data) {
    return axios
      .post(`${API_ROOT}/consult`
        + api.API_CANDIDATURE_ID_SLOTS.replace(':candidatureId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postCandidaturesReminder(token, candidature) {
    return axios
      .post(`${API_ROOT}/consult/candidatures/${candidature.id}/reminder`, {}, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Contacts
   */

  getContacts(token, role) {
    return axios
      .get(`${API_ROOT}/${RoleService.getRoleString(role)}/contacts`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  postContact(token, role, data) {
    return axios
      .post(`${API_ROOT}/consult/contacts`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putContact(token, role, id, data) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_CONTACT_ID.replace(':contactId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteContact(token, role, id) {
    return axios
      .delete(`${API_ROOT}/consult`
        + api.API_CONTACT_ID.replace(':contactId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Offers
   */

  getOffers(token) {
    return axios
      .get(`${API_ROOT}/consult/offers`, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  getPublicValidateOffers() {
    return axios
      .get(`${API_ROOT}/public/offers`)
      .then(response => response.data);
  },

  postOffer(token, data) {
    return axios
      .post(`${API_ROOT}/consult/offers`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putOffer(token, id, data) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_OFFER_ID.replace(':offerId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deleteOffer(token, id) {
    return axios
      .delete(`${API_ROOT}/consult`
        + api.API_OFFER_ID.replace(':offerId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Posts
   */

  postPost(token, role, data) {
    return axios
      .post(`${API_ROOT}/consult/posts`, data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  putPost(token, role, id, data) {
    return axios
      .put(`${API_ROOT}/consult`
        + api.API_POST_ID.replace(':postId', id), data, {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  deletePost(token, role, id) {
    return axios
      .delete(`${API_ROOT}/consult`
        + api.API_POST_ID.replace(':postId', id), {
        headers: this.headers(token),
      })
      .then(response => response.data);
  },

  /**
   * Static
   */

  getStaticLegalEntities() {
    return axios
      .get(`${API_ROOT}/public/static/legal_entities`)
      .then(response => response.data);
  },

  getStaticCodeNAF() {
    return axios
      .get(`${API_ROOT}/public/static/code_naf`)
      .then(response => response.data);
  },

  getStaticSectors() {
    return axios
      .get(`${API_ROOT}/public/static/sectors`)
      .then(response => response.data);
  },

  getStaticCibles() {
    return axios
      .get(`${API_ROOT}/public/static/cibles`)
      .then(response => response.data);
  },

  getStaticStatus() {
    return axios
      .get(`${API_ROOT}/public/static/status`)
      .then(response => response.data);
  },

  getStaticJobs() {
    return axios
      .get(`${API_ROOT}/public/codes_rome`)
      .then(response => response.data);
  },

  getSkills(codeRome) {
    return axios
      .get(`${API_ROOT}/public/skills/${codeRome}`)
      .then(response => response.data);
  },

  getSoftSkills(codeRome) {
    return axios
      .get(`${API_ROOT}/public/softskills/${codeRome}`)
      .then(response => response.data);
  },

  postCandidate(offer, data) {
    return axios
      .post(`${API_ROOT}/public/candidate/${offer}`, data)
      .then(response => response.data);
  },

  postCandidatePDF(id, pdf) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('pdf', pdf);
    return axios
      .post(`${API_ROOT}/public/candidate/${id}/cv`, data)
      .then(response => {
        return response.data;
      });
  },

  postCandidateMotivation(id, pdf) {
    // eslint-disable-next-line no-undef
    let data = new FormData();
    data.append('pdf', pdf);
    return axios
      .post(`${API_ROOT}/public/candidate/${id}/motivation`, data)
      .then(response => {
        return response.data;
      });
  },

  postFirebaseCheckToken(pushToken) {
    return axios
      .get(api.GOOGLE_API_TOKEN_INFO.replace(':pushToken', pushToken), {
        headers: this.headersFirebase(config.serverKey),
      })
      .then(response => {
        return response.data;
      });
  },

  postCloudFunctionForPushNotificationiOS(
    pushToken,
    postName,
    companyName,
    companyImage,
    candidatureId,
  )
  {
    return axios
      .post(CLOUD_FUNCTION_API_URL, {
        candidature_id: candidatureId,
        post_name: postName,
        company_name: companyName,
        company_image: companyImage,
        token: pushToken,
      }, {
        headers: this.headersFirebase(config.serverKey),
      })
      .then(response => {
        return response.data;
      });
  },

  postFirebasePushNotificationiOS(
    pushToken,
    postName,
    companyName,
    companyImage,
    candidatureId,
  )
  {
    return axios
      .post(FIREBASE_API_URL, {
        to: pushToken,
        content_available: true,
        data: {
          post_name: postName,
          company_name: companyName,
          company_image: companyImage,
          candidature_id: candidatureId,
          timestamp: Date.now() / 1000 | 0,
        },
        notification: {
          title: 'AVA Talent',
          subtitle: 'appel visio',
          body: 'appel visio',
          sound: 'incoming.aiff',
          click_action: 'ROOM_INVITATION',
        },
      }, {
        headers: this.headersFirebase(config.serverKey),
      })
      .then(response => {
        return response.data;
      });
  },

  postFirebasePushNotificationAndroid(
    pushToken,
    postName,
    companyName,
    companyImage,
    candidatureId,
  )
  {
    return axios
      .post(FIREBASE_API_URL, {
        to: pushToken,
        data: {
          post_name: postName,
          company_name: companyName,
          company_image: companyImage,
          candidature_id: candidatureId,
          timestamp: Date.now() / 1000 | 0,
        },
      }, {
        headers: this.headersFirebase(config.serverKey),
      })
      .then(response => {
        return response.data;
      });
  },

  /**
   * TalentPlug
   */
  postMyTalentPlugOfferSendToUbipostingLite(offerId, token) {
    return axios.post(
      `${API_ROOT}/admin/my-talent-plug/offer/${offerId}`,
      {},
        {
          headers: this.headers(token)
        }
    )
      .then(response => {
        const data = response.data;
        return data.substring(data.indexOf('<OK>') + 4, data.indexOf('</OK>'));
      })
      .catch(error => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
          return 'Error : ' + error.response.data;
        } else {
          console.log(error);
          return 'Error : ' + error;
        }
      });
  },

  /**
   * LinkedIn
   */
  getLinkedInAuthorization() {
    let clientId = '86d4zt36hc6qxy';

    let redirectURI = '';
    if (process.env.REACT_APP_ENV) {
      if (process.env.REACT_APP_ENV === ENV_LOCAL) {
        redirectURI = 'http://localhost:3000';
      } else if (process.env.REACT_APP_ENV === ENV_DEV) {
        redirectURI = 'https://ad-conseils-dev.openium.fr';
      } else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
        redirectURI = 'https://ad-conseils-preprod.openium.fr';
      }
    } else {
      redirectURI = 'https://app.ava-talent.com';
    }

    redirectURI += '/linkedin/auth';
    let state = 'DCEeFWf45A53sdfKef424';
    let scope = 'w_member_social%20r_liteprofile';
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&state=${state}&scope=${scope}`,
      'test', params,
    );
  },

  retrieveLinkedInAccessToken(code, token) {
    let redirectURI = '';
    if (process.env.REACT_APP_ENV) {
      if (process.env.REACT_APP_ENV === ENV_LOCAL) {
        redirectURI = 'http://localhost:3000';
      } else if (process.env.REACT_APP_ENV === ENV_DEV) {
        redirectURI = 'https://ad-conseils-dev.openium.fr';
      } else if (process.env.REACT_APP_ENV === ENV_PREPROD) {
        redirectURI = 'https://ad-conseils-preprod.openium.fr';
      }
    } else {
      redirectURI = 'https://app.ava-talent.com';
    }

    redirectURI += '/linkedin/auth';
    return axios.post(
      `${API_ROOT}/admin/linked-in/access-token`,
      {
        code: code,
        redirect_uri: redirectURI
      },
      {
        headers: this.headers(token)
      }
    )
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  },

  getMeLinkedIn(accessToken, token) {
    return axios.post(
      `${API_ROOT}/admin/linked-in/me`,
      {
        access_token: accessToken
      },
      {
        headers: this.headers(token)
      }
    )
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  },

  shareOfferLinkedIn(accessToken, userId, offer, offerLink, token) {
    let data = {
      access_token: accessToken,
      'owner': 'urn:li:person:' + userId,
      'text': {
        'text': offer,
      },
      'content': {
        'description': offer,
        'title': 'AVA Talent',
        'contentEntities': [
          {
            'entityLocation': offerLink,
            'thumbnails': [
              {
                'resolvedUrl': window.location.origin + '/adconseils_recruteV3.png',
              },
            ],
          },
        ],
      },
      'distribution': {
        'linkedInDistributionTarget': {
          'visibleToGuest': true,
          'connectionsOnly': false,
        },
      },
    };
    console.log(process.env.PUBLIC_URL);
    return axios.post(
      `${API_ROOT}/admin/linked-in/shares`,
      data,
      {
        headers: this.headers(token)
      }
    )
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(err => {
        console.log(err);
      });

  },
};

export default ApiService;
