export const salaryFormFields = [
  {
    id: 'extendor_salary',
    type: 'extendor',
    gridMD: 12,
    label: 'Rémunération',
    noPadding: true,
  },
  {
    id: 'salary12_month',
    type: 'checkboxNormal',
    label: 'Rémunération fixe sur 12 mois',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'salary13_month',
    type: 'checkboxNormal',
    label: 'Rémunération fixe sur 13 mois',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'salary14_month',
    type: 'checkboxNormal',
    label: 'Rémunération fixe sur 14 mois',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'salary',
    type: 'checkboxNormal',
    label: 'Rémunération fixe',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'salary_info',
    type: 'richText',
    gridMD: 8,
    label: 'Commentaires',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'variable_pay',
    type: 'checkboxNormal',
    label: 'Variable',
    dependances: ['extendor_salary'],
    isMandatory: false,
    tooltip: 'Le variable individuel export constitue un complément de rémunération, imposé et soumis aux charges sociales selon les règles de droit commun',
  },
  {
    id: 'variable_pay_info',
    type: 'richText',
    gridMD: 8,
    label: 'Commentaires',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'annual_bonus',
    type: 'checkboxNormal',
    label: 'Primes sur objectifs annuels',
    dependances: ['extendor_salary'],
    isMandatory: false,
    tooltip: 'Le versement de cette prime est subordonné à la réalisation de certains objectifs, devant être fixés avec un minimum de précision. En général , un seuil de déclenchement est prévu, situé en deca de l’objectif. Lorsque celui-ci est dépassé, le montant de la prime peut augmenter, de manière linéaire ou progressive',
  },
  {
    id: 'annual_bonus_by_quarter',
    type: 'checkboxNormal',
    label: 'Primes sur objectifs annuels/trimestre',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'ranking_bonus',
    type: 'checkboxNormal',
    label: 'Prime de classement',
    dependances: ['extendor_salary'],
    isMandatory: false,
    tooltip: 'Le montant versé dépend du classement du salarié, déterminé en fonction de critères précis et objectifs (chiffre d’affaires réalisé). Pour l’entreprise, le coût total d’un tel dispositif est fixe, puisqu’une somme déterminée à l’avance et allouée à chaque place du classement. Toutefois, des modulations peuvent intervenir ; ainsi par exemple, un coefficient multiplicateur peut être prévu lorsqu’un certain seuil de chiffre d’affaires est atteint',
  },
  {
    id: 'ca_commission',
    type: 'checkboxNormal',
    label: 'Commissions sur CA',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'ca_commission_percentage',
    type: 'textField',
    subtype: 'number',
    label: '%',
    dependances: ['extendor_salary'],
    isMandatory: false,
    InputProps: {
      step: 0.1,
      min: 0.0,
      max: 100.0,
    },
  },
  {
    id: 'ca_commission_info',
    type: 'richText',
    gridMD: 4,
    label: 'Commentaire',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'brut_commission',
    type: 'checkboxNormal',
    label: 'Commissions sur marge brute',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'brut_commission_percentage',
    type: 'textField',
    subtype: 'number',
    label: '%',
    dependances: ['extendor_salary'],
    isMandatory: false,
    InputProps: {
      step: 0.1,
      min: 0.0,
      max: 100.0,
    },
  },
  {
    id: 'brut_commission_info',
    type: 'richText',
    gridMD: 4,
    label: 'Commentaire',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'net_commission',
    type: 'checkboxNormal',
    label: 'Commissions sur Résultat Net',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'net_commission_percentage',
    type: 'textField',
    subtype: 'number',
    label: '%',
    dependances: ['extendor_salary'],
    isMandatory: false,
    InputProps: {
      step: 0.1,
      min: 0.0,
      max: 100.0,
    },
  },
  {
    id: 'net_commission_info',
    type: 'richText',
    gridMD: 4,
    label: 'Commentaire',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'stake',
    type: 'checkboxNormal',
    label: 'Participation',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'profit_sharing',
    type: 'checkboxNormal',
    label: 'Intéressement',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'company_vehicle',
    type: 'checkboxNormal',
    label: 'Véhicule de fonction',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
  {
    id: 'other_benefits',
    type: 'richText',
    gridMD: 12,
    label: 'Autres',
    dependances: ['extendor_salary'],
    isMandatory: false,
  },
];
