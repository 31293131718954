export const HOMEPAGE = '/home';
export const SIGN_IN = '/signin';
export const NOT_FOUND = '/notfound';
export const PASSWORD_FORGET = '/pw-forget';
export const CLIENTS = '/clients';
export const CLIENT_ADD = '/clients-add';
export const CLIENT_EDIT = '/clients/:clientId/edit';
export const CLIENT_VIEW = '/clients/:clientId';
export const CLIENT_CONTACT_ADD = '/clients/:clientId/contacts-add';
export const CLIENT_CONTACT_EDIT = '/clients/:clientId/contacts/:contactId';
export const CLIENT_POST_ADD = '/clients/:clientId/posts-add';
export const CLIENT_POST_EDIT = '/clients/:clientId/posts/:postId';
export const CLIENT_POST_CANDIDATES = '/clients/:clientId/posts/:postId/search';
export const OFFER_CANDIDATE = '/offers/:offerId/candidate';
export const CANDIDATES = '/candidates';
export const USERS = '/users';
export const USER_ADD = '/users-add';
export const USER_EDIT = '/users/:userId';
export const CONTACTS = '/contacts';
export const CONTACT_ADD = '/contacts-add';
export const CONTACT_EDIT = '/contacts/:contactId';
export const OFFERS = '/offers';
export const OFFER_ADD = '/offers-add';
export const OFFER_EDIT = '/offers/:offerId/edit';
export const OFFER_CANDIDATURES = '/offers/:offerId/candidatures';
export const OFFER_VIEW = '/offers/:offerId';
export const CANDIDATE_VIEW = '/candidates/:candidateId';
export const CANDIDATE_VIEW_CR = '/candidates/:candidateId/compte-rendu/:candidatureId';
export const CANDIDATE_VISIO = '/candidates/:candidateId/visio/:candidatureId';
export const CANDIDATE_PHONE = '/candidates/:candidateId/phone/:candidatureId';
export const CANDIDATE_CONSULT = '/candidates/:candidateId/consult/:candidatureId';
export const CANDIDATE_CLIENT = '/candidates/:candidateId/client/:candidatureId';
export const CGU = '/cgu';
export const CONFIDENTIAL = '/confidential';
export const TEMPLATES = '/templates';
export const LINKEDIN_AUTH = '/linkedin/auth';
