import * as types from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CANDIDATES_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case types.CANDIDATE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case types.CANDIDATES_DOWNLOADED: {
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    }
    case types.CANDIDATES_UNLOAD: {
      return INITIAL_STATE;
    }
    case types.CANDIDATE_DOWNLOADED: {
      const candidateDownloaded = action.payload;
      let candidates = [];
      let candidateIndex = -1;
      if (state.content) {
        candidates = state.content.slice();
        candidateIndex = candidates.findIndex(candidate => candidate.id === candidateDownloaded.id);
      }
      if (candidateIndex === -1) {
        candidates.push(candidateDownloaded);
      } else {
        candidates[candidateIndex] = candidateDownloaded;
      }

      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: candidates,
      };
    }
    case types.CANDIDATES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    default:
      break;
  }
  return state;
}
