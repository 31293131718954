import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import UserForm from '../../components/forms/UserForm';

export const UserAddPage = props => (
  <UserForm history={props.history} />
);

UserAddPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Utilisateurs',
  subtitle: 'Ajouter un utilisateur',
})(UserAddPage);
