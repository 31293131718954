export const REJECT_1 = "Votre CV n’a pas réussi à se démarquer malgré une solide expérience, si vous ne soignez pas la présentation de votre CV ou ne prenez pas le temps de l’adapter au poste pour lequel vous postulez, celui-ci pourrait rejoindre la mauvaise pile. Ce qui est malheureusement le cas cette fois. Pour vos prochaines candidatures, veillez à ce que votre CV soit concis et clair. Citez en premier l’expérience la plus pertinente. Pensez à ajuster votre CV à chaque nouvelle candidature (tout en restant bien sûr honnête) afin de mettre en avant vos qualifications en lien avec le poste.";
export const REJECT_2 = "Vos motivations n’ont pas été suffisamment clairement exposées nous vous invitons pour vos prochains entretiens, à bien expliquer votre volonté d’intégrer l’entreprise en question et à détailler clairement vos motivations.";
export const REJECT_3 = "Vous n’avez pas suffisamment préparé votre entretien en amont ne pas se renseigner en amont sur l’entreprise réduit considérablement vos chances d’être retenu. Nous vous recommandons pour vos prochains entretiens de bien connaître l’activité de l’entreprise et démontrer en quoi vos compétences et votre expérience pourraient être une valeur ajoutée pour la société.";
export const REJECT_4 = "Vous n’avez pas su démontrer votre esprit d’équipe au-delà des compétences et de l’expérience, nous recherchons des candidats capables de mettre leur esprit d’équipe au service des valeurs et de l’éthique de l’entreprise. Un solide bagage technique n’est pas suffisant pour ce poste et notre client. Un candidat qui démontrera des compétences en communication, qui saura inspirer confiance et qui dégagera de la sympathie parviendra sans aucun doute à se démarquer des autres candidats.";
export const REJECT_5 = "Vous avez laissé transparaître un manque de motivation nous avons perçu chez vous un manque d’intérêt et d’enthousiasme pour l’entreprise et le poste.";
export const REJECT_6 = "Vous étiez en compétition avec un candidat plus expérimenté.";
export const REJECT_7 = "";

export const REASON_REJECT_1 = "Votre CV n’a pas réussi à se démarquer";
export const REASON_REJECT_2 = "Vos motivations n’ont pas été suffisamment clairement exposées";
export const REASON_REJECT_3 = "Vous n’avez pas suffisamment préparé votre entretien en amont";
export const REASON_REJECT_4 = "Vous n’avez pas su démontrer votre esprit d’équipe";
export const REASON_REJECT_5 = "Vous avez laissé transparaître un manque de motivation";
export const REASON_REJECT_6 = "Vous étiez en compétition avec un candidat plus expérimenté";
export const REASON_REJECT_7 = "Autre";
