export const motivationsFields = [
  {
    id: 'extendor_motivations',
    type: 'extendor',
    gridMD: 12,
    label: 'Motivations',
    noPadding: true,
  },
  {
    id: 'poste',
    type: 'richText',
    gridMD: 12,
    label: 'Pour le poste',
    dependances: ['extendor_motivations'],
    isMandatory: false,
  },
  {
    id: 'personnelles',
    type: 'richText',
    gridMD: 12,
    label: 'Personnelles',
    dependances: ['extendor_motivations'],
    isMandatory: false,
  },
];