import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from "@material-ui/core/TextField";
import { getVariationTheme } from '../../../themes/theme';

const colorTheme = getVariationTheme();

const styles = () => ({
  head: {
    color: colorTheme.table_head,
    fontSize: '15px',
    fontWeight: 'bold'
  }
});

export class CustomTableHead extends Component {

  constructor(props) {
    super(props);
    this.state = {showFilters: false};
  }

  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  switchShowFilters = () => {
    this.setState({showFilters: !this.state.showFilters});
  };

  render() {
    const {order, orderBy, classes, columnData} = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            let label = this.props.onRequestSort ?
              (
                <Tooltip
                  title='Trier'
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === column.id || orderBy === column.specialOrderBy)}
                    direction={order === 'asc' ? 'desc' : 'asc'}
                    onClick={
                      this.createSortHandler(
                        (column.specialOrderBy) ? column.specialOrderBy : column.id
                      )
                    }
                  >
                    {`${column.label}`}
                  </TableSortLabel>
                </Tooltip>
              )
              :
              column.label;

            if (column.id) {
              return (
                <TableCell
                  className={classes.head}
                  key={column.id}
                  align={column.align}
                  size={column.size || 'medium'}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={
                    (orderBy === column.id || orderBy === column.specialOrderBy) ? order : false
                  }
                >
                  {label}
                  {this.state.showFilters &&
                  <TextField />
                  }
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  className={classes.head}
                  key='empty'
                />
              );
            }
          })
          }
          <TableCell key='filter' className={classes.head}>
            {this.state.showFilters && <Tooltip title={'Filtres'}>
              <IconButton
                id="filter_button"
                aria-label="update"
                onClick={this.switchShowFilters}
                color="primary"
              >
                {this.state.showFilters ? <ClearIcon/> : <FilterIcon/>}
              </IconButton>
            </Tooltip>}
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

CustomTableHead.propTypes = {
// eslint-disable-next-line react/require-default-props
  onRequestSort: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columnData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomTableHead);
