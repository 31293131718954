import { db } from './firebase';

export const getUsers = () =>
  db.collection('users').get();

export const getRoles = () =>
  db.collection('roles').get();

export const getRole = userId =>
  db.collection('roles').doc(userId).get();

export const doRoleUpdate = (userId, role) =>
  db.collection('roles').doc(userId).set(role);

export const doProfileUpdate = (userId, user) =>
  db.collection('users').doc(userId).set(user);
