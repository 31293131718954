import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import OfferForm from '../../components/forms/OfferForm';

export const OfferEditPage = props => (
  <OfferForm history={props.history} match={props.match} />
);

OfferEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Édition de l\'offre',
  subtitle: 'Éditer une offre',
})(OfferEditPage);
