import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withTitle from '../hocs/withTitle';
import { CLIENTS } from '../constants/routes';
import DashboardCard from '../components/common/DashboardCard';

const shortcuts = [
  {
    'label': 'Clients',
    'link': CLIENTS,
    'icon': 'ClientIcon',
  },
];

export class HomePage extends Component {

  static propTypes = {
    // eslint-disable-next-line
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line
  render() {
    return (
      <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        {
          shortcuts.map(element => (
            <Grid item xs={6} xl={4} key={element.label}>
              <DashboardCard
                icon={element.icon}
                label={element.label}
                link={element.link}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }
}

export default withTitle({
  title: 'Accueil',
  subtitle: 'Accès aux entrées du menu',
})(HomePage);
