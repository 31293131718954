import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClientIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M22,19 C22,19.552 21.553,20 21,20 C20.447,20 20,19.552 20,19 C20,17.346 18.654,16 17,16 C16.317,16 15.668,16.234 15.144,16.649 C15.688,17.645 16,18.787 16,20 C16,20.552 15.553,21 15,21 C14.447,21 14,20.552 14,20 C14,17.243 11.757,15 9,15 C6.243,15 4,17.243 4,20 C4,20.552 3.553,21 3,21 C2.447,21 2,20.552 2,20 C2,16.14 5.141,13 9,13 C10.927,13 12.673,13.783 13.94,15.046 C14.809,14.374 15.879,14 17,14 C19.757,14 22,16.243 22,19 M17,9 C17.552,9 18,9.449 18,10 C18,10.551 17.552,11 17,11 C16.448,11 16,10.551 16,10 C16,9.449 16.448,9 17,9 M17,13 C18.654,13 20,11.654 20,10 C20,8.346 18.654,7 17,7 C15.346,7 14,8.346 14,10 C14,11.654 15.346,13 17,13 M9,5 C10.103,5 11,5.897 11,7 C11,8.103 10.103,9 9,9 C7.897,9 7,8.103 7,7 C7,5.897 7.897,5 9,5 M9,11 C11.206,11 13,9.206 13,7 C13,4.794 11.206,3 9,3 C6.794,3 5,4.794 5,7 C5,9.206 6.794,11 9,11" />
    </SvgIcon>
  );
};

export default ClientIcon;
