import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Participant from './Participant';
import user from "../../resources/icons/user.svg"
import { getVariationTheme } from '../../themes/theme';
import { DEFAULT_GRAY_LIGHT } from '../../themes/defaultColors';

const colorTheme = getVariationTheme();
const useStyles = makeStyles(() => {
    return ({
      picto: {
        width: 65,
        height: 65,
        borderRadius: 65,
        margin: 20
      },
      smallSinch: {
        height: 160,
        width: 100,
        borderRadius: 40,
        objectFit: 'cover',
        backgroundColor: DEFAULT_GRAY_LIGHT
      }
    });
  }
);

const Room = ({ roomName, token, candidate }) => {

  const classes = useStyles();

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    Video.connect(token, {
      name: roomName
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });
    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);


  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} mute={false}/>
  ));

  return (
    <div>
      <CardHeader
        avatar={
          <CardMedia
            className={classes.picto}
            component="img"
            image={candidate.image_path || user}
            title="Image"
          />
        }
        title={
          <Typography variant="h3" style={{alignSelf: 'center', color: colorTheme.text_light}}>
            {candidate.firstname} {candidate.lastname}
          </Typography>
        }
        action={
          <Card className={classes.smallSinch}>
            {room ? (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                mute={false}
              />
            ) : (
              ''
            )} </Card>
        }
      />
      <CardContent style={{width: '100%', height: 'calc(100% - 260px)'}}>
        {participants.length === 0 &&
          <Typography variant="h3" style={{
            alignSelf: 'center',
            textAlign: 'center',
            padding: '200px 0',
            color: colorTheme.text_light}}
          >
            En attente de connexion du candidat. Veuillez utiliser la cloche ci-dessous pour l'inviter à nouveau.
          </Typography>
        }
        {remoteParticipants}
      </CardContent>
    </div>
  );
};

export default Room;
