import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dispatchInfo, handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import Spinner from '../../components/common/Spinner';
import CustomTable from '../../components/common/table/CustomTable';
import { getClients, pendingClients, putClient } from '../../stores/clients/actions';
import { getUsers } from '../../stores/users/actions';
import { CLIENT_ADD, CLIENT_EDIT, CLIENT_VIEW } from '../../constants/routes';
import { getContacts } from '../../stores/contacts/actions';

const INITIAL_STATE = {
  datas: [],
  clientPending: false,
  token: null
};

export class ClientsPage extends Component {


  static propTypes = {
    history: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    userPending: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    getClients: PropTypes.func.isRequired,
    pendingClients: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    authUser: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (
      props.authUser !== null
      && props.clients === null
      && !props.pending
      && props.roles !== null
      && !props.error
      && !state.clientPending
    ) {
      const role = props.roles[props.authUser.uid];
      props.authUser.getIdToken(true)
        .then(token => {
          if (
            props.authUser !== null
            && props.clients === null
            && !props.pending
            && props.roles !== null
            && !props.error
            && !state.clientPending
          ) {
            props.getClients(token, role);
          }
        });
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.clients && props.users) {
      props.clients.forEach((client) => {
        const referent = props.users.find(user => user.uid === client.referent_id);
        if (referent) {
          client.referent_mail = referent.mail;
        }
      });
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.clients,
        };
      }
      if (props.clients !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.clients,
        };
      }
    }
    return newState;
  }

  handleAdd = () => {
    this.props.history.push(CLIENT_ADD);
  };

  handleEdit = element => {
    this.props.history.push(CLIENT_EDIT.replace(':clientId', element.id));
  };

  handleSelect = element => {
    this.props.history.push(CLIENT_VIEW.replace(':clientId', element.id));
  };

  handleArchive = (element, qualification) => {

    const role = this.props.roles[this.props.authUser.uid];
    this.props.authUser.getIdToken(true).then(token => {
      this.props.putClient(
        token,
        role,
        element.id,
        {
          is_archived: true,
          archive_qualification: qualification,
        },
      );
      this.props.getClients(token, role);
      this.props.getContacts(token, role);
      return token;
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  handleSwitchActive = element => {

    const role = this.props.roles[this.props.authUser.uid];
    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putClient(
        token,
        role,
        element.id,
        { is_active: !element.is_active },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
  };

  render() {

    const columnData = [
      {
        id: 'business_name',
        label: 'Raison sociale',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'siret',
        label: 'SIRET',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'phone',
        label: 'Téléphone',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'director_name',
        label: 'Dirigeant',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'city',
        label: 'Ville',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'referent_mail',
        label: 'Référent',
        align: 'left',
        disablePadding: false,
      },
    ];
    return (
      <div>
        {this.props.pending &&
          <Spinner />
        }
        {!this.props.pending && (
          <CustomTable
            datas={this.state.datas}
            buttonLabel="Ajouter un client"
            paginationLabel="Clients par page"
            emptyDataMessage="Aucun Client"
            deleteDialogText="Veuillez spécifier une qualification afin d'archiver ce client :"
            columnData={columnData}
            sending={this.props.sending}
            onDelete={this.handleArchive}
            onAdd={this.handleAdd}
            onClickRow={this.handleSelect}
            onEdit={this.handleEdit}
            onSwitchActive={this.handleSwitchActive}
            shouldArchive={true}
            defaultOrderBy={'business_name'}
            defaultOrder={'asc'}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    userPending: state.users.pending,
    authUser: state.users.authUser,
    roles: state.roles.content,
    clients: state.clients.content,
    pending: state.clients.pending,
    sending: state.clients.sending,
    error: state.clients.error,
  };
}

export default compose(
  withTitle({
    title: 'Clients',
    subtitle: 'Gestion des clients',
  }),
  connect(mapStateToProps,
    {
      getClients,
      getUsers,
      getContacts,
      putClient,
      handleError,
      dispatchInfo,
      pendingClients
    },
  ),
)(ClientsPage);
