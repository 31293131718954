import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const offersPendingAction = () => ({
  type: types.OFFERS_PENDING,
});
export const offerSendingAction = () => ({
  type: types.OFFER_SENDING,
});
export const offersUnloadAction = () => ({
  type: types.OFFERS_UNLOAD,
});

export const offersDownloadedAction = offers => ({
  type: types.OFFERS_DOWNLOADED,
  payload: offers,
});

export const offerDownloadedAction = (offer) => ({
  type: types.OFFER_DOWNLOADED,
  payload: offer
});

export const offerRemovedAction = (offer) => ({
  type: types.OFFER_REMOVED,
  payload: offer,
});

export const offersFailedAction = error => ({
  type: types.OFFERS_FAILED,
  payload: error,
});


// --------------------------------
// Actions

export function unloadOffers() {
  return (dispatch) => {
    dispatch(offersUnloadAction());
  }
}

export function getOffers(token) {
  return (dispatch) => {
    dispatch(offersPendingAction());
    return ApiService
      .getOffers(token)
      .then((offers) => {
        dispatch(offersDownloadedAction(offers));
      })
      .catch((error) => {
        dispatch(offersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getPublicValidateOffers() {
  return (dispatch) => {
    dispatch(offersPendingAction());
    return ApiService
      .getPublicValidateOffers()
      .then((offers) => {
        dispatch(offersDownloadedAction(offers));
      })
      .catch((error) => {
        dispatch(offersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postOffer(token, offer) {
  return (dispatch) => {
    dispatch(offerSendingAction());

    return ApiService
      .postOffer(token, offer)
      .then((data) => {
        dispatch(offerDownloadedAction(data));
        return data.id
      })
      .catch((error) => {
        dispatch(offersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putOffer(token, offerId, offer) {
  return (dispatch) => {
    dispatch(offerSendingAction());
    return ApiService
      .putOffer(token, offerId, offer)
      .then((data) => {
        const updatedOffer = {
          ...data,
          id: offerId,
        };
        dispatch(offerDownloadedAction(updatedOffer));
        return data.id
      })
      .catch((error) => {
        dispatch(offersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteOffer(token, offerId) {
  return (dispatch) => {
    dispatch(offerSendingAction());

    return ApiService
      .deleteOffer(token, offerId)
      .then(() => {
        dispatch(offerRemovedAction(offerId));
      })
      .catch((error) => {
        dispatch(offersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
