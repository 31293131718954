import React from 'react';
import { getVariationTheme } from '../../themes/theme';
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_WHITE } from '../../themes/defaultColors';

const headerHeight = 100;
const colorTheme = getVariationTheme();
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: '10px',
    marginRight: '10px'
  },
  appBar: {
    height: headerHeight,
    justifyContent: 'center',
    marginLeft: 0,
    color: colorTheme.text_light,
    [theme.breakpoints.up('lg')]: {
      width: `100%`,
    },
    backgroundColor: DEFAULT_WHITE,
    background: colorTheme.app_bar_background,
    backgroundSize: 'cover',
  },
  content: {
    flexGrow: 1,
    paddingTop: headerHeight + 40,
    maxWidth: '100%'
  },
}));
const AnonymousLayout = (props) => {
  const {children} = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <img alt="logo" src={colorTheme.logo_white} style={{height: '80px'}}/>
      </AppBar>
      <div className={classes.content}>
            {children}
      </div>
    </div>
  );
};

export default AnonymousLayout;
