import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LinkedInIcon = (props) => {

  return (
    <SvgIcon {...props}>
        <path d="M4,10 L3.998,10 L4,20 L6,20 L6,10 L4,10 Z M6,22 L4,22 C2.897,22 2,21.103 2,20 L2,10 C2,8.897 2.897,8 4,8 L6,8 C7.103,8 8,8.897 8,10 L8,20 C8,21.103 7.103,22 6,22 L6,22 Z M15.5049,11.6924 C15.6579,11.6924 15.8129,11.7054 15.9659,11.7294 C17.3159,11.9504 18.3329,13.1964 18.3329,14.6294 L18.3329,20.0004 L19.9999,20.0004 L19.9999,14.5384 C19.9999,12.0364 17.9819,10.0004 15.4999,10.0004 C13.0189,10.0004 10.9999,12.0364 10.9999,14.5384 L10.9999,20.0004 L12.6669,20.0004 L12.6669,14.5384 C12.6669,13.6994 13.0319,12.9074 13.6699,12.3654 C14.1859,11.9274 14.8349,11.6924 15.5049,11.6924 M19.9999,22.0004 L18.3329,22.0004 C17.2309,22.0004 16.3329,21.1024 16.3329,20.0004 L16.3329,14.6294 C16.3329,14.1724 16.0299,13.7664 15.6449,13.7044 C15.3089,13.6464 15.0749,13.7964 14.9659,13.8884 C14.7759,14.0504 14.6669,14.2874 14.6669,14.5384 L14.6669,20.0004 C14.6669,21.1024 13.7699,22.0004 12.6669,22.0004 L10.9999,22.0004 C9.8979,22.0004 8.9999,21.1024 8.9999,20.0004 L8.9999,14.5384 C8.9999,10.9324 11.9159,8.0004 15.4999,8.0004 C19.0839,8.0004 21.9999,10.9324 21.9999,14.5384 L21.9999,20.0004 C21.9999,21.1024 21.1029,22.0004 19.9999,22.0004 M5,3 C4.448,3 4,3.448 4,4 C4,4.552 4.448,5 5,5 C5.552,5 6,4.552 6,4 C6,3.448 5.552,3 5,3 M5,7 C3.346,7 2,5.654 2,4 C2,2.346 3.346,1 5,1 C6.654,1 8,2.346 8,4 C8,5.654 6.654,7 5,7" />
    </SvgIcon>
  );
};

export default LinkedInIcon;
