import * as types from './types';

// Actions creator

export function resetInfoAction() {
  return {type: types.INFO_RESET};
}

export function genericInfoAction(info) {
  return {
    type: types.INFO,
    payload: info
  };
}

export function resetErrorAction() {
  return {type: types.ERROR_RESET};
}

export function genericErrorAction(error) {
  return {
    type: types.ERROR,
    payload: error
  };
}

// Actions

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorAction());
  }
}

export function resetInfo() {
  return (dispatch) => {
    dispatch(resetInfoAction());
  }
}

export function dispatchInfo(info) {
  return (dispatch) => {
    dispatch(genericInfoAction(info));
  }
}

export function handleError(error, type = null) {
  return (dispatch) => {
    let event = {
      type: type,
      payload: 'Impossible de réaliser cette action, veuillez réessayer ultérieurement.'
    };
    if (error) {
      if (error.response) {
        if (error.response.status === 401) {
          event.payload = 'Email ou mot de passe incorrect.';
        } else if (error.response.status === 403) {
            event.payload = 'Vous n\'êtes pas autorisé à effectuer cette action.';
        } else if (error.response.status === 404) {
          if (error.response.data && error.response.data.message) {
            event.payload = error.response.data.message;
          } else {
            event.payload = 'Contenu introuvable.';
          }
        } else if (error.response.status === 400 || error.response.status === 409) {
          if (error.response.data && error.response.data.message) {
            event.payload = error.response.data.message;
          }
        } else if (error.response.statusText) {
          event.payload = error.response.statusText;
        }
      } else if (error.message) {
        event.payload = error.message;
      }
    }

    // Dispatch real event error
    if (type) {
      dispatch(event);
    }

    // Dispatch generic error
    dispatch(genericErrorAction(event.payload));
  }
}
