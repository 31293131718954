import React from 'react';
import PropTypes from "prop-types";
import withTitle from "../../hocs/withTitle";
import ClientContactForm from "../../components/forms/ClientContactForm";

export const ClientContactAddPage = props => (
  <ClientContactForm history={props.history} match={props.match}/>
);

ClientContactAddPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default withTitle({title: 'Contacts', subtitle: 'Ajouter un Contact'})(ClientContactAddPage);
