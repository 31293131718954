import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CallIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.6001,4 C5.1661,4 4.0001,5.166 4.0001,6.6 C4.0001,13.988 10.0111,20 17.4001,20 C18.8331,20 20.0001,18.834 20.0001,17.4 C20.0001,17.289 19.9931,17.18 19.9791,17.072 L15.3591,16.012 C15.2671,16.188 15.1661,16.379 15.0741,16.557 C14.6181,17.427 14.2881,18.059 13.4461,17.72 C10.1401,16.56 7.4411,13.86 6.2661,10.514 C5.9091,9.729 6.5891,9.373 7.4511,8.923 C7.6261,8.831 7.8151,8.732 7.9881,8.642 L6.9281,4.021 C6.8171,4.007 6.7091,4 6.6001,4 M17.4001,22 C8.9081,22 2.0001,15.092 2.0001,6.6 C2.0001,4.063 4.0631,2 6.6001,2 C6.8601,2 7.1171,2.022 7.3661,2.065 C7.6091,2.104 7.8531,2.166 8.0911,2.248 C8.4161,2.361 8.6611,2.634 8.7381,2.97 L10.1071,8.934 C10.1831,9.262 10.0881,9.606 9.8541,9.85 C9.7181,9.992 9.7151,9.995 8.4751,10.644 C9.4791,12.818 11.2241,14.557 13.3541,15.527 C14.0041,14.286 14.0081,14.282 14.1501,14.146 C14.3941,13.912 14.7401,13.821 15.0661,13.893 L21.0311,15.262 C21.3661,15.339 21.6391,15.583 21.7511,15.908 C21.8331,16.144 21.8951,16.388 21.9361,16.639 C21.9781,16.886 22.0001,17.142 22.0001,17.4 C22.0001,19.937 19.9361,22 17.4001,22" />
    </SvgIcon>
  );
};

export default CallIcon;
