import { DEFAULT_WHITE } from '../defaultColors';
import logo from '../../resources/images/logo_ava_talent.svg';
import logoWhite from '../../resources/images/logo_ava_talent_white.svg';
import header from '../../resources/images/header_ava_talent.png';

export const AVA_PRIMARY = '#3BBBEE';
export const AVA_SECONDARY = '#C5027F';
export const AVA_LABEL = '#392C8A';
export const AVA_SUCCESS = '#09A04B';
export const AVA_WARNING = '#D06519';
export const AVA_MENU = '#0F0A2B';
export const AVA_BACKGROUND = '#0F0A2C';
export const AVA_TEXT_DARK = '#2A2929';
export const AVA_TEXT_PLACEHOLDER = '#656A6E';
export const AVA_TABLE_HEAD = '#222B45';

const ava_variation = {
  font : "Roboto",
  primary: AVA_PRIMARY,
  secondary: AVA_SECONDARY,
  label: AVA_LABEL,
  info: AVA_PRIMARY,
  success: AVA_SUCCESS,
  warning: AVA_WARNING,
  error: AVA_SECONDARY,
  menu: AVA_MENU,
  background: AVA_BACKGROUND,
  text_light: DEFAULT_WHITE,
  text_dark: AVA_TEXT_DARK,
  text_placeholder: AVA_TEXT_PLACEHOLDER,
  table_head: AVA_TABLE_HEAD,
  logo: logo,
  logo_white: logoWhite,
  app_bar_background: `url(${header})`
}

export default ava_variation;
