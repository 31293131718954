import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SaveIcon = (props) => {

  return (
    <SvgIcon {...props}>
      <path
        d="M19,18 C19,18.551 18.552,19 18,19 L16,19 L16,16 C16,15.448 15.553,15 15,15 L9,15 C8.447,15 8,15.448 8,16 L8,19 L6,19 C5.448,19 5,18.551 5,18 L5,6 C5,5.449 5.448,5 6,5 L8,5 L8,10 C8,10.552 8.447,11 9,11 L13,11 C13.553,11 14,10.552 14,10 C14,9.448 13.553,9 13,9 L10,9 L10,5 L13.172,5 C13.435,5 13.692,5.107 13.879,5.293 L18.707,10.121 C18.896,10.31 19,10.562 19,10.829 L19,18 Z M10,19 L10,17 L14,17 L14,19 L10,19 Z M20.121,8.707 L15.293,3.879 C14.727,3.312 13.973,3 13.172,3 L6,3 C4.346,3 3,4.346 3,6 L3,18 C3,19.654 4.346,21 6,21 L9,21 L15,21 L18,21 C19.654,21 21,19.654 21,18 L21,10.829 C21,10.027 20.687,9.273 20.121,8.707 L20.121,8.707 Z" />
    </SvgIcon>
  );
};

export default SaveIcon;
