import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import ContactForm from '../../components/forms/ContactForm';

export const ContactEditPage = props => (
  <ContactForm history={props.history} match={props.match} />
);

ContactEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Contacts',
  subtitle: 'Éditer un Contact',
})(ContactEditPage);
