import * as types from './types';
import ApiService from '../../services/ApiService';
import {handleError} from "../infos/actions";

// --------------------------------
// Action creators

export const candidatesPendingAction = () => ({
  type: types.CANDIDATES_PENDING,
});
export const candidateSendingAction = () => ({
  type: types.CANDIDATE_SENDING,
});

export const candidatesUnloadAction = () => ({
  type: types.CANDIDATES_UNLOAD,
});

export const candidatesDownloadedAction = candidates => ({
  type: types.CANDIDATES_DOWNLOADED,
  payload: candidates,
});

export const candidatesFailedAction = error => ({
  type: types.CANDIDATES_FAILED,
  payload: error,
});

export const candidateDownloadedAction = (candidate) => ({
  type: types.CANDIDATE_DOWNLOADED,
  payload: candidate
});


// --------------------------------
// Actions

export function unloadCandidates() {
  return (dispatch) => {
    dispatch(candidatesUnloadAction());
  }
}

export function getCandidates(token) {
  return (dispatch) => {
    dispatch(candidatesPendingAction());
    return ApiService
      .getCandidates(token)
      .then((candidates) => {
        dispatch(candidatesDownloadedAction(candidates));
      })
      .catch((error) => {
        dispatch(candidatesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getCandidatesWithFilters(token, filters) {
  return (dispatch) => {
    dispatch(candidatesPendingAction());
    return ApiService
      .getCandidatesWithFilters(token, filters)
      .then((candidates) => {
        dispatch(candidatesDownloadedAction(candidates));
      })
      .catch((error) => {
        dispatch(candidatesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function linkCandidateWithPost(token, candidate, PostId) {
  return (dispatch) => {
    dispatch(candidateSendingAction());
    return ApiService
      .linkCandidateWithPost(token, candidate.id, PostId)
      .then((data) => {
        const updatedCandidate = {
          ...data,
          scoring: candidate.scoring,
          id: candidate.id,
        };
        dispatch(candidateDownloadedAction(updatedCandidate));
        return data.id
      })
      .catch((error) => {
        dispatch(candidatesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function unlinkCandidateWithPost(token, candidate, PostId) {
  return (dispatch) => {
    dispatch(candidateSendingAction());
    return ApiService
      .unlinkCandidateWithPost(token, candidate.id, PostId)
      .then((data) => {
        const updatedCandidate = {
          ...data,
          scoring: candidate.scoring,
          id: candidate.id,
        };
        dispatch(candidateDownloadedAction(updatedCandidate));
        return data.id
      })
      .catch((error) => {
        dispatch(candidatesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putCandidate(token, candidateId, candidate, silent = false) {
  return (dispatch) => {
    if (!silent) {
      dispatch(candidateSendingAction());
    }
    return ApiService
      .putCandidate(token, candidateId, candidate)
      .then((data) => {
        const updatedCandidate = {
          ...data,
          id: candidateId,
        };
        dispatch(candidateDownloadedAction(updatedCandidate));
        return updatedCandidate
      })
      .catch((error) => {
        dispatch(candidatesFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}
