import React from 'react';
import PropTypes from 'prop-types';
import withTitle from '../../hocs/withTitle';
import ClientContactForm from '../../components/forms/ClientContactForm';

export const ClientContactEditPage = props => (
    <ClientContactForm history={props.history} match={props.match} />
  );

ClientContactEditPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTitle({
  title: 'Contacts',
  subtitle: 'Éditer un Contact',
})(ClientContactEditPage);
